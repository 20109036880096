/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { useAuth } from '../../../../app/modules/auth'
import { toAbsoluteUrl } from '../../../helpers'
import clsx from 'clsx'
import { HeaderRequestPinMenu } from './HeadeRequestPinMenu'

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth()
  const [requestPinCode, setRequestPinCode] = useState<boolean>(false);

  const closeModal = () => {
    setRequestPinCode(false)
  }
  
  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-50px me-5'>
              <img src={currentUser?.image_128 ? 'data:image/png;base64,' + currentUser.image_128 : toAbsoluteUrl('/media/avatars/blank.png')} alt='user avatar' />
            </div>

            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                {currentUser?.name}
                <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>
              </div>
              <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                {currentUser?.partner_id?.email}
              </a>
            </div>
          </div>
        </div>

        <div className='separator my-2'></div>



        <div className='menu-item px-5'>
          <a className='menu-link px-5' onClick={()  =>{
            setRequestPinCode(true)
          }}>
            <div className={clsx('d-flex align-items-center')}>
              {/* begin::Menu- wrapper */}
              <div
                className={clsx('position-relative')}
                data-kt-menu-trigger='click'
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
              >
                Manage your PIN
                <span className='pulse-ring w-45px h-45px' />
              </div>
              {/* end::Menu wrapper */}
            </div>
          </a>
        </div>

        <div className='menu-item px-5'>
          <a onClick={logout} className='menu-link px-5'>
            Sign Out
          </a>
        </div>
      </div>
      {requestPinCode && <HeaderRequestPinMenu closeModal={function (): {} {
        setRequestPinCode(false)
        return closeModal
      }} />}
    </>
  )
}

export { HeaderUserMenu }
