import { AZKHelper } from 'app/modules/components/azkHelper'
import { AuthModel, ReportIssue } from './_models'
import { reportIssue } from './_requests'
// import React from 'react'

const AUTH_LOCAL_STORAGE_KEY = 'azk-auth-hr-lite'

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}
// let errorAccorded = React.createContext(true);
// export default errorAccorded;
export function setupAxios(axios: any) {

  const location = window.location
  const Swal = require('sweetalert2')
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: { headers: { 'Access-Token': string } }) => {
      const auth = getAuth()
      if (auth && auth.access_token) {
        config.headers['Access-Token'] = auth.access_token
      }

      return config
    },
    (err: any) => {
      Promise.reject(err)
    }
  )
  axios.interceptors.response.use(
    (response: any) => {
      return response
    },
    (err: any) => {
      if (err?.request?.status === 409) {
        document.body.classList.remove('page-loading')
        let errorMessage = err?.response?.data?.error_descrip
        var startIndex = err?.response?.data?.error_descrip.indexOf("Error(");
        var endIndex = err?.response?.data?.error_descrip.lastIndexOf(")");
        if (startIndex !== -1 && endIndex !== -1 && endIndex > startIndex) {
          errorMessage = errorMessage.substring(startIndex + 6, endIndex - 1).replace(/\\n/gi, '<br/>');
        }
        if (err?.response?.data?.error_descrip.indexOf('ValidationError') > 0 ){
        errorMessage = errorMessage + `
        <div class="col-12 mt-2">
        <h6>Please Enter your comment</h6>
        <textarea id="issue_comment" key="issue_comment" class="p-1 form-control">
        </textarea>
        </div>
        `
        Swal.fire({
          position: "top-right",
          title: 'Oops!',
          html: errorMessage,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Report Issue',
          icon: "error",
          iconColor: 'white',
          customClass: {
            popup: 'colored-toast'
          },
          timerProgressBar: true
        }).then((result) => {
          if (result.isConfirmed) {
            const comment = document.getElementById('issue_comment') as HTMLInputElement
            const issue: ReportIssue = new ReportIssue()
            issue.comment = comment.value
            issue.error_data = err?.response?.data
            issue.url = err.config.url
            issue.method = err.config.method
            issue.payload_data = err?.response?.config.data
            reportIssue(issue).then(res => {
              Swal.fire({
                position: "top-right",
                title: 'Sorry for the interuption!',
                text: 'Your issue was posted and will be handled ASAP!',
                icon: "success",
                showConfirmButton: false,
                timer: AZKHelper.SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                  popup: 'colored-toast'
                },
                timerProgressBar: true
              })
            })
          }
        })
      } else {
        errorMessage = errorMessage.replace("'", '');
        if (location.pathname.includes('/app/')){
          Swal.fire({
            position: "center",
            // title: 'Warning!',
            html: errorMessage,
            icon: "warning",
            iconColor: 'white',
            showConfirmButton: false,
            timer: AZKHelper.SWAL_TOAST_TIMER,
            // toast: true,
            customClass: {
              popup: 'colored-toast'
            },
            timerProgressBar: true
          })
        }else{
        Swal.fire({
          position: "top-right",
          title: 'Warning!',
          html: errorMessage,
          icon: "warning",
          iconColor: 'white',
          showConfirmButton: false,
          timer: AZKHelper.SWAL_TOAST_TIMER,
          toast: true,
          customClass: {
            popup: 'colored-toast'
          },
          timerProgressBar: true
        })
      }
      }
      } else if (err?.request?.status === 401) {
        document.location.reload()
        // if (auth && auth.refresh_token) {
        //   refresh(auth.refresh_token).then(() => {
        //   }).catch(e => {
        //     logoutR(auth.access_token)
        //   })
        // }
      }
      if (err && err.response && err.response.data && err.response.data.error && err.response.data.error_descrip) {
        err.message = err.response.data.error_descrip
        err.code = err.response.data.error
        err.response.data = err.response.data.error_descrip
      }
      throw err
    }
  )
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY }
