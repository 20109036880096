/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'


type Props = {
    modelName: string;
    current: any;
    currentName?: string;
    transactionId: number
    steps?: [{
        id?: number,
        name?: string
    }]
}

const AZKTimeLine: React.FC<Props> = ({ modelName, current, currentName, transactionId, steps }) => {
    return (
        <div className={`card`}>
            {/* begin::Header */}
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body pt-5'>
                {/* begin::Timeline */}
                <div className='timeline-label'>
                    {steps?.map((step, index) => (
                        <>
                            {/* begin::Item */}
                            <div className='timeline-item'>
                                {/* begin::Label */}
                                <div className='timeline-label fw-bold text-gray-800 fs-6'>{index + 1}</div>
                                {/* end::Label */}
                                {/* begin::Badge */}
                                <div className='timeline-badge'>
                                    <i className={`fa fa-genderless fs-1 ${step.name === currentName ? 'text-primary': 'text-light-warning'}`}></i> 
                                </div>
                                {/* end::Badge */}
                                {/* begin::Text */}
                                <div className='fw-mormal timeline-content text-muted ps-3'>
                                    {step.name}
                                </div>
                                {/* end::Text */}
                            </div>
                            {/* end::Item */}
                        </>
                    ))}
                </div>
                {/* end::Timeline */}
            </div>
            {/* end: Card Body */}
        </div>
    )
}

export { AZKTimeLine }
