import axios, {AxiosResponse} from 'axios'
import { ManagerAssignment, ManagerAssignmentQueryResponse } from './model'
import { ID, Response } from '_metronic/helpers'
import moment from 'moment'
import { AZKHelper } from 'app/modules/components/azkHelper'
import { API_URL } from '_azkatech/azkHelper' 
import useFixServerClientData from 'app/utilities/fix-Server-Client-Data'

const MODEL_NAME = 'az.trn.manager.assignment'
const URL = `${API_URL}/${MODEL_NAME}`
const GET_URL = `${API_URL}/${MODEL_NAME}/`
const INCLUDE_FIELDS = `'id', 'name', 'employee_id', 'type_id', 'x_state', 'x_state_view', 'can_review', 'can_write', 'can_delete', 'validated', 'rejected', 'restarted', 'az_country_id', 'az_company_id', 'payroll_id', 'brand_id', 'azadea_id', 'azadea_employee_name', 'branch_id', 'section_id', 'grade_id', 'job_id', 'salary', 'as_effective_date', 'time_set', ('employees', [('id','name', 'azadea_employee_name', 'as_effective_date', 'time_set', ('grade_id',('id','grade_name')), ('branch_id',('id','name')), ('job_id',('id','name')), ('brand_id',('id','name', 'brand_code')), ('az_country_id',('id','name')), ('az_company_id',('id','name')), ('payroll_id',('id','payroll_name')), ('section_id',('id','section')),('employment_category_id',('id','name', 'code')), 'azadea_id')]), 'target_as_effective_date', 'can_edit', 'current_approval_ids'`

// const getManagerAssignment = (domain: string): Promise<ManagerAssignmentQueryResponse> => {
//   return axios
//     .get(`${GET_URL}?filters=[${domain}]`)
//     .then((d: AxiosResponse<ManagerAssignmentQueryResponse>) => d.data)
// }

const GetManagerAssignmentById = (id: ID): Promise<ManagerAssignment> => {
  const { FixServerData} = useFixServerClientData();
  return axios
    .get(`${URL}?filters=[('id','=',${id})]&include_fields=[${INCLUDE_FIELDS}]`)
    .then((response: AxiosResponse<Response<ManagerAssignment>>) => response.data)
    .then((response: Response<ManagerAssignment>) => FixServerData(response['results'][0]))
}

const CreateManagerAssignment = async (transfer: ManagerAssignment): Promise<ManagerAssignment | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await  FixClientData(transfer)
  return axios
    .post(URL, shallowTransaction)
    .then((response: AxiosResponse<ManagerAssignment>) => response.data)
}

const UpdateManagerAssignment = async (transfer: ManagerAssignment): Promise<ManagerAssignment | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await  FixClientData(transfer)
  return axios
    .put(`${URL}/${transfer.id}`, shallowTransaction)
    .then((response: AxiosResponse<Response<ManagerAssignment>>) => response.data)
    .then((response: Response<ManagerAssignment>) => response.data)
}

const deleteManagerAssignment = (transactionId: number): Promise<ManagerAssignment | undefined> => {
  return axios
    .delete(`${URL}/${transactionId}`)
    .then((response: AxiosResponse<Response<ManagerAssignment>>) => response.data)
    .then((response: Response<ManagerAssignment>) => response.data)
}

export { GetManagerAssignmentById, CreateManagerAssignment, UpdateManagerAssignment, MODEL_NAME, deleteManagerAssignment}
function fixDate(transfer: ManagerAssignment) {
  let shallowTransaction = Object.assign({}, transfer);
  shallowTransaction.as_effective_date = moment(transfer.as_effective_date).utc(true)?.format(AZKHelper.BACKEND_DATE_FORMAT)
  shallowTransaction.target_as_effective_date = moment(transfer.target_as_effective_date).utc(true)?.format(AZKHelper.BACKEND_DATE_FORMAT)
  return shallowTransaction
}

