import moment from 'moment';
import useAESEncrypt from './AESEncrypt';
import { AZKHelper } from 'app/modules/components/azkHelper';

const useFixServerClientData = () => {

    const FixClientData = async (model: any, withDetails?: boolean) => {
        const { encryptAndEncode } = useAESEncrypt();
        let shallowModel = Object.assign({}, model);
        if (!isNaN(+model['salary'])) shallowModel.salary = encryptAndEncode(shallowModel.salary)
        if (!isNaN(+model['target_salary'])) shallowModel.target_salary = encryptAndEncode(shallowModel.target_salary)
        if (model['as_effective_date']) shallowModel.as_effective_date = moment(model.as_effective_date).utc(true)?.format(AZKHelper.BACKEND_DATE_FORMAT)
        if (model['target_as_effective_date']) shallowModel.target_as_effective_date = moment(model.target_as_effective_date).utc(true)?.format(AZKHelper.BACKEND_DATE_FORMAT)
        // Salary structure settings
        if (!isNaN(+model['package'])) shallowModel.package = encryptAndEncode(shallowModel.package)
        // Salary structure
        if (!isNaN(+model['sales'])) shallowModel.sales = encryptAndEncode(shallowModel.sales)
        if (!isNaN(+model['revenue_per_hour'])) shallowModel.revenue_per_hour = encryptAndEncode(shallowModel.revenue_per_hour)
        if (!isNaN(+model['sqm_employee'])) shallowModel.sqm_employee = encryptAndEncode(shallowModel.sqm_employee)
        if (!isNaN(+model['sc_distribution'])) shallowModel.sc_distribution = encryptAndEncode(shallowModel.sc_distribution)
        if (!isNaN(+model['distribution'])) shallowModel.distribution = encryptAndEncode(shallowModel.distribution)
        if (!isNaN(+model['total_points'])) shallowModel.total_points = encryptAndEncode(shallowModel.total_points)
        if (!isNaN(+model['pay_per_point'])) shallowModel.pay_per_point = encryptAndEncode(shallowModel.pay_per_point)
        if (!isNaN(+model['com_hc_salary'])) shallowModel.com_hc_salary = encryptAndEncode(shallowModel.com_hc_salary)
        if (!isNaN(+model['non_com_hc_salary'])) shallowModel.non_com_hc_salary = encryptAndEncode(shallowModel.non_com_hc_salary)
        if (!isNaN(+model['total_salary'])) shallowModel.total_salary = encryptAndEncode(shallowModel.total_salary)
        if (!isNaN(+model['ratio'])) shallowModel.ratio = encryptAndEncode(shallowModel.ratio)
        if (!isNaN(+model['sales_per_sqm'])) shallowModel.sales_per_sqm = encryptAndEncode(shallowModel.sales_per_sqm)
        if (!isNaN(+model['selling_area'])) shallowModel.selling_area = encryptAndEncode(shallowModel.selling_area)

        if (withDetails && model['detail_ids']) {
            const details = model['detail_ids']
            const promises = await details?.map(async (detail, index) => new Promise(async (resolve, reject) => {
                if (!isNaN(+detail['id'])) detail['id'] = undefined
                if (!isNaN(+detail['amount'])) detail['amount'] = encryptAndEncode(detail['amount'])
                if (!isNaN(+detail['package'])) detail['package'] = encryptAndEncode(detail['package'])
                if (!isNaN(+detail['commission_value'])) detail['commission_value'] = encryptAndEncode(detail['commission_value'])
                if (!isNaN(+detail['total_gross'])) detail['total_gross'] = encryptAndEncode(detail['total_gross'])
                resolve(detail)
            }));
            if (promises)
                await Promise.all(promises).then((details: any) => {
                    shallowModel['detail_ids'] = details
                })
            return shallowModel
        } else {
            return shallowModel
        }
    }

    const FixServerData = async (model: any, withDetails?: boolean) => {
        const { decodeAndDecrypt, decodeAndDecryptText } = useAESEncrypt();
        let shallowModel = Object.assign({}, model);
        if (model) {
            if (model['salary']) shallowModel.salary = decodeAndDecrypt(shallowModel.salary)
            if (model['target_salary']) shallowModel.target_salary = decodeAndDecrypt(shallowModel.target_salary)
            // Final settlement
            if (model['basic_salary']) shallowModel.basic_salary = decodeAndDecrypt(shallowModel.basic_salary)
            if (model['housing']) shallowModel.housing = decodeAndDecrypt(shallowModel.housing)
            if (model['other_allowance']) shallowModel.other_allowance = decodeAndDecrypt(shallowModel.other_allowance)
            // if (model['working_days']) shallowModel.working_days = decodeAndDecrypt(shallowModel.working_days)
            // if (model['salary_days']) shallowModel.salary_days = decodeAndDecrypt(shallowModel.salary_days)
            // if (model['housing_days']) shallowModel.housing_days = decodeAndDecrypt(shallowModel.housing_days)
            // if (model['transportation_days']) shallowModel.transportation_days = decodeAndDecrypt(shallowModel.transportation_days)
            if (model['commission']) shallowModel.commission = decodeAndDecrypt(shallowModel.commission)
            // if (model['notice_period_days']) shallowModel.notice_period_days = decodeAndDecrypt(shallowModel.notice_period_days)
            if (model['salary_notice_period']) shallowModel.salary_notice_period = decodeAndDecrypt(shallowModel.salary_notice_period)
            if (model['housing_notice_period']) shallowModel.housing_notice_period = decodeAndDecrypt(shallowModel.housing_notice_period)
            if (model['transportation_notice_period']) shallowModel.transportation_notice_period = decodeAndDecrypt(shallowModel.transportation_notice_period)
            // if (model['leave_days']) shallowModel.leave_days = decodeAndDecrypt(shallowModel.leave_days)
            if (model['leave_amount']) shallowModel.leave_amount = decodeAndDecrypt(shallowModel.leave_amount)
            // if (model['indemnity_days']) shallowModel.indemnity_days = decodeAndDecrypt(shallowModel.indemnity_days)
            if (model['indemnity_amount']) shallowModel.indemnity_amount = decodeAndDecrypt(shallowModel.indemnity_amount)
            if (model['net_to_pay']) shallowModel.net_to_pay = decodeAndDecrypt(shallowModel.net_to_pay)
            if (model['eos_extra_hours']) shallowModel.eos_extra_hours = decodeAndDecrypt(shallowModel.eos_extra_hours)
            if (model['eos_public_holiday']) shallowModel.eos_public_holiday = decodeAndDecrypt(shallowModel.eos_public_holiday)
            if (model['ticket_amount']) shallowModel.ticket_amount = decodeAndDecrypt(shallowModel.ticket_amount)
            if (model['proposed_salary_n']) shallowModel.proposed_salary_n = decodeAndDecrypt(shallowModel.proposed_salary_n)
            if (model['housing_all']) shallowModel.housing_all = decodeAndDecrypt(shallowModel.housing_all)
            if (model['trans_all']) shallowModel.trans_all = decodeAndDecrypt(shallowModel.trans_all)
            if (model['eos_settlement']) shallowModel.eos_settlement = decodeAndDecrypt(shallowModel.eos_settlement)
            if (model['annual_leave_settlement']) shallowModel.annual_leave_settlement = decodeAndDecrypt(shallowModel.annual_leave_settlement)
            if (model['ticket_encashment_settlement']) shallowModel.ticket_encashment_settlement = decodeAndDecrypt(shallowModel.ticket_encashment_settlement)
            if (model['commission_settlement']) shallowModel.commission_settlement = decodeAndDecrypt(shallowModel.commission_settlement)
            if (model['paid_amount1']) shallowModel.paid_amount1 = decodeAndDecrypt(shallowModel.paid_amount1)
            if (model['paid_amount2']) shallowModel.paid_amount2 = decodeAndDecrypt(shallowModel.paid_amount2)
            if (model['other_deduction_amount']) shallowModel.other_deduction_amount = decodeAndDecrypt(shallowModel.other_deduction_amount)
            if (model['other_addition_amount']) shallowModel.other_addition_amount = decodeAndDecrypt(shallowModel.other_addition_amount)
            if (model['bankname']) shallowModel.bankname = decodeAndDecryptText(shallowModel.bankname)
            if (model['iban_number']) shallowModel.iban_number = decodeAndDecryptText(shallowModel.iban_number)
            if (model['bankcode']) shallowModel.bankcode = decodeAndDecrypt(shallowModel.bankcode)
            if (model['swift_code']) shallowModel.swift_code = decodeAndDecrypt(shallowModel.swift_code)
            // Salary structure settings
            if (model['package']) shallowModel.package = decodeAndDecrypt(shallowModel.package)
            // Salary structure
            if (model['sales']) shallowModel.sales = decodeAndDecrypt(shallowModel.sales)
            if (model['revenue_per_hour']) shallowModel.revenue_per_hour = decodeAndDecrypt(shallowModel.revenue_per_hour)
            // if (model['sc_distribution']) shallowModel.sc_distribution = decodeAndDecrypt(shallowModel.sc_distribution)
            if (model['distribution']) shallowModel.distribution = decodeAndDecrypt(shallowModel.distribution)
            if (model['total_points']) shallowModel.total_points = decodeAndDecrypt(shallowModel.total_points)
            if (model['pay_per_point']) shallowModel.pay_per_point = decodeAndDecrypt(shallowModel.pay_per_point)
            if (model['sales_per_sqm']) shallowModel.sales_per_sqm = decodeAndDecrypt(shallowModel.sales_per_sqm)
            if (model['sqm_employee']) shallowModel.sqm_employee = decodeAndDecrypt(shallowModel.sqm_employee)
            if (model['total_salary']) shallowModel.total_salary = decodeAndDecrypt(shallowModel.total_salary)
            if (model['ratio']) shallowModel.ratio = decodeAndDecrypt(shallowModel.ratio)
            if (model['selling_area']) shallowModel.selling_area = decodeAndDecrypt(shallowModel.selling_area)

            if (withDetails && model['detail_ids']) {
                const details = model['detail_ids']
                const promises = await details?.map(async (detail, index) => new Promise(async (resolve, reject) => {
                    if (detail['amount']) detail['amount'] = decodeAndDecrypt(detail['amount'])
                    if (detail['package']) detail['package'] = decodeAndDecrypt(detail['package'])
                    if (detail['commission_value']) detail['commission_value'] = decodeAndDecrypt(detail['commission_value'])
                    if (detail['total_gross']) detail['total_gross'] = decodeAndDecrypt(detail['total_gross'])
                    resolve(detail)
                }));
                if (promises)
                    await Promise.all(promises).then((details: any) => {
                        shallowModel['detail_ids'] = details
                    })
                return shallowModel
            } else {
                return shallowModel
            }
        } else {
            return model
        }
    }


    const FixClientArrayData = async (models: any[]) => {
        let shallowModels: any[] = []
        if (models && models.length > 0) {
            const promises = await models?.map(async (model) => new Promise(async (resolve, reject) => {
                const shallowModel = await FixClientData(model)
                resolve(shallowModel)
            }));
            if (promises)
                await Promise.all(promises).then((shallowModel: any) => {
                    shallowModels.push(shallowModel)
                })
                return shallowModels
        } else {
            return shallowModels
        }
    }

    const FixServerArrayData = async (models: any[]) => {
        let shallowModels: any[] = []
        if (models && models.length > 0) {
            const promises = await models?.map(async (model) => new Promise(async (resolve, reject) => {
                const shallowModel = await FixServerData(model)
                resolve(shallowModel)
            }));
            if (promises)
                await Promise.all(promises).then((shallowModel: any) => {
                    shallowModels = shallowModel
                })
                return shallowModels
        } else {
            return shallowModels
        }
    }
    return {
        FixClientData,
        FixServerData,
        FixClientArrayData,
        FixServerArrayData
    };
};

export default useFixServerClientData;