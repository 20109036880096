import axios, { AxiosResponse } from 'axios'
import {AuthModel, ReportIssue, UserModel} from './_models'
import { API_URL } from '_azkatech/azkHelper'

export const LOGIN_URL = `${API_URL}/auth/get_tokens`
export const LOGOUT_URL = `${API_URL}/auth/delete_tokens`
export const REFRESH_URL = `${API_URL}/auth/refresh_token`
export const REGISTER_URL = `${API_URL}/auth/register`
export const OAUTH_PROVIDERS = `${API_URL}/auth/get_oauth_providers`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.get<AuthModel>(LOGIN_URL, {params: {
    username: email,
    password: password,
    access_lifetime: 7200
  }})
}

export function refresh(refresh_token: string) {
  return axios.post<AuthModel>(REFRESH_URL, {
    refresh_token: refresh_token,
  })
}

export function logoutR(refresh_token: string) {
  return axios.post(LOGOUT_URL, {
    refresh_token: refresh_token,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(auth: AuthModel) {
  return axios.get<UserModel>(`${API_URL}/res.users?filters=[('id','=',${auth.uid})]&include_fields=['id','name', 'login', 'image_128', 'groups_id', ('partner_id',('id','email','is_company','company_type','parent_id')), ('company_id',('id','name','logo'))]`).then((result:any)=>{
    if(result.data && result.data.results && result.data.count>0){
      return  result.data.results[0]
    }else return undefined
  })
}

export function reportIssue(issue: ReportIssue) {
  return axios.post(`${API_URL}/az.report.issue`, issue)
  .then((response: AxiosResponse<ReportIssue>) => response.data)
}

export function getOauthProviders() {
  return axios.get(`${OAUTH_PROVIDERS}`,{params: {}})
  .then((response: AxiosResponse<ReportIssue>) => response.data)
}