import { Response } from "_metronic/helpers"

export type Promotion = {
  id?: number
  name?: string
  employee_id?: any
  type_id?: number
  x_state?: string
  x_state_view?: string
  can_review?: boolean
  can_delete?: boolean
  can_write?: boolean
  validated?: boolean, 
  rejected?: boolean,
  state?: string
  az_country_id?: any
  az_company_id?: any
  payroll_id?: any
  brand_id?: any
  azadea_id?: number
  azadea_employee_name?: string
  branch_id?: any
  section_id?: any
  grade_id?: any
  job_id?: any
  salary?: string
  as_effective_date?: string
  time_set?: string
  target_salary?: string
  target_as_effective_date?: string
  can_edit?: boolean
  restarted?: boolean
  target_job_id?: any
  target_grade_id?: any
  current_approval_ids?: number[]
}

export const initialTransfaction: Promotion = {
  name: '',
}

export type PromotionQueryResponse = Response<Array<Promotion>>

export const initialTransferCompany: Promotion = {

}
