import axios, {AxiosResponse} from 'axios'
import { Separation, SeparationQueryResponse } from './model'
import { ID, Response } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper' 
import useFixServerClientData from 'app/utilities/fix-Server-Client-Data'

const MODEL_NAME = 'az.trn.separation'
const URL = `${API_URL}/${MODEL_NAME}`
const GET_URL = `${API_URL}/${MODEL_NAME}/`
const INCLUDE_FIELDS = `'id', 'name', 'employee_id', 'type_id', 'x_state', 'x_state_view', 'can_review', 'can_write', 'can_delete', 'validated', 'rejected', 'restarted', 'az_country_id', 'az_company_id', 'payroll_id', 'brand_id', 'azadea_id', 'azadea_employee_name', 'branch_id', 'section_id', 'grade_id', 'job_id', 'salary', 'as_effective_date', 'time_set', 'target_as_effective_date', 'can_edit', 'resone_id', 'actual_termination_date', 'current_approval_ids', 'no_rehire', 'back_dated_reason', 'back_dated_comment', 'exit_interview_answer', 'exit_interview_sub_answer'`


const GetSeparationById = (id: ID): Promise<Separation> => {
  const { FixServerData} = useFixServerClientData();
  return axios
    .get(`${URL}?filters=[('id','=',${id})]&include_fields=[${INCLUDE_FIELDS}]`)
    .then((response: AxiosResponse<Response<Separation>>) => response.data)
    .then((response: Response<Separation>) => FixServerData(response['results'][0]))
}

const CreateSeparation = async (transfer: Separation): Promise<Separation | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transfer)
  shallowTransaction.target_as_effective_date = shallowTransaction.actual_termination_date
  return axios
    .post(URL, shallowTransaction)
    .then((response: AxiosResponse<Separation>) => response.data)
}

const UpdateSeparation = async (transfer: Separation): Promise<Separation | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transfer)
  shallowTransaction.target_as_effective_date = shallowTransaction.actual_termination_date
  return axios
    .put(`${URL}/${transfer.id}`, shallowTransaction)
    .then((response: AxiosResponse<Response<Separation>>) => response.data)
    .then((response: Response<Separation>) => response.data)
}


const DeleteSeparation = (transactionId: number): Promise<Separation | undefined> => {
  return axios
    .delete(`${URL}/${transactionId}`)
    .then((response: AxiosResponse<Response<Separation>>) => response.data)
    .then((response: Response<Separation>) => response.data)
}
export { GetSeparationById, CreateSeparation, UpdateSeparation, MODEL_NAME, DeleteSeparation}


