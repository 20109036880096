import { PageLink, PageTitle } from '_metronic/layout/core'
import { Outlet, Route, Routes } from 'react-router-dom'
// import Transactionform from './form'
import TransactionAPPform from './app-form'
// import TransactionAppListView from './app-list'
// import TransactionAppform from './app-form'

const transactionBreadCrumbs: Array<PageLink> = [
  {
    title: 'Exit Interview Request',
    path: '/app/self-service/exit-interview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Exit Interview Request',
    path: '/app/self-service/exit-interview',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]


const ExitInterViewRequestAPPRouter = () => (
  <Routes>
    <Route
      element={
        <>
          <Outlet />
        </>
      }
    >
      <Route
        path=''
        element={
          <>
            <PageTitle breadcrumbs={transactionBreadCrumbs}></PageTitle>
            <TransactionAPPform />
          </>
        }
      />
    </Route>
  </Routes>
)

export default ExitInterViewRequestAPPRouter
