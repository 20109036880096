import { PageLink, PageTitle } from '_metronic/layout/core'
import { Outlet, Route, Routes } from 'react-router-dom'
import Transactionform from './form'
import TransactionList from './list'
import TransactionViewform from './view'
import DetailedReportView from './list-detailed_report'
import SummaryReportView from './summary _report'
import DailyAttendanceReportView from './daily_attendance_report'

const transactionBreadCrumbs: Array<PageLink> = [
  {
    title: 'Attendance',
    path: '/self-service/attendance',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Attendance',
    path: '/self-service/attendance',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AttendanceAdjustmentRouter = () => (
  <Routes>
    <Route
      element={
        <>
          <Outlet />
        </>
      }
    >
      <Route
        path=''
        element={
          <>
            <PageTitle breadcrumbs={transactionBreadCrumbs}>List</PageTitle>
            <TransactionList />
          </>
        }
      />
      <Route
        path=':id/view'
        element={
          <>
            <PageTitle breadcrumbs={transactionBreadCrumbs}>Edit</PageTitle>
            <TransactionViewform />
          </>
        }
      />
      <Route
        path='new'
        element={
          <>
            <PageTitle breadcrumbs={transactionBreadCrumbs}>New</PageTitle>
            <Transactionform />
          </>
        }
      />
      <Route
        path='detailed-report'
        element={
          <>
            <PageTitle breadcrumbs={transactionBreadCrumbs}>Detailed Report</PageTitle>
            <DetailedReportView />
          </>
        }
      />
      <Route
        path='summary-report'
        element={
          <>
            <PageTitle breadcrumbs={transactionBreadCrumbs}>Summary Report</PageTitle>
            <SummaryReportView />
          </>
        }
      />
      <Route
        path='daily-attendance-report'
        element={
          <>
            <PageTitle breadcrumbs={transactionBreadCrumbs}>Daily Attendance</PageTitle>
            <DailyAttendanceReportView />
          </>
        }
      />
    </Route>
  </Routes>
)

export default AttendanceAdjustmentRouter
