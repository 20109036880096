import AzkSelect from "_azkatech/azkSelect";
import { EmployeeDetail, EmpoyeeModel } from "app/modules/components/employeeDetail/employeeDetail";
import axios from "axios";
import * as Yup from 'yup'
import { Clearance, ClearanceElement, ClearanceGroupNodues, initialTransaction } from "./model";
import { FC, useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik, FormikProps, FormikValues } from 'formik'
import { UpdateClearance, GetClearanceById, MODEL_NAME } from "./_requests";
import moment from "moment";
import './style.scss'
import "react-datepicker/dist/react-datepicker.css";
import { AzkStatusRibbon } from "_azkatech/azkStatusRibbon";
import { Form, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { ID, isNotEmpty, KTIcon } from "_metronic/helpers";
import { useLocation, useNavigate } from "react-router";
import { AZKHelper } from "app/modules/components/azkHelper";
import { AzkChatter } from "_azkatech";
import { GrouphModel } from "_azkatech/_models/_group";
import FinalSettlementParametersModal from "../final-settlement-parameters/form";
import { checkTrnsactionTypeAccessId } from "app/utilities/transactionTyperequests";
import { useParams } from "react-router-dom";
import { GetEmployeeById } from "app/modules/components/employeeDetail/_requests";
import { API_URL } from "_azkatech/azkHelper";

type Props = {
}
const editTransactionSchema = Yup.object().shape({
    employee_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Employee is required'),
})

type LocationTransactionProps = {
    groups: GrouphModel[]
};
const Transactionform: FC<Props> = () => {
    const { id } = useParams();
    const Swal = require('sweetalert2')
    const intl = useIntl();
    const navigate = useNavigate()
    const location = useLocation();
    const state = location.state as LocationTransactionProps;
    const formRef = useRef<FormikProps<Clearance>>(null);
    const [elementDomain, setElementDomain] = useState('');
    const [selectedGroup, setSelectedGroup] = useState<number>();
    const [selectedGroupNoDues, setSelectedGroupNoDues] = useState<boolean>(false);
    const [showNoDues, setShowNoDues] = useState<boolean>(false);
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [transactionClosed, setTransactionClosed] = useState<boolean>(false);
    const [canValidate, setCanValidate] = useState<boolean>(false);
    const [canDelete, setCanDelete] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [needSubmit, setNeedSubmit] = useState<boolean>(false);
    const [employee, setEmployee] = useState<EmpoyeeModel>();
    const [transactionState, setTransactionState] = useState<string>('draft');
    const [transactionStateName, setTransactionStateName] = useState<string>('Draft');
    const [hitSend, setHitSend] = useState<boolean>(false);
    const [needFinalSettelement, setNeedFinalSettelement] = useState<boolean>(false);
    const [objectId, setObjectId] = useState<number>();
    const [disabledOptions, setDisabledOptions] = useState<any[]>([]);

    const handleEmployeeChange = async function (employeeId, changeClearanceData): Promise<{}> {
        document.body.classList.add('page-loading')
        if (employeeId) {
            setEmployee(undefined)
            GetEmployeeById(employeeId).then(response => {
                if (response) {
                    const tempEmployee = response
                    if (id || !tempEmployee.has_running_transaction) {
                        response.salary = undefined
                        setEmployee(response)
                        setElementDomain(tempEmployee.az_country_id ? `('country_id','=', ${tempEmployee.az_country_id?.id})` : '');
                        formRef.current?.setValues({
                            employee_id: tempEmployee.id,
                            az_country_id: tempEmployee.az_country_id?.id,
                            az_company_id: tempEmployee.az_company_id?.id,
                            payroll_id: tempEmployee.payroll_id?.id,
                            branch_id: tempEmployee.branch_id?.id,
                            brand_id: tempEmployee.brand_id?.id,
                            grade_id: tempEmployee.grade_id?.id,
                            section_id: tempEmployee.section_id?.id,
                            job_id: tempEmployee.job_id?.id,
                            as_effective_date: moment(tempEmployee.as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT),
                            time_set: tempEmployee.time_set,
                            // ---------------------------------------------------
                            target_as_effective_date: formRef.current.values.target_as_effective_date ? moment(formRef.current.values.target_as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT) : moment().format(AZKHelper.FRONTEND_DATE_FORMAT),
                        })
                        if (changeClearanceData) pobulateFormData(changeClearanceData)
                        document.body.classList.remove('page-loading')
                    } else {
                        document.body.classList.remove('page-loading')
                        Swal.fire({
                            position: "top-right",
                            title: 'Sorry!',
                            text: 'This employee ' + tempEmployee.name + ' already has an open transaction with details ' + tempEmployee.running_transaction + '!',
                            icon: "error",
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            iconColor: 'white',
                            customClass: {
                                popup: 'colored-toast'
                            },
                            timerProgressBar: true
                        })
                    }
                } else {
                    document.body.classList.remove('page-loading')
                }
            });
        } else {
            document.body.classList.remove('page-loading')
            formRef.current?.resetForm();
            setEmployee(undefined)
            formRef.current?.setValues({
                employee_id: undefined,
                az_country_id: undefined,
                az_company_id: undefined,
                payroll_id: undefined,
                branch_id: undefined,
                brand_id: undefined,
                grade_id: undefined,
                section_id: undefined,
                job_id: undefined,
                as_effective_date: undefined,
                time_set: undefined,
            })
        }
        return true
    }

    const pobulateFormData = async (data?: Clearance) => {
        if (data) {
            formRef.current?.setValues({
                id: data.id,
                employee_id: data.employee_id?.id,
                az_country_id: data.az_country_id?.id,
                az_company_id: data.az_company_id?.id,
                payroll_id: data.payroll_id?.id,
                branch_id: data.branch_id?.id,
                brand_id: data.brand_id?.id,
                grade_id: data.grade_id?.id,
                section_id: data.section_id?.id,
                job_id: data.job_id?.id,
                as_effective_date: moment(data.as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT),
                time_set: data.time_set,
                azadea_employee_name: data.azadea_employee_name,
                element_ids: data.element_ids,
                clearance_group_nodue: data.clearance_group_nodue,
                allowed_group_ids: data.allowed_group_ids,
                can_override: data.can_override,
                target_as_effective_date: moment(data?.target_as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT),
            })
            state.groups = []
            if (data.allowed_group_ids && data.allowed_group_ids.length > 0) {
                state.groups = data.allowed_group_ids
                const group = data.allowed_group_ids[0].id
                setSelectedGroup(group)
                // handleGroupChange(data.allowed_group_ids[0])
                await data.clearance_group_nodue?.forEach(groupdNoDues => {
                    if (groupdNoDues.group_id === group && groupdNoDues.no_dues) {
                        setSelectedGroupNoDues(groupdNoDues.no_dues)
                    }
                })
                setShowNoDues(true)
            }
            handleDisabledOptions()
        }
    }

    const checkPermission = async (action: string) => {
        const allowed = await checkTrnsactionTypeAccessId(MODEL_NAME, action)
        if (!allowed) {
            if (id) {
                const allowed_read = await checkTrnsactionTypeAccessId(MODEL_NAME, 'read')
                if (!allowed_read) {
                    navigate(`/transactions/`, { state: {}, replace: true });
                    Swal.fire({
                        position: "top-right",
                        title: 'Access Error!',
                        text: 'You does not have access to perform read action!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                } else {
                    setReadOnly(true)
                }
            } else {
                navigate(`/transactions/`, { state: {}, replace: true });
                Swal.fire({
                    position: "top-right",
                    title: 'Access Error!',
                    text: 'You does not have access to perform this action!',
                    icon: "error",
                    showConfirmButton: false,
                    timer: AZKHelper.SWAL_TOAST_TIMER,
                    toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
            }
        }
    }

    useEffect(() => {
        document.body.classList.add('page-loading')
        formRef.current?.resetForm()
        setObjectId(undefined)
        setCanValidate(false)
        handleEmployeeChange(undefined, undefined)
        if (id) {
            setObjectId(parseInt(id))
            checkPermission('write');
            document.body.classList.add('page-loading')
            GetClearanceById(parseInt(id)).then(((data: Clearance) => {
                setNeedFinalSettelement(data?.az_country_id?.due_date && data?.az_country_id?.due_date != null && data?.az_country_id?.due_date >= 0)
                handleEmployeeChange(data?.employee_id?.id, data)
                if (data?.x_state && data?.x_state_view) {
                    setTransactionState(data?.x_state)
                    setTransactionStateName(data?.x_state_view)
                }
                if (data?.can_write && data?.can_write !== null) {
                    setCanEdit(data?.can_write)
                }
                setTransactionClosed(data.validated || data.rejected ? true : false)
                setCanValidate(data?.can_review ? true : false)
                setCanDelete(data?.can_delete ? true : false)
            }))
        } else {
            checkPermission('create');
            formRef.current?.setFieldValue('employee_id', undefined)
            handleEmployeeChange(undefined, undefined)
            setCanEdit(true)
            document.body.classList.remove('page-loading')
        }
    }, [id])

    const cancel = () => {
        navigate(`/transactions/`, { state: {}, replace: true });
    }

    const handleResultOfsubmit = (transactionId: ID | undefined, isUpdate: boolean | false) => {
        if (needSubmit && transactionId) {
            submitTransaction(transactionId)
        } else {
            navigate(`/transactions/`, { state: {}, replace: true })
            Swal.fire({
                position: "top-right",
                title: 'Success!',
                text: isUpdate ? 'Your transaction had been updated!' : 'Your transaction had been saved!',
                icon: "success",
                showConfirmButton: false,
                timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        }
    }

    const hasNoDues = async (group_id) => {
        let noDuesGroups: ClearanceGroupNodues[] = formRef.current?.getFieldProps('clearance_group_nodue').value
        if (!noDuesGroups) noDuesGroups = []
        const matchingGroup = noDuesGroups.find(group => group.group_id === group_id);
        return (matchingGroup && matchingGroup.no_dues) || false;
    };

    const hasProperElements = async (group_id) => {
        let elements: ClearanceElement[] = formRef.current?.getFieldProps('element_ids').value
        if (!elements) elements = []
        const filteredElements = await elements.filter(element => element.group_id === group_id)
        if (!filteredElements || filteredElements.length === 0) return false
        return filteredElements.length > 0 && filteredElements.every(item => item.element_id && item.amount && item.amount > 0);
    };

    const checkElement = async () => {
        if (state?.groups && state?.groups.length > 0) {
            const promises = await state?.groups?.map(async (group, index) => new Promise(async (resolve, reject) => {
                const canSave = await hasNoDues(group.id).then(val => {
                    if (val) return true;
                    else return false;
                }) || hasProperElements(group.id)
                resolve(canSave)
            }));
            if (promises)
                return Promise.all(promises).then((canSave: any) => {
                    if (canSave.includes(false)) return false
                    else return true
                })
        } else {
            return false
        }
    }

    const submitTransaction = (transactionId: number) => {
        setNeedSubmit(false)
        axios.put(`${API_URL}/${MODEL_NAME}/${transactionId}/request_validation`, '{}').then(data => {
            document.body.classList.remove('page-loading')
            navigate(`/transactions/`, { state: {}, replace: true });
            Swal.fire({
                position: "top-right",
                title: 'Success!',
                text: 'Your transaction had been submited!',
                icon: "success",
                showConfirmButton: false,
                timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        })
    }

    const onSubmitHandler = async (values: Clearance, actions: FormikValues) => {
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        if (formRef.current?.isValid) {
            checkElement().then(async (canSave) => {
                if (canSave) {
                    if (isNotEmpty(values.id)) {
                        await UpdateClearance(values).then(data => {
                            if (id) handleResultOfsubmit(Number.parseInt(id), true)
                        })
                    } else {
                    }
                } else {
                    Swal.fire({
                        position: "top-right",
                        title: 'Error!',
                        text: 'Please fill the list of elements properly!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                }
            })
        } else {
            actions.setSubmitting(false)
            formRef.current?.validateForm().then(errors => {
                if (Object.keys(errors).length > 0) {
                    console.log(errors)
                }
            });
        }
    }

    const handleGroupChange = async (group: GrouphModel) => {
        setSelectedGroup(group.id)
        setShowNoDues(false)
        hasNoDues(group.id).then(val => {
            setShowNoDues(true)
            if (val) setSelectedGroupNoDues(true)
            else setSelectedGroupNoDues(false)
        }
        )
    }

    const replaceElementObject = async (element: ClearanceElement, index: number) => {
        let elements: ClearanceElement[] = formRef.current?.getFieldProps('element_ids').value
        if (!elements) elements = []
        // replace the element object
        await elements.map((d, i) => {
            if (i === index) {
                return element;
            } else {
                return d;
            }
        });
        formRef.current?.setFieldValue('element_ids', elements)
        handleDisabledOptions()
    }

    const handleDeleteElement = async (detail: ClearanceElement, index: number) => {
        detail.creation_info = undefined
        detail.group_id = undefined
        detail.az_country_id = undefined
        detail.element_id = undefined
        detail.amount = undefined
        detail.comment = undefined
        detail.override_amount = undefined
        detail.override_comment = undefined
        detail.pay_in_payroll = undefined
        let elements: ClearanceElement[] = formRef.current?.getFieldProps('element_ids').value
        if (!elements) elements = []
        // replace the element object
        elements = await elements.filter((d, i) => i !== index);
        formRef.current?.setFieldValue('element_ids', elements)
        handleDisabledOptions()
    }

    const handleDisabledOptions = () => {
        let elements: ClearanceElement[] = formRef.current?.getFieldProps('element_ids').value
        if (!elements) elements = []
        const disabledOptionsList: any[] = []
        elements.forEach(element => {
            if (!disabledOptionsList[element.group_id]) disabledOptionsList[element.group_id] = []
            disabledOptionsList[element.group_id].push(element.element_id)
        })
        setDisabledOptions(disabledOptionsList)
    }

    const handleAddElement = () => {
        const detail: ClearanceElement = {}
        detail.creation_info = undefined
        detail.group_id = selectedGroup
        detail.az_country_id = formRef.current?.getFieldProps('az_country_id').value
        detail.element_id = undefined
        detail.amount = undefined
        detail.comment = undefined
        detail.override_amount = undefined
        detail.override_comment = undefined
        detail.pay_in_payroll = undefined
        let elements: ClearanceElement[] = formRef.current?.getFieldProps('element_ids').value
        if (!elements) elements = []
        elements.push(detail)
        formRef.current?.setFieldValue('element_ids', elements)
        handleDisabledOptions()
    }
    const check_clearance_element = async (is_element: boolean, noDues: boolean = false) => {
        let noDuesGroups: ClearanceGroupNodues[] = formRef.current?.getFieldProps('clearance_group_nodue').value
        let elements: ClearanceElement[] = formRef.current?.getFieldProps('element_ids').value
        let can_override: boolean = formRef.current?.getFieldProps('can_override').value
        if (can_override && noDues && (elements?.length === 0 || (noDuesGroups && noDuesGroups?.filter(group => group.no_dues == true).length >  0))) {
            Swal.fire({
                position: "center",
                title: "Clearance not completed",
                text: "There was no response from any of the group(s) for this clearance yet. Do you want to proceed?",
                showCancelButton: true,
                confirmButtonText: 'Confirm',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    if (is_element) {
                        handleAddElement()
                    } else {
                        handleNoDues(noDues)
                    }
                } else if (result.isDenied) {
                }
            })
        } else {
            if (is_element) {
                handleAddElement()
            } else {
                handleNoDues(noDues)
            }
        }
    }
    const handleNoDues = async (noDues: boolean) => {
        setShowNoDues(true)
        setSelectedGroupNoDues(noDues)
        let noDuesGroups: ClearanceGroupNodues[] = formRef.current?.getFieldProps('clearance_group_nodue').value
        if (!noDuesGroups) noDuesGroups = []
        let found = false
        await noDuesGroups?.forEach(groupdNoDues => {
            if (groupdNoDues.group_id === selectedGroup) {
                found = true
                groupdNoDues.no_dues = noDues
            }
        })
        if (!found) {
            const detail: ClearanceGroupNodues = {}
            detail.group_id = selectedGroup
            detail.az_country_id = formRef.current?.getFieldProps('az_country_id').value
            detail.no_dues = noDues
            noDuesGroups.push(detail)
        }
        formRef.current?.setFieldValue('clearance_group_nodue', noDuesGroups)
        // if (noDues){
        //     let elements: ClearanceElement[] = formRef.current?.getFieldProps('element_ids').value
        //     if (!elements) elements = []
        //     const filteredElements = elements.filter(element => element.group_id !== selectedGroup)
        //     formRef.current?.setFieldValue('element_ids', filteredElements)
        // }
        handleDisabledOptions()
    }

    const closeModal = () => {
        setNeedSubmit(false)
    }

    return (
        <>
            <Row className="m-0 my-2">
                <Col md='12' lg={id ? '9' : '9'} xl={id ? '9' : '9'}>
                    <Formik
                        innerRef={formRef}
                        validationSchema={editTransactionSchema}
                        onSubmit={onSubmitHandler}
                        initialValues={initialTransaction}
                        validateeOnChange={true}
                        validateOnMount={true}
                        initialErrors={''}
                    >
                        {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
                            // ,validateForm,isSubmitting, errors, touched, isValidating
                            return (
                                <>
                                    <Form onSubmit={handleSubmit} noValidate>
                                        {employee && (
                                            <>
                                                <EmployeeDetail employee={employee}
                                                    classcss='mt-0' />
                                                <Row className="m-0 card">
                                                    <h4 className="py-2 px-6 m-0 card-header custom-card-header justify-content-start">
                                                        <i className="fa fa-info-circle" aria-hidden="true"></i>Clearance Details</h4>
                                                    <fieldset disabled={!canEdit || readOnly || transactionClosed}>
                                                        <Row className="m-0 my-2">
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Effective date
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        type='date'
                                                                        onChange={(value) => {
                                                                            setFieldValue('target_as_effective_date', value.target.value)
                                                                        }}
                                                                        disabled={!canEdit}
                                                                        value={moment(values.target_as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                    />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='target_as_effective_date' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </fieldset>
                                                    <Row className="m-0 my-2">
                                                        <Col lg="12" md="12" sm="12" xs="12" className="d-flex clearance-element-tabs">
                                                            {(state?.groups) ?
                                                                (state?.groups.map((group) => {
                                                                    return (
                                                                        <>
                                                                            <button disabled={false}
                                                                                className={`btn btn-light mx-1 px-2 ${group.id === selectedGroup ? "active" : ""}`}
                                                                                type='reset'
                                                                                onClick={() => handleGroupChange(group)}>{group.name.replace('HR Lite / ', '')}</button>
                                                                        </>
                                                                    )
                                                                })) : (
                                                                    <></>
                                                                )}
                                                        </Col>
                                                    </Row>
                                                    <fieldset disabled={!canEdit || readOnly || transactionClosed}>
                                                        {selectedGroup ? (
                                                            <Row className="m-0 my-2">
                                                                <Row >
                                                                    <Col xl="4" md="6">
                                                                        <button disabled={selectedGroupNoDues}
                                                                            type="reset"
                                                                            onClick={() => { check_clearance_element(true) }}
                                                                            className='btn btn-light btn-light-info p-1 px-2 btn-sm'
                                                                        ><i className="fa fa-plus p-0"></i> Add Element</button>
                                                                    </Col>
                                                                    <Col xl="4" md="6">
                                                                        {showNoDues && <Form.Check className="py-1"
                                                                            key={`no_dues`}
                                                                            label="No Dues"
                                                                            name={`no_dues`}
                                                                            onChange={(value) => {
                                                                                check_clearance_element(false, value.target.checked)
                                                                            }}
                                                                            id={`no_dues_` + selectedGroup}
                                                                            defaultChecked={selectedGroupNoDues}
                                                                            // checked={selectedGroupNoDues}
                                                                        />
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                                <fieldset disabled={selectedGroupNoDues || readOnly || transactionClosed}>
                                                                    <div className=''> {/* table-responsive */}
                                                                        <table
                                                                            id='kt_table_users'
                                                                            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                                                            <thead>
                                                                                <tr className='text-start text-muted fw-bold fs-8 text-uppercase gs-0'>
                                                                                    <th>Element</th>
                                                                                    <th>Name</th>
                                                                                    <th>Amount</th>
                                                                                    <th>Comment</th>
                                                                                    {values.can_override ? (
                                                                                        <><th>Override Amount</th>
                                                                                            <th>Override Comment</th></>) : (<></>)}

                                                                                    <th>Pay in payroll</th>
                                                                                    <th></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className='text-gray-600 fs-8'>
                                                                                {(values && values.element_ids && values.element_ids.length > 0) ? (values.element_ids.map((element, index) => {
                                                                                    return (
                                                                                        <>
                                                                                            {(element.group_id === selectedGroup) ? (
                                                                                                <>
                                                                                                    <tr>
                                                                                                        <td className="py-2">
                                                                                                            <Form.Group className='mb-2'>
                                                                                                                <AzkSelect
                                                                                                                    compID={`az_clearance_element-` + index}
                                                                                                                    modelName="az.clearance.elements"
                                                                                                                    valueField="id"
                                                                                                                    titleField="element_name"
                                                                                                                    dataDomain={elementDomain}
                                                                                                                    isMultiple={false}
                                                                                                                    allowEmpty={true}
                                                                                                                    fieldName={`element_id-` + index}
                                                                                                                    disabledOptions={disabledOptions[selectedGroup]}
                                                                                                                    disabled={!canEdit || selectedGroupNoDues}
                                                                                                                    onChange={(value) => {
                                                                                                                        element.element_id = parseInt(value)
                                                                                                                        replaceElementObject(element, index)
                                                                                                                    }}
                                                                                                                    defaultValue={element.element_id} />
                                                                                                                <div className='text-danger'>
                                                                                                                    <ErrorMessage name='element_id' />
                                                                                                                </div>
                                                                                                            </Form.Group>
                                                                                                        </td>
                                                                                                        <td className="py-2">
                                                                                                            <Form.Control
                                                                                                                className='p-1'
                                                                                                                id={`creation_info-` + index}
                                                                                                                name={`creation_info-` + index}
                                                                                                                disabled={true}
                                                                                                                defaultValue={element.creation_info}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td className="py-2">
                                                                                                            <Form.Control
                                                                                                                className='p-1'
                                                                                                                id={`amount-` + index}
                                                                                                                name={`amount-` + index}
                                                                                                                disabled={values.can_override || (element.override_amount ? true : false)}
                                                                                                                defaultValue={(!values.can_override && element.override_amount) ? element.override_amount : element.amount}
                                                                                                                type="number"
                                                                                                                min={0}
                                                                                                                onChange={(value) => {
                                                                                                                    element.amount = parseFloat(value.target.value)
                                                                                                                    replaceElementObject(element, index)
                                                                                                                }}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td className="py-2">
                                                                                                            <Form.Control
                                                                                                                className='p-1'
                                                                                                                id={`comment-` + index}
                                                                                                                name={`comment-` + index}
                                                                                                                disabled={values.can_override || (element.override_amount ? true : false)}
                                                                                                                defaultValue={(!values.can_override && element.override_comment) ? element.override_comment : element.comment}
                                                                                                                onChange={(value) => {
                                                                                                                    element.comment = value.target.value
                                                                                                                    replaceElementObject(element, index)
                                                                                                                }
                                                                                                                }
                                                                                                            />
                                                                                                        </td>

                                                                                                        {values.can_override ? (
                                                                                                            <>
                                                                                                                <td className="py-2">
                                                                                                                    <Form.Control
                                                                                                                        className='p-1'
                                                                                                                        id={`override_amount-` + index}
                                                                                                                        name={`override_amount-` + index}
                                                                                                                        defaultValue={element.override_amount}
                                                                                                                        type="number"
                                                                                                                        min={0}
                                                                                                                        onChange={(value) => {
                                                                                                                            element.override_amount = parseFloat(value.target.value)
                                                                                                                            if (!element.id) {
                                                                                                                                element.amount = parseFloat(value.target.value)
                                                                                                                            }
                                                                                                                            replaceElementObject(element, index)
                                                                                                                        }
                                                                                                                        }
                                                                                                                    />
                                                                                                                </td>
                                                                                                                <td className="py-2">
                                                                                                                    <Form.Control
                                                                                                                        className='p-1'
                                                                                                                        id={`override_comment-` + index}
                                                                                                                        name={`override_comment-` + index}
                                                                                                                        defaultValue={element.override_comment}
                                                                                                                        onChange={(value) => {
                                                                                                                            element.override_comment = value.target.value
                                                                                                                            if (!element.id) {
                                                                                                                                element.comment = value.target.value
                                                                                                                            }
                                                                                                                            replaceElementObject(element, index)
                                                                                                                        }
                                                                                                                        }
                                                                                                                    />
                                                                                                                </td>
                                                                                                            </>) : (<></>)}
                                                                                                        <td className="py-2">
                                                                                                            <Form.Check className="py-1"
                                                                                                                value={element.id}
                                                                                                                checked={element.pay_in_payroll}
                                                                                                                name={`pay_in_payroll-` + index}
                                                                                                                onChange={(value) => {
                                                                                                                    element.pay_in_payroll = value.target.checked
                                                                                                                    replaceElementObject(element, index)
                                                                                                                }}
                                                                                                                id={`pay_in_payroll-` + index}
                                                                                                            // defaultValue={element.pay_in_payroll}
                                                                                                            />
                                                                                                        </td>
                                                                                                        <td className="py-2">
                                                                                                            <button
                                                                                                                type="reset"
                                                                                                                onClick={() => { handleDeleteElement(element, index) }}
                                                                                                                className='btn btn-light btn-light-info p-1 px-2'
                                                                                                            ><i className="bi bi-trash p-0"></i></button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </>) : (<></>)}
                                                                                        </>
                                                                                    )
                                                                                })) : (
                                                                                    <tr>
                                                                                        <td colSpan={11}>
                                                                                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                                                                No matching records found
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </fieldset>
                                                            </Row>
                                                        ) : (
                                                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                                No Configuration found for this transacrtion
                                                            </div>)}
                                                    </fieldset>
                                                    {/* begin::Actions */}
                                                    <div className='py-5 d-flex justify-content-end'>
                                                        {!canValidate && canEdit && !transactionClosed &&
                                                            <> {needFinalSettelement && values.can_override && ((values?.element_ids?.length && values?.element_ids?.length > 0) || (values.clearance_group_nodue && values.clearance_group_nodue?.filter(group => group.no_dues == true).length > 0)) &&
                                                                <button
                                                                    title="Submit for approval"
                                                                    type='reset'
                                                                    onClick={() => { setNeedSubmit(true) }}
                                                                    className='btn btn-light btn-light-info mx-5 btn-sm'
                                                                    disabled={!canEdit}>
                                                                    <i className="fa fa-podcast fs-2" aria-hidden="true"></i>
                                                                    <span className='indicator-label'>Request FS</span>
                                                                </button>
                                                            }
                                                                {!needFinalSettelement && values.can_override && ((values?.element_ids?.length && values?.element_ids?.length > 0) || (values.clearance_group_nodue && values.clearance_group_nodue?.filter(group => group.no_dues == true).length > 0)) &&
                                                                    <button
                                                                        title="Submit for approval"
                                                                        type='submit'
                                                                        onClick={() => { setNeedSubmit(true) }}
                                                                        className='btn btn-light btn-light-info mx-5 btn-sm'
                                                                        disabled={!canEdit}>
                                                                        <i className="fa fa-podcast fs-2" aria-hidden="true"></i>
                                                                        <span className='indicator-label'>Submit</span>
                                                                    </button>
                                                                }
                                                            </>
                                                        }
                                                        {!canValidate && canEdit && !transactionClosed &&
                                                            <button
                                                                title="Save"
                                                                type='submit'
                                                                onClick={() => { setNeedSubmit(false) }}
                                                                className='btn btn-light btn-light-primary mx-5 btn-sm  '
                                                                disabled={isSubmitting || !canEdit}
                                                            >
                                                                {/* <KTIcon iconName='fa-save' className='fs-2' /> */}
                                                                <i className="fas fa-check fs-2"></i>
                                                                <span className='indicator-label'>Save</span>
                                                            </button>
                                                        }
                                                        {!canValidate &&
                                                            <button
                                                                title="Discard & back"
                                                                type='reset'
                                                                onClick={() => cancel()}
                                                                className='btn btn-light btn-active-light-primary me-3 mx-5 btn-sm'
                                                                data-kt-users-modal-action='cancel'
                                                            >
                                                                <KTIcon iconName='exit-left' className='fs-2' />
                                                                <span className='indicator-label'>Discard</span>

                                                            </button>
                                                        }
                                                        {/* {canDelete && id &&
                                                            <button
                                                                title="Delete"
                                                                type='reset'
                                                                onClick={() => deleteTransaction()}
                                                                className='btn btn-light btn-light-danger me-3 mx-5 btn-sm'
                                                                data-kt-users-modal-action='delete'
                                                            >
                                                                <KTIcon iconName='trash' className='fs-2' />
                                                                <span className='indicator-label'>Delete</span>

                                                            </button>
                                                        } */}
                                                    </div>
                                                    {/* end::Actions */}
                                                </Row>
                                            </>)}
                                    </Form>
                                </>
                            )
                        }}
                    </Formik>
                </Col>
                <Col md='12' lg='3' xl='3'>
                    {id && (<AzkStatusRibbon transactionId={parseInt(id)} modelName={MODEL_NAME}
                        current={transactionState} currentName={transactionStateName} />
                    )}
                    {employee && <AzkChatter transactionClosed={transactionClosed} isDrawer={true} modelName={MODEL_NAME} itemID={objectId} hitSend={hitSend} sendMessagesCallback={handleResultOfsubmit} />}
                </Col>
            </Row>

            {needFinalSettelement && needSubmit && id && <FinalSettlementParametersModal transactionId={parseInt(id)} closeModal={function (): {} {
                setNeedSubmit(false)
                return closeModal
            }} />}

        </>
    )
}

export default Transactionform;