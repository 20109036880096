import { PageLink, PageTitle } from '_metronic/layout/core'
import { Outlet, Route, Routes } from 'react-router-dom'

import TransactionViewform from './view'
import TransactionListView from './list'

const transactionBreadCrumbs: Array<PageLink> = [
  {
    title: 'Exit Interview Request',
    path: '/self-service/exit-interview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Exit Interview Request',
    path: '/self-service/exit-interview',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ExitInterViewRequestRouter = () => (
  <Routes>
    <Route
      element={
        <>
          <Outlet />
        </>
      }
    >
       <Route
        path=''
        element={
          <>
            <PageTitle breadcrumbs={transactionBreadCrumbs}>List</PageTitle>
            <TransactionListView />
          </>
        }
      />
      <Route
        path=':id/view'
        element={
          <>
            <PageTitle breadcrumbs={transactionBreadCrumbs}>view</PageTitle>
            <TransactionViewform />
          </>
        }
      />
      {/* <Route
        path='new'
        element={
          <>
            <PageTitle breadcrumbs={transactionBreadCrumbs}>New</PageTitle>
            <Transactionform />
          </>
        }
      /> */}
    </Route>
  </Routes>
)

export default ExitInterViewRequestRouter
