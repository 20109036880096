import axios, {AxiosResponse} from 'axios'
import { Promotion, PromotionQueryResponse } from './model'
import { ID, Response } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper' 
import useFixServerClientData from 'app/utilities/fix-Server-Client-Data'

const MODEL_NAME = 'az.trn.promotion'
const URL = `${API_URL}/${MODEL_NAME}`
const GET_URL = `${API_URL}/${MODEL_NAME}/`
const INCLUDE_FIELDS = `'id', 'name', 'employee_id', 'type_id', 'x_state', 'x_state_view', 'can_review', 'can_write', 'can_delete', 'validated', 'rejected', 'restarted', 'az_country_id', 'az_company_id', 'payroll_id', 'brand_id', 'azadea_id', 'azadea_employee_name', 'branch_id', 'section_id', 'grade_id', 'job_id', 'salary', 'as_effective_date', 'time_set', 'target_salary', 'target_as_effective_date', 'can_edit', 'target_job_id', 'target_grade_id', 'current_approval_ids'`


const GetPromotionById = (id: ID): Promise<Promotion> => {
  const { FixServerData} = useFixServerClientData();
  return axios
    .get(`${URL}?filters=[('id','=',${id})]&include_fields=[${INCLUDE_FIELDS}]`)
    .then((response: AxiosResponse<Response<Promotion>>) => response.data)
    .then((response: Response<Promotion>) => FixServerData(response['results'][0]))
}

const CreatePromotion = async (transaction: Promotion): Promise<Promotion | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transaction)
  return axios
    .post(URL, shallowTransaction)
    .then((response: AxiosResponse<Promotion>) => response.data)
}

const UpdatePromotion = async (transaction: Promotion): Promise<Promotion | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transaction)
  return axios
    .put(`${URL}/${transaction.id}`, shallowTransaction)
    .then((response: AxiosResponse<Response<Promotion>>) => response.data)
    .then((response: Response<Promotion>) => response.data)
}

const DeletePromotion = (transactionId: number): Promise<Promotion | undefined> => {
  return axios
    .delete(`${URL}/${transactionId}`)
    .then((response: AxiosResponse<Response<Promotion>>) => response.data)
    .then((response: Response<Promotion>) => response.data)
}

export { GetPromotionById, CreatePromotion, UpdatePromotion, MODEL_NAME, DeletePromotion}


