import axios, { ResponseType } from "axios";
import { FC, useEffect, useRef, useState } from "react";
import * as Yup from 'yup'
import { ErrorMessage, Field, FieldArray, Formik, FormikProps, FormikValues } from 'formik'
import "react-datepicker/dist/react-datepicker.css";
import { Form, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { ExitInterViewRequest } from "./model";
import { isNotEmpty } from "_metronic/helpers";
import { UpdateExitInterViewRequestLang } from "./_requests";
import { AsyncPaginate } from "react-select-async-paginate";

type Props = {
    requestId: any,
    closeModal: () => {}
}

const editTransactionSchema = Yup.object().shape({
    lang: Yup.string().required('Language is required'),
})

const TransactionAPPLangSelectionForm: FC<Props> = ({ requestId, closeModal }) => {
    const { id } = useParams();
    const formRef = useRef<FormikProps<ExitInterViewRequest>>(null);

    useEffect(() => {
        formRef.current?.resetForm()
        formRef.current?.setValues({
            id: requestId,
            lang: '',
        })
    }, [id])

    const onSubmitHandler = async (values: ExitInterViewRequest, actions: FormikValues) => {
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        if (formRef.current?.isValid) {
            document.body.classList.add('page-loading')
            if (isNotEmpty(values.id) && values?.id) {
                await UpdateExitInterViewRequestLang(values?.id, values.lang).then(data => {
                    closeModal()
                })
            }
        }
    }


    const loadLangOptions = async (search, loadedOptions) => {
        const options: any[] = []
        options.push({ value: 'en', label: 'English' })
        options.push({ value: 'fr', label: 'French' })
        options.push({ value: 'ar', label: 'العربية' })
        const filteredOptions = options.filter((option) =>
            option.label.toString().includes(search.toLowerCase())
        );
        return {
            options: filteredOptions,
            hasMore: false,
        };
    };

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
                style={{ zIndex: 99999 }}
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered px-3'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>

                        <div className='modal-header py-3 px-2'>
                            {/* begin::Modal title */}
                            <h4 className='m-0'>Please select your preferred language</h4>
                            {/* end::Modal title */}
                        </div>
                        {/* <div className='text-center py-3'>
                            <h4 className='m-0'>Leave Details</h4>
                        </div> */}
                        {/* begin::Modal body */}
                        <div className='' >
                            <Row className="m-0 py-3">
                                <Formik
                                    innerRef={formRef}
                                    validationSchema={editTransactionSchema}
                                    onSubmit={onSubmitHandler}
                                    validateeOnChange={true}
                                    initialValues={''}                                >
                                    {({ values, setFieldValue, handleSubmit, isSubmitting, validateForm }) => {
                                        return (
                                            <Form onSubmit={handleSubmit} noValidate>
                                                <Row className="m-0 my-2">
                                                    <Col lg="12" md="12" sm="12" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Select Language
                                                            </Form.Label>
                                                            <AsyncPaginate className='rounded-0 col-12'
                                                                placeholder="Select lang"
                                                                loadOptions={loadLangOptions}
                                                                onChange={(value) => {
                                                                    setFieldValue('lang', value.value)
                                                                }}
                                                                id={`lang`}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name={`lang`} component="div" />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <div className='my-2 row m-0'>
                                                    <div className={`col-12 d-flex justify-content-end py-3`}>
                                                        <button id='submit-button' type='submit' className='btn btn-dark rounded-0 btn-sm col-4'>
                                                            <span className='indicator-label'>Confirm</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Row>
                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}

export default TransactionAPPLangSelectionForm;


