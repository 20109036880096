import axios, {AxiosResponse} from 'axios'
import { ChangeEmploymentCategory } from './model'
import { ID, Response } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper' 
import useFixServerClientData from 'app/utilities/fix-Server-Client-Data'

const MODEL_NAME = 'az.trn.change.employment.category'
const URL = `${API_URL}/${MODEL_NAME}`
const GET_URL = `${API_URL}/${MODEL_NAME}/`
const INCLUDE_FIELDS = `'id', 'name', 'employee_id', 'type_id', 'x_state', 'x_state_view', 'can_review', 'can_write', 'can_delete', 'validated', 'rejected', 'restarted', 'az_country_id', 'az_company_id', 'payroll_id', 'brand_id', 'azadea_id', 'azadea_employee_name', 'branch_id', 'section_id', 'grade_id', 'job_id', 'salary', 'as_effective_date', 'time_set', 'target_salary', 'target_as_effective_date', 'can_edit', 'target_employment_category_id', 'current_approval_ids'`


const GetChangeEmploymentCategoryById = (id: ID): Promise<ChangeEmploymentCategory> => {
  const { FixServerData} = useFixServerClientData();
  return axios
    .get(`${URL}?filters=[('id','=',${id})]&include_fields=[${INCLUDE_FIELDS}]`)
    .then((response: AxiosResponse<Response<ChangeEmploymentCategory>>) => response.data)
    .then((response: Response<ChangeEmploymentCategory>) => FixServerData(response['results'][0]))
}

const CreateChangeEmploymentCategory = async (transfer: ChangeEmploymentCategory): Promise<ChangeEmploymentCategory | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transfer)
  return axios
    .post(URL, shallowTransaction)
    .then((response: AxiosResponse<ChangeEmploymentCategory>) => response.data)
}

const UpdateChangeEmploymentCategory = async (transfer: ChangeEmploymentCategory): Promise<ChangeEmploymentCategory | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transfer)
  return axios
    .put(`${URL}/${transfer.id}`, shallowTransaction)
    .then((response: AxiosResponse<Response<ChangeEmploymentCategory>>) => response.data)
    .then((response: Response<ChangeEmploymentCategory>) => response.data)
}

const DeleteChangeEmploymentCategory = (transactionId: number): Promise<ChangeEmploymentCategory | undefined> => {
  return axios
    .delete(`${URL}/${transactionId}`)
    .then((response: AxiosResponse<Response<ChangeEmploymentCategory>>) => response.data)
    .then((response: Response<ChangeEmploymentCategory>) => response.data)
}

export { GetChangeEmploymentCategoryById, CreateChangeEmploymentCategory, UpdateChangeEmploymentCategory, MODEL_NAME, DeleteChangeEmploymentCategory}


