import { PageLink, PageTitle } from '_metronic/layout/core'
import { Outlet, Route, Routes } from 'react-router-dom'
import TransferList from './transferList'
import TransferForm from './transferForm'
import { useAuth } from 'app/modules/auth'

const transactionBreadCrumbs: Array<PageLink> = [
  {
    title: 'Transactions',
    path: '/transactions/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Transfer Between Companies',
    path: '/transactions/transfer-company',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]


const TransferRouter = () => {
  const { hasAuthority } = useAuth()
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >

        <Route
          path=''
          element={
            <>
              <PageTitle breadcrumbs={transactionBreadCrumbs}>List</PageTitle>
              <TransferList />
            </>
          }
        />
        (hasAuthority('transfer_company', 'write')&&
        <Route
          path=':id/edit'
          element={
            <>
              <PageTitle breadcrumbs={transactionBreadCrumbs}>Edit</PageTitle>
              <TransferForm />
            </>
          }
        />)
        (hasAuthority('transfer_company', 'create')&&
        <Route
          path='new'
          element={
            <>
              <PageTitle breadcrumbs={transactionBreadCrumbs}>New</PageTitle>
              <TransferForm />
            </>
          }
        />
        )
      </Route>
    </Routes>
  )
}

export default TransferRouter
