import axios, {AxiosResponse} from 'axios'
import { Clearance } from './model'
import { ID, Response } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper' 
import useFixServerClientData from 'app/utilities/fix-Server-Client-Data'
import { GrouphHelper } from '_azkatech/_models/_group'

const MODEL_NAME = 'az.trn.clearance'
const URL = `${API_URL}/${MODEL_NAME}`
const GET_URL = `${API_URL}/${MODEL_NAME}/`
const  ELEMENT_INCLUDE_FIELDS = `('id', 'creation_info', 'group_id', 'az_country_id', 'element_id', 'amount', 'comment', 'override_amount', 'override_comment', 'pay_in_payroll')`
const  GROUP_NO_DUE_INCLUDE_FIELDS = `('id', 'group_id', 'no_dues')`
const INCLUDE_FIELDS = `'id', 'name', 'employee_id', 'type_id', 'x_state', 'x_state_view', 'state', 'can_review', 'can_write', 'can_delete', 'validated', 'rejected', 'restarted', ('az_country_id', ('id', 'name', 'due_date')), 'az_company_id', 'payroll_id', 'brand_id', 'azadea_id', 'azadea_employee_name', 'branch_id', 'section_id', 'grade_id', 'job_id', 'salary', 'as_effective_date', 'time_set', 'target_as_effective_date', 'can_edit', 'current_approval_ids', 'separation_trn_id', 'can_override', ('allowed_group_ids', [${GrouphHelper.INCLUDE_FIELDS}]), ('element_ids', [${ELEMENT_INCLUDE_FIELDS}]), ('clearance_group_nodue', [${GROUP_NO_DUE_INCLUDE_FIELDS}]) `


const GetClearanceForSettlementById = (id: ID): Promise<Clearance> => {
  const { FixServerData} = useFixServerClientData();
  return axios
    .get(`${URL}?filters=[('id','=',${id})]&include_fields=[${INCLUDE_FIELDS}, 'allowed_paramters']`)
    .then((response: AxiosResponse<Response<Clearance>>) => response.data)
    .then((response: Response<Clearance>) => FixServerData(response['results'][0]))
}

const GetClearanceById = (id: ID): Promise<Clearance> => {
  const { FixServerData} = useFixServerClientData();
  return axios
    .get(`${URL}?filters=[('id','=',${id})]&include_fields=[${INCLUDE_FIELDS}]`)
    .then((response: AxiosResponse<Response<Clearance>>) => response.data)
    .then((response: Response<Clearance>) => FixServerData(response['results'][0]))
}

const CreateClearance = async (transaction: Clearance): Promise<Clearance | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transaction)
  shallowTransaction.element_ids_view_owner = transaction.element_ids
  return axios
    .post(URL, shallowTransaction)
    .then((response: AxiosResponse<Clearance>) => response.data)
}

const UpdateClearance = async (transaction: Clearance): Promise<Clearance | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transaction)
  return axios
    .put(`${URL}/${transaction.id}`, shallowTransaction)
    .then((response: AxiosResponse<Response<Clearance>>) => response.data)
    .then((response: Response<Clearance>) => response.data)
}

const DeleteClearance = (transactionId: number): Promise<Clearance | undefined> => {
  return axios
    .delete(`${URL}/${transactionId}`)
    .then((response: AxiosResponse<Response<Clearance>>) => response.data)
    .then((response: Response<Clearance>) => response.data)
}

export { GetClearanceById, CreateClearance, UpdateClearance, MODEL_NAME, GetClearanceForSettlementById}


