import AzkSelect from "_azkatech/azkSelect";
import axios from "axios";
import * as Yup from 'yup'
import { SalaryStructureSetting, initialTransaction } from "./model";
import { FC, useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik, FormikProps, FormikValues } from 'formik'
import { CreateSalaryStructureSetting, UpdateSalaryStructureSetting, GetSalaryStructureSettingById, MODEL_NAME, DeleteSalaryStructureSetting } from "./_requests";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { AzkStatusRibbon } from "_azkatech/azkStatusRibbon";
import { Form, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { ID, isNotEmpty, KTIcon } from "_metronic/helpers";
import { useNavigate } from "react-router";
import { API_URL } from "_azkatech/azkHelper";
import { AZKHelper } from "app/modules/components/azkHelper";
import { AzkChatter } from "_azkatech";
import { EmploymentCategory } from "../change-employment-category/form";
import { checkTrnsactionTypeAccessId } from "app/utilities/transactionTyperequests";
import { useParams } from "react-router-dom";

type Props = {
}
const editTransactionSchema = Yup.object().shape({
    az_country_id: Yup.number().nonNullable().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Country is required'),
    brand_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Brand is required'),
    job_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Job is required'),
    grade_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Grade is required'),
    package: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Package is required').min(1, 'Package must be grater than Zero!'),
    has_com: Yup.boolean(),
    com: Yup.number().nonNullable(),
    // .when('has_com', {
    //     is: (has_com)=> has_com === true,
    //     then: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value).required('Com is required').min(1, 'Com must be grater than Zero!').max(100, 'Com must be less than 100!'),
    //     otherwise: Yup.number()
    // })
})

const Transactionform: FC<Props> = () => {
    const { id } = useParams();
    const Swal = require('sweetalert2')
    const intl = useIntl();
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<SalaryStructureSetting>>(null);
    const [brandDomain, setBrandDomain] = useState('');
    const [locationDomain, setLocationDomain] = useState(`('country_id','=', 0)`);
    const [jobDomain, setJobDomain] = useState(`('country_id','=', 0)`);
    const [gradeDomain, setGradeDomain] = useState(`('country_id','=', 0)`);
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [transactionClosed, setTransactionClosed] = useState<boolean>(false);
    const [needSubmit, setNeedSubmit] = useState<boolean>(false);
    const [employmentCategoryOptions, setEmploymentCategoryOptions] = useState<EmploymentCategory[]>();
    const [canDelete, setCanDelete] = useState<boolean>(false);
    const [canValidate, setCanValidate] = useState<boolean>(false);
    const [transactionState, setTransactionState] = useState<string>('draft');
    const [transactionStateName, setTransactionStateName] = useState<string>('Draft');
    const [hitSend, setHitSend] = useState<boolean>(false);
    const [objectId, setObjectId] = useState<number>();

    const pobulateFormData = async (data?: SalaryStructureSetting) => {
        if (data) {
            handleCountryChange(data.az_country_id?.id)
            formRef.current?.setValues({
                id: data.id,
                name: data.name,
                az_country_id: data.az_country_id?.id,
                brand_id: data.brand_id?.id,
                location_id: data.location_id?.id,
                job_id: data.job_id?.id,
                grade_id: data.grade_id?.id,
                person_type: data.person_type,
                employment_category_id: data.employment_category_id?.id,
                package: data.package,
                has_com: data.has_com,
                com: data.com,
                as_effective_date: moment(data.as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT),
                is_service_charge: data.is_service_charge,
                applied: data.applied,
                business_type: data.business_type,
                is_editable: data.is_editable,
            })
        }
    }

    const checkPermission = async (action: string) => {
        const allowed = await checkTrnsactionTypeAccessId(MODEL_NAME, action)
        if (!allowed) {
            if (id) {
                const allowed_read = await checkTrnsactionTypeAccessId(MODEL_NAME, 'read')
                if (!allowed_read) {
                    navigate(`/salary-structure/settings/`, { state: {}, replace: true });
                    Swal.fire({
                        position: "top-right",
                        title: 'Access Error!',
                        text: 'You does not have access to perform read action!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                } else {
                    setReadOnly(true)
                }
            } else {
                navigate(`/salary-structure/settings/`, { state: {}, replace: true });
                Swal.fire({
                    position: "top-right",
                    title: 'Access Error!',
                    text: 'You does not have access to perform this action!',
                    icon: "error",
                    showConfirmButton: false,
                    timer: AZKHelper.SWAL_TOAST_TIMER,
                    toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
            }
        }
    }
    

    useEffect(() => {
        document.body.classList.add('page-loading')
        axios.get(`${API_URL}/hr.employee.category?filters=[]&include_fields=['id','name',  'code']`).then(response => {
            if (response && response.data && response.data.results && response.data.count > 0) {
                setEmploymentCategoryOptions(response.data.results)
            }
        })
        setObjectId(undefined)
        if (id) {
            setObjectId(parseInt(id))
            checkPermission('write');
            GetSalaryStructureSettingById(Number.parseInt(id)).then(((data: SalaryStructureSetting) => {
                pobulateFormData(data)
                setCanEdit(data.is_editable)
                document.body.classList.remove('page-loading')
            }))
        } else {
            checkPermission('create');
            setCanEdit(true)
            document.body.classList.remove('page-loading')
        }
    }, [])

    const cancel = () => {
        navigate(`/salary-structure/settings/`, { state: {}, replace: true });
    }

    const deleteTransaction = () => {
        Swal.fire({
            position: "center",
            title: 'Delete',
            text: 'Delete Transaction confirm?',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed && id) {
                DeleteSalaryStructureSetting(Number.parseInt(id)).then(() => {
                    cancel()
                    Swal.fire({
                        position: "top-right",
                        title: 'Deleted!',
                        text: 'Your transaction had been deleted!',
                        icon: "warning",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                })
            } else if (result.isDenied) {
                Swal.fire('Transaction was not deleted', '', 'info')
            }
        })
    }

    const submitTransaction = (transactionId: number) => {
        setNeedSubmit(false)
        axios.put(`${API_URL}/${MODEL_NAME}/${transactionId}/request_validation`, '{}').then(data => {
            document.body.classList.remove('page-loading')
            navigate(`/salary-structure/settings/`, { state: {}, replace: true });
            Swal.fire({
                position: "top-right",
                title: 'Success!',
                text: 'Your transaction had been submited!',
                icon: "success",
                showConfirmButton: false,
                timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        })
    }

    const handleResultOfsubmit = (transactionId: ID | undefined, isUpdate: boolean | false) => {
        if (needSubmit && transactionId) {
            submitTransaction(transactionId)
        } else {
            navigate(`/salary-structure/settings/`, { state: {}, replace: true })
            document.body.classList.remove('page-loading')
            Swal.fire({
                position: "top-right",
                title: 'Success!',
                text: isUpdate ? 'Your transaction had been updated!' : 'Your transaction had been saved!',
                icon: "success",
                showConfirmButton: false,
                timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        }
    }


    const onSubmitHandler = async (values: SalaryStructureSetting, actions: FormikValues) => {
        document.body.classList.add('page-loading')
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        if (formRef.current?.isValid) {
            // if (isNotEmpty(values.id)) {
            //     await UpdateSalaryStructureSetting(values).then(data => {
            //         if (id) handleResultOfsubmit(parseInt(id), true)
            //     })
            // } else {
            values.id = undefined
            await CreateSalaryStructureSetting(values).then(data => {
                document.body.classList.remove('page-loading')
                setObjectId(data?.id)
                setHitSend(true)
            })
            // }
        } else {
            document.body.classList.remove('page-loading')
            actions.setSubmitting(false)
            formRef.current?.validateForm().then(errors => {
                if (Object.keys(errors).length > 0) {
                    console.log(errors)
                    //   setStatus('error');
                }
            });
        }
    }


    const handleCountryChange = (val) => {
        if (val) {
            formRef.current?.setFieldValue('brand_id', undefined)
            formRef.current?.setFieldValue('job_id', undefined)
            document.body.classList.add('page-loading')
            axios.get(`${API_URL}/az.country?filters=[('id', '=', ${val})]&include_fields=['brand_ids']`).then(response => {
                if (response && response.data && response.data.results) {
                    const beand_ids = response.data.results[0].brand_ids ? response.data.results[0].brand_ids : [0]
                    setBrandDomain(`('id', 'in', [${beand_ids}])`)
                }
                document.body.classList.remove('page-loading')
            })
            setJobDomain(`('country_id','=', ${val})`);
            setGradeDomain(val ? `('country_id','=', ${val})` : '');
            setLocationDomain(`('country_id','=', ${val})`);
        } else {
            setJobDomain(`('country_id','=', 0)`);
            setGradeDomain(`('country_id','=', 0)`);
            setLocationDomain(val ? `('country_id','=', 0)` : '');
        }
    }
    const handleBrandChange = (val) => {
        if (val) {
            const country_id = formRef.current?.getFieldProps('az_country_id').value
            const filters = `('country_id','=', ${country_id}), ('brand_id','=', ${val})`
            axios.get(`${API_URL}/az.branch?filters=[${filters}]&include_fields=['id','name', 'business_type']`).then(response => {
                if (response && response.data && response.data.results) {
                    if (response.data.results[0].business_type.indexOf('F&B') > -1) {
                        formRef.current?.setFieldValue('business_type', 'F&B')
                        formRef.current?.setFieldValue('is_service_charge', true)
                    } else {
                        formRef.current?.setFieldValue('business_type', 'Fashion')
                        formRef.current?.setFieldValue('is_service_charge', false)
                    }
                }
            })
        }
    }
    return (
        <>
            <Row className="m-0 my-2">
                <Col md='12' lg={id ? '9' : '9'} xl={id ? '9' : '9'}>
                    <Formik
                        innerRef={formRef}
                        validationSchema={editTransactionSchema}
                        onSubmit={onSubmitHandler}
                        initialValues={initialTransaction}
                        validateeOnChange={true}
                        validateOnMount={true}
                        initialErrors={''}
                    >
                        {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
                            // ,validateForm,isSubmitting, errors, touched, isValidating
                            return (
                                <>
                                    <Form onSubmit={handleSubmit} noValidate>

                                        <Row className="card m-0 py-2 mb-3">
                                            <fieldset disabled={!canEdit || readOnly}>
                                                <Row className="m-0 my-2">
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Country
                                                            </Form.Label>
                                                            <AzkSelect
                                                                compID="az_country_select"
                                                                modelName="az.country"
                                                                valueField="id"
                                                                titleField="name"
                                                                dataDomain=''
                                                                isMultiple={false}
                                                                allowEmpty={true}
                                                                fieldName='az_country_id'
                                                                disabled={!canEdit}
                                                                onChange={(value) => {
                                                                    setFieldValue('az_country_id', parseInt(value))
                                                                    handleCountryChange(value)
                                                                }}
                                                                defaultValue={values.az_country_id} />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='az_country_id' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Brand
                                                            </Form.Label>
                                                            <AzkSelect
                                                                compID="az_brand_select"
                                                                modelName="az.brand"
                                                                valueField="id"
                                                                titleField="name"
                                                                dataDomain={brandDomain}
                                                                isMultiple={false}
                                                                allowEmpty={true}
                                                                fieldName='brand_id'
                                                                disabled={!canEdit}
                                                                onChange={(value) => {
                                                                    setFieldValue('brand_id', parseInt(value))
                                                                    handleBrandChange(value)
                                                                }}
                                                                defaultValue={values.brand_id} />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='brand_id' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label>
                                                                Location
                                                            </Form.Label>
                                                            <AzkSelect
                                                                compID="az_location_select"
                                                                modelName="az.location"
                                                                valueField="id"
                                                                titleField="name"
                                                                dataDomain={locationDomain}
                                                                isMultiple={false}
                                                                allowEmpty={true}
                                                                fieldName='location_id'
                                                                disabled={!canEdit}
                                                                onChange={(value) => {
                                                                    setFieldValue('location_id', parseInt(value))
                                                                    handleBrandChange(value)
                                                                }}
                                                                defaultValue={values.location_id} />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='location_id' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Title
                                                            </Form.Label>
                                                            <AzkSelect
                                                                compID="az_job_select"
                                                                modelName="hr.job"
                                                                valueField="id"
                                                                titleField="name"
                                                                dataDomain={jobDomain}
                                                                isMultiple={false}
                                                                allowEmpty={true}
                                                                fieldName='job_id'
                                                                disabled={!canEdit}
                                                                onChange={(value) => {
                                                                    setFieldValue('job_id', parseInt(value))
                                                                }}
                                                                defaultValue={values.job_id} />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='job_id' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Grade
                                                            </Form.Label>
                                                            <AzkSelect
                                                                compID="az_grade_select"
                                                                modelName="az.grade"
                                                                valueField="id"
                                                                titleField="grade_name"
                                                                dataDomain={gradeDomain}
                                                                isMultiple={false}
                                                                allowEmpty={false}
                                                                disabled={!canEdit}
                                                                fieldName='grade_id'
                                                                onChange={(value) => {
                                                                    setFieldValue('grade_id', parseInt(value))
                                                                }}
                                                                defaultValue={values.grade_id} />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='grade_id' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label >
                                                                Business Type
                                                            </Form.Label>
                                                            <Form.Control
                                                                className='p-1'
                                                                id='business_type'
                                                                name='business_type'
                                                                disabled={true}
                                                                defaultValue={values.business_type}
                                                                value={values.business_type}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='target_employment_category_id' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Employment Category
                                                            </Form.Label>
                                                            <div className="d-flex justify-content-around">
                                                                {employmentCategoryOptions && employmentCategoryOptions?.map(option => (
                                                                    <Form.Check
                                                                        label={option.name}
                                                                        value={option.id}
                                                                        checked={values.employment_category_id ? values.employment_category_id === option.id : option.code === 'FULLT'}
                                                                        name="employment-category-option"
                                                                        onChange={(value) => {
                                                                            setFieldValue('employment_category_id', option.id)
                                                                        }}
                                                                        type="radio"
                                                                        id={`employment-category-option-` + option.id}
                                                                    />
                                                                )
                                                                )}
                                                            </div>
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='target_employment_category_id' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Person Type
                                                            </Form.Label>
                                                            <div className="d-flex justify-content-around">
                                                                <Form.Check
                                                                    label='National'
                                                                    value='national'
                                                                    checked={values.person_type ? 'national' === values.person_type : false}
                                                                    name="person-type-option"
                                                                    onChange={(value) => {
                                                                        setFieldValue('person_type', value.target.value)
                                                                    }}
                                                                    type="radio"
                                                                    id={`person-type-option-national`}
                                                                />
                                                                <Form.Check
                                                                    label='Expat'
                                                                    value='expat'
                                                                    checked={values.person_type ? 'expat' === values.person_type : false}
                                                                    name="person-type-option"
                                                                    onChange={(value) => {
                                                                        setFieldValue('person_type', value.target.value)
                                                                    }}
                                                                    type="radio"
                                                                    id={`person-type-option-expat`}
                                                                />
                                                                <Form.Check
                                                                    label='N/A'
                                                                    value='N/A'
                                                                    checked={values.person_type ? 'N/A' === values.person_type : true}
                                                                    name="person-type-option"
                                                                    onChange={(value) => {
                                                                        setFieldValue('person_type', value.target.value)
                                                                    }}
                                                                    type="radio"
                                                                    id={`person-type-option-N/A`}
                                                                />
                                                            </div>
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='person_type' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Package
                                                            </Form.Label>
                                                            <Form.Control
                                                                className='p-1'
                                                                id='package'
                                                                name='package'
                                                                disabled={!canEdit}
                                                                onChange={(value) =>
                                                                    setFieldValue('package', value.target.value)
                                                                }
                                                                defaultValue={values.package}
                                                                type='number'
                                                                min={1}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='package' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                {values.is_service_charge ? 'SC' : 'HAS COM'}
                                                            </Form.Label>
                                                            <Form.Check
                                                                className='p-1'
                                                                id='has_com'
                                                                type="checkbox"
                                                                name='has_com'
                                                                disabled={!canEdit}
                                                                onChange={(value) => {
                                                                    setFieldValue('has_com', value.target.checked)
                                                                    if (!value.target.checked){
                                                                        setFieldValue('com', 0)
                                                                    }
                                                                }
                                                                }
                                                                checked={values.has_com}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='has_com' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className={`${(values.has_com) ? "required" : ''}`}>
                                                                {values.is_service_charge ? 'SC Points' : 'COM %'}
                                                            </Form.Label>
                                                            <Form.Control
                                                                className='p-1'
                                                                id='com'
                                                                name='com'
                                                                disabled={!canEdit||!values.has_com}
                                                                onChange={(value) =>
                                                                    setFieldValue('com', value.target.value)
                                                                }
                                                                value={values.com}
                                                                defaultValue={values.com}
                                                                type='number'
                                                                min={1}
                                                                max={100}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='com' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label className='required'>
                                                                Effective date
                                                            </Form.Label>
                                                            <Form.Control
                                                                className='p-1'
                                                                type='date'
                                                                onChange={(value) => {
                                                                    setFieldValue('as_effective_date', value.target.value)
                                                                }}
                                                                disabled={!canEdit}
                                                                value={moment(values.as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                            />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='as_effective_date' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </fieldset>


                                            {/* begin::Actions */}
                                            <div className='py-5 d-flex justify-content-end'>
                                                {/* {canEdit &&
                                                    <button
                                                        title="Submit for approval"
                                                        type='submit'
                                                        onClick={() => { setNeedSubmit(true) }}
                                                        className='btn btn-light btn-light-info mx-5 btn-sm'
                                                        disabled={!canEdit}>
                                                        <i className="fa fa-podcast fs-2" aria-hidden="true"></i>
                                                        <span className='indicator-label'>Submit</span>
                                                    </button>
                                                } */}
                                                {canEdit&&
                                                    <button
                                                        title="Save"
                                                        type='submit'
                                                        onClick={() => { setNeedSubmit(false) }}
                                                        className='btn btn-light btn-light-primary mx-5 btn-sm  '
                                                        disabled={isSubmitting || !canEdit}
                                                    >
                                                        {/* <KTIcon iconName='fa-save' className='fs-2' /> */}
                                                        <i className="fas fa-check fs-2"></i>
                                                        <span className='indicator-label'>Save</span>
                                                    </button>
                                                }
                                                {!canValidate &&
                                                    <button
                                                        title="Discard & back"
                                                        type='reset'
                                                        onClick={() => cancel()}
                                                        className='btn btn-light btn-active-light-primary me-3 mx-5 btn-sm'
                                                        data-kt-users-modal-action='cancel'
                                                    >
                                                        <KTIcon iconName='exit-left' className='fs-2' />
                                                        <span className='indicator-label'>Discard</span>

                                                    </button>
                                                }
                                                {canDelete && id &&
                                                    <button
                                                        title="Delete"
                                                        type='reset'
                                                        onClick={() => deleteTransaction()}
                                                        className='btn btn-light btn-light-danger me-3 mx-5 btn-sm'
                                                        data-kt-users-modal-action='delete'
                                                    >
                                                        <KTIcon iconName='trash' className='fs-2' />
                                                        <span className='indicator-label'>Delete</span>

                                                    </button>
                                                }
                                            </div>
                                            {/* end::Actions */}
                                        </Row>
                                    </Form>
                                </>
                            )
                        }}
                    </Formik>
                </Col>
                <Col md='12' lg='3' xl='3'>
                    {id && (<AzkStatusRibbon transactionId={Number.parseInt(id)} modelName={MODEL_NAME}
                        current={transactionState} currentName={transactionStateName} />
                    )}
                    <AzkChatter transactionClosed={transactionClosed} isDrawer={true} modelName={MODEL_NAME} itemID={objectId} hitSend={hitSend} sendMessagesCallback={handleResultOfsubmit} />
                </Col>
            </Row>
        </>
    )
}

export default Transactionform;