import axios, {AxiosResponse} from 'axios'
import { TravelRequest, TravelRequestQueryResponse } from './model'
import { ID, Response } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper' 
import useFixServerClientData from 'app/utilities/fix-Server-Client-Data'

const MODEL_NAME = 'az.trn.travel.request'
const URL = `${API_URL}/${MODEL_NAME}`
const GET_URL = `${API_URL}/${MODEL_NAME}/`
const INCLUDE_FIELDS = `'id', 'name', 'employee_id', 'x_state', 'x_state_view', 'can_review', 'can_write', 'can_delete', 'validated', 'rejected', 'restarted', 'az_country_id', 'az_company_id', 'brand_id', 'azadea_id', 'azadea_employee_name', 'branch_id', 'section_id', 'grade_id', 'job_id', 'create_date', 'name_in_passport', 'passport_number', 'passport_expiry', 'date_of_birth', 'mobile_number', 'smoking', 'has_credit_card', 'travel_insurance', 'save_personal_data', ('line_ids', [('id' , 'departure_date', 'departure_city', 'arrival_city', 'need_hotel', 'need_visa', 'purpose', 'passport_visa_info')])`


const GetTravelRequestById = (id: ID): Promise<TravelRequest> => {
  const { FixServerData} = useFixServerClientData();
  return axios
    .get(`${URL}?filters=[('id','=',${id})]&include_fields=[${INCLUDE_FIELDS}]`)
    .then((response: AxiosResponse<Response<TravelRequest>>) => response.data)
    .then((response: Response<TravelRequest>) => FixServerData(response['results'][0]))
}

const CreateTravelRequest = async (transaction: TravelRequest): Promise<TravelRequest | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transaction)
  return axios
    .post(URL, shallowTransaction)
    .then((response: AxiosResponse<TravelRequest>) => response.data)
}

const UpdateTravelRequest = async (transaction: TravelRequest): Promise<TravelRequest | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transaction, true)
  return axios
    .put(`${URL}/${transaction.id}`, shallowTransaction)
    .then((response: AxiosResponse<Response<TravelRequest>>) => response.data)
    .then((response: Response<TravelRequest>) => response.data)
}

const DeleteTravelRequest = (transactionId: number): Promise<TravelRequest | undefined> => {
  return axios
    .delete(`${URL}/${transactionId}`)
    .then((response: AxiosResponse<Response<TravelRequest>>) => response.data)
    .then((response: Response<TravelRequest>) => response.data)
}

export { GetTravelRequestById, CreateTravelRequest, UpdateTravelRequest, MODEL_NAME, DeleteTravelRequest}


