import { Response } from "_metronic/helpers"
import { AZKHelper } from "app/modules/components/azkHelper"
import moment from "moment"

export type SalaryStructure = {
  id?: number
  name?: string
  az_country_id?: any
  brand_id?: any
  location_id?: any
  as_effective_date?: string
  is_service_charge?: boolean
  sales?: string
  selling_area?: string
  detail_ids?: SalaryStructureDetail[],
  detail_ids_view?: SalaryStructureDetail[],
  commission_hc?: number,
  non_commission_hc?: number,
  total_hc: number,
  sales_per_sqm: string,
  sqm_employee: string | '',
  monthly_working_hours_ft?: number,
  monthly_working_hours_pt?: number,
  total_monthly_working_hours?: number,
  revenue_per_hour: string | '',
  sc_distribution: string | '',
  distribution: string | '',
  total_points: string | '',
  pay_per_point: string | '',
  com_hc_salary: string | '',
  non_com_hc_salary: string | '',
  total_salary: string | '',
  ratio: string | '',
  restarted?: boolean
  current_approval_ids?: number[]
  type_id?: number
  x_state?: string
  state_view?: string
  can_review?: boolean
  can_delete?: boolean
  can_write?: boolean
  can_duplicate?: boolean
  validated?: boolean, 
  rejected?: boolean,
  deleted?: boolean,
  state?: string
}

export const initialTransaction: SalaryStructure = {
  name: '',
  as_effective_date: moment().format(AZKHelper.FRONTEND_DATE_FORMAT),
  total_hc: 0,
  sales_per_sqm: "",
  sqm_employee: "",
  revenue_per_hour: "",
  sc_distribution: "",
  distribution: "",
  total_points: "",
  pay_per_point: "",
  com_hc_salary: "",
  non_com_hc_salary: "",
  total_salary: "",
  ratio: "",
}

export type SalaryStructureQueryResponse = Response<Array<SalaryStructure>>

export const initialTransferCompany: SalaryStructure = {
  total_hc: 0,
  sales_per_sqm: '',
  sqm_employee: "",
  revenue_per_hour: "",
  sc_distribution: "",
  distribution: "",
  total_points: "",
  pay_per_point: "",
  com_hc_salary: "",
  non_com_hc_salary: "",
  total_salary: "",
  ratio: ""
}

export type SalaryStructureDetail = {
  id?: number
  salary_structure_id?: any
  job_id?: any
  grade_id?: any
  grade_id_name?: any
  person_type?: any
  employment_category_id?: any
  package: string
  has_com?: boolean
  head_count: number | 0
  commission: number | 0
  commission_value: string
  total_gross: string
  state?: any
  is_full_time?: boolean
  can_edit_job?: boolean
}
