import axios, { AxiosResponse } from 'axios'
import { API_URL } from '_azkatech/azkHelper'
import useAESEncrypt from './AESEncrypt'


const GET_URL = `${API_URL}/`

const useSalaryReqeust = () => {

  const { decodeAndDecrypt } = useAESEncrypt();
  const Swal = require('sweetalert2')

  const GetSalaryByTitlebranch = async (job_id?: number, branch_id?: number, timeset?: any, business_group_id?: number): Promise<any> => {

    const response = []
    return axios
      .put(`${GET_URL}hr.job/${job_id}/get_target_employee_salary?title_job_id=${job_id}&branch_id=${branch_id}&timeset=${timeset}&business_group_id=${business_group_id}`)
      .then((response: AxiosResponse<any>) => response.data)
      // .then((response: Response<any>) => response['results'])
      .then(res => {
        const result = JSON.parse(res)
        if (result && result !== null && result!== undefined){
        const salary = decodeAndDecrypt(result['salary'])
        response['salary'] = salary
        response['grade_id'] = result['grade_id']
        return response
      }else {
        Swal.fire({
          position: "top-right",
          title: 'Sorry!',
          text: 'This Targeted Salary structure does not exist!',
          icon: "error",
          showConfirmButton: false,
          timer: 5000,
          toast: true,
          iconColor: 'white',
          customClass: {
              popup: 'colored-toast'
          },
          timerProgressBar: true
      })
      }
      })

  }
  return { GetSalaryByTitlebranch }
}
export { useSalaryReqeust }