/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { useThemeMode } from '_metronic/partials'
import { getCSS, getCSSVariableValue } from '_metronic/assets/ts/_utils'
import { KTIcon } from '_metronic/helpers'
import { GetFinalSettelementChartData, MODEL_NAME } from './_requests-final-settelement-chart'
import { TransactrionsStatusChartFilter } from './transaction-status-chart-filter'
import { useAuth } from '../auth'
import { AZKHelper } from '../components/azkHelper'

type Props = {
    className: string
}

const FinalSettelementChart: React.FC<Props> = ({ className }) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    const { mode } = useThemeMode()
    const { hasAuthority } = useAuth()
    const [noData, setNoData] = useState<boolean>(false);
    let countries: string[] = []
    let seriesList: any[] = [{ name: 'Clearence Completed', data: [] },
    { name: 'Payroll Validation (SSC Payroll Team)', data: [] },
    { name: 'To pay', data: [] },
    { name: 'Exceeded Due date', data: [] }]
    const [canAccess, setCanAccess] = useState<boolean | undefined>(undefined);


    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }
        const height = parseInt(getCSS(chartRef.current, 'height'))
        chartRef.current.innerHTML = ''
        const chart = new ApexCharts(chartRef.current, getChartOptions(height, countries, seriesList))
        if (chart) {
            chart.render()
        }
        if (chart && countries.length === 0) {
            chart.destroy()
        }
        return chart
    }

    const checkAccess = async () => {
        if (canAccess === undefined) {
            let allowedData = await hasAuthority(MODEL_NAME, AZKHelper.READ_ACTION)
            setCanAccess(allowedData)
            return allowedData
        } else {
            return canAccess
        }
    }
    const fillChartData: any = async (dataDomain: string) => {
        if (await checkAccess()) {
            setNoData(false)
            countries = []
            seriesList = [{ name: 'Clearence Completed', data: [] },
            { name: 'Payroll Validation (SSC Payroll Team)', data: [] },
            { name: 'To pay', data: [] },
            { name: 'Exceeded Due date', data: [] }]
            GetFinalSettelementChartData(dataDomain).then(async data => {
                if (data && data.length > 0) {
                    await data.forEach(record => {
                        const countryName = record.az_country_id.name
                        let countryIndex = countries.indexOf(countryName)
                        if (countryIndex === -1) {
                            countries.push(countryName)
                            countryIndex = countries.indexOf(countryName)
                            seriesList[0].data[countryIndex] = 0
                            seriesList[1].data[countryIndex] = 0
                            seriesList[2].data[countryIndex] = 0
                            seriesList[3].data[countryIndex] = 0
                        }
                        if (record.exceeded_due_date) { // Exceeded Due date
                            const seriesDate = seriesList[3].data
                            seriesDate[countryIndex] = seriesDate[countryIndex] + record.count
                            seriesList[3].data = seriesDate
                        }
                        else if (record.marked_to_pay) { // To pay
                            const seriesDate = seriesList[2].data
                            seriesDate[countryIndex] = seriesDate[countryIndex] + record.count
                            seriesList[2].data = seriesDate
                        } else if (record.payroll_validation) { // Payroll Validation (SSC Payroll Team) not vaild and not marked to pay
                            const seriesDate = seriesList[1].data
                            seriesDate[countryIndex] = seriesDate[countryIndex] + record.count
                            seriesList[1].data = seriesDate
                        } else if (record.clearance_completed) { // Clearence Completed
                            const seriesDate = seriesList[0].data
                            seriesDate[countryIndex] = seriesDate[countryIndex] + record.count
                            seriesList[0].data = seriesDate
                        }
                    })
                    return refreshChart()
                } else {
                    setNoData(true)
                    return refreshChart()
                }
            }, () => {
                setNoData(true)
                return refreshChart()
            })
        }
    }
    useEffect(() => {
        const chart = fillChartData('')
        return () => {
            if (chart) {
                try {
                    chart.destroy()
                } catch (error) {
                    console.error(error);
                }
            }
        }

    }, [])

    return (
        <>
            {canAccess &&
                <div className='col-xl-4 col-lg-6 col-md-12'>
                    <div className={`card ${className}`}>
                        {/* begin::Header */}
                        <div className='card-header border-0 pt-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bold fs-3 mb-1'>Final Settlement by Country </span>
                            </h3>


                            <div className='card-toolbar'>
                                {/* begin::Menu */}
                                <button
                                    type='button'
                                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                >
                                    <KTIcon iconName='category' className='fs-2' />
                                </button>
                                {/* <TransactrionsStatusChartFilter callBackFun={fillChartData} /> */}
                                {/* end::Menu */}
                            </div>
                        </div>
                        {/* end::Header */}

                        {/* begin::Body */}
                        <div className='card-body'>
                            {/* begin::Chart */}
                            {noData && <h6 className='text-center'>No data to show</h6>}
                            {!noData && <div ref={chartRef} id='kt_charts_widget_2_chart' style={{ height: '350px' }}></div>}
                            {/* end::Chart */}
                        </div>
                        {/* end::Body */}
                    </div>
                </div>
            }
        </>
    )
}

export { FinalSettelementChart }

function getChartOptions(height: number, countries: string[], seriesList: any[]): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const dueColor = getCSSVariableValue('--bs-danger')
    const toPayColor = getCSSVariableValue('--bs-warning')
    const completeColor = getCSSVariableValue('--bs-success')
    const inprogressColor = getCSSVariableValue('--bs-info')

    return {
        series: seriesList,
        chart: {
            fontFamily: 'inherit',
            type: 'bar',
            height: height,
            toolbar: {
                show: true,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '30px',
                borderRadius: 5,
            },
        },
        legend: {
            show: true,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: countries,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        fill: {
            opacity: 1,
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return + val + ' Transactions'
                },
            },
        },
        colors: [completeColor, inprogressColor, toPayColor, dueColor],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
    }
}
