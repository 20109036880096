import axios, {AxiosResponse} from 'axios'
import { Leave, LeaveQueryResponse } from './model'
import { ID, Response } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper' 
import useFixServerClientData from 'app/utilities/fix-Server-Client-Data'

const MODEL_NAME = 'az.trn.leave'
const URL = `${API_URL}/${MODEL_NAME}`
const GET_URL = `${API_URL}/${MODEL_NAME}/`
const INCLUDE_FIELDS = `'id', 'name', 'employee_id', 'x_state', 'x_state_view', 'can_review', 'can_write', 'can_delete', 'validated', 'rejected', 'restarted', 'az_country_id', 'az_company_id', 'brand_id', 'azadea_id', 'azadea_employee_name', 'branch_id', 'section_id', 'grade_id', 'job_id', ('leave_type_id', ('id' ,'name', 'lookup_code', 'is_hourly')), 'date_from', 'date_to', 'time_from', 'time_to', 'relationship_type_id', 'family_member_id', 'childbirth_date', 'expected_delivery_date', 'country_visited_id', 'reason_for_travel_id', 'document'`


const GetLeaveById = (id: ID): Promise<Leave> => {
  const { FixServerData} = useFixServerClientData();
  return axios
    .get(`${URL}?filters=[('id','=',${id})]&include_fields=[${INCLUDE_FIELDS}]`)
    .then((response: AxiosResponse<Response<Leave>>) => response.data)
    .then((response: Response<Leave>) => FixServerData(response['results'][0]))
}

const CreateLeave = async (transaction: Leave): Promise<Leave | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transaction)
  return axios
    .post(URL, shallowTransaction)
    .then((response: AxiosResponse<Leave>) => response.data)
}

const UpdateLeave = async (transaction: Leave): Promise<Leave | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transaction)
  return axios
    .put(`${URL}/${transaction.id}`, shallowTransaction)
    .then((response: AxiosResponse<Response<Leave>>) => response.data)
    .then((response: Response<Leave>) => response.data)
}

const DeleteLeave = (transactionId: number): Promise<Leave | undefined> => {
  return axios
    .delete(`${URL}/${transactionId}`)
    .then((response: AxiosResponse<Response<Leave>>) => response.data)
    .then((response: Response<Leave>) => response.data)
}

export { GetLeaveById, CreateLeave, UpdateLeave, MODEL_NAME, DeleteLeave}


