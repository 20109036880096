import moment from 'moment';
import useAESEncrypt from './AESEncrypt';
import { AZKHelper } from 'app/modules/components/azkHelper';

const useFixServerClientData = () => {

    const FixServerData = async (model: any, withDetails?: boolean) => {
        const { decodeAndDecrypt, decodeAndDecryptText } = useAESEncrypt();
        let shallowModel = Object.assign({}, model);
        if (model) {
            if (model['amount']) shallowModel.amount = decodeAndDecrypt(shallowModel.amount)
            if (model['amountYTD']) shallowModel.amountYTD = decodeAndDecrypt(shallowModel.amountYTD)
                    // if (model['iban_number']) shallowModel.iban_number = decodeAndDecryptText(shallowModel.iban_number)
            
            if (withDetails && model['details']) {
                const details = model['details']
                const promises = await details?.map(async (detail, index) => new Promise(async (resolve, reject) => {
                    if (detail['amount']) detail['amount'] = decodeAndDecrypt(detail['amount'])
                    if (detail['amountYTD']) detail['amountYTD'] = decodeAndDecrypt(detail['amountYTD'])
                    resolve(detail)
                }));
                if (promises)
                    await Promise.all(promises).then((details: any) => {
                        shallowModel['details'] = details
                    })
                    const subDetails = model['subDetails']
                    const promisesSubdetails = await subDetails?.map(async (detail, index) => new Promise(async (resolve, reject) => {
                        if (detail['amount']) detail['amount'] = decodeAndDecrypt(detail['amount'])
                        resolve(detail)
                    }));
                    if (promisesSubdetails)
                        await Promise.all(promisesSubdetails).then((details: any) => {
                            shallowModel['subDetails'] = details
                        })
                return shallowModel
            } else {
                return shallowModel
            }
        } else {
            return model
        }
    }

    const FixServerArrayData = async (models: any[]) => {
        let shallowModels: any[] = []
        if (models && models.length > 0) {
            const promises = await models?.map(async (model) => new Promise(async (resolve, reject) => {
                const shallowModel = await FixServerData(model)
                resolve(shallowModel)
            }));
            if (promises)
                await Promise.all(promises).then((shallowModel: any) => {
                    shallowModels = shallowModel
                })
                return shallowModels
        } else {
            return shallowModels
        }
    }
    return {
        FixServerData,
        FixServerArrayData
    };
};

export default useFixServerClientData;