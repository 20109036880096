import { Response } from "_metronic/helpers"


export type TravelPerDiemLineDetail = {
  id?: number
  date?: any
  line_sequence?: any
  perdiem_x?: any
  euro_per_diem?: any
  per_diem?: any
  is_euro_per_diem?: any
  comment?: any
}

export type TravelPerDiemLine = {
  id?: number
  line_sequence?: any
  perdiem_x?: any
  date?: any
  days?: any
  euro_per_diem?: any
  per_diem?: any
  booked_travel?: any
  comment?: any
  is_euro_per_diem?: any
  flight_number?: any
  flight_date?: any
  departure_city?: any
  arrival_city?: any
  departure_time?: any
  arrival_time?: any
  detail_ids?: TravelPerDiemLineDetail[]
}

export type TravelPerDiem = {
  id?: number
  name?: string
  employee_id?: any
  setting_id?: any
  travel_request_id?: any
  traveler_name?: string
  euro_per_diem?: any
  per_diem?: any
  type_id?: number
  x_state?: string
  x_state_view?: string
  can_review?: boolean
  can_delete?: boolean
  can_write?: boolean
  validated?: boolean,
  rejected?: boolean,
  state?: string
  az_country_id?: any
  az_company_id?: any
  brand_id?: any
  azadea_id?: number
  azadea_employee_name?: string
  branch_id?: any
  section_id?: any
  grade_id?: any
  job_id?: any
  time_set?: string
  can_edit?: boolean
  restarted?: boolean
  create_date?: any
  date?: any
  subject?: string
  line_ids: TravelPerDiemLine[]
  total_euro_per_diem?: any
  total_per_diem?: any
  total_days?: any
}

export const initialTransfaction: TravelPerDiem = {
  name: '',

  line_ids: [
    {
      line_sequence: '',
      perdiem_x: '',
      days: '',
      euro_per_diem: '',
      per_diem: '',
      booked_travel: '',
      comment: '',
      is_euro_per_diem: '',
      id: 0
    },
    // Add more initial values for additional lines
  ]
}

export type TravelPerDiemQueryResponse = Response<Array<TravelPerDiem>>

