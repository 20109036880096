import axios, { AxiosResponse } from 'axios'
import { Response } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper'

const MODEL_NAME = 'az.transaction.type'
const GET_URL = `${API_URL}/${MODEL_NAME}/`
const GET_URL_SETTING_DATE = `${API_URL}/az.date.type.setting/`
const INCLUDE_FIELDS = `'id', 'technical_name'`
const INCLUDE_FIELDS_SETTING_DATE = `'back_dated', 'start_of_month'`

const checkTrnsactionTypeAccessId = (menu_name?: string, action?: string): Promise<any> => {
  return axios
    .get(`${API_URL}/${menu_name}/access_right/${action}`)
    .then((response: AxiosResponse<any>) => response.data['allowed']).then(allowed => {
      return allowed
    })
}

const checkDateConstrains = (country_id, company_id, brand_id, type_technical_name): Promise<any> => {
  return axios
    .get(`${GET_URL_SETTING_DATE}?filters=[('az_country_ids','in',[${country_id}, False]), ('az_company_ids','in',[${company_id}, False]),
    ('brand_ids','in',[${brand_id}, False]), ('technical_name','=','${type_technical_name}')
  ]&include_fields=[${INCLUDE_FIELDS_SETTING_DATE}]`)
    .then((response: AxiosResponse<any>) => response.data)
}
export { checkTrnsactionTypeAccessId, MODEL_NAME, checkDateConstrains }