import axios from 'axios';
import DataTable from 'react-data-table-component';
import React, { useState, useEffect } from 'react';
import { KTCardBody } from '_metronic/helpers/components/KTCardBody';
import { API_URL } from './azkHelper';
import useFixServerClientData from 'app/utilities/fix-Server-Client-Data';

type AzkDataTableProps = {
    modelName: string;
    fields: string;
    columns: any;
    dataDomain: string;
    dataOrder?: string;
    exportFunction?: Function,
    onRowClickedFunction?: Function,
    pageReady?: boolean,
    selectableRows?: boolean,
    diableSelectableRows?: boolean
    forceRefresh?: boolean
};


const AzkDataTable: React.FC<AzkDataTableProps> = ({ modelName, fields, columns, dataDomain, dataOrder, exportFunction = Function, pageReady = true, selectableRows= true, diableSelectableRows= false, onRowClickedFunction = Function, forceRefresh=false }) => {
    const [items, setData] = useState<any[]>([]);
    const [pending, setPending] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [offset, setOffset] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [order, setOrder] = useState('');

    const handlePageChange = page => {
        let newOffset = page > 0 ? (page - 1) * perPage : 0;
        setOffset(newOffset);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setOrder(`"${column.tech_name} ${sortDirection}"`);
    };

    const onSelectedRowsChange = row => {
        exportFunction(row.selectedRows)
    };

    const onRowClickedFun = row=>{
        onRowClickedFunction(row)
    };

    const { FixServerArrayData } = useFixServerClientData();

    useEffect(() => {
        const fetchItems = async () => {
            if (pageReady) {
                setPending(true);
                let remoteData = []
                try {
                    const result = await axios.get(`${API_URL}/${modelName}?filters=[${dataDomain}]&include_fields=[${fields}]&offset=${offset}&limit=${perPage}&order=${order ? order : dataOrder}`); //dataOrder ? dataOrder : 
                    remoteData = result.data?.results ? result.data.results : []
                    setTotalRows(result?.data?.recordsTotal);
                } catch (error) {
                    console.error(error);
                }
                const data = await FixServerArrayData(remoteData)
                if (data)
                    setData(data);
                setPending(false);
            }
        };

        fetchItems();
    }, [dataDomain, fields, modelName, offset, order, perPage, pageReady, forceRefresh]);


    return (
        <KTCardBody className='py-4 cutom-padding'>
            <div className='table-responsive'>
                <DataTable
                    columns={columns}
                    data={items}
                    onSort={handleSort}
                    onRowClicked={onRowClickedFun}
                    sortServer
                    progressPending={pending}
                    pagination
                    paginationServer
                    selectableRowDisabled={(row) => {
                       return diableSelectableRows && (row['state'] !== "to_approve" || row['restarted'])
                    }}
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    selectableRows={selectableRows}
                    // expandableRows={true}
                    // expandableRowsComponent
                    onSelectedRowsChange={onSelectedRowsChange}
                />
            </div>
        </KTCardBody>
    );
};

export default AzkDataTable;