import { Response } from "_metronic/helpers"

export type Payslip = {
  employee_id?: number
  date?: string
  fullName?: string
  companyName?: string
  branchName?: string
  title?: string
  nationality?: string
  grade?: string
  startDate?: string
  elementName?: string
  amount?: string
  amountYTD?: string
  commissionHours?: number
  nonCommissionHours?: number
  paidHours?: number
  effectiveDate?: string
  maritalStatus?: string
  annualLeaveBalance?: string
  details?:[{
    label?: string
    type?: string
    amountYTD?: string
    amount?: string
  }],
  subDetails?:[{
    elementName?: string
    label?: string
    type?: string
    amount?: string
  }],
  payslip_month?: string,
  payslip_year?: string,
}

export const initialTransfaction: Payslip = {
}

export type PayslipQueryResponse = Response<Array<Payslip>>

export const initialTransferCompany: Payslip = {

}
