import AzkSelect from "_azkatech/azkSelect";
import { EmployeeDetail, EmpoyeeModel } from "app/modules/components/employeeDetail/employeeDetail";
import axios, { ResponseType } from "axios";
import * as Yup from 'yup'
import { SelfService, SelfServiceFields, initialTransfaction } from "./model";
import { FC, useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik, FormikProps, FormikValues } from 'formik'
import { CreateSelfService, UpdateSelfService, GetSelfServiceById, MODEL_NAME, DeleteSelfService } from "./_requests";
import "react-datepicker/dist/react-datepicker.css";
import { AzkStatusRibbon } from "_azkatech/azkStatusRibbon";
import AzkActionButtons from "_azkatech/azkActionButtons";
import { Form, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { ID, isNotEmpty, KTIcon } from "_metronic/helpers";
import { useNavigate } from "react-router";
import { API_URL } from "_azkatech/azkHelper";
import { AZKHelper } from "app/modules/components/azkHelper";
import { AzkChatter } from "_azkatech";
import { checkTrnsactionTypeAccessId } from "app/utilities/transactionTyperequests";
import { useParams } from "react-router-dom";
import { GetEmployeeByIdNoSalary, GetEmployeeByUserId } from "app/modules/components/employeeDetail/_requests";
import { AUTH_LOCAL_STORAGE_KEY, AuthModel } from "app/modules/auth";

type Props = {
}
const editTransactionSchema = Yup.object().shape({
    employee_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Employee is required'),
    lookup_code: Yup.string().notRequired(),
    service_type_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('SelfService type is required')
})

const TransactionViewform: FC<Props> = () => {
    const { id } = useParams();
    const Swal = require('sweetalert2')
    const intl = useIntl();
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<SelfService>>(null);
    const [selfServiceTypeDomain, setSelfServiceTypeDomain] = useState('');
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [transactionClosed, setTransactionClosed] = useState<boolean>(false);
    const [canValidate, setCanValidate] = useState<boolean>(false);
    const [canDelete, setCanDelete] = useState<boolean>(false);
    const [needSubmit, setNeedSubmit] = useState<boolean>(false);
    const [employee, setEmployee] = useState<EmpoyeeModel>();
    const [transactionState, setTransactionState] = useState<string>('draft');
    const [transactionStateName, setTransactionStateName] = useState<string>('Draft');
    const [hitSend, setHitSend] = useState<boolean>(false);
    const [objectId, setObjectId] = useState<number>();

    const [formFields, setFormFields] = useState<SelfServiceFields[]>();
    const [hasFileUploaded, setHasFileUploaded] = useState<any>(false);

    const [mangerUser, setMangerUser] = useState<boolean>(false);
    const [shopUser, setShopUser] = useState<boolean>(true);
    const [employeeDomain, setEmployeeDomain] = useState<string>('');
    const checkUserType = () => {
        let lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
        if (lsValue) {
            const auth: AuthModel = JSON.parse(lsValue) as AuthModel
            if (auth && auth.uid) {
                GetEmployeeByUserId(auth.uid).then(response => {
                    if (response) {
                        let domain = ``
                        if (response.is_back_office_fr && response.is_back_office_fr !== null) setShopUser(!response?.is_back_office_fr)
                        if (response.is_manager_fr) setMangerUser(response?.is_manager_fr)
                        // if (response.is_manager_fr && response.is_back_office_fr) {
                        //     domain = domain.concat(`'|', ('id', '=', ${response.id})`)
                        // }
                        // if (!response.is_manager_fr && !response.is_back_office_fr) {
                        //     domain = domain.concat(`('id', '=', ${response.id})`)
                        // }
                        // if (!response.is_manager_fr && response.is_back_office_fr) {
                        //     domain = domain.concat(`('id', '=', ${response.id})`)
                        // }
                        if (response.is_manager_fr && !response.is_back_office_fr) {
                            domain = domain.concat(`'|', '|', ('id', '=', ${response.id}), ('parent_id', '=', ${response.id}), ('super_parent_id', '=', ${response.id})`)
                        } else {
                            domain = domain.concat(`('id', '=', ${response.id})`)
                        }
                        setEmployeeDomain(domain)
                    }

                })
            }
        }
    }

    const handleSelfServiceTypeChange = (typeId) => {
        if (typeId !== '' && typeId) {
            axios.get(`${API_URL}/az.self.service.type?filters=[('id', '=', ${typeId ? typeId : '0'})]&include_fields=['name',('fields', [('id' ,'name', 'type', 'required', 'model', 'data_set')])]`).then((result: any) => {
                if (result.data && result.data.results && result.data.count > 0 && result.data.results[0] !== null) {
                    setFormFields(result.data.results[0]['fields'])
                }
            });
        }
    }
    const handleEmployeeChange = async function (employeeId, SelfServiceData): Promise<{}> {
        document.body.classList.add('page-loading')
        if (employeeId) {
            setEmployee(undefined)
            GetEmployeeByIdNoSalary(employeeId).then(response => {
                if (response) {
                    const tempEmployee = response
                    setEmployee(response)
                    setSelfServiceTypeDomain(tempEmployee.az_country_id ? `('country_id','=', ${tempEmployee.az_country_id?.id})` : '');
                    // handleJobChange(false)
                    formRef.current?.setValues({
                        employee_id: tempEmployee.id,
                        az_country_id: tempEmployee.az_country_id?.id,
                        az_company_id: tempEmployee.az_company_id?.id,
                        branch_id: tempEmployee.branch_id?.id,
                        brand_id: tempEmployee.brand_id?.id,
                        grade_id: tempEmployee.grade_id?.id,
                        section_id: tempEmployee.section_id?.id,
                        job_id: tempEmployee.job_id?.id,
                        // time_set: tempEmployee.time_set,
                        // ---------------------------------------------------
                        self_service_field: []
                    })
                    if (SelfServiceData) {
                        pobulateFormData(SelfServiceData)
                    } else {
                    }
                    document.body.classList.remove('page-loading')
                } else {
                    document.body.classList.remove('page-loading')
                }
            });
        } else {
            document.body.classList.remove('page-loading')
            formRef.current?.resetForm();
            setEmployee(undefined)
            formRef.current?.setValues({
                employee_id: undefined,
                az_country_id: undefined,
                az_company_id: undefined,
                branch_id: undefined,
                brand_id: undefined,
                grade_id: undefined,
                section_id: undefined,
                job_id: undefined,
                time_set: undefined,
                self_service_field: []
            })
        }
        return true
    }
    const pobulateFormData = async (data?: SelfService) => {
        setHasFileUploaded(false)
        if (data) {
            if (data.file_id) {
                setHasFileUploaded(true)
            }
            formRef.current?.setValues({
                id: data.id,
                employee_id: data.employee_id?.id,
                az_country_id: data.az_country_id?.id,
                az_company_id: data.az_company_id?.id,
                branch_id: data.branch_id?.id,
                brand_id: data.brand_id?.id,
                grade_id: data.grade_id?.id,
                section_id: data.section_id?.id,
                job_id: data.job_id?.id,
                time_set: data.time_set,
                comment: data.comment,
                file_id: data.file_id,
                azadea_employee_name: data.azadea_employee_name,
                service_type_id: data?.service_type_id?.id,
                self_service_field: []
            })
        }
    }

    const checkPermission = async (action: string) => {
        const allowed = await checkTrnsactionTypeAccessId(MODEL_NAME, action)
        if (!allowed) {
            if (id) {
                const allowed_read = await checkTrnsactionTypeAccessId(MODEL_NAME, 'read')
                if (!allowed_read) {
                    navigate(`/self-service/service/`, { state: {}, replace: true });
                    Swal.fire({
                        position: "top-right",
                        title: 'Access Error!',
                        text: 'You does not have access to perform read action!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                } else {
                    setReadOnly(true)
                }
            } else {
                navigate(`/self-service/service/`, { state: {}, replace: true });
                Swal.fire({
                    position: "top-right",
                    title: 'Access Error!',
                    text: 'You does not have access to perform this action!',
                    icon: "error",
                    showConfirmButton: false,
                    timer: AZKHelper.SWAL_TOAST_TIMER,
                    toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
            }
        }
    }

    useEffect(() => {
        checkUserType()
        document.body.classList.add('page-loading')
        formRef.current?.resetForm()
        setObjectId(undefined)
        setCanValidate(false)
        handleEmployeeChange(undefined, undefined)
        if (id) {
            setObjectId(parseInt(id))
            checkPermission('write');
            document.body.classList.add('page-loading')
            GetSelfServiceById(parseInt(id)).then(((data: SelfService) => {
                handleEmployeeChange(data?.employee_id?.id, data)
                if (data?.x_state && data?.x_state_view) {
                    setTransactionState(data?.x_state)
                    setTransactionStateName(data?.x_state_view)
                }
                if (data?.can_write && data?.can_write !== null) {
                    setCanEdit(data?.can_write)
                }
                setTransactionClosed(data.validated || data.rejected ? true : false)
                setCanValidate(data?.can_review ? true : false)
                setCanDelete(data?.can_delete ? true : false)
                setFormFields(data?.self_service_field)
            }))
        } else {
            checkPermission('create');
            formRef.current?.setFieldValue('employee_id', undefined)
            handleEmployeeChange(undefined, undefined)
            setCanEdit(true)
            document.body.classList.remove('page-loading')
        }
    }, [id])


    const cancel = () => {
        navigate(`/self-service/service/`, { state: {}, replace: true });
    }

    const deleteTransaction = () => {
        Swal.fire({
            position: "center",
            title: 'Delete',
            text: 'Delete Transaction confirm?',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed && id) {
                DeleteSelfService(parseInt(id)).then(() => {
                    cancel()
                    Swal.fire({
                        position: "top-right",
                        title: 'Deleted!',
                        text: 'Your transaction had been deleted!',
                        icon: "warning",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                })
            } else if (result.isDenied) {
                Swal.fire('Transaction was not deleted', '', 'info')
            }
        })
    }

    const submitTransaction = (transactionId: number) => {
        axios.put(`${API_URL}/${MODEL_NAME}/${transactionId}/request_validation`, '{}').then(data => {
            document.body.classList.remove('page-loading')
            navigate(`/self-service/service/`, { state: {}, replace: true });
            Swal.fire({
                position: "top-right",
                title: 'Success!',
                text: 'Your transaction had been submited!',
                icon: "success",
                showConfirmButton: false,
                timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        })
    }

    const handleResultOfsubmit = (transactionId: ID | undefined, isUpdate: boolean | false) => {
        document.body.classList.remove('page-loading')
        navigate(`/self-service/service/`, { state: {}, replace: true })
        Swal.fire({
            position: "top-right",
            title: 'Success!',
            text: isUpdate ? 'Your transaction had been updated!' : 'Your transaction had been saved!',
            icon: "success",
            showConfirmButton: false,
            timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
            toast: true,
            iconColor: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            timerProgressBar: true
        })
    }

    const onSubmitHandler = async (values: SelfService, actions: FormikValues) => {
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        if (formRef.current?.isValid) {
            document.body.classList.add('page-loading')
            if (isNotEmpty(values.id)) {
                await UpdateSelfService(values).then(data => {
                    if (id) handleResultOfsubmit(parseInt(id), true)
                })
            } else {
                await CreateSelfService(values).then(data => {
                    setObjectId(data?.id)
                    setHitSend(true)
                })
            }
        } else {
            actions.setSubmitting(false)
            formRef.current?.validateForm().then(errors => {
                if (Object.keys(errors).length > 0) {
                    console.log(errors)
                    //   setStatus('error');
                }
            });
        }
    }

    const approveTransaction = () => {
        axios.put(`${API_URL}/${MODEL_NAME}/${id}/validate_tier`, '{}').then(data => {
            document.body.classList.remove('page-loading')
            Swal.fire({
                position: "top-right",
                title: 'Success!',
                text: intl.formatMessage({ id: 'TRANSACTION.APPROVED' }),
                icon: "success",
                showConfirmButton: false,
                timer: AZKHelper.SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
            navigate('/self-service/service/', { state: {}, replace: true });
        })
    }
    const addcomment = async () => {
        const comment = formRef?.current?.values.comment
        if (id && comment) {
            axios.put(`${API_URL}/${MODEL_NAME}/${parseInt(id)}/add_comment`, { 'comment': comment }).then(response => {
                approveTransaction()
            })
        } else {
            approveTransaction()
        }
    }

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        document.body.classList.add('page-loading')
        if (files) {
            await Array.from(files).forEach((file, index) => {
                const fileType = file.type;
                if (AZKHelper.ALLOWED_DOC_TYPES.includes(fileType)) {
                    const reader = new FileReader();
                    reader.onloadend = async () => {
                        const arrayBuffer = reader.result;
                        let datas = ''
                        if (arrayBuffer instanceof ArrayBuffer) {
                            let binary = '';
                            const uint8Array = new Uint8Array(arrayBuffer);
                            const len = uint8Array.byteLength;
                            for (let i = 0; i < len; i++) {
                                binary += String.fromCharCode(uint8Array[i]);
                            }
                            datas = window.btoa(binary)
                        } else if (arrayBuffer) {
                            datas = arrayBuffer.split(',')[1]
                        }
                        const formData = new FormData();
                        formData.append('res_model', MODEL_NAME);
                        if (id) formData.append('res_id', '' + parseInt(id));
                        formData.append('name', 'FS' + file.name);
                        formData.append('type', "binary");
                        formData.append('datas', datas);
                        axios.post(`${API_URL}/attachments/upload`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then(response => {
                            if (id && response && response.data) {
                                axios.put(`${API_URL}/${MODEL_NAME}/${parseInt(id)}/upload_pdf`, { 'attachment_id': response.data['id'] }).then(response => {
                                    // updateForm(parseInt(id))
                                    document.body.classList.remove('page-loading')
                                    Swal.fire({
                                        position: "top-right",
                                        title: 'Success!',
                                        text: 'The File had been uploaded!',
                                        icon: "success",
                                        showConfirmButton: false,
                                        timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                                        toast: true,
                                        iconColor: 'white',
                                        customClass: {
                                            popup: 'colored-toast'
                                        },
                                        timerProgressBar: true
                                    })
                                }, err => {
                                    document.body.classList.remove('page-loading')
                                })
                                setHasFileUploaded(true)
                                formRef.current?.setFieldValue('file_id', response.data.id)
                            }
                            return response.data.id
                        }).catch(function (error) {
                            console.log(error)
                            document.body.classList.remove('page-loading')
                            return error;
                        })
                    }
                    reader.readAsArrayBuffer(file);
                } else {
                    document.body.classList.remove('page-loading')
                    Swal.fire({
                        position: "top-right",
                        title: 'Sorry!',
                        text: 'Please check you attachments, allowed file types (' + AZKHelper.ALLOWED_DOC_TYPE + ')!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: 5000,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                };
            })
        }
    }

    const downloadFilePDF = (responseType: ResponseType = 'blob') => {
        const file_id = formRef?.current?.values.file_id
        if (file_id) {
            axios
                .get(`${API_URL}/attachments/${file_id.id}`, {
                    responseType,
                })
                .then((response) => {
                    let file_name = response.headers['content-disposition']
                    file_name = file_name.replace('attachment; filename=', '')
                    file_name = file_name.replaceAll('\"', '')
                    const blob = new Blob([response.data], { type: response.headers['content-type'] })
                    const url = window.URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', file_name)
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(url)
                })
                .catch((error) => {
                    console.error('Download error:', error)
                })
        }
    }
    return (
        <>
            <Row className="m-0 my-2">
                <Col md='12' lg={id ? '9' : '9'} xl={id ? '9' : '9'}>
                    <Formik
                        innerRef={formRef}
                        validationSchema={editTransactionSchema}
                        onSubmit={onSubmitHandler}
                        initialValues={initialTransfaction}
                        validateeOnChange={true}
                        validateOnMount={true}
                        initialErrors={''}
                    >
                        {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
                            // ,validateForm,isSubmitting, errors, touched, isValidating
                            return (
                                <>
                                    {/* {id && (<AzkStatusBar transactionId={id} modelName={MODEL_NAME}
                                        current={transactionState} />
                                    )} */}
                                    {canValidate && id &&
                                        <AzkActionButtons
                                            backLink="/self-service/service/"
                                            actionUrl={`${API_URL}/${MODEL_NAME}/${id}/`}
                                            thenNavigate="/self-service/service/"
                                            divStyle="card py-2 mb-3"
                                            buttons={[
                                                { title: "Approve", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.APPROVED' }), method: "validate_tier", confirmMsg: "Approve Transaction, Confirm?", cssClass: "btn btn-light btn-light-success", icon: "fa-thumbs-up", callback: addcomment },
                                                // { title: "Review", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.REVIEW' }), method: "restart_validation", confirmMsg: "Send Transaction back to draft, Confirm?", cssClass: "btn btn-light btn-light-warning", icon: "fa-refresh" },
                                                { title: "Decline", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.DECLINE' }), method: "reject_tier", confirmMsg: "Decline Transaction, Confirm?", cssClass: "btn btn-light btn-light-danger", icon: "fa-times" },
                                                hasFileUploaded && { title: "Download PDF", cssClass: "btn btn-light btn-light-warning", icon: "fa-download", callback: downloadFilePDF },
                                            ]} />}

                                    <Form onSubmit={handleSubmit} noValidate>
                                        <fieldset disabled={!canEdit || readOnly}>
                                            {!id && (
                                                <Row className="card m-0 py-2 mb-3">
                                                    <Col>
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label>
                                                                <h4 className="px-3 m-0 custom-employee-header justify-content-start">
                                                                    <i className="fa fa-magnifying-glass" aria-hidden="true"></i>{intl.formatMessage({ id: 'TRANSACTION.EMPLOYEE' })}
                                                                </h4>
                                                            </Form.Label>
                                                            <AzkSelect
                                                                compID="azk_temp"
                                                                modelName="hr.employee"
                                                                valueField="id"
                                                                titleField="display_name_fr"
                                                                dataDomain={employeeDomain}
                                                                allowEmpty={true}
                                                                fieldName='employee_id'
                                                                onChange={(value) => {
                                                                    setFieldValue('employee_id', parseInt(value))
                                                                    handleEmployeeChange(value, undefined)
                                                                }}
                                                                defaultValue={values.employee_id}
                                                                disabled={!canEdit}
                                                                searchFields={['name', 'azadea_id', 'branch_id', 'job_id', 'work_email', 'first_name']} />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='employee_id' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            )}
                                        </fieldset>
                                        {employee && (
                                            <>
                                                <EmployeeDetail employee={employee} />
                                                <Row className="m-0 card">
                                                    <h4 className="py-2 px-6 m-0 card-header custom-card-header justify-content-start">
                                                        <i className="fa fa-info-circle" aria-hidden="true"></i>SelfService Detail</h4>
                                                    <fieldset disabled={!canEdit || readOnly}>
                                                        <Row className="m-0 my-2">
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Self Service Type
                                                                    </Form.Label>
                                                                    <AzkSelect
                                                                        compID="az_service_type_id_select"
                                                                        modelName="az.self.service.type"
                                                                        valueField="id"
                                                                        titleField="name"
                                                                        dataDomain={selfServiceTypeDomain}
                                                                        isMultiple={false}
                                                                        allowEmpty={true}
                                                                        fieldName='service_type_id'
                                                                        disabled={!canEdit}
                                                                        onChange={(value) => {
                                                                            setFieldValue('service_type_id', parseInt(value))
                                                                            handleSelfServiceTypeChange(parseInt(value))
                                                                        }}
                                                                        defaultValue={values.service_type_id} />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='service_type_id' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </fieldset>
                                                    <Row className="m-0 my-2">
                                                        {formFields && formFields.length > 0 &&
                                                            formFields.map(field => {
                                                                return <>
                                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                                        <fieldset disabled={!canEdit || readOnly}>
                                                                            <Form.Group className='mb-2'>
                                                                                <Form.Label>
                                                                                    {field.key}
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    className="col-12 p-1"
                                                                                    defaultValue={field.value}>
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </fieldset>
                                                                    </Col>
                                                                </>
                                                            })
                                                        }
                                                    </Row >
                                                    <Row className="m-0 my-2">
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label>
                                                                    Upload File
                                                                </Form.Label>
                                                                <Form.Control
                                                                    accept={AZKHelper.ALLOWED_DOC_TYPE}
                                                                    disabled={!canValidate}
                                                                    className="p-1"
                                                                    type='file'
                                                                    id='file'
                                                                    onChange={handleFileChange} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label>
                                                                    Approver Comment
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled={!canValidate}
                                                                    type='text'
                                                                    className="p-1"
                                                                    name="approver-comment"
                                                                    id='approver-comment'
                                                                    onChange={(value) => {
                                                                        setFieldValue('comment', value.target.value)
                                                                    }}
                                                                    value={values.comment}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row >

                                                    {/* begin::Actions */}
                                                    <div className='py-5 d-flex justify-content-end'>
                                                        {canValidate || canEdit &&
                                                            <button
                                                                title="Submit for approval"
                                                                type='submit'
                                                                className='btn btn-light btn-light-info mx-5 btn-sm'
                                                                disabled={!canEdit}>
                                                                <i className="fa fa-podcast fs-2" aria-hidden="true"></i>
                                                                <span className='indicator-label'>Submit</span>
                                                            </button>
                                                        }
                                                        {!canValidate && <>
                                                            {hasFileUploaded &&
                                                                <button
                                                                    title="Download PDF"
                                                                    type='reset'
                                                                    onClick={() => downloadFilePDF()}
                                                                    className='btn btn-light btn-light-warning me-3 mx-5 btn-sm'
                                                                    data-kt-users-modal-action='cancel'
                                                                >
                                                                    <KTIcon iconName='fa-download' className='fs-2' />
                                                                    <span className='indicator-label'>Download PDF</span>

                                                                </button>
                                                            }
                                                            <button
                                                                title="Discard & back"
                                                                type='reset'
                                                                onClick={() => cancel()}
                                                                className='btn btn-light btn-active-light-primary me-3 mx-5 btn-sm'
                                                                data-kt-users-modal-action='cancel'
                                                            >
                                                                <KTIcon iconName='exit-left' className='fs-2' />
                                                                <span className='indicator-label'>Discard</span>

                                                            </button>
                                                        </>
                                                        }
                                                    </div>
                                                    {/* end::Actions */}
                                                </Row>
                                            </>)}
                                    </Form>
                                </>
                            )
                        }}
                    </Formik>
                </Col>
                <Col md='12' lg='3' xl='3'>
                    {id && (<AzkStatusRibbon transactionId={parseInt(id)} modelName={MODEL_NAME}
                        current={transactionState} currentName={transactionStateName} />
                    )}
                    {employee && <AzkChatter transactionClosed={transactionClosed} isDrawer={true} modelName={MODEL_NAME} itemID={objectId} hitSend={hitSend} sendMessagesCallback={handleResultOfsubmit} />}
                </Col>
            </Row>
        </>
    )
}

export default TransactionViewform;
