/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '_metronic/assets/ts/_utils'
import { KTIcon } from '_metronic/helpers'
import { GetTransactionSubmitDurationChartData } from './_requests-transaction-submit-duration-chart'
import { TransactrionsStatusChartFilter } from './transaction-status-chart-filter'
import { AZKHelper } from '../components/azkHelper'
import { MODEL_NAME } from './_requests-transaction-submit-duration-chart'
import { useAuth } from '../auth'

type Props = {
    className: string
}

const TransactrionSubmitDurationChart: React.FC<Props> = ({ className }) => {
    const { hasAuthority } = useAuth()
    const chartRef = useRef<HTMLDivElement | null>(null)
    let categories: string[] = []
    let seriesList: any[] = []
    const [noData, setNoData] = useState<boolean>(false);
    const [canAccess, setCanAccess] = useState<boolean | undefined>(undefined);

    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }
        const height = parseInt(getCSS(chartRef.current, 'height'))
        chartRef.current.innerHTML = ''
        const chart = new ApexCharts(chartRef.current, getChartOptions(height, categories, seriesList))
        if (chart) {
            chart.render()
        }
        if (chart && categories.length === 0) {
            chart.destroy()
        }
        return chart
    }

    const checkAccess = async () => {
        if (canAccess === undefined) {
            let allowedData = await hasAuthority(MODEL_NAME, AZKHelper.READ_ACTION)
            setCanAccess(allowedData)
            return allowedData
        } else {
            return canAccess
        }
    }

    const fillChartData: any = async (dataDomain: string) => {
        if (await checkAccess()) {
            setNoData(false)
            categories = []
            seriesList = []
            const seriesListCounting: number[] = []
            const seriesListDuration: number[] = []
            GetTransactionSubmitDurationChartData(dataDomain).then(async data => {
                if (data && data.length > 0) {
                    await data.forEach(record => {
                        if (!record.count) {
                            record.count = 0
                        }
                        if (!record.submit_duration) {
                            record.submit_duration = 0
                        }
                        const categoryName = record.type_name
                        let categoryIndex = categories.indexOf(categoryName)
                        if (categoryIndex === -1) {
                            categories.push(categoryName)
                            categoryIndex = categories.indexOf(categoryName)
                            seriesListCounting[categoryIndex] = 0
                            seriesListDuration[categoryIndex] = 0
                        }
                        seriesListCounting[categoryIndex] = seriesListCounting[categoryIndex] + record.count
                        seriesListDuration[categoryIndex] = seriesListDuration[categoryIndex] + record.submit_duration
                    })
                    await seriesListCounting.forEach((val, index) => {
                        if (val > 0) {
                            seriesList[index] = { name: categories[index], data: [seriesListDuration[index] / val] }
                        }
                    })
                    return refreshChart()
                } else {
                    setNoData(true)
                    return refreshChart()
                }
            }, () => {
                setNoData(true)
                return refreshChart()
            })
        }
    }

    useEffect(() => {
        const chart = fillChartData('')
        return () => {
            if (chart) {
                try {
                    chart.destroy()
                } catch (error) {
                    console.error(error);
                }
            }
        }
    }, [])

    return (
        <>
            {canAccess &&
                <div className='col-xl-4 col-lg-6 col-md-12'>
                    <div className={`card ${className}`}>
                        {/* begin::Header */}
                        <div className='card-header border-0 pt-5 d-flex justify-content-between'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bold fs-4 mb-1'>Transaction AVG Timeline</span>
                            </h3>
                            <div className='card-toolbar'>
                                {/* begin::Menu */}
                                <button
                                    type='button'
                                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                >
                                    <KTIcon iconName='category' className='fs-2' />
                                </button>
                                {/* <TransactrionsStatusChartFilter callBackFun={fillChartData} /> */}
                                {/* end::Menu */}
                            </div>
                        </div>
                        {/* end::Header */}

                        {/* begin::Body */}
                        <div className='card-body'>
                            {/* begin::Chart */}
                            {noData && <h6 className='text-center'>No data to show</h6>}
                            {!noData && <div ref={chartRef} id='kt_charts_widget_2_chart' style={{ height: '350px' }}></div>}
                            {/* end::Chart */}
                        </div>
                        {/* end::Body */}
                    </div>
                </div>
            }</>
    )
}

export { TransactrionSubmitDurationChart }

function getChartOptions(height: number, categories: string[], seriesList: any[]): ApexOptions {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    // const dueColor = getCSSVariableValue('--bs-danger')
    // const toPayColor = getCSSVariableValue('--bs-warning')
    // const completeColor = getCSSVariableValue('--bs-success')
    // const inprogressColor = getCSSVariableValue('--bs-info')

    return {
        series: seriesList,
        chart: {
            fontFamily: 'inherit',
            type: 'bar',
            height: height,
            toolbar: {
                show: true,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '30px',
                borderRadius: 5,
            },
        },
        legend: {
            show: true,
            position: 'bottom'
        },
        labels: categories,
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return parseFloat('' + val).toFixed(2) + "day"
            },
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: categories,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        fill: {
            opacity: 1,
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return + val + '  Days'
                },
            },
        },
        // colors: [completeColor, inprogressColor, toPayColor, dueColor],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: false,
                },
            },
        },
    }
}
