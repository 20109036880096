import * as Yup from 'yup'
import { FC, useEffect, useRef, useState } from "react";
import { ErrorMessage, FieldArray, Formik, FormikProps, FormikValues } from 'formik'
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import { AZKHelper } from "app/modules/components/azkHelper";
import { KTIcon } from '_metronic/helpers';
import { TravelPerDiemLine, TravelPerDiemLineDetail } from './model';
import AzkSelect from '_azkatech/azkSelect';
import { validate } from '@material-ui/pickers';

type Props = {
    lineData?: TravelPerDiemLine,
    closeModal: () => {}
    confirmModal: (values) => {}
}
const editTransactionSchema = Yup.object().shape({
    detail_ids: Yup.array().of(
        Yup.object().shape({
            date: Yup.date().transform((value) => !moment(value).isValid() ? undefined : value).required('Date is required'),
            perdiem_x: Yup.number().required('Field id is required'),
        })
    )
})

const initialTransaction: TravelPerDiemLineDetail = {
    id: 0
}

const PerDiemDetailModal: FC<Props> = ({ lineData, closeModal, confirmModal }) => {
    const Swal = require('sweetalert2')
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<TravelPerDiemLine>>(null);
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const pobulateFormData = async (data?: TravelPerDiemLine) => {
        if (data) {
            if (!data?.detail_ids || data?.detail_ids.length === 0 || data?.detail_ids.length !== data.days) {
                const details: TravelPerDiemLineDetail[] = []
                for (let i = 0; i < data.days; i++) {
                    let travelPerDiemLineDetail: TravelPerDiemLineDetail = {}
                    travelPerDiemLineDetail.date = moment(data.date).add(i, 'day').format(AZKHelper.BACKEND_DATE_FORMAT)
                    travelPerDiemLineDetail.is_euro_per_diem = data.is_euro_per_diem
                    travelPerDiemLineDetail.perdiem_x = parseFloat(data.perdiem_x)
                    travelPerDiemLineDetail.comment = data.comment
                    details.push(travelPerDiemLineDetail)
                }
                data.detail_ids = details
            }
            formRef.current?.setValues({
                detail_ids: data?.detail_ids,
            })
        }
    }

    useEffect(() => {
        // document.body.classList.add('page-loading')
        if (lineData) {
            pobulateFormData(lineData)
        } else {
            document.body.classList.remove('page-loading')
        }
    }, [])

    const cancel = () => {
        navigate(`/transactions/`, { state: {}, replace: true });
    }

    const onSubmitHandler = async (values: TravelPerDiemLine, actions: FormikValues) => {
        // document.body.classList.add('page-loading')
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        if (formRef.current?.isValid) {
            confirmModal(values)
        }
    }

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered mw-100'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>

                        <div className='modal-header py-2'>
                            {/* begin::Modal title */}
                            <h4 className='m-0'>New Per diem</h4>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => closeModal()}
                                style={{ cursor: 'pointer' }}
                            >
                                <i className="fa fa-times fs-1 btn btn-sm" aria-hidden="true"></i>
                            </div>
                            {/* end::Close */}
                        </div>
                        {/* begin::Modal body */}
                        <div className='modal-body scroll-y mx-5 mx-xl-7 my-5' >
                            <Row className="m-0 my-2">
                                <Col md='12' lg='12' xl='12'>
                                    <Formik
                                        innerRef={formRef}
                                        validationSchema={editTransactionSchema}
                                        onSubmit={onSubmitHandler}
                                        initialValues={initialTransaction}
                                        validateeOnChange={true}
                                        validateOnMount={true}
                                        initialErrors={''}>
                                        {({ values, setFieldValue, handleSubmit, isSubmitting, validateForm }) => {
                                            // ,validateForm,isSubmitting, errors, touched, isValidating
                                            return (
                                                <><Form onSubmit={handleSubmit} noValidate>
                                                    <Row className="m-0">
                                                        <fieldset>
                                                            <Row className="m-0 my-2">

                                                                <div className=''> {/* table-responsive */}
                                                                    <table
                                                                        id='kt_table_users'
                                                                        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                                                        <thead>
                                                                            <tr className='text-start text-muted fw-bold fs-8 text-uppercase gs-0'>
                                                                                <th style={{ width: "20%" }}>Date</th>
                                                                                <th style={{ width: "35%" }}>Per Diem</th>
                                                                                <th style={{ width: "25%" }}></th>
                                                                                <th style={{ width: "20%" }}>Comment</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className='text-gray-600 fs-8'>
                                                                            <FieldArray name="detail_ids">
                                                                                {({ push, remove }) => (
                                                                                    <>
                                                                                        {values.detail_ids && values?.detail_ids.map((line, index) => (
                                                                                            <>
                                                                                                {values.detail_ids && <>
                                                                                                    <tr key={index}>
                                                                                                        <td>
                                                                                                            <Form.Control
                                                                                                                className='p-1'
                                                                                                                type="date"
                                                                                                                id={`detail_ids[${index}].date`}
                                                                                                                name={`detail_ids[${index}].date`}
                                                                                                                disabled={true}
                                                                                                                value={moment(values?.detail_ids[index].date).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                                                            />
                                                                                                            <div className='text-danger'>
                                                                                                                <ErrorMessage name={`detail_ids[${index}].date`} component="div" />
                                                                                                            </div>
                                                                                                        </td>

                                                                                                        <td>
                                                                                                            <Row className="m-0">
                                                                                                                <Form.Check
                                                                                                                    className='col-lg-2 col-md-2 col-sm-3 col-4'
                                                                                                                    label={"Zero"}
                                                                                                                    value={0}
                                                                                                                    checked={0 === line?.perdiem_x}
                                                                                                                    name={`detail_ids[${index}].perdiem_x`}
                                                                                                                    id={`detail_ids[${index}].perdiem_x`}
                                                                                                                    onClick={(value) => {
                                                                                                                            line.perdiem_x = 0
                                                                                                                            if (values.detail_ids) values.detail_ids[index].perdiem_x = 0
                                                                                                                            validateForm()
                                                                                                                        }}
                                                                                                                    type="radio"
                                                                                                                />
                                                                                                                <Form.Check
                                                                                                                    className='col-lg-2 col-md-2 col-sm-3 col-4'
                                                                                                                    label={"X 1/2"}
                                                                                                                    value={0.5}
                                                                                                                    checked={0.5 === line?.perdiem_x}
                                                                                                                    name={`detail_ids[${index}].perdiem_x`}
                                                                                                                    id={`detail_ids[${index}].perdiem_x`}
                                                                                                                    onClick={(value) => {
                                                                                                                            line.perdiem_x = 0.5
                                                                                                                            if (values.detail_ids) values.detail_ids[index].perdiem_x = 0.5
                                                                                                                            validateForm()
                                                                                                                        }
                                                                                                                    }
                                                                                                                    type="radio"
                                                                                                                />
                                                                                                                <Form.Check
                                                                                                                    className='col-lg-2 col-md-2 col-sm-3 col-4'
                                                                                                                    label={"X 1"}
                                                                                                                    value={1}
                                                                                                                    checked={1 === line?.perdiem_x}
                                                                                                                    name={`detail_ids[${index}].perdiem_x`}
                                                                                                                    id={`detail_ids[${index}].perdiem_x`}
                                                                                                                    onClick={(value) => {
                                                                                                                            line.perdiem_x = 1
                                                                                                                            if (values.detail_ids) values.detail_ids[index].perdiem_x = 1
                                                                                                                            validateForm()
                                                                                                                        }}
                                                                                                                    type="radio"
                                                                                                                />
                                                                                                                <Form.Check
                                                                                                                    className='col-lg-2 col-md-2 col-sm-3 col-4'
                                                                                                                    label={"X 1.5"}
                                                                                                                    value={1.5}
                                                                                                                    defaultChecked={1.5 === line?.perdiem_x}
                                                                                                                    name={`detail_ids[${index}].perdiem_x`}
                                                                                                                    id={`detail_ids[${index}].perdiem_x`}
                                                                                                                    onClick={(value) => {
                                                                                                                            line.perdiem_x = 1.5
                                                                                                                            if (values.detail_ids) values.detail_ids[index].perdiem_x = 1.5
                                                                                                                            validateForm()
                                                                                                                    }}
                                                                                                                    type="radio"
                                                                                                                />
                                                                                                                <Form.Check
                                                                                                                    className='col-lg-2 col-md-2 col-sm-3 col-4'
                                                                                                                    label={"X 2"}
                                                                                                                    value={2}
                                                                                                                    defaultChecked={2 === line?.perdiem_x}
                                                                                                                    name={`detail_ids[${index}].perdiem_x`}
                                                                                                                    id={`detail_ids[${index}].perdiem_x`}
                                                                                                                    onClick={(value) => {
                                                                                                                            line.perdiem_x = 2
                                                                                                                            if (values.detail_ids) values.detail_ids[index].perdiem_x = 2
                                                                                                                            validateForm()
                                                                                                                    }}
                                                                                                                    type="radio"
                                                                                                                />
                                                                                                            </Row>
                                                                                                        </td>

                                                                                                        <td>
                                                                                                            <Row className="m-0">
                                                                                                                <Form.Check
                                                                                                                    className='col-lg-4 col-md-4 col-sm-6 col-6'
                                                                                                                    label={"Not Europ"}
                                                                                                                    defaultChecked={true !== line?.is_euro_per_diem}
                                                                                                                    name={`detail_ids[${index}].is_euro_per_diem`}
                                                                                                                    id={`detail_ids[${index}].is_euro_per_diem`}
                                                                                                                    onClick={(value) => {
                                                                                                                            line.is_euro_per_diem = false
                                                                                                                            if (values.detail_ids) values.detail_ids[index].is_euro_per_diem = false
                                                                                                                            validateForm()
                                                                                                                    }}
                                                                                                                    type="radio"
                                                                                                                // defaultValue={values?.is_euro_per_diem}
                                                                                                                />
                                                                                                                <Form.Check
                                                                                                                    className='col-lg-4 col-md-4 col-sm-6 col-6'
                                                                                                                    label={"Europ"}
                                                                                                                    defaultChecked={true === line?.is_euro_per_diem}
                                                                                                                    name={`detail_ids[${index}].is_euro_per_diem`}
                                                                                                                    id={`detail_ids[${index}].is_euro_per_diem`}
                                                                                                                    onClick={(value) => {
                                                                                                                            line.is_euro_per_diem = true
                                                                                                                            if (values.detail_ids) values.detail_ids[index].is_euro_per_diem = true
                                                                                                                            validateForm()
                                                                                                                    }}
                                                                                                                    type="radio"
                                                                                                                // defaultValue={values?.is_euro_per_diem}
                                                                                                                />
                                                                                                            </Row>
                                                                                                        </td>

                                                                                                        <td><AzkSelect
                                                                                                            modelName="az.travel.request.setting.comment"
                                                                                                            compID={`detail_ids[${index}].comment`}
                                                                                                            valueField="id"
                                                                                                            titleField="comment"
                                                                                                            dataDomain={``}
                                                                                                            allowEmpty={true}
                                                                                                            fieldName='id'
                                                                                                            onChange={(value) => {
                                                                                                                line.comment = parseInt(value)
                                                                                                                if (values.detail_ids) values.detail_ids[index].comment = parseInt(value)
                                                                                                            }}
                                                                                                            defaultValue={line.comment}
                                                                                                            searchFields={['comment']} />
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </>
                                                                                                }
                                                                                            </>
                                                                                        ))}

                                                                                    </>
                                                                                )}
                                                                            </FieldArray>
                                                                        </tbody>
                                                                    </table>


                                                                </div>
                                                            </Row>
                                                        </fieldset>

                                                        {/* begin::Actions */}
                                                        <div className='py-5 d-flex justify-content-end'>
                                                            <button
                                                                title="Save"
                                                                type='submit'
                                                                className='btn btn-light btn-light-info mx-5 btn-sm'
                                                            >
                                                                <i className="fas fa-check fs-2"></i>
                                                                <span className='indicator-label'>Submit</span>
                                                            </button>
                                                            <button
                                                                title="Discard & back"
                                                                type='reset'
                                                                onClick={() => closeModal()}
                                                                className='btn btn-light btn-active-light-primary me-3 mx-5 btn-sm'
                                                                data-kt-users-modal-action='cancel'
                                                            >
                                                                <KTIcon iconName='exit-left' className='fs-2' />
                                                                <span className='indicator-label'>Discard</span>

                                                            </button>
                                                        </div>
                                                        {/* end::Actions */}
                                                    </Row>
                                                </Form>
                                                </>
                                            )
                                        }}
                                    </Formik>
                                </Col>
                            </Row>
                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}

export default PerDiemDetailModal;

