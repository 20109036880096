import * as Yup from 'yup'
import { ExitInterViewRequest, initialTransfaction } from "./model";
import { FC, useEffect, useRef, useState } from "react";
import { ErrorMessage, Field, FieldArray, Formik, FormikProps, FormikValues } from 'formik'
import { UpdateExitInterViewRequest, MODEL_NAME, GetExitInterViewRequestByUserId, RequestValidationExitInterViewRequest } from "./_requests";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Row, Col } from "react-bootstrap";
import { ID, isNotEmpty } from "_metronic/helpers";
import { useNavigate } from "react-router";
import { AZKHelper } from "app/modules/components/azkHelper";
import { checkTrnsactionTypeAccessId } from "app/utilities/transactionTyperequests";
import { AUTH_LOCAL_STORAGE_KEY, AuthModel } from "app/modules/auth";
import { AsyncPaginate } from "react-select-async-paginate";
import TransactionAPPLangSelectionForm from "./app-lang-selections";

type Props = {
}
const editTransactionSchema = Yup.object().shape({
    employee_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Employee is required'),
    submit_approve: Yup.boolean().transform((value) => value==true ? value : undefined).required('Please check this'),
    // office_submit_approve: Yup.boolean(),
    executive_member: Yup.boolean(),
    executive_employee_name: Yup.string().notRequired()
    .test('min', 'Executive employee name is required', function (value) {
        const executiveMember= this.parent['executive_member']
        console.log('--------------', executiveMember)
        if ( executiveMember === true && (!value || value === '')) {
            return false
        } else {
            return true
        }
    }),

})

const TransactionAPPform: FC<Props> = () => {
    const Swal = require('sweetalert2')
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<ExitInterViewRequest>>(null);
    const [exitInterViewRequestId, setExitInterViewRequestId] = useState<number>();
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [exitInterViewRequestClosed, setExitInterViewRequestClosed] = useState<boolean>(false);
    const [activeSectionId, setActiveSectionId] = useState<number>(0);
    const [validateSection, setValidateSection] = useState<boolean>(false);
    const [backOffice, setBackOffice] = useState<boolean>(false);
    const [needLangSelection, setNeedLangSelection] = useState<boolean>(false);
    const [direction, setDirection] = useState<string>('ltr');
    const [selectedLang, setselectedLang] = useState<string>('en');
    const [needSave, setNeedSave] = useState<boolean>(false);
    const [employeeName, setEmployeeName] = useState<string>('');

    const fecthExitInterview = async function (employeeId): Promise<{}> {
        document.body.classList.add('page-loading')
        if (employeeId) {
            GetExitInterViewRequestByUserId(employeeId).then(response => {
                if (response) {
                    if (response.validated === true || response.rejected === true || response.restarted !== true) {
                        setExitInterViewRequestClosed(true)
                    }
                    if (response.restarted === true) setNeedSave(true)
                    setEmployeeName(response.employee_id.name)
                    if (response.lang == null) {
                        setNeedLangSelection(true)
                    } else {
                        setNeedLangSelection(false)
                        if (response.lang == 'ar') setDirection('rtl')
                        else setDirection('ltr')
                        setselectedLang(response.lang)
                    }
                    setExitInterViewRequestId(response.id)
                    formRef.current?.setValues({
                        id: response.id,
                        employee_id: response.employee_id?.id,
                        az_country_id: response.az_country_id?.id,
                        az_company_id: response.az_company_id?.id,
                        branch_id: response.branch_id?.id,
                        brand_id: response.brand_id?.id,
                        grade_id: response.grade_id?.id,
                        section_id: response.section_id?.id,
                        job_id: response.job_id?.id,
                        type_id: response.type_id?.id,
                        sections: response.sections,
                        submit_approve: response.submit_approve,
                        office_submit_approve: response.office_submit_approve,
                        executive_employee_name: response.executive_employee_name && response.executive_employee_name === null ? '' : response.executive_employee_name,
                        hr_department: response.hr_department,
                        executive_member: response.executive_member === true ? true : false,
                    })
                    if (response.type_id && response.type_id.back_office === true) {
                        setBackOffice(true)
                    }
                    document.body.classList.remove('page-loading')
                } else {
                    document.body.classList.remove('page-loading')
                }
            });
        } else {
            document.body.classList.remove('page-loading')
            formRef.current?.resetForm();
        }
        return true
    }

    const checkPermission = async (action: string) => {
        const allowed = await checkTrnsactionTypeAccessId(MODEL_NAME, action)
        if (!allowed) {
            if (exitInterViewRequestId) {
                const allowed_read = await checkTrnsactionTypeAccessId(MODEL_NAME, 'read')
                if (!allowed_read) {
                    Swal.fire({
                        position: "center",
                        text: 'You does not have access to perform read action!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        // toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                } else {
                    setReadOnly(true)
                }
            } else {
                // navigate(`/app/self-service/`, { state: {}, replace: true });
                Swal.fire({
                    position: "center",
                    // title: 'Access Error!',
                    text: 'You does not have access to perform this action!',
                    icon: "error",
                    showConfirmButton: false,
                    timer: AZKHelper.SWAL_TOAST_TIMER,
                    // toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
            }
        }
    }

    useEffect(() => {
        document.body.classList.add('page-loading')
        formRef.current?.resetForm()
        checkPermission('write');
        setCanEdit(true)
        document.body.classList.remove('page-loading')
        reload_page_date()
    }, [needLangSelection])

    const reload_page_date = () => {
        let lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
        if (lsValue) {
            const auth: AuthModel = JSON.parse(lsValue) as AuthModel
            if (auth && auth.uid) {
                fecthExitInterview(auth.uid)
            }
        }
    }

    const handleResultOfsubmit = (transactionId: ID | undefined, isUpdate: boolean | false) => {
        document.body.classList.remove('page-loading')
        Swal.fire({
            position: "center",
            // title: 'Success!',
            text: 'Thank you, Your Exit interview had been submitted!',
            icon: "success",
            showConfirmButton: false,
            // timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
            // toast: true,
            iconColor: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            timerProgressBar: true
        })
    }

    const validateFields = async (sections) => {
        let canSave = true
        // replace the element object
        let questions_to_save: any[] = []
        let sections_to_save: any[] = []
        await sections.map(async (section) => {
            questions_to_save = questions_to_save.concat(section.questions)
            section.questions = undefined
            section.section_id = section.section_id['id']
            sections_to_save.push(section)
        });
        await questions_to_save.map(async (question) => {
            question.question_id = question.question_id['id']
        });
        return { canSave, sections_to_save, questions_to_save }
    }
    const onSubmitHandler = async (values: ExitInterViewRequest, actions: FormikValues) => {
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        if (formRef.current?.isValid) {
            validateFields(values.sections).then(async result => {
                if (result['canSave']) {
                    values.questions = result['questions_to_save']
                    values.sections = result['sections_to_save']
                    document.body.classList.add('page-loading')
                    if (isNotEmpty(values.id)) {
                        await UpdateExitInterViewRequest(values).then(data => {
                            if (exitInterViewRequestId) RequestValidationExitInterViewRequest(exitInterViewRequestId).then(data => {
                                setExitInterViewRequestClosed(true)
                                if (exitInterViewRequestId) handleResultOfsubmit(exitInterViewRequestId, true)
                            })
                        })
                    }
                }
            })

        } else {
            actions.setSubmitting(false)
            formRef.current?.validateForm().then(errors => {
                if (Object.keys(errors).length > 0) {
                    console.log(errors)
                    //   setStatus('error');
                }
            });
        }
    }

    const validateQuestionFields = async (sections) => {
        setValidateSection(true)
        let canSave = true
        // replace the element object
        let questions_to_save: any[] = []
        let sections_to_save: any[] = []
        await sections.map(async (section, index) => {
            if (index === activeSectionId)
                questions_to_save = questions_to_save.concat(section.questions)
        });
        await questions_to_save.map(async (question) => {
            if (question.question_id['required_question'] === true && !question.answer) {
                canSave = false
            }
        });
        return { canSave, sections_to_save, questions_to_save }
    }

    const prepareQuestionFields = async (sections) => {
        let canSave = true
        // replace the element object
        let questions_to_save: any[] = []
        let sections_to_save: any[] = []
        await sections.map(async (section, index) => {
            if (index === activeSectionId) {
                questions_to_save = questions_to_save.concat(section.questions)
                section.questions = undefined
                section.section_id = section.section_id['id']
                sections_to_save.push(section)
            }
        });
        await questions_to_save.map(async (question) => {
            question.question_id = question.question_id['id']
        });
        return { canSave, sections_to_save, questions_to_save }
    }

    const onSimeSubmitHandler = async (values: ExitInterViewRequest) => {
        if (needSave) {
            formRef.current?.setSubmitting(true)
            validateQuestionFields(values.sections).then(async result => {
                if (result['canSave']) {
                    prepareQuestionFields(values.sections).then(async result => {
                        values.questions = result['questions_to_save']
                        values.sections = result['sections_to_save']
                        document.body.classList.add('page-loading')
                        if (isNotEmpty(values.id)) {
                            await UpdateExitInterViewRequest(values).then(data => {
                                reload_page_date()
                                setValidateSection(false)
                                setActiveSectionId(activeSectionId + 1)
                                document.body.classList.remove('page-loading')
                            })
                        }
                    })
                }
            })
        } else {
            setActiveSectionId(activeSectionId + 1)
        }
    }


    return (
        <>
            {needLangSelection &&
                <TransactionAPPLangSelectionForm requestId={exitInterViewRequestId} closeModal={function (): {} {
                    setNeedLangSelection(false)
                    return false;
                }}></TransactionAPPLangSelectionForm>}

            {!needLangSelection &&
                <Row className="m-0 my-2 exit-interview-container" dir={direction}>
                    <Col md='12' lg={exitInterViewRequestId ? '9' : '9'} xl={exitInterViewRequestId ? '9' : '9'}>
                        <Formik
                            innerRef={formRef}
                            validationSchema={editTransactionSchema}
                            onSubmit={onSubmitHandler}
                            initialValues={initialTransfaction}
                            validateeOnChange={true}
                            validateOnMount={true}
                            initialErrors={''}
                        >
                            {({ values, setFieldValue, handleSubmit, isSubmitting, validateForm }) => {
                                // ,validateForm,isSubmitting, errors, touched, isValidating
                                return (
                                    <>
                                        {/* {id && (<AzkStatusBar transactionId={id} modelName={MODEL_NAME}
                                        current={transactionState} />
                                    )} */}
                                        <Form onSubmit={handleSubmit} noValidate>
                                            {exitInterViewRequestId && (
                                                <>
                                                    <Row className="card">
                                                        {selectedLang === 'en' &&
                                                            <h6>
                                                                EXIT INTERVIEW QUESTIONNAIRE
                                                            </h6>
                                                        }
                                                        {selectedLang === 'ar' &&
                                                            <h6>
                                                                استبيان مقابلة الخروج
                                                            </h6>
                                                        }
                                                        {selectedLang === 'fr' &&
                                                            <h6>
                                                                QUESTIONNAIRE D’ENTREVUE DE DÉPART
                                                            </h6>
                                                        }
                                                        <Row className='d-flex justify-content-between m-0 p-0'>
                                                            {values?.sections && <>
                                                                {values?.sections.map((section, index) => {
                                                                    return <>
                                                                        {values?.sections && <>
                                                                            <div className={index === activeSectionId ? 'active-section-step' : 'section-step'} style={{ width: `${100 / (values?.sections?.length + 1.5)}%` }}></div>
                                                                        </>
                                                                        }
                                                                    </>
                                                                })}
                                                            </>}
                                                            {values?.sections && <>
                                                                <div className={activeSectionId === values?.sections?.length ? 'active-section-step' : 'section-step'} style={{ width: `${100 / (values?.sections?.length + 1.5)}%` }}></div>
                                                            </>}
                                                        </Row>
                                                        <fieldset disabled={!canEdit || readOnly} className="p-0">

                                                            {values?.sections && activeSectionId < values?.sections?.length && <>

                                                                <fieldset disabled={exitInterViewRequestClosed}>
                                                                    <Row className="m-0 my-2">
                                                                        <FieldArray name="sections">
                                                                            {({ push, remove }) => (
                                                                                <>
                                                                                    {
                                                                                        values?.sections?.map(
                                                                                            (section, index) => (
                                                                                                <>
                                                                                                    {activeSectionId === index && values.sections && values.sections[index] &&
                                                                                                        <Row className="m-0 my-2 py-2 p-0">
                                                                                                            <Col lg="12" md="12" sm="12" xs="12" className="d-flex justify-content-between">
                                                                                                                <h6>
                                                                                                                    {section?.section_id?.title}
                                                                                                                </h6>
                                                                                                            </Col>
                                                                                                            <FieldArray name="questions">
                                                                                                                {({ push, remove }) => (
                                                                                                                    <>
                                                                                                                        {(!section?.section_id?.is_table_content || section?.section_id?.is_table_content === null) &&
                                                                                                                            section?.questions?.map(
                                                                                                                                (question, questionIndex) => (
                                                                                                                                    <>
                                                                                                                                        <Row className="m-0 my-2 py-2 p-0">
                                                                                                                                            <Col lg="12" md="12" sm="12" xs="12">
                                                                                                                                                <h6 className={`text-small ${question?.question_id?.required_question === true ? 'required' : ''}`}>
                                                                                                                                                    <small>{question?.question_id?.name}</small>
                                                                                                                                                </h6>
                                                                                                                                            </Col>
                                                                                                                                            <Col lg="12" md="12" sm="12" xs="12">
                                                                                                                                                {question?.question_id?.type === AZKHelper.INPUT_TEXT_FIELD &&
                                                                                                                                                    <>
                                                                                                                                                        <Form.Control
                                                                                                                                                            required={true}
                                                                                                                                                            className={`p-1 rounded-0`}
                                                                                                                                                            type='text'
                                                                                                                                                            onChange={async (value) => {
                                                                                                                                                                question.answer = value?.target.value
                                                                                                                                                                if (values.type_id && values.sections && values.sections[index]
                                                                                                                                                                    && values.sections[index].questions && values.sections[index].questions[questionIndex]
                                                                                                                                                                ) values.sections[index].questions[questionIndex].answer = value?.target.value
                                                                                                                                                                formRef?.current?.validateForm()
                                                                                                                                                            }}
                                                                                                                                                            value={question.answer}
                                                                                                                                                        />
                                                                                                                                                        <div className='text-danger'>
                                                                                                                                                            {question.question_id?.required_question === true && isSubmitting && validateSection &&
                                                                                                                                                                values.type_id && values.sections && values.sections[index]
                                                                                                                                                                && values.sections[index].questions && values.sections[index].questions[questionIndex]
                                                                                                                                                                && (values.sections[index].questions[questionIndex].answer === undefined || values.sections[index].questions[questionIndex].answer === '' || values.sections[index].questions[questionIndex].answer === null) &&
                                                                                                                                                                "Theis field is required!"
                                                                                                                                                            }
                                                                                                                                                        </div>
                                                                                                                                                    </>}
                                                                                                                                                {question?.question_id?.type === AZKHelper.INPUT_DROPDOWN_FIELD
                                                                                                                                                    && values.sections && values.sections[index]
                                                                                                                                                    && values.sections[index].questions && values.sections[index].questions[questionIndex]
                                                                                                                                                    &&
                                                                                                                                                    <>
                                                                                                                                                        <AsyncPaginate className='rounded-0 col-12'
                                                                                                                                                            placeholder="Select an option"
                                                                                                                                                            loadOptions={async (search, loadedOptions) => {
                                                                                                                                                                const options: any[] = []
                                                                                                                                                                const data = question?.question_id?.answer_ids
                                                                                                                                                                for (const item in data) {
                                                                                                                                                                    if (activeSectionId === 0 && questionIndex === 3 &&
                                                                                                                                                                        values.sections && values.sections[index].questions[questionIndex - 1]) {
                                                                                                                                                                        if (values.sections[index].questions[questionIndex - 1].answer_code !== data[item]['code'])
                                                                                                                                                                            options.push({ value: data[item]['code'], label: data[item]['description'] })
                                                                                                                                                                    } else {
                                                                                                                                                                        options.push({ value: data[item]['code'], label: data[item]['description'] })
                                                                                                                                                                    }
                                                                                                                                                                }
                                                                                                                                                                const filteredOptions = options.filter((option) =>
                                                                                                                                                                    option.label.toString().includes(search.toLowerCase())
                                                                                                                                                                );
                                                                                                                                                                return {
                                                                                                                                                                    options: filteredOptions,
                                                                                                                                                                    hasMore: false,
                                                                                                                                                                };
                                                                                                                                                            }}
                                                                                                                                                            // isMulti
                                                                                                                                                            onChange={(value) => {
                                                                                                                                                                question.answer = value?.value
                                                                                                                                                                if (values.type_id && values.sections && values.sections[index]
                                                                                                                                                                    && values.sections[index].questions && values.sections[index].questions[questionIndex]
                                                                                                                                                                ) {
                                                                                                                                                                    values.sections[index].questions[questionIndex].answer = value?.label
                                                                                                                                                                    values.sections[index].questions[questionIndex].answer_code = value?.value
                                                                                                                                                                }
                                                                                                                                                                formRef?.current?.validateForm()
                                                                                                                                                            }}
                                                                                                                                                            defaultValue={{ value: values.sections[index].questions[questionIndex].answer_code, label: values.sections[index].questions[questionIndex].answer }}
                                                                                                                                                            value={{ value: values.sections[index].questions[questionIndex].answer_code, label: values.sections[index].questions[questionIndex].answer }}
                                                                                                                                                            id={`question[${question.id}]`} name={`question[${question.id}]`} />
                                                                                                                                                        <div className='text-danger'>
                                                                                                                                                            {question.question_id?.required_question === true && isSubmitting && validateSection &&
                                                                                                                                                                values.type_id && values.sections && values.sections[index]
                                                                                                                                                                && values.sections[index].questions && values.sections[index].questions[questionIndex]
                                                                                                                                                                && (values.sections[index].questions[questionIndex].answer === undefined || values.sections[index].questions[questionIndex].answer === '' || values.sections[index].questions[questionIndex].answer === null) &&
                                                                                                                                                                "Theis field is required!"
                                                                                                                                                            }
                                                                                                                                                        </div>
                                                                                                                                                    </>}



                                                                                                                                                {question?.question_id?.type === AZKHelper.INPUT_RADIO_BUTTON_FIELD
                                                                                                                                                    && values.sections && values.sections[index]
                                                                                                                                                    && values.sections[index].questions && values.sections[index].questions[questionIndex]
                                                                                                                                                    &&
                                                                                                                                                    <>
                                                                                                                                                        <Row className="m-0 my-2 py-2 p-0">
                                                                                                                                                            {question?.question_id?.answer_ids?.map(
                                                                                                                                                                (option, optionIndex) => (
                                                                                                                                                                    <>
                                                                                                                                                                        {values && values?.sections &&
                                                                                                                                                                            <Form.Check
                                                                                                                                                                                className='col-lg-4 col-md-4 col-sm-4 col-6'
                                                                                                                                                                                label={option['description']}
                                                                                                                                                                                value={option['code']}
                                                                                                                                                                                defaultChecked={option['code'] === values?.sections[index]?.questions[questionIndex].answer_code}
                                                                                                                                                                                // disabled={option.id !== values.sections[index].questions[questionIndex].answer}
                                                                                                                                                                                name="employment-category-option"
                                                                                                                                                                                onChange={(value) => {
                                                                                                                                                                                    question.answer = value.target.value
                                                                                                                                                                                    if (values.type_id && values.sections && values.sections[index]
                                                                                                                                                                                        && values.sections[index].questions && values.sections[index].questions[questionIndex]
                                                                                                                                                                                    ) {
                                                                                                                                                                                        values.sections[index].questions[questionIndex].answer_code = value.target.value
                                                                                                                                                                                        values.sections[index].questions[questionIndex].answer = option['description']
                                                                                                                                                                                    }
                                                                                                                                                                                }}
                                                                                                                                                                                type="radio"
                                                                                                                                                                                id={question.question_id.id + option.id}
                                                                                                                                                                                defaultValue={values?.sections[index]?.questions[questionIndex].answer_code}
                                                                                                                                                                            />
                                                                                                                                                                        }
                                                                                                                                                                    </>))
                                                                                                                                                            }
                                                                                                                                                            <div className='text-danger'>
                                                                                                                                                                {question.question_id?.required_question === true && isSubmitting && validateSection &&
                                                                                                                                                                    values.type_id && values.sections && values.sections[index]
                                                                                                                                                                    && values.sections[index].questions && values.sections[index].questions[questionIndex]
                                                                                                                                                                    && (values.sections[index].questions[questionIndex].answer === undefined || values.sections[index].questions[questionIndex].answer === '' || values.sections[index].questions[questionIndex].answer === null) &&
                                                                                                                                                                    "Theis field is required!"
                                                                                                                                                                }
                                                                                                                                                            </div>
                                                                                                                                                        </Row>
                                                                                                                                                    </>}


                                                                                                                                                {question?.question_id?.type === AZKHelper.INPUT_RATE_FIELD
                                                                                                                                                    && values.sections && values.sections[index]
                                                                                                                                                    && values.sections[index].questions && values.sections[index].questions[questionIndex]
                                                                                                                                                    &&
                                                                                                                                                    <>
                                                                                                                                                        <Row className="m-0 my-2 py-2 p-0 d-flex justify-content-center">
                                                                                                                                                            {question?.question_id?.answer_ids?.map(
                                                                                                                                                                (option, optionIndex) => (
                                                                                                                                                                    <>
                                                                                                                                                                        {values && values?.sections &&
                                                                                                                                                                            <Form.Label className={`${question.answer_code === option['code'] ? 'active-rate-lable' : 'rate-lable'}`}
                                                                                                                                                                                onClick={() => {
                                                                                                                                                                                    if (!exitInterViewRequestClosed) {
                                                                                                                                                                                        question.answer = option['description']
                                                                                                                                                                                        question.answer_code = option['code']
                                                                                                                                                                                        if (values.type_id && values.sections && values.sections[index]
                                                                                                                                                                                            && values.sections[index].questions && values.sections[index].questions[questionIndex]
                                                                                                                                                                                        ) {
                                                                                                                                                                                            values.sections[index].questions[questionIndex].answer_code = option['code']
                                                                                                                                                                                            values.sections[index].questions[questionIndex].answer = option['description']
                                                                                                                                                                                        }
                                                                                                                                                                                        validateForm()
                                                                                                                                                                                    }
                                                                                                                                                                                }}
                                                                                                                                                                            >
                                                                                                                                                                                {option['code']}
                                                                                                                                                                                <br />
                                                                                                                                                                                <br />
                                                                                                                                                                                {option['description']}
                                                                                                                                                                                <Form.Check
                                                                                                                                                                                    className='custom-rate-radio-button w-auto d-none'
                                                                                                                                                                                    label={option['description']}
                                                                                                                                                                                    value={option['code']}
                                                                                                                                                                                    defaultChecked={option['code'] === values?.sections[index]?.questions[questionIndex].answer_code}
                                                                                                                                                                                    // disabled={option.id !== values.sections[index].questions[questionIndex].answer}
                                                                                                                                                                                    name="employment-category-option"
                                                                                                                                                                                    onChange={(value) => {
                                                                                                                                                                                        if (!exitInterViewRequestClosed) {
                                                                                                                                                                                            question.answer = value.target.value
                                                                                                                                                                                            if (values.type_id && values.sections && values.sections[index]
                                                                                                                                                                                                && values.sections[index].questions && values.sections[index].questions[questionIndex]
                                                                                                                                                                                            ) {
                                                                                                                                                                                                values.sections[index].questions[questionIndex].answer_code = value.target.value
                                                                                                                                                                                                values.sections[index].questions[questionIndex].answer = option['description']
                                                                                                                                                                                            }
                                                                                                                                                                                        }
                                                                                                                                                                                    }}
                                                                                                                                                                                    type="radio"
                                                                                                                                                                                    id={question.question_id.id + option.id}
                                                                                                                                                                                    defaultValue={values?.sections[index]?.questions[questionIndex].answer_code}
                                                                                                                                                                                />
                                                                                                                                                                            </Form.Label>
                                                                                                                                                                        }
                                                                                                                                                                    </>))
                                                                                                                                                            }
                                                                                                                                                            <div className='text-danger'>
                                                                                                                                                                {question.question_id?.required_question === true && isSubmitting && validateSection &&
                                                                                                                                                                    values.type_id && values.sections && values.sections[index]
                                                                                                                                                                    && values.sections[index].questions && values.sections[index].questions[questionIndex]
                                                                                                                                                                    && (values.sections[index].questions[questionIndex].answer === undefined || values.sections[index].questions[questionIndex].answer === '' || values.sections[index].questions[questionIndex].answer === null) &&
                                                                                                                                                                    "Theis field is required!"
                                                                                                                                                                }
                                                                                                                                                            </div>
                                                                                                                                                        </Row>
                                                                                                                                                    </>}


                                                                                                                                            </Col>

                                                                                                                                        </Row>
                                                                                                                                        {question.question_id.comment_title &&
                                                                                                                                            <Row className="m-0 pt-0 pb-2 p-0 row">
                                                                                                                                                <Col lg="12" md="12" sm="12" xs="12">
                                                                                                                                                    <h6 className="text-small">
                                                                                                                                                        <small>{question?.question_id?.comment_title}</small>
                                                                                                                                                    </h6>
                                                                                                                                                </Col>
                                                                                                                                                <Col lg="12" md="12" sm="12" xs="12">
                                                                                                                                                    <Form.Control
                                                                                                                                                        required={true}
                                                                                                                                                        className={`p-1 rounded-0`}
                                                                                                                                                        type='text'
                                                                                                                                                        onChange={async (value) => {
                                                                                                                                                            question.comment = value?.target.value
                                                                                                                                                            if (values.type_id && values.sections && values.sections[index]
                                                                                                                                                                && values.sections[index].questions && values.sections[index].questions[questionIndex]
                                                                                                                                                            ) values.sections[index].questions[questionIndex].comment = value?.target.value
                                                                                                                                                            formRef?.current?.validateForm()
                                                                                                                                                        }}
                                                                                                                                                        value={question.comment}
                                                                                                                                                    />
                                                                                                                                                </Col>
                                                                                                                                            </Row>
                                                                                                                                        }
                                                                                                                                    </>
                                                                                                                                )
                                                                                                                            )
                                                                                                                        }

                                                                                                                        {(section?.section_id?.is_table_content && section?.section_id?.is_table_content === true) &&
                                                                                                                            <>
                                                                                                                                <Row className="m-0 my-2 py-2 p-0">
                                                                                                                                    <Col lg="12" md="12" sm="12" xs="12">
                                                                                                                                        <table className="table-bordered w-100">
                                                                                                                                            <tbody>
                                                                                                                                                {section?.questions?.map(
                                                                                                                                                    (question, questionIndex) => (
                                                                                                                                                        <>
                                                                                                                                                            <tr>
                                                                                                                                                                <td className="w-70 px-1">
                                                                                                                                                                    <h6 className={`text-small ${question?.question_id?.required_question === true ? 'required' : ''}`}>
                                                                                                                                                                        <small>{question?.question_id.name}</small>
                                                                                                                                                                    </h6>
                                                                                                                                                                </td>
                                                                                                                                                                <td className="w-30">
                                                                                                                                                                    {question?.question_id.type === AZKHelper.INPUT_DROPDOWN_FIELD
                                                                                                                                                                        &&
                                                                                                                                                                        values.type_id && values.sections && values.sections[index]
                                                                                                                                                                        && values.sections[index].questions && values.sections[index].questions[questionIndex]
                                                                                                                                                                        &&
                                                                                                                                                                        <>
                                                                                                                                                                            <AsyncPaginate className='rounded-0 col-12'
                                                                                                                                                                                placeholder="Select an option"
                                                                                                                                                                                loadOptions={async (search, loadedOptions) => {
                                                                                                                                                                                    const options: any[] = []
                                                                                                                                                                                    const data = question?.question_id.answer_ids
                                                                                                                                                                                    for (const item in data) {
                                                                                                                                                                                        options.push({ value: data[item]['code'], label: data[item]['description'] })
                                                                                                                                                                                    }
                                                                                                                                                                                    const filteredOptions = options.filter((option) =>
                                                                                                                                                                                        option.label.toString().includes(search.toLowerCase())
                                                                                                                                                                                    );
                                                                                                                                                                                    return {
                                                                                                                                                                                        options: filteredOptions,
                                                                                                                                                                                        hasMore: false,
                                                                                                                                                                                    };
                                                                                                                                                                                }}
                                                                                                                                                                                onChange={(value) => {
                                                                                                                                                                                    question.answer = value?.label
                                                                                                                                                                                    question.answer_code = value?.value
                                                                                                                                                                                    if (values.type_id && values.sections && values.sections[index]
                                                                                                                                                                                        && values.sections[index].questions && values.sections[index].questions[questionIndex]
                                                                                                                                                                                    ) {
                                                                                                                                                                                        values.sections[index].questions[questionIndex].answer = value?.label
                                                                                                                                                                                        values.sections[index].questions[questionIndex].answer_code = value?.value
                                                                                                                                                                                    }
                                                                                                                                                                                    formRef?.current?.validateForm()
                                                                                                                                                                                }}
                                                                                                                                                                                defaultValue={{ value: values.sections[index].questions[questionIndex].answer_code, label: values.sections[index].questions[questionIndex].answer }}
                                                                                                                                                                                value={{ value: values.sections[index].questions[questionIndex].answer_code, label: values.sections[index].questions[questionIndex].answer }}
                                                                                                                                                                                id={`question[${question.id}]`} name={`question[${question.id}]`} />
                                                                                                                                                                            <div className='text-danger'>
                                                                                                                                                                                {question.question_id?.required_question === true && isSubmitting && validateSection &&
                                                                                                                                                                                    values.type_id && values.sections && values.sections[index]
                                                                                                                                                                                    && values.sections[index].questions && values.sections[index].questions[questionIndex]
                                                                                                                                                                                    && (values.sections[index].questions[questionIndex].answer === undefined || values.sections[index].questions[questionIndex].answer === '' || values.sections[index].questions[questionIndex].answer === null) &&
                                                                                                                                                                                    "Theis field is required!"
                                                                                                                                                                                }
                                                                                                                                                                            </div>
                                                                                                                                                                        </>}
                                                                                                                                                                </td>
                                                                                                                                                            </tr>
                                                                                                                                                        </>
                                                                                                                                                    )
                                                                                                                                                )
                                                                                                                                                }

                                                                                                                                                {section?.section_id?.comment_title &&
                                                                                                                                                    <tr>
                                                                                                                                                        <td colSpan={2}>
                                                                                                                                                            <Row className="m-0 my-1">
                                                                                                                                                                <Col lg="12" md="12" sm="12" xs="12">
                                                                                                                                                                    <h6 className="text-small">
                                                                                                                                                                        <small>{section?.section_id?.comment_title}</small>
                                                                                                                                                                    </h6>
                                                                                                                                                                </Col>
                                                                                                                                                                <Col lg="12" md="12" sm="12" xs="12">
                                                                                                                                                                    <Form.Control
                                                                                                                                                                        required={true}
                                                                                                                                                                        className={`p-1 rounded-0`}
                                                                                                                                                                        type='text'
                                                                                                                                                                        onChange={async (value) => {
                                                                                                                                                                            section.comment = value?.target.value
                                                                                                                                                                            if (values.type_id && values.sections && values.sections[index])
                                                                                                                                                                                values.sections[index].comment = value?.target.value
                                                                                                                                                                            formRef?.current?.validateForm()
                                                                                                                                                                        }}
                                                                                                                                                                        value={section.comment}
                                                                                                                                                                    />
                                                                                                                                                                </Col>
                                                                                                                                                            </Row>
                                                                                                                                                        </td>
                                                                                                                                                    </tr>
                                                                                                                                                }
                                                                                                                                            </tbody>
                                                                                                                                        </table>
                                                                                                                                    </Col>
                                                                                                                                </Row>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </FieldArray>
                                                                                                            <Row className='m-0 pt-0 pb-2 p-0'>
                                                                                                                {section?.section_id?.comment_title && section?.section_id?.is_table_content === null &&
                                                                                                                    <>
                                                                                                                        <Col lg="12" md="12" sm="12" xs="12">
                                                                                                                            <h6 className="text-small">
                                                                                                                                <small>{section?.section_id?.comment_title}</small>
                                                                                                                            </h6>
                                                                                                                        </Col>
                                                                                                                        <Col lg="12" md="12" sm="12" xs="12">
                                                                                                                            <Form.Control
                                                                                                                                required={true}
                                                                                                                                className={`p-1 rounded-0`}
                                                                                                                                type='text'
                                                                                                                                onChange={async (value) => {
                                                                                                                                    section.comment = value?.target.value
                                                                                                                                    if (values.type_id && values.sections && values.sections[index])
                                                                                                                                        values.sections[index].comment = value?.target.value
                                                                                                                                    formRef?.current?.validateForm()
                                                                                                                                }}
                                                                                                                                value={section.comment}
                                                                                                                            />
                                                                                                                        </Col>
                                                                                                                    </>
                                                                                                                }
                                                                                                            </Row>
                                                                                                        </Row>
                                                                                                    }
                                                                                                </>
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                </>
                                                                            )}
                                                                        </FieldArray>
                                                                    </Row>
                                                                </fieldset>
                                                                {activeSectionId > 0 &&
                                                                    <div className='py-5 d-flex justify-content-between col-12'>
                                                                        <button
                                                                            title='Back'
                                                                            type='reset'
                                                                            className='btn btn-dark rounded-0 btn-sm col-3 mr-1'
                                                                            onClick={() => {
                                                                                setActiveSectionId(activeSectionId - 1)
                                                                            }}
                                                                        >
                                                                            <span className='indicator-label'>Back</span>
                                                                        </button>
                                                                        <button
                                                                            title='Next'
                                                                            type='reset'
                                                                            className='btn btn-dark rounded-0 btn-sm col-3 mr-1'
                                                                            onClick={() => {
                                                                                onSimeSubmitHandler(values)
                                                                            }}
                                                                        >
                                                                            <span className='indicator-label'>Next</span>
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {activeSectionId === 0 &&
                                                                    <div className='py-5 d-flex justify-content-end col-12'>
                                                                        <button
                                                                            title='Next'
                                                                            type='reset'
                                                                            className='btn btn-dark rounded-0 btn-sm col-3 mr-1'
                                                                            onClick={() => {
                                                                                onSimeSubmitHandler(values)
                                                                            }}
                                                                        >
                                                                            <span className='indicator-label'>Next</span>
                                                                        </button>
                                                                    </div>
                                                                }
                                                            </>
                                                            }
                                                                {values?.sections && activeSectionId === values?.sections?.length &&
                                                                    <>
                                                            <fieldset disabled={exitInterViewRequestClosed}>
                                                                        {selectedLang === 'en' &&
                                                                            <>
                                                                                <Row className="m-0 my-2">
                                                                                    <Form.Group className='mb-2'>
                                                                                        <Form.Check className="py-1"
                                                                                            key={`submit_approve`}
                                                                                            label={`By submitting this exit interview questionnaire, I, ${employeeName}, acknowledge and confirm the accuracy of all information provided herein. Furthermore, I understand that the information provided may be used by the company for internal purposes, including but not limited to improving organizational practices and addressing concerns related to employee satisfaction and retention.`}
                                                                                            name={`submit_approve`}
                                                                                            onChange={(value) => {
                                                                                                values.submit_approve = value.target.checked
                                                                                                formRef?.current?.validateForm()
                                                                                            }}
                                                                                            id={`submit_approve`}
                                                                                            defaultChecked={values.submit_approve}
                                                                                        // checked={selectedGroupNoDues}
                                                                                        />

                                                                                        <div className='text-danger'>
                                                                                            <ErrorMessage name='submit_approve' />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </Row>
                                                                                <Row className="m-0 my-2">
                                                                                    <Form.Group className='mb-2'>
                                                                                        <Form.Check className="py-1"
                                                                                            key={`office_submit_approve`}
                                                                                            label="Upon submission of this exit interview questionnaire, I express my willingness to further discuss the information provided above with a member of the HR department or the Executive Board in a confidential meeting. Please select your choice:"
                                                                                            name={`office_submit_approve`}
                                                                                            onChange={(value) => {
                                                                                                values.office_submit_approve = value.target.checked
                                                                                                if (!value.target.checked){
                                                                                                    values.hr_department = false
                                                                                                    values.executive_member = false
                                                                                                    values.executive_employee_name = undefined
                                                                                                }
                                                                                                formRef?.current?.validateForm()
                                                                                            }}
                                                                                            id={`office_submit_approve`}
                                                                                            defaultChecked={values.office_submit_approve}
                                                                                        // checked={selectedGroupNoDues}
                                                                                        />

                                                                                        <div className='text-danger'>
                                                                                            <ErrorMessage name='office_submit_approve' />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                    {values.office_submit_approve && <>
                                                                                        <div className="col-12 px-6">
                                                                                            <Form.Group className='col-12'>
                                                                                                <Form.Label>
                                                                                                    <Form.Check className="py-1"
                                                                                                        key={`hr_department`}
                                                                                                        label="HR department"
                                                                                                        name={`hr_department`}
                                                                                                        onClick={(value) => {
                                                                                                            values.executive_member = false
                                                                                                            values.hr_department = true
                                                                                                            formRef?.current?.validateForm()
                                                                                                        }}
                                                                                                        id={`hr_department`}
                                                                                                        defaultChecked={false}
                                                                                                        checked={values.hr_department}
                                                                                                    />

                                                                                                </Form.Label>
                                                                                                <div className='text-danger'>
                                                                                                    <ErrorMessage name='hr_department' />
                                                                                                </div>
                                                                                            </Form.Group>
                                                                                            {backOffice &&
                                                                                                <Form.Group className='col-12'>
                                                                                                    <Form.Label>
                                                                                                        <Form.Check className="py-1"
                                                                                                            key={`executive_member`}
                                                                                                            label="Executive member"
                                                                                                            name={`executive_member`}
                                                                                                            onClick={(value) => {
                                                                                                                values.executive_member = true
                                                                                                                values.hr_department = false
                                                                                                                formRef?.current?.validateForm()
                                                                                                            }}
                                                                                                            id={`executive_member`}
                                                                                                            defaultChecked={false}
                                                                                                            checked={values.executive_member}
                                                                                                        />
                                                                                                    </Form.Label>
                                                                                                    {values.executive_member === true &&
                                                                                                        <>
                                                                                                            <Form.Control
                                                                                                                id={'executive_employee_name'}
                                                                                                                name={'executive_employee_name'}
                                                                                                                className={`p-1 rounded-0`}
                                                                                                                type='text'
                                                                                                                onChange={async (value) => {
                                                                                                                    values.executive_employee_name = value?.target.value
                                                                                                                    formRef?.current?.validateForm()
                                                                                                                }}
                                                                                                                value={values.executive_employee_name}
                                                                                                            />
                                                                                                            <div className='text-danger'>
                                                                                                                <ErrorMessage name='executive_employee_name' />
                                                                                                            </div>
                                                                                                        </>
                                                                                                    }
                                                                                                </Form.Group>
                                                                                            }
                                                                                        </div>
                                                                                    </>}
                                                                                </Row>
                                                                            </>
                                                                        }
                                                                        {selectedLang === 'fr' &&
                                                                            <>
                                                                                <Row className="m-0 my-2">
                                                                                    <Form.Group className='mb-2'>
                                                                                        <Form.Check className="py-1"
                                                                                            key={`submit_approve`}
                                                                                            label="En soumettant ce questionnaire d’entrevue de départ, je soussigné, [nom de l’employé], reconnais et confirme l’exactitude de tous les renseignements fournis dans le présent document. De plus, je comprends que les renseignements fournis peuvent être utilisés par l’entreprise à des fins internes, y compris, mais sans s’y limiter, pour améliorer les pratiques organisationnelles et répondre aux préoccupations liées à la satisfaction et à la rétention des employés."
                                                                                            name={`submit_approve`}
                                                                                            onChange={(value) => {
                                                                                                values.submit_approve = value.target.checked
                                                                                                formRef?.current?.validateForm()
                                                                                            }}
                                                                                            id={`submit_approve`}
                                                                                            defaultChecked={values.submit_approve}
                                                                                        // checked={selectedGroupNoDues}
                                                                                        />

                                                                                        <div className='text-danger'>
                                                                                            <ErrorMessage name='submit_approve' />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </Row>
                                                                                <Row className="m-0 my-2">
                                                                                    <Form.Group className='mb-2'>
                                                                                        <Form.Check className="py-1"
                                                                                            key={`office_submit_approve`}
                                                                                            label="Après avoir soumis ce questionnaire d’entretien de départ, j’exprime ma volonté de discuter plus avant des informations fournies ci-dessus avec un membre du département des ressources humaines ou du conseil d’administration lors d’une réunion confidentielle. Veuillez sélectionner votre choix"
                                                                                            name={`office_submit_approve`}
                                                                                            onChange={(value) => {
                                                                                                values.office_submit_approve = value.target.checked
                                                                                                if (!value.target.checked){
                                                                                                    values.hr_department = false
                                                                                                    values.executive_member = false
                                                                                                    values.executive_employee_name = undefined
                                                                                                }
                                                                                                formRef?.current?.validateForm()
                                                                                            }}
                                                                                            id={`office_submit_approve`}
                                                                                            defaultChecked={values.office_submit_approve}
                                                                                        // checked={selectedGroupNoDues}
                                                                                        />

                                                                                        <div className='text-danger'>
                                                                                            <ErrorMessage name='office_submit_approve' />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                    <div className="col-12 px-6">
                                                                                        <Form.Group className='col-12'>
                                                                                            <Form.Label>
                                                                                                <Form.Check className="py-1"
                                                                                                    key={`hr_department`}
                                                                                                    label="Département RH"
                                                                                                    name={`hr_department`}
                                                                                                    onClick={(value) => {
                                                                                                        values.executive_member = false
                                                                                                        values.hr_department = true
                                                                                                        formRef?.current?.validateForm()
                                                                                                    }}
                                                                                                    id={`hr_department`}
                                                                                                    defaultChecked={false}
                                                                                                    checked={values.hr_department}
                                                                                                />

                                                                                            </Form.Label>
                                                                                            <div className='text-danger'>
                                                                                                <ErrorMessage name='hr_department' />
                                                                                            </div>
                                                                                        </Form.Group>
                                                                                        {backOffice &&
                                                                                            <Form.Group className='col-12'>
                                                                                                <Form.Label>
                                                                                                    <Form.Check className="py-1"
                                                                                                        key={`executive_member`}
                                                                                                        label="Membre exécutif"
                                                                                                        name={`executive_member`}
                                                                                                        onClick={(value) => {
                                                                                                            values.executive_member = true
                                                                                                            values.hr_department = false
                                                                                                            formRef?.current?.validateForm()
                                                                                                        }}
                                                                                                        id={`executive_member`}
                                                                                                        defaultChecked={false}
                                                                                                        checked={values.executive_member}
                                                                                                    />
                                                                                                </Form.Label>
                                                                                                {values.executive_member === true &&
                                                                                                    <>
                                                                                                        <Form.Control
                                                                                                            id={'executive_employee_name'}
                                                                                                            name={'executive_employee_name'}
                                                                                                            className={`p-1 rounded-0`}
                                                                                                            type='text'
                                                                                                            onChange={async (value) => {
                                                                                                                values.executive_employee_name = value?.target.value
                                                                                                                formRef?.current?.validateForm()
                                                                                                            }}
                                                                                                            value={values.executive_employee_name}
                                                                                                        />
                                                                                                        <div className='text-danger'>
                                                                                                            <ErrorMessage name='executive_employee_name' />
                                                                                                        </div>
                                                                                                    </>
                                                                                                }
                                                                                            </Form.Group>
                                                                                        }
                                                                                    </div>
                                                                                </Row>
                                                                            </>
                                                                        }
                                                                        {selectedLang === 'ar' &&
                                                                            <>
                                                                                <Row className="m-0 my-2 row-rtl">
                                                                                    <Form.Group className='mb-2'>
                                                                                        <Form.Check className="py-1"
                                                                                            key={`submit_approve`}
                                                                                            label="من خلال تقديم استبيان مقابلة الخروج هذا ، أقر وأؤكد دقة جميع المعلومات المقدمة هنا. علاوة على ذلك ، أفهم أن المعلومات المقدمة قد تستخدمها الشركة لأغراض داخلية ، بما في ذلك على سبيل المثال لا الحصر تحسين الممارسات التنظيمية ومعالجة المخاوف المتعلقة برضا الموظفين والاحتفاظ بهم."
                                                                                            name={`submit_approve`}
                                                                                            onChange={(value) => {
                                                                                                values.submit_approve = value.target.checked
                                                                                                formRef?.current?.validateForm()
                                                                                            }}
                                                                                            id={`submit_approve`}
                                                                                            defaultChecked={values.submit_approve}
                                                                                        // checked={selectedGroupNoDues}
                                                                                        />

                                                                                        <div className='text-danger'>
                                                                                            <ErrorMessage name='submit_approve' />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </Row>
                                                                                <Row className="m-0 my-2 row-rtl">
                                                                                    <Form.Group className='mb-2'>
                                                                                        <Form.Check className="py-1"
                                                                                            key={`office_submit_approve`}
                                                                                            label="وعند تقديم استبيان مقابلة انتهاء الخدمة هذا، أعرب عن استعدادي لمواصلة مناقشة المعلومات المقدمة أعلاه مع أحد أعضاء إدارة الموارد البشرية أو المجلس التنفيذي في اجتماع سري. يرجى تحديد اختيارك"
                                                                                            name={`office_submit_approve`}
                                                                                            onChange={(value) => {
                                                                                                values.office_submit_approve = value.target.checked
                                                                                                if (!value.target.checked){
                                                                                                    values.hr_department = false
                                                                                                    values.executive_member = false
                                                                                                    values.executive_employee_name = ''
                                                                                                }
                                                                                                formRef?.current?.validateForm()
                                                                                            }}
                                                                                            id={`office_submit_approve`}
                                                                                            defaultChecked={values.office_submit_approve}
                                                                                        // checked={selectedGroupNoDues}
                                                                                        />

                                                                                        <div className='text-danger'>
                                                                                            <ErrorMessage name='office_submit_approve' />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                    <div className="col-12 px-6">
                                                                                        <Form.Group className='col-12'>
                                                                                            <Form.Label>
                                                                                                <Form.Check className="py-1"
                                                                                                    key={`hr_department`}
                                                                                                    label="قسم الموارد البشرية"
                                                                                                    name={`hr_department`}
                                                                                                    onClick={(value) => {
                                                                                                        values.executive_member = false
                                                                                                        values.hr_department = true
                                                                                                        formRef?.current?.validateForm()
                                                                                                    }}
                                                                                                    id={`hr_department`}
                                                                                                    defaultChecked={false}
                                                                                                    checked={values.hr_department}
                                                                                                />

                                                                                            </Form.Label>
                                                                                            <div className='text-danger'>
                                                                                                <ErrorMessage name='hr_department' />
                                                                                            </div>
                                                                                        </Form.Group>
                                                                                        {backOffice &&
                                                                                            <Form.Group className='col-12'>
                                                                                                <Form.Label>
                                                                                                    <Form.Check className="py-1"
                                                                                                        key={`executive_member`}
                                                                                                        label="عضو تنفيذي"
                                                                                                        name={`executive_member`}
                                                                                                        onClick={(value) => {
                                                                                                            values.executive_member = true
                                                                                                            values.hr_department = false
                                                                                                            formRef?.current?.validateForm()
                                                                                                        }}
                                                                                                        id={`executive_member`}
                                                                                                        defaultChecked={false}
                                                                                                        checked={values.executive_member}
                                                                                                    />
                                                                                                </Form.Label>
                                                                                                {values.executive_member === true &&
                                                                                                    <>
                                                                                                        <Form.Control
                                                                                                            id={'executive_employee_name'}
                                                                                                            name={'executive_employee_name'}
                                                                                                            className={`p-1 rounded-0`}
                                                                                                            type='text'
                                                                                                            onChange={async (value) => {
                                                                                                                values.executive_employee_name = value?.target.value
                                                                                                                formRef?.current?.validateForm()
                                                                                                            }}
                                                                                                            value={values.executive_employee_name}
                                                                                                        />
                                                                                                        <div className='text-danger'>
                                                                                                            <ErrorMessage name='executive_employee_name' />
                                                                                                        </div>
                                                                                                    </>
                                                                                                }
                                                                                            </Form.Group>
                                                                                        }
                                                                                    </div>
                                                                                </Row>
                                                                            </>
                                                                        }
                                                            </fieldset>
                                                                        <fieldset disabled={exitInterViewRequestClosed}>
                                                                            <Row className="m-0 my-2">
                                                                                <Form.Group className='mb-2'>
                                                                                    {selectedLang === 'en' &&
                                                                                        <Form.Label>
                                                                                            I understand that this meeting will be arranged at my request and will be conducted in accordance with the company's policies on confidentiality and data protection.
                                                                                        </Form.Label>
                                                                                    }
                                                                                    {selectedLang === 'ar' &&
                                                                                        <Form.Label>
                                                                                            أفهم أنه سيتم ترتيب هذا الاجتماع بناء على طلبي وسيتم إجراؤه وفقا لسياسات الشركة بشأن السرية وحماية البيانات.
                                                                                        </Form.Label>
                                                                                    }
                                                                                    {selectedLang === 'fr' &&
                                                                                        <Form.Label>
                                                                                            Je comprends que cette réunion sera organisée à ma demande et se déroulera conformément aux politiques de l’entreprise en matière de confidentialité et de protection des données.
                                                                                        </Form.Label>
                                                                                    }
                                                                                </Form.Group>
                                                                            </Row>
                                                                        </fieldset>
                                                                            <div className='py-5 d-flex justify-content-between col-12'>
                                                                                <button
                                                                                    title='Back'
                                                                                    type='reset'
                                                                                    className='btn btn-dark rounded-0 btn-sm col-3 mr-1'
                                                                                    onClick={() => {
                                                                                        setActiveSectionId(activeSectionId - 1)
                                                                                    }}
                                                                                >
                                                                                    <span className='indicator-label'>Back</span>
                                                                                </button>
                                                                                
                                                                        {!exitInterViewRequestClosed &&
                                                                                <button
                                                                                    title="Submit"
                                                                                    type='submit'
                                                                                    className='btn btn-dark rounded-0 btn-sm col-3 mr-1'>
                                                                                    <span className='indicator-label'>Submit</span>
                                                                                </button>
                                                                        }
                                                                            </div>
                                                                    </>
                                                                }
                                                        </fieldset>

                                                        {/* begin::Actions */}
                                                        <Row className="m-0 my-2">
                                                        </Row>
                                                        {/* end::Actions */}
                                                    </Row>
                                                </>)
                                            }
                                        </Form>
                                    </>
                                )
                            }}
                        </Formik>
                    </Col >
                </Row >
            }
        </>
    )
}

export default TransactionAPPform;
