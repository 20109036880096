import axios, { ResponseType } from "axios";
import { AttendanceAdjustment } from "./model";
import { FC, useEffect, useRef, useState } from "react";
import { FormikProps } from 'formik'
import { GetAttendanceAdjustmentById, MODEL_NAME } from "./_requests";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "_azkatech/azkHelper";
import moment from "moment";
import { AZKHelper } from "app/modules/components/azkHelper";

type Props = {
    adjustmentId: any,
    closeModal: () => {}
}

const TransactionAPPViewform: FC<Props> = ({ adjustmentId, closeModal }) => {
    const { id } = useParams();
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<AttendanceAdjustment>>(null);

    const [transaction, setTransaction] = useState<AttendanceAdjustment>();

    useEffect(() => {
        document.body.classList.add('page-loading')
        formRef.current?.resetForm()
        if (adjustmentId) {
            document.body.classList.add('page-loading')
            GetAttendanceAdjustmentById(parseInt(adjustmentId)).then(((data: AttendanceAdjustment) => {
                setTransaction(data)
                document.body.classList.remove('page-loading')
            }))
        } else {
            document.body.classList.remove('page-loading')
        }
    }, [id])



    return (
        <>{transaction && <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
                style={{ zIndex: 99999 }}
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered px-3'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>

                        <div className='modal-header py-3 px-2'>
                            {/* begin::Modal title */}
                            <h4 className='m-0'>Details</h4>
                            {/* end::Modal title */}
                        </div>
                        {/* <div className='text-center py-3'>
                            <h4 className='m-0'>Leave Details</h4>
                        </div> */}
                        {/* begin::Modal body */}
                        <div className='' >
                            <Row className="m-0 py-3">
                                <Col lg="4" md="4" sm="6" xs="12">
                                    <Form.Group className='mb-2 d-flex justify-content-between'>
                                        <Form.Label className="payslip-form-label">
                                            Id/Name
                                        </Form.Label>
                                        <Form.Label className="payslip-form-label-value">
                                            {transaction?.azadea_employee_name}
                                        </Form.Label>
                                    </Form.Group>
                                </Col>

                                {transaction && transaction.line_ids && transaction.line_ids.length > 0 &&
                                    transaction.line_ids.map((line, index) => {
                                        return <>
                                            <Row className="m-0 my-2 py-4" style={{ backgroundColor: '#F6F6F6' }}>
                                                <Col lg="12" md="12" sm="12" xs="12" className="d-flex justify-content-between">
                                                    <h6>
                                                        Attendance Entry {index + 1} :
                                                    </h6>
                                                </Col>
                                                <Col lg="4" md="12" sm="12" xs="12">
                                                    <Form.Group className='mb-2 d-flex justify-content-between'>
                                                        <Form.Label className="payslip-form-label">
                                                            Date
                                                        </Form.Label>
                                                        <Form.Label className="payslip-form-label-value">
                                                            {moment(line.date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                                                        </Form.Label>
                                                    </Form.Group>
                                                </Col>

                                                <Col lg="4" md="5" sm="5" xs="5">
                                                    <Form.Group className='mb-2 d-flex justify-content-between'>
                                                        <Form.Label className="payslip-form-label">
                                                            Type
                                                        </Form.Label>
                                                        <Form.Label className="payslip-form-label-value">
                                                            {line.adjustment_type}
                                                        </Form.Label>
                                                    </Form.Group>
                                                </Col>

                                                <Col lg="4" md="1" sm="1" xs="1">
                                                </Col>
                                                <Col lg="4" md="3" sm="3" xs="3">
                                                    <Form.Group className='mb-2 d-flex justify-content-between'>
                                                        <Form.Label className="payslip-form-label">
                                                            Hours
                                                        </Form.Label>
                                                        <Form.Label className="payslip-form-label-value">
                                                            {line.hour}
                                                        </Form.Label>
                                                    </Form.Group>
                                                </Col>

                                                <Col lg="4" md="3" sm="3" xs="3">
                                                    <Form.Group className='mb-2 d-flex justify-content-between'>
                                                        <Form.Label className="payslip-form-label">
                                                            Mins
                                                        </Form.Label>
                                                        <Form.Label className="payslip-form-label-value">
                                                            {line.min}
                                                        </Form.Label>
                                                    </Form.Group>
                                                </Col>
                                            </Row >
                                        </>
                                    })
                                }
                                {transaction?.comment && transaction.validated &&
                                    <Col lg="4" md="4" sm="6" xs="12">
                                        <Form.Group className='mb-2 d-flex justify-content-between'>
                                            <Form.Label className="payslip-form-label">
                                                Approver Comment
                                            </Form.Label>
                                            <Form.Label className="payslip-form-label-value">
                                                {transaction?.comment}
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                }
                            </Row>
                        </div>
                        {/* end::Modal body */}
                        <div className='my-2 row m-0'>
                            <div className={`col-12 d-flex justify-content-end py-3`}>
                                <button id='discard-button' type='button' className='btn btn-dark rounded-0 btn-sm col-4'
                                    onClick={() => closeModal()}>
                                    <span className='indicator-label'>Discard</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>}
        </>
    )
}

export default TransactionAPPViewform;
