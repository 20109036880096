import React, { useEffect, useLayoutEffect, useState } from 'react'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { useIntl } from 'react-intl'
import { ThemeModeComponent } from '../../../assets/ts/layout'
import { toAbsoluteUrl } from '../../../helpers'
import { useAuth } from 'app/modules/auth'
import { AZKHelper } from 'app/modules/components/azkHelper'

export function MenuInner() {

  const [accessrights, setAccessrights] = useState<string[]>([]);
  const [accessrightsLoaded, setAccessrightsLoaded] = useState<Boolean>(false);
  const intl = useIntl()
  const { getAuthority } = useAuth()
  useLayoutEffect(() => {
    if (!accessrightsLoaded) {
      fillAccessreights()
    }
  }, [accessrights])

  const fillAccessreights = async () => {
    setAccessrightsLoaded(true)
    setAccessrights([])
    const neededAccessRights = [[AZKHelper.TRANSACTIONTYPE.AZ_SALARY_STRUCTURE_SETTING, AZKHelper.READ_ACTION],
    [AZKHelper.TRANSACTIONTYPE.AZ_TRN_SALARY_STRUCTURE, AZKHelper.READ_ACTION],
    [AZKHelper.TRANSACTIONTYPE.AZ_TRN_ADDITION_DEDUCTION, AZKHelper.CREATE_ACTION],
    [AZKHelper.TRANSACTIONTYPE.AZ_TRN_CHANGE_EMPLOYMENT_CATEGORY, AZKHelper.CREATE_ACTION],
    [AZKHelper.TRANSACTIONTYPE.AZ_TRN_MANAGER_ASSIGNMENT, AZKHelper.CREATE_ACTION],
    [AZKHelper.TRANSACTIONTYPE.AZ_TRN_DEMOTION, AZKHelper.CREATE_ACTION],
    [AZKHelper.TRANSACTIONTYPE.AZ_TRN_PROMOTION, AZKHelper.CREATE_ACTION],
    [AZKHelper.TRANSACTIONTYPE.AZ_TRN_SEPARATION, AZKHelper.CREATE_ACTION],
    [AZKHelper.TRANSACTIONTYPE.AZ_TRN_TRANSFER_COMPANY, AZKHelper.CREATE_ACTION],
    [AZKHelper.TRANSACTIONTYPE.AZ_TRN_TRANSFER_WITHIN_COMPANY, AZKHelper.CREATE_ACTION],
    [AZKHelper.TRANSACTIONTYPE.AZ_TRN_LEAVE, AZKHelper.READ_ACTION],
    [AZKHelper.TRANSACTIONTYPE.AZ_TRN_PAYSLIP, AZKHelper.READ_ACTION],
    [AZKHelper.TRANSACTIONTYPE.AZ_TRN_SELF_SERVICE, AZKHelper.READ_ACTION],
    [AZKHelper.TRANSACTIONTYPE.AZ_TRN_ATTENDANCE_ADJUSTMENT, AZKHelper.READ_ACTION],
    [AZKHelper.TRANSACTIONTYPE.AZ_TRN_EXIT_INTERVIEW, AZKHelper.CREATE_ACTION],
    [AZKHelper.TRANSACTIONTYPE.AZ_TRN_TRAVEL_REQUEST, AZKHelper.READ_ACTION],
    [AZKHelper.TRANSACTIONTYPE.AZ_TRN_TRAVEL_PER_DEIM, AZKHelper.READ_ACTION],
    [AZKHelper.TRANSACTIONTYPE.AZ_TRN_ALL_TRANSACTION_VIEW, AZKHelper.READ_ACTION],
    ]
    await getAuthority(neededAccessRights).then((allowed: any) => {
      if (allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_SALARY_STRUCTURE) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_SALARY_STRUCTURE_SETTING)) allowed.push('salary.structure')
      if (allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_LEAVE) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_ATTENDANCE_ADJUSTMENT) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_PAYSLIP) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_SELF_SERVICE)) allowed.push(AZKHelper.TRANSACTIONTYPE.AZ_TRN_SELF_SERVICE_MENU)
      if (allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_ADDITION_DEDUCTION) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_CHANGE_EMPLOYMENT_CATEGORY) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_MANAGER_ASSIGNMENT) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_DEMOTION) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_PROMOTION) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_SEPARATION) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_TRANSFER_COMPANY) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_TRANSFER_WITHIN_COMPANY)) allowed.push(AZKHelper.TRANSACTIONTYPE.AZ_TRN_TRANSACTION_MENU)
      setAccessrights(allowed)
    })
    // const promises = await neededAccessRights?.map(async (accessRight, index) => new Promise(async (resolve, reject) => {
    //   const allowed = await getAuthority(neededAccessRights)
    //   if (allowed) {
    //     if (accessRight[1] === AZKHelper.CREATE_ACTION) {
    //       resolve(accessRight[0])
    //     }
    //     else
    //       resolve(accessRight[0])
    //   } else {
    //     resolve('')
    //   }

    // }));
    // if (promises)

    //   Promise.all(promises).then((allowed: any) => {
    //     if (allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_SALARY_STRUCTURE) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_SALARY_STRUCTURE_SETTING)) allowed.push('salary.structure')
    //     if (allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_LEAVE) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_ATTENDANCE_ADJUSTMENT) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_PAYSLIP) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_SELF_SERVICE)) allowed.push(AZKHelper.TRANSACTIONTYPE.AZ_TRN_SELF_SERVICE_MENU)
    //     if (allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_ADDITION_DEDUCTION) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_CHANGE_EMPLOYMENT_CATEGORY) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_MANAGER_ASSIGNMENT) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_DEMOTION) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_PROMOTION) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_SEPARATION) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_TRANSFER_COMPANY) || allowed.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_TRANSFER_WITHIN_COMPANY)) allowed.push(AZKHelper.TRANSACTIONTYPE.AZ_TRN_TRANSACTION_MENU)
    //     setAccessrights(allowed)
    //     setTimeout(() => {
    //       setAccessrightsLoaded(true);
    //     }, 1000);
    //   })
  }

  return (
    <>
      {accessrightsLoaded && <>
        <div className="menu-item me-lg-1">
          <img alt='Logo' src={ThemeModeComponent.getMode() === 'dark' ? toAbsoluteUrl('/media/logos/logo-dark.svg') : toAbsoluteUrl('/media/logos/logo.svg')} className='h-40px' />
        </div>

        <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />

        {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_ALL_TRANSACTION_VIEW) &&
          <MenuItem title={'Home'} to='/home' />
        }

        {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_TRANSACTION_MENU) &&
          <MenuInnerWithSub title='Transactions' to='/transactions' menuPlacement='bottom-start' menuTrigger="{default:'click', lg: 'hover'}">

            {/* <MenuItem to='/transactions/list' title='List' hasBullet={true} /> */}
            {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_TRANSFER_COMPANY) && <MenuItem to='/transactions/transfer-company/new' title='Transfer Between Companies' hasBullet={true} />}
            {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_TRANSFER_WITHIN_COMPANY) && <MenuItem to='/transactions/transfer-within-company/new' title='Transfer within same Company' hasBullet={true} />}
            {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_PROMOTION) && <MenuItem to='/transactions/promotion/new' title='Promotion' hasBullet={true} />}
            {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_DEMOTION) && <MenuItem to='/transactions/demotion/new' title='Demotion' hasBullet={true} />}
            {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_CHANGE_EMPLOYMENT_CATEGORY) && <MenuItem to='/transactions/change-employment-category/new' title='Change Employment Category' hasBullet={true} />}
            {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_ADDITION_DEDUCTION) && <MenuItem to='/transactions/addition-deduction/new' title='Addition/Deduction' hasBullet={true} />}
            {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_MANAGER_ASSIGNMENT) && <MenuItem to='/transactions/manager-assignment/new' title='Change Supervisor' hasBullet={true} />}
            {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_SEPARATION) && <MenuItem to='/transactions/separation/new' title='Separation' hasBullet={true} />}
            {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_CLEARANCE) && <MenuItem to='/transactions/clearance/new' title='Clearance' hasBullet={true} />}
            {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_FINAL_SETTLEMENT) && <MenuItem to='/transactions/final-settlement/new' title='Final Settlement' hasBullet={true} />}
          </MenuInnerWithSub>
        }
        {accessrights.includes('salary.structure') && <MenuInnerWithSub title='Salary Structure' to='/salary-structure' menuPlacement='bottom-start' menuTrigger="{default:'click', lg: 'hover'}">
          {accessrights.includes('az.salary.structure.setting') && <MenuItem to='/salary-structure/settings' title='Settings' hasBullet={true} />}
          {accessrights.includes('az.trn.salary.structure') && <MenuItem to='/salary-structure/records' title='Salary Structure' hasBullet={true} />}
        </MenuInnerWithSub>
        }
        {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_SELF_SERVICE_MENU) &&
          <MenuInnerWithSub title='Self Service' to='/self-service' menuPlacement='bottom-start' menuTrigger="{default:'click', lg: 'hover'}">
            {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_LEAVE) && <MenuItem to='/self-service/leave-submission' title='Leave Submission' hasBullet={true} />}
            {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_PAYSLIP) && <MenuItem to='/self-service/payslip' title='Pay Slip' hasBullet={true} />}
            {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_SELF_SERVICE) && <MenuItem to='/self-service/service' title='Service' hasBullet={true} />}
            {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_ATTENDANCE_ADJUSTMENT) && 
            <MenuInnerWithSub title='Attendance' menuPlacement='right-start' menuTrigger="{default:'click', lg: 'hover'}" to={'#'} hasBullet={true} hasArrow={true} >
            <MenuItem to='/self-service/attendance' title='Attendance Adjustment' hasBullet={true} />
            <MenuItem to='/self-service/attendance/detailed-report' title='Detailed Report' hasBullet={true} />
            <MenuItem to='/self-service/attendance/summary-report' title='Summary Report' hasBullet={true} />
            <MenuItem to='/self-service/attendance/daily-attendance-report' title='Daily Attendance' hasBullet={true} />
            </MenuInnerWithSub>
          }
            {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_EXIT_INTERVIEW) && <MenuItem to='/self-service/exit-interview' title='Exit Interview' hasBullet={true} />}
            {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_TRAVEL_REQUEST) && <MenuItem to='/self-service/travel-request' title='Travel request' hasBullet={true} />}
            {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_TRN_TRAVEL_PER_DEIM) && <MenuItem to='/self-service/travel-per-diem' title='Travel per diem' hasBullet={true} />}

          </MenuInnerWithSub>
        }
      </>}
    </>
  )
}
