import { useState, useEffect } from 'react';
import axios from 'axios';
import azkSelect from "_azkatech/azkSelect"
import azkDataTable from "_azkatech/azkDataTable"
import { useNavigate } from 'react-router';
import { KTCard, KTIcon } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper';
import * as authHelper from 'app/modules/auth/core/AuthHelpers'
import { AzkStatusRibbon } from '_azkatech/azkStatusRibbon';
import { Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { AZKHelper } from 'app/modules/components/azkHelper';
import { AuthModel } from 'app/modules/auth';
import { CSVLink } from 'react-csv'
import ReactDOM from 'react-dom';
import { GetEmployeeByUserId } from 'app/modules/components/employeeDetail/_requests';

export const transactionModle = {
    transfer_company: 'az.trn.travel.per.diem',
};

const TransactionListView = () => {
    const [listDataDomain, setListDataDomain] = useState("");
    const [searchTerm, setSearchTerm] = useState<string>('')
    const navigate = useNavigate()
    const MODEL_NAME = "az.trn.travel.per.diem"

    const [myPerDiems, setMyPerDiems] = useState<boolean>(false);
    const [deleted, setDeleted] = useState<boolean>(false);
    const [selectedExcelData, setSelectedExcelData] = useState<any[]>([]);
    const [userId, setUserId] = useState<number>();
    const [currectEmployee, setCurrectEmployee] = useState<number>();
    const [dateFrom, setDateFrom] = useState<any>();
    const [dateTo, setDateTo] = useState<any>();
    const [employeeId, setEmployeeId] = useState<number>()

    const [pageLoaded, setPageLoaded] = useState<boolean>(false);

    const [shopUser, setShopUser] = useState<boolean>(true);
    const [isManger, setIsManger] = useState<boolean>(false);
    const [superManager, setSuperManager] = useState<boolean>(false);
    // #region Transfer List as DataTable
    const TRN_list_LOCAL_STORAGE_FILTER_KEY = 'azk-trn-self-service-list-filter-hr-lite'
    const fields = "'id', 'name', 'employee_id', 'azadea_id', 'date', 'employee_name', 'subject', 'traveler_name', ('az_country_id',('name')), ('az_company_id',('name')), ('brand_id',('name')), ('job_id',('name')), 'x_state', 'state', 'x_state_view', 'restarted'"

    const updateListDataDomain = (currectEmployee_id, search, country, company, brand, state, myPerDiem, adjustment_reason_id) => {
        let filter = {}
        filter['showFilters'] = showFilters
        let conds: string[] = [];
        if (country !== '' && country && country != 'undefined') {
            conds.push(`('az_country_id','=', ${country})`)
            if (handleChangeSelection) filter['az_country_id'] = country
        }
        if (company !== '' && company && company != 'undefined') {
            conds.push(`('az_company_id','=', ${company})`)
            if (handleChangeSelection) filter['az_company_id'] = company
        }
        if (brand !== '' && brand && brand != 'undefined') {
            conds.push(`('brand_id','=', ${brand})`)
            if (handleChangeSelection) filter['brand_id'] = brand
        }
        if (state !== '' && state && state != 'undefined') {
            conds.push(`('x_state','ilike', '${state}')`)
            if (handleChangeSelection) filter['x_state'] = state
        }
        if (myPerDiem && userId && myPerDiem != 'undefined') {
            conds.push(`('create_uid','=', ${userId})`)
            if (handleChangeSelection) filter['create_uid'] = userId
        }
        if (adjustment_reason_id && adjustment_reason_id !== undefined && adjustment_reason_id != 'undefined') {
            conds.push(`('adjustment_reason_id','=', ${adjustment_reason_id})`)
        }
        // if (dateFrom && moment(dateFrom).isValid()) {
        //     conds.push(`('date','>=', '${moment(dateFrom).utc(true)?.format(AZKHelper.BACKEND_DATE_FORMAT)}')`)
        // }
        // if (dateTo && moment(dateTo).isValid()) {
        //     conds.push(`('date_t','<=', '${moment(dateTo).utc(true)?.format(AZKHelper.BACKEND_DATE_FORMAT)}')`)
        // }
        if (search !== '' && search && search != 'undefined') {
            filter['search'] = search
            search = encodeURIComponent(search)
            conds.push(`'|', '|','|', '|', '|',('name','ilike', '${search}'), ('employee_id','ilike', '${search}'), ('azadea_id','ilike', '${search}'), ('az_country_id','ilike', '${search}'), ('az_company_id','ilike', '${search}'), ('brand_id','ilike', '${search}')`)
        }
        const lsValue = JSON.stringify(filter)
        if (handleChangeSelection) localStorage.setItem(TRN_list_LOCAL_STORAGE_FILTER_KEY, lsValue)
        return conds.join(',');
    }

    const handleEditBtnClick = (id: any) => {
        navigate(`/self-service/travel-per-diem/${id}/view`, { state: {}, replace: true });
    }

    const SystemColumns = [
        {
            name: 'Azadea ID',
            selector: row => row.azadeaId ? row.azadea_id : undefined,
            sortable: false,
            tech_name: 'azadea_id',
            width: '17%',
            cell: (d) => [(<>
                <Row className='m-0'>
                    <Row className='fs-7 fw-bold text-gray-800'>
                        {d.employeeName}
                    </Row>
                    <Row className='fs-8 fw-bold text-gray-600'>
                        {d.azadeaId}
                    </Row>
                </Row>
            </>)
            ]
        },
        {
            name: 'Country',
            selector: row => row.countryName ? row.countryName : undefined,
            sortable: false,
            tech_name: 'companyName',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {d.countryName}
                </Row>
            </>)]
        },
        {
            name: 'Company',
            selector: row => row.companyName ? row.companyName : undefined,
            sortable: false,
            tech_name: 'companyName',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {d.companyName}
                </Row>
            </>)]
        },
        {
            name: 'Brand',
            selector: row => row.brandName ? row.brandName : undefined,
            sortable: false,
            tech_name: 'brandName',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {d.brandName}
                </Row>
            </>)]
        },
        {
            name: 'Date',
            selector: row => row.attendanceDate ? row.attendanceDate : undefined,
            sortable: false,
            tech_name: 'attendanceDate',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {moment(d.attendanceDate).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                </Row>
            </>)]
        },
        {
            name: <> <span className='w-100'>Type</span> </>,
            selector: row => row.attendanceType ? row.attendanceType : undefined,
            sortable: false,
            tech_name: 'attendanceType',
            cell: (d) => [
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {d.attendanceType === 'I' ? 'In' : 'Out'}
                </Row>
            ]
        },
        {
            name: <> <span className='w-100'>Time</span> </>,
            selector: row => row.attendanceHour ? row.attendanceHour : undefined,
            sortable: false,
            tech_name: 'attendanceHour',
            cell: (d) => [
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {d.attendanceHour.toString().padStart(2, '0')}:{d.attendanceMinute.toString().padStart(2, '0')}
                </Row>
            ]
        },
        // {
        //     name: <> <span className='w-100 text-center'>Actions</span> </>,
        //     sortable: false,
        //     selector: undefined,
        //     cell: (d) => [
        //         (<>
        //             <div className='w-100 m-0 d-flex justify-content-center'>
        //                 <span
        //                     onClick={handleEditBtnClick.bind(this, d.id)}
        //                     className="btn btn-sm btn-icon btn-active-light-primary me-1"
        //                 ><KTIcon iconName='pencil' className='fs-3' /></span>
        //             </div>
        //         </>)
        //     ]
        // }
    ]

    const columns = [
        {
            name: 'Subject',
            selector: row => row.subject ? row.subject : undefined,
            sortable: true,
            tech_name: 'subject',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {d.subject}
                </Row>
            </>)]
        },
        {
            name: 'Date',
            selector: row => row.date ? row.date : undefined,
            sortable: true,
            tech_name: 'date',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {moment(d.date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                </Row>
            </>)]
        },
        {
            name: 'Azadea ID',
            selector: row => row.employee_id ? row.azadea_id : undefined,
            sortable: true,
            tech_name: 'azadea_id',
            width: '17%',
            cell: (d) => [(<>
                <Row className='m-0'>
                    <Row className='fs-7 fw-bold text-gray-800'>
                        {d.traveler_name}
                    </Row>
                    <Row className='fs-7 fw-bold text-gray-800'>
                        {d.employee_name}
                    </Row>
                    <Row className='fs-8 fw-bold text-gray-600'>
                        {d.azadea_id}
                    </Row>
                </Row>
            </>)
            ]
        },
        {
            name: 'Company',
            selector: row => row.az_company_id ? row.company_id.name : undefined,
            sortable: true,
            tech_name: 'az_company_id',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {d?.az_company_id?.name}
                </Row>
            </>)]
        },
        {
            name: 'Brand',
            selector: row => row.brand_id ? row.brand_id.name : undefined,
            sortable: true,
            tech_name: 'brand_id',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {d?.brand_id?.name}
                </Row>
            </>)]
        },
        {
            name: <> <span className='w-100 text-center'>Status</span> </>,
            selector: row => row.x_state ? row.x_state : undefined,
            sortable: true,
            tech_name: 'x_state',
            style: {
                display: 'block',
                'text-wrap': 'nowrap'
            },
            cell: (d) => [
                <AzkStatusRibbon current={d.x_state} modelName={'az.trn.travel.per.diem'} transactionId={d.id} currentName={d.x_state_view} />
            ]
        },
        {
            name: <> <span className='w-100 text-center'>Actions</span> </>,
            sortable: false,
            selector: undefined,
            cell: (d) => [
                (<>
                    <div className='w-100 m-0 d-flex justify-content-center'>
                        <span
                            onClick={handleEditBtnClick.bind(this, d.id)}
                            className="btn btn-sm btn-icon btn-active-light-primary me-1"
                        ><KTIcon iconName='pencil' className='fs-3' /></span>
                    </div>
                </>)
            ]
        }
    ];

    const onSelectedRowsChange = row => {
        getDataToExport(row.selectedRows)
    };

    const getDataToExport = async (data: any[]) => {
        let excelDataList: any[] = []
        if (data) {
            await data.forEach(row => {
                const object = builtExportObject(row)
                excelDataList.push(object)
            })
            setSelectedExcelData(excelDataList)
        }
    }

    const hitExport = (data: any[]) => {
        // if (data && data.length > 0) {
        //     const inputElement = document.querySelector('#export_data_excel') as HTMLInputElement
        //     inputElement.click()
        // }
        document.body.classList.remove('page-loading')
        if (data && data.length > 0) {
            const parentElement = document.createElement('div');
            const element = <div><CSVLink data={data} filename={'Transaction List ' + moment().format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)} id="export_data_excel" ></CSVLink></div>
            ReactDOM.render(element, parentElement);
            document.body.appendChild(parentElement)
            const inputElement = document.querySelector('#export_data_excel') as HTMLInputElement
            inputElement.click()
            document.body.removeChild(parentElement)
        }
    }

    const exportSelectedDataToExcel = async () => {
        document.body.classList.add('page-loading')
        hitExport(selectedExcelData)
    }

    const exportDataToExcel = async () => {
        let excelDataList: any[] = []
        document.body.classList.add('page-loading')
        await axios.get(`${API_URL}/${MODEL_NAME}?filters=[${listDataDomain}]&include_fields=[${fields}]&order='date desc, name desc'`).then(async response => {
            const remoteData = response.data?.results ? response.data.results : []
            await remoteData.forEach(row => {
                const object = builtExportObject(row)
                excelDataList.push(object)
            })
            if (excelDataList && excelDataList.length > 0) {
                hitExport(excelDataList)
            }
        })
    }

    const builtExportObject = (row) => {
        const object = {}
        const time = row.attendanceHour.toString().padStart(2, '0') + ':' + row.attendanceMinute.toString().padStart(2, '0')
        object['Type'] = 'I' === row.attendanceType ? 'In' : 'Out'
        object['Date'] = row.attendanceDate
        object['Azadea ID'] = row.azadeaId
        object['Employee Name'] = row.employeeName
        object['Country'] = row.countryName
        object['Company'] = row.companyName
        object['Brand'] = row.brandName
        object['Shop'] = row.organizationName
        object['Time'] = time
        return object
    }
    const table = azkDataTable({
        modelName: MODEL_NAME,
        fields: fields,
        dataDomain: listDataDomain,
        columns: columns,
        dataOrder: 'date desc, name desc',
        exportFunction: getDataToExport,
        pageReady: pageLoaded
    })

    // #endregion Transfer List

    // #region State Dropdown

    const fetchUser = async () => {
        let supper_access = false
        let shpeUserTemp = true
        let managerEmployee = false
        const from_date = moment().startOf('month').format(AZKHelper.BACKEND_DATE_FORMAT)
        const to_date = moment().startOf('month').add(1, 'month').format(AZKHelper.BACKEND_DATE_FORMAT)
        setDateFrom(from_date)
        setDateTo(to_date)
        let _auth = authHelper.getAuth()
        if (!_auth) {
            const auth = localStorage.getItem('azk-auth-hr-lite')
            if (auth) {
                _auth = JSON.parse(auth) as AuthModel
            }
        }
        GetEmployeeByUserId(_auth?.uid).then(async response => {
            if (response && response.is_manager_fr && response.is_manager_fr !== null) {
                setIsManger(true)
                managerEmployee = true
                setCurrectEmployee(response.id)
            } else {
                setIsManger(false)
                setCurrectEmployee(response.id)
            }
            setUserId(_auth?.uid)
            if (response.is_back_office_fr && response.is_back_office_fr !== null) {
                setShopUser(!response?.is_back_office_fr)
                shpeUserTemp = !response?.is_back_office_fr
            }
            if (response.is_supper_access && response.is_supper_access !== null) {
                supper_access = true;
                setSuperManager(response?.is_supper_access);
            }
            const filterVals: string | null = localStorage.getItem(TRN_list_LOCAL_STORAGE_FILTER_KEY)
            if (filterVals) {
                const jsonObj = JSON.parse(filterVals)
                // setShowFilters(jsonObj['showFilters'])
                if (jsonObj['search']) await setSearchTerm(jsonObj['search'])
                if (jsonObj['az_country_id']) await setCountry(jsonObj['az_country_id'])
                if (jsonObj['brand_id']) await setBrandefaultValue(jsonObj['brand_id'])
                if (jsonObj['company_id']) await setCompany(jsonObj['company_id'])
                if (jsonObj['deleted']) await setDeleted(jsonObj['deleted'])
                if (jsonObj['x_state']) await setState(jsonObj['x_state'])
                if (jsonObj['adjustment_reason_id']) await setState(jsonObj['x_state'])
                setTimeout(() => {
                    setListDataDomain(updateListDataDomain(response.id, jsonObj['search'], jsonObj['az_country_id'], jsonObj['company_id'], jsonObj['brand_id'], jsonObj['x_state'], myPerDiems, jsonObj['adjustment_reason_id']));
                }, 500)
            } else {
                setTimeout(() => {
                    setListDataDomain(updateListDataDomain(response.id, searchTerm, az_country, az_company, az_brand, az_state, myPerDiems, az_adjustment_reason_id));
                }, 500)
            }
        })
        setTimeout(() => {
            setPageLoaded(true);
        }, 1000)
    }

    useEffect(() => {
        document.body.classList.remove('page-loading')
        fetchUser();
    }, []);

    // #region status Dropdown
    const [az_state, setState] = useState('');
    const handleStateChange = (option) => {
        if (handleChangeSelection) {
            setState(option);
            setListDataDomain(updateListDataDomain(currectEmployee, searchTerm, az_country, az_company, az_brand, option, myPerDiems, az_adjustment_reason_id));
        }
    }

    const az_state_select = azkSelect({
        compID: "az_state_select",
        modelName: "az.transaction.status",
        valueField: "technical_name",
        dataDomain: ``,
        titleField: "name",
        allowEmpty: true,
        isSearchable: true,
        defaultValue: az_state,
        onChange: handleStateChange
    });
    // #endregion status Dropdown


    const loadTypeOptions = async (search, loadedOptions) => {
        const options: any[] = []
        options.push({ value: 'in', label: 'In' })
        options.push({ value: 'out', label: 'Out' })
        const filteredOptions = options.filter((option) =>
            option.label.toString().includes(search.toLowerCase())
        );
        return {
            options: filteredOptions,
            hasMore: false,
        };
    };

    const [az_adjustment_type, setTravelPerDiemType] = useState<any>('');

    // #region status Dropdown
    const [az_adjustment_reason_id, setAdjustmentReasonId] = useState('');
    const handleAdjustmentReasonIdChange = (option) => {
        if (handleChangeSelection) {
            setAdjustmentReasonId(option);
            setListDataDomain(updateListDataDomain(currectEmployee, searchTerm, az_country, az_company, az_brand, az_state, myPerDiems, option));
        }
    }

    const az_type_select = azkSelect({
        compID: "az_type_select",
        modelName: "az.attendance.adjust.reason",
        valueField: "id",
        dataDomain: ``,
        titleField: "name",
        allowEmpty: true,
        isSearchable: true,
        defaultValue: az_adjustment_reason_id,
        onChange: handleAdjustmentReasonIdChange
    });
    // #endregion Type Dropdown

    // #region Country Dropdown
    const [az_country, setCountry] = useState('');
    const [activetCountryDomain, setActivetCountryDomain] = useState(false);

    const handleCountryChange = (val) => {
        if (activetCountryDomain) {
            if (handleChangeSelection) {
                setCountry(val);
                setCompanyDomain(val ? `('country_id','=', ${val})` : '');
                setListDataDomain(updateListDataDomain(currectEmployee, searchTerm, val, az_company, az_brand, az_state, myPerDiems, az_adjustment_reason_id));
            }
        }
        setActivetCountryDomain(true)
    }

    const az_country_select = azkSelect({
        compID: "az_country_select",
        modelName: "az.country",
        valueField: "id",
        titleField: "name",
        allowEmpty: true,
        isSearchable: true,
        defaultValue: az_country,
        onChange: handleCountryChange
    });
    // #endregion Country Dropdown


    // #region Company Dropdown
    const [az_company, setCompany] = useState(null);
    const [companyDomain, setCompanyDomain] = useState('');
    const [activetCompanyDomain, setActivetCompanyDomain] = useState(false);

    const handleCompanyChange = (val) => {
        if (activetCompanyDomain) {
            setCompany(val);
            setBrandefaultValue('')
            if (val !== '') {
                if (handleChangeSelection) {
                    setListDataDomain(updateListDataDomain(currectEmployee, searchTerm, az_country, val, '', az_state, myPerDiems, az_adjustment_reason_id));
                }
                axios.get(`${API_URL}/az.company?filters=[('id', '=', ${val ? val : '0'})]&include_fields=['brand_ids']`).then((result: any) => {
                    if (result.data && result.data.results && result.data.count > 0 && result.data.results[0].brand_ids !== null) {
                        setBrandDomain(`('id', 'in', [${result.data.results[0].brand_ids}])`);
                    } else {
                        setBrandDomain('');
                    }
                });
            } else {
                setBrandDomain('');
            }
        }
        setActivetCompanyDomain(true)
    }

    const az_company_select = azkSelect({
        compID: "az_company_select",
        modelName: "az.company",
        valueField: "id",
        titleField: "name",
        dataDomain: companyDomain,
        defaultValue: az_company,
        allowEmpty: true,
        onChange: handleCompanyChange
    });
    // #endregion Company Dropdown

    // #region Brand Dropdown
    const [az_brand, setBrand] = useState('');
    const [brandDomain, setBrandDomain] = useState('');
    const [brandefaultValue, setBrandefaultValue] = useState('');
    const [activetBrandDomain, setActivetBrandDomain] = useState(false);

    const handleBrandChange = (val) => {
        if (activetBrandDomain) {
            setBrand(val);
            setBrandefaultValue(val)
            if (handleChangeSelection) {
                setListDataDomain(updateListDataDomain(currectEmployee, searchTerm, az_country, az_company, val, az_state, myPerDiems, az_adjustment_reason_id));
            }
        }
        setActivetBrandDomain(true)
    }

    const az_brand_select = azkSelect({
        compID: "az_brand_select",
        modelName: "az.brand",
        valueField: "id",
        titleField: "name",
        dataDomain: brandDomain,
        allowEmpty: true,
        onChange: handleBrandChange,
        defaultValue: brandefaultValue
    });

    // #endregion Brand Dropdown

    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [handleChangeSelection, setHandleChangeSelection] = useState<boolean>(false);

    function showFilter() {
        setShowFilters(!showFilters)
        setHandleChangeSelection(true)
    }

    return (
        <>
            <KTCard>
                <div className='card-header'>
                    <div className='card-title'>
                        {/* begin::Search */}
                        <div className='d-flex align-items-center position-relative my-1'>
                            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                            <input
                                type='text'
                                data-kt-user-table-filter='search'
                                className='form-control form-control-solid w-300px ps-14'
                                placeholder='Search Employee, Transaction...'
                                value={searchTerm}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value)
                                    setListDataDomain(updateListDataDomain(currectEmployee, e.target.value, az_country, az_company, az_brand, az_state, myPerDiems, az_adjustment_reason_id))
                                }}
                            />
                        </div>
                        {/* end::Search */}
                    </div>
                    {/* begin::Card toolbar */}
                    <div className='card-toolbar'>
                        {/* begin::Group actions */}
                        {/* begin::Export */}
                        <button type='button' className='btn btn-light-primary me-3 btn-sm' onClick={() => navigate(`/self-service/travel-per-diem/new`, { state: {}, replace: true })}>
                            <KTIcon iconName='add-item' className='fs-2' />
                            New
                        </button>
                        <button type='button' className='btn btn-light-primary me-3 btn-sm' onClick={() => showFilter()}>
                            <KTIcon iconName='filter-search' className='fs-2' />
                            Filter
                        </button>
                        {/* <CSVLink data={excelData} filename={'Transaction List ' + moment().format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)} id="export_data_excel" ></CSVLink> */}
                        <button type='button' onClick={exportSelectedDataToExcel} className={`btn btn-light-primary me-3 btn-sm ${selectedExcelData && selectedExcelData.length > 0 ? '' : 'd-none'}`}>
                            <KTIcon iconName='exit-up' className='fs-2' />
                            Export Selected
                        </button>
                        <button type='button' className={`btn btn-light-primary me-3 btn-sm ${selectedExcelData && selectedExcelData.length > 0 ? 'd-none' : ''}`} onClick={exportDataToExcel} >
                            <KTIcon iconName='exit-up' className='fs-2' />
                            Export
                        </button>
                        {/* end::Export */}

                        {/* end::Group actions */}
                    </div>
                    {/* end::Card toolbar */}
                </div>
            </KTCard>
            {showFilters &&
                <KTCard className='mt-3'>
                    <div className='card-body'>
                        <div className='row m-0'>
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-7 my-auto col-12 col-md-4'>Status</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {handleChangeSelection && (<>
                                    {az_state_select}
                                </>)}
                                {/* end::Input */}
                            </div>
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-7 my-auto col-12 col-md-4'>Country</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {handleChangeSelection && (<>
                                    {az_country_select}
                                </>)}
                                {/* end::Input */}
                            </div>
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-7 my-auto col-12 col-md-4'>Company</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {handleChangeSelection && (<>
                                    {az_company_select}
                                </>)}
                                {/* end::Input */}
                            </div>
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-7 my-auto col-12 col-md-4'>Brand</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {az_brand_select}
                                {/* end::Input */}
                            </div>
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-7 my-auto col-12 col-md-4'></label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                <div className='d-flex align-items-center justify-content-center'>
                                    <label htmlFor='my_reqest'>
                                        <Form.Check
                                            type="checkbox"
                                            id='my_reqest'
                                            value='my_reqest'
                                            label='My PerDiems'
                                            name='my_reqest'
                                            checked={myPerDiems}
                                            onChange={(val) => {
                                                setMyPerDiems(val.target.checked);
                                                setListDataDomain(updateListDataDomain(currectEmployee, searchTerm, az_country, az_company, az_brand, az_state, val.target.checked, az_adjustment_reason_id))
                                            }}
                                            className='p-1'
                                        />
                                    </label>
                                </div>
                                {/* end::Input */}
                            </div>
                        </div>
                    </div>
                </KTCard >
            }
            <KTCard className='mt-3'>
                {table}
            </KTCard>
        </>)
}

export default TransactionListView;