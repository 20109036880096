import axios, { AxiosResponse } from 'axios'
import { Response } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper'

const MODEL_NAME = 'az.transaction.status.chart'
const URL = `${API_URL}/${MODEL_NAME}`
const INCLUDE_FIELDS = `'count', 'az_country_id', 'az_company_id',  'brand_id', 'year', 'month', 'closed', 'type_name', 'type_id' `



export type TransactionStatusChart = {
  count?: number
  az_country_id?: any
  az_company_id?: any
  brand_id?: any
  closed: boolean | false
  type_name: string
  type_id: number
  year?: number
  month?: number
}

const GetTransactionStatusChartData = (listDataDomain: string): Promise<TransactionStatusChart[]> => {
  return axios
    .get(`${URL}?filters=[${listDataDomain}]&include_fields=[${INCLUDE_FIELDS}]`)
    .then((response: AxiosResponse<Response<TransactionStatusChart[]>>) => response.data)
    .then((response: Response<TransactionStatusChart[]>) => response['results'])
}

export { GetTransactionStatusChartData, MODEL_NAME}


