import { PageLink, PageTitle } from '_metronic/layout/core'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import TransactionList from './list'
import { useAuth } from 'app/modules/auth'

const transactionBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/home/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const TransactionListRouter = () => (
  <Routes>
    <Route
      element={
        <>
          <Outlet />
        </>
      }
    >
      <Route
        path='list'
        element={
          <>
            <PageTitle breadcrumbs={transactionBreadCrumbs}>List</PageTitle>
            <TransactionList />
          </>
        }
      />
    </Route>
    <Route index element={<Navigate to='/home/list' />} />

  </Routes>
)

export default TransactionListRouter
