import axios, {AxiosResponse} from 'axios'
import { SalaryStructure, SalaryStructureQueryResponse } from './model'
import { ID, Response } from '_metronic/helpers'
import moment from 'moment'
import { AZKHelper } from 'app/modules/components/azkHelper'
import { API_URL } from '_azkatech/azkHelper'
import useFixServerClientData from 'app/utilities/fix-Server-Client-Data'

const MODEL_NAME = 'az.trn.salary.structure'
const URL = `${API_URL}/${MODEL_NAME}`
const GET_URL = `${API_URL}/${MODEL_NAME}/`
const INCLUDE_FIELDS_DETAILS = `('id', 'salary_structure_id', 'job_id', ('grade_id',('id', 'grade_name')), 'person_type', 'employment_category_id', 'package', 'has_com', 'head_count', 'commission', 'commission_value', 'total_gross', 'state', 'is_full_time', 'can_edit_job')`
const INCLUDE_FIELDS = `'id', 'name', 'restarted', 'can_write', 'can_review', 'can_delete', 'can_duplicate', 'validated', 'rejected', 'x_state', 'state', 'state_view', 'current_approval_ids', 'az_country_id', 'brand_id', 'location_id', 'as_effective_date', 'is_service_charge', ('detail_ids', [${INCLUDE_FIELDS_DETAILS}]), ('detail_ids_view', [${INCLUDE_FIELDS_DETAILS}]), 'sales', 'selling_area', 'commission_hc', 'non_commission_hc', 'total_hc', 'sales_per_sqm', 'sqm_employee', 'monthly_working_hours_ft', 'monthly_working_hours_pt', 'total_monthly_working_hours', 'revenue_per_hour', 'is_service_charge', 'sc_distribution', 'distribution', 'total_points', 'pay_per_point', 'com_hc_salary', 'non_com_hc_salary', 'total_salary', 'ratio', 'trigger_compute', 'deleted'`

const GetSalaryStructureByFilter = (country_id: number, brand_id: number, location_id: number): Promise<SalaryStructure[]> => {
  let filters = `('az_country_id','=', ${country_id}), ('brand_id','=', ${brand_id}), ('location_id','=', ${location_id})`
  const { FixServerArrayData} = useFixServerClientData();
  return axios
    .get(`${URL}?filters=[${filters}]&include_fields=[${INCLUDE_FIELDS}]&order= as_effective_date desc&limit=1`)
    .then((response: AxiosResponse<Response<SalaryStructure[]>>) => response.data)
    .then((response: Response<SalaryStructure[]>) => FixServerArrayData(response['results']))
}

const GetSalaryStructureById = (id: ID): Promise<SalaryStructure> => {
  const { FixServerData} = useFixServerClientData();
  return axios
    .get(`${URL}?filters=[('id','=',${id})]&include_fields=[${INCLUDE_FIELDS}]`)
    .then((response: AxiosResponse<Response<SalaryStructure>>) => response.data)
    .then((response: Response<SalaryStructure>) => {
      response['results'][0]['detail_ids'] = response['results'][0]['detail_ids_view']
      return FixServerData(response['results'][0], true)
    })
}

const CreateSalaryStructure = async (transaction: SalaryStructure): Promise<SalaryStructure | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transaction, true)
  return axios
    .post(URL, shallowTransaction)
    .then((response: AxiosResponse<SalaryStructure>) => response.data)
}

const UpdateSalaryStructure = async (transaction: SalaryStructure): Promise<SalaryStructure | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transaction, true)
  return axios
    .put(`${URL}/${transaction.id}`, shallowTransaction)
    .then((response: AxiosResponse<Response<SalaryStructure>>) => response.data)
    .then((response: Response<SalaryStructure>) => response.data)
}

const DeleteSalaryStructure = (transactionId: number): Promise<SalaryStructure | undefined> => {
  return axios
    .delete(`${URL}/${transactionId}`)
    .then((response: AxiosResponse<Response<SalaryStructure>>) => response.data)
    .then((response: Response<SalaryStructure>) => response.data)
}
export { GetSalaryStructureById, CreateSalaryStructure, UpdateSalaryStructure, MODEL_NAME, GetSalaryStructureByFilter, DeleteSalaryStructure}