import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken, logoutR} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import axios, { AxiosResponse } from 'axios'
import { API_URL } from '_azkatech/azkHelper'
import { AZKHelper } from 'app/modules/components/azkHelper'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  hasAuthority: any
  getAuthority: any
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  hasAuthority: (menu, action) =>{},
  getAuthority: (neededAccessRights) =>{},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    let _auth = authHelper.getAuth()
    localStorage.removeItem(AZKHelper.PIN_CODE_LOCAL_STORAGE_KEY)
    if (_auth && _auth.refresh_token){
      logoutR(_auth.refresh_token)
    }
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  const hasAuthority = (modle_name:string, action:string) => {
    return axios
    .get(`${API_URL}/${modle_name}/access_right/${action}`)
    .then((response: AxiosResponse<any>) => response.data['allowed']).then(allowed => {
      return allowed
    })
  }

  const getAuthority = (neededAccessRights) => {
    return axios
    .put(`${API_URL}/res.users/${auth?.uid}/get_access_right/`, {'model_list': neededAccessRights})
    .then((response: AxiosResponse<any>) => response.data)
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout, hasAuthority, getAuthority}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S access_token) before rendering the application
  useEffect(() => {
    const requestUser = async (auth: AuthModel) => {
      try {
        if (!didRequest.current) {
          const user = await getUserByToken(auth)
          if (user) {
            setCurrentUser(user)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.access_token) {
      requestUser(auth)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
