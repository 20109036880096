import axios, {AxiosResponse} from 'axios'
import { AttendanceAdjustment, AttendanceAdjustmentQueryResponse } from './model'
import { ID, Response } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper' 
import useFixServerClientData from 'app/utilities/fix-Server-Client-Data'

const MODEL_NAME = 'az.trn.attendance.adjustment'
const URL = `${API_URL}/${MODEL_NAME}`
const GET_URL = `${API_URL}/${MODEL_NAME}/`
const INCLUDE_FIELDS = `'id', 'name', 'employee_id', 'x_state', 'x_state_view', 'can_review', 'can_write', 'can_delete', 'validated', 'rejected', 'restarted', 'az_country_id', 'az_company_id', 'brand_id', 'azadea_id', 'azadea_employee_name', 'branch_id', 'section_id', 'grade_id', 'job_id', 'adjustment_reason_id', 'comment', 'create_date', ('line_ids', [('id' ,'date', 'hour', 'min', 'adjustment_type')])`


const GetAttendanceAdjustmentById = (id: ID): Promise<AttendanceAdjustment> => {
  const { FixServerData} = useFixServerClientData();
  return axios
    .get(`${URL}?filters=[('id','=',${id})]&include_fields=[${INCLUDE_FIELDS}]`)
    .then((response: AxiosResponse<Response<AttendanceAdjustment>>) => response.data)
    .then((response: Response<AttendanceAdjustment>) => FixServerData(response['results'][0]))
}

const CreateAttendanceAdjustment = async (transaction: AttendanceAdjustment): Promise<AttendanceAdjustment | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transaction)
  return axios
    .post(URL, shallowTransaction)
    .then((response: AxiosResponse<AttendanceAdjustment>) => response.data)
}

const UpdateAttendanceAdjustment = async (transaction: AttendanceAdjustment): Promise<AttendanceAdjustment | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transaction)
  return axios
    .put(`${URL}/${transaction.id}`, shallowTransaction)
    .then((response: AxiosResponse<Response<AttendanceAdjustment>>) => response.data)
    .then((response: Response<AttendanceAdjustment>) => response.data)
}

const DeleteAttendanceAdjustment = (transactionId: number): Promise<AttendanceAdjustment | undefined> => {
  return axios
    .delete(`${URL}/${transactionId}`)
    .then((response: AxiosResponse<Response<AttendanceAdjustment>>) => response.data)
    .then((response: Response<AttendanceAdjustment>) => response.data)
}

export { GetAttendanceAdjustmentById, CreateAttendanceAdjustment, UpdateAttendanceAdjustment, MODEL_NAME, DeleteAttendanceAdjustment}


