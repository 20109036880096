import { FC, useEffect, useState } from 'react'
import clsx from 'clsx'
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  ThemeModeSwitcher,
} from '../../../partials'
import { useLayout } from '../../core'
import { useAuth } from '../../../../app/modules/auth'
import { useNotifications } from 'app/utilities/notifications'
import { AZKHelper } from 'app/modules/components/azkHelper'

const itemClass = 'ms-1 ms-lg-3',
  btnClass = 'btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px',
  userAvatarClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {
  const { config } = useLayout()
  const { currentUser } = useAuth()
  const { checkNotifications } = useNotifications();
  const [hasNotification, setHasNotification] = useState<boolean>(false)
  const [notificationCount, setNotificationCount] = useState<number>()
  const [notifications, setNotifications] = useState<any>([])

  useEffect(() => {
    checkNotificationsFun(true)
    const interval = setInterval(() => {
      checkNotificationsFun(true)
    }, AZKHelper.NOTIFICATION_FETCH_PERIOD);
    return () => clearInterval(interval);
  }, [])

  const checkNotificationsFun = (auto: boolean) => {
    checkNotifications()?.then(async data => {
      if (data && data.length > 0) {
        if (!hasNotification)
          setNotifications(Object.values(data))
        if (auto) {
          setHasNotification(true)
          let count = 0
          await data.forEach(rec => {
            count = count + rec.pending_count
          })
          setNotificationCount(count)
        } else {
          setNotificationCount(undefined)
        }
      }
    })
  }
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>

      {/* NOTIFICATIONS */}
      <div className={clsx('d-flex align-items-center', itemClass)}>
        {/* begin::Menu- wrapper */}
        <div
          className={clsx(btnClass, 'position-relative')}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          onClick={() => { setHasNotification(false); checkNotificationsFun(false); setNotificationCount(undefined); }}
          id='notification-list-btn'
        >
          <i className='bi bi-bell fs-2' />
          {notificationCount && <span className='notification-count'>{notificationCount}</span>}
          {hasNotification && <span className='pulse-ring w-45px h-45px' />}

        </div>
        <HeaderNotificationsMenu  {...notifications} />
        {/* end::Menu wrapper */}
      </div>

      {/* begin::Theme mode */}
      <div className={clsx('d-flex align-items-center', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={btnClass} />
      </div>
      {/* end::Theme mode */}

      {/* begin::User */}
      <div className={clsx('d-flex align-items-center', itemClass)} id='kt_header_user_menu_toggle'>
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={currentUser?.image_128 ? 'data:image/png;base64,' + currentUser.image_128 : toAbsoluteUrl('/media/avatars/blank.png')} alt='user avatar' />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTIcon iconName='text-align-left' className='fs-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export { Topbar }
