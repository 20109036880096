import { Response } from "_metronic/helpers"

export type SelfServiceTypeFields = {
  id: number
  name: string | ''
  type: string | ''
  value: string | ''
  required: boolean | false
  model?: string | ''
  data_set?: string | ''
  valid: boolean | false
}

export type SelfServiceFields = {
  key: any
  value: any
  field_id: any
}
export type SelfService = {
  id?: number
  name?: string
  employee_id?: any
  type_id?: number
  x_state?: string
  x_state_view?: string
  can_review?: boolean
  can_delete?: boolean
  can_write?: boolean
  validated?: boolean, 
  rejected?: boolean,
  state?: string
  az_country_id?: any
  az_company_id?: any
  brand_id?: any
  azadea_id?: number
  azadea_employee_name?: string
  branch_id?: any
  section_id?: any
  grade_id?: any
  job_id?: any
  time_set?: string
  can_edit?: boolean
  restarted?: boolean
  service_type_id?: any
  create_date?: any
  comment?: string
  file_id?: any
  self_service_field: {
    key: any
    value: any
    field_id: any
  }[] | []
  
}

export const initialTransfaction: SelfService = {
  name: '',
  self_service_field: [{
    key: '',
    value:'',
    field_id: ''
  }]
}

export type SelfServiceQueryResponse = Response<Array<SelfService>>

export const initialTransferCompany: SelfService = {
  self_service_field: []
}
