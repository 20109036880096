import AzkSelect from "_azkatech/azkSelect";
import { EmployeeDetail, EmpoyeeModel } from "app/modules/components/employeeDetail/employeeDetail";
import * as Yup from 'yup'
import { Payslip, initialTransfaction } from "./model";
import { FC, useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik, FormikProps, FormikValues } from 'formik'
import { PutPayslip } from "./_requests";
import moment from "moment";
import { Form, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { AZKHelper } from "app/modules/components/azkHelper";
import { useParams } from "react-router-dom";
import { GetEmployeeByIdNoSalary, GetEmployeeByUserId } from "app/modules/components/employeeDetail/_requests";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import reactElementToJSXString from "react-element-to-jsx-string";
import ReactDOM from 'react-dom';
import { AUTH_LOCAL_STORAGE_KEY, AuthModel } from "app/modules/auth";
import { AsyncPaginate } from "react-select-async-paginate";

type Props = {
}
const editTransactionSchema = Yup.object().shape({
    employee_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Employee is required'),
    payslip_month: Yup.string().required('Month from is required'),
    payslip_year: Yup.string().required('Year from is required')
})


const Transactionform: FC<Props> = () => {
    const { id } = useParams();
    const intl = useIntl();
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<Payslip>>(null);
    const [employee, setEmployee] = useState<EmpoyeeModel>();
    const [payslip, setPayslip] = useState<Payslip>();
    const [payslipDate, setPayslipDate] = useState<String>();
    var nf = new Intl.NumberFormat();

    const [employeeDomain, setEmployeeDomain] = useState<string>('');

    const checkUserType = () => {
        let lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
        if (lsValue) {
            const auth: AuthModel = JSON.parse(lsValue) as AuthModel
            if (auth && auth.uid) {
                GetEmployeeByUserId(auth.uid).then(response => {
                    if (response) {
                        let is_manager = false
                        let shop_user = true
                        let domain = ``
                        if (response.is_manager_fr === true) is_manager = true
                        if (response.is_back_office_fr == true) shop_user = false
                        if (is_manager && !shop_user) {
                            domain = domain.concat(`'|', ('id', '=', ${response.id}), ('parent_id', '=', ${response.id})`)
                        } else if (is_manager && shop_user) {
                            domain = domain.concat(`'|', '|', ('id', '=', ${response.id}), ('parent_id', '=', ${response.id}), ('super_parent_id', '=', ${response.id})`)
                        } else {
                            domain = domain.concat(`('id', '=', ${response.id})`)
                        }
                        setEmployeeDomain(domain)
                    }

                })
            }
        }
    }
    const handleEmployeeChange = async function (employeeId): Promise<{}> {
        document.body.classList.add('page-loading')
        setPayslip(undefined)
        if (employeeId) {
            setEmployee(undefined)
            GetEmployeeByIdNoSalary(employeeId).then(response => {
                if (response) {
                    setEmployee(response)
                    formRef.current?.setFieldValue('employee_id', response.id)
                    if (!month || month === null) {
                        setMonth(moment().format(AZKHelper.FRONTEND_DATE_MONTH_ONLY_FORMAT))
                        formRef.current?.setFieldValue('payslip_month', moment().format(AZKHelper.FRONTEND_DATE_MONTH_ONLY_FORMAT))
                    }
                    if (!year || year === null) {
                        setYear(moment().format(AZKHelper.FRONTEND_DATE_YEAR_ONLY_FORMAT))
                        formRef.current?.setFieldValue('payslip_year', moment().format(AZKHelper.FRONTEND_DATE_YEAR_ONLY_FORMAT))
                    }
                    document.body.classList.remove('page-loading')
                } else {
                    document.body.classList.remove('page-loading')
                }
            });
        } else {
            document.body.classList.remove('page-loading')
            formRef.current?.resetForm();
            setEmployee(undefined)
            formRef.current?.setValues({
            })
        }
        return true
    }

    useEffect(() => {
        formRef.current?.resetForm()
        checkUserType()
    }, [id])

    const cancel = () => {
        navigate(`/self-service/payslip/`, { state: {}, replace: true });
    }

    const onSubmitHandler = async (values: Payslip, actions: FormikValues) => {
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        if (formRef.current?.isValid) {
            document.body.classList.add('page-loading')
            PutPayslip(employee?.id, month, year).then(date => {
                setPayslip(date)
                document.body.classList.remove('page-loading')
            })
        } else {
            actions.setSubmitting(false)
            formRef.current?.validateForm().then(errors => {
                if (Object.keys(errors).length > 0) {
                    console.log(errors)
                }
            });
        }
    }

    const getTargetElement = () => document.getElementById('content-id');
    const generate_report = async (fs) => {
        let to_pay_itme: any = []
        let total_to_pay = 0;
        let total_to_pay_YTD = 0;
        let deduction_itme: any = []
        let total_deduction = 0;
        let total_deduction_YTD = 0;
        let medical_itme: any = []
        let medical_deduction_itme: any = []
        let NFFS_itme: any = []
        let total_NFFS = 0;
        let total_NFFS_YTD = 0;
        await payslip?.details?.map((detail, index) => {
            if (detail.type === 'Employee Pay Type') {
                if (detail?.amount && detail?.amountYTD) {
                    total_to_pay += parseFloat(detail?.amount)
                    total_to_pay_YTD += parseFloat(detail?.amountYTD)
                }
                to_pay_itme.push(<tr>
                    <td width="40%" align="left">{detail.label}</td>
                    {detail?.amount &&
                        <td width="30%" align="right">
                            {detail.amount && parseFloat(detail?.amount)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </td>
                    }{!detail?.amount &&
                        <td width="30%" align="right">0.00</td>
                    }
                    {detail?.amountYTD &&
                        <td width="30%" align="right">
                            {detail.amountYTD && parseFloat(detail?.amountYTD)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </td>
                    }{!detail?.amountYTD &&
                        <td width="30%" align="right">0.00</td>
                    }
                </tr>)
            }
            if (detail.type === 'Employee Deductions') {
                if (detail?.amount && detail?.amountYTD) {
                    total_deduction += parseFloat(detail?.amount)
                    total_deduction_YTD += parseFloat(detail?.amountYTD)
                }
                deduction_itme.push(<tr>
                    <td width="40%" align="left">{detail.label}</td>
                    {detail?.amount &&
                        <td width="30%" align="right">
                            {detail.amount && parseFloat(detail?.amount)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </td>
                    }{!detail?.amount &&
                        <td width="30%" align="right">0.00</td>
                    }
                    {detail?.amountYTD &&
                        <td width="30%" align="right">
                            {detail.amountYTD && parseFloat(detail?.amountYTD)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </td>
                    }{!detail?.amountYTD &&
                        <td width="30%" align="right">0.00</td>
                    }
                </tr>)
            }
            if (detail.type === 'NSSF') {
                if (detail?.amount && detail?.amountYTD) {
                    total_NFFS += parseFloat(detail?.amount)
                    total_NFFS_YTD += parseFloat(detail?.amountYTD)
                }
                NFFS_itme.push(<tr>
                    <td width="40%" align="left">{detail.label}</td>
                    {detail?.amount &&
                        <td width="30%" align="right">
                            {detail.amount && parseFloat(detail?.amount)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </td>
                    }{!detail?.amount &&
                        <td width="30%" align="right">0.00</td>
                    }
                    {detail?.amountYTD &&
                        <td width="30%" align="right">
                            {detail.amountYTD && parseFloat(detail?.amountYTD)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                    }{!detail?.amountYTD &&
                        <td width="30%" align="right">0.00</td>
                    }
                </tr>)
            }
        })
        await payslip?.subDetails?.map((detail, index) => {
            if (detail.type === 'Employee Pay Type') {
                // if (detail?.amount){
                //     total_to_pay += parseFloat(detail?.amount)
                // }
                medical_itme.push(<tr>
                    <td width="75%" align="left" className="box-container-boarder small-padding" style={{ border: '1px solid black', borderCollapse: 'collapse', paddingLeft: 10 }}>{detail.label}</td>
                    {detail?.amount &&
                        <td width="25%" align="right" className="box-container-boarder small-padding" style={{ border: '1px solid black', borderCollapse: 'collapse', paddingRight: 10 }}>
                            {detail.amount && parseFloat(detail?.amount)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </td>
                    }{!detail?.amount &&
                        <td width="25%" align="right" className="box-container-boarder small-padding" style={{ border: '1px solid black', borderCollapse: 'collapse', paddingRight: 10 }}>0.00</td>
                    }
                </tr>)
            }
            if (detail.type === 'Employee Deductions') {
                // if (detail?.amount) {
                //     total_deduction += parseFloat(detail?.amount)
                // }
                medical_deduction_itme.push(<tr>
                    <td width="75%" align="left" className="box-container-boarder small-padding" style={{ border: '1px solid black', borderCollapse: 'collapse', paddingLeft: 10 }}>{detail.label}</td>
                    {detail?.amount &&
                        <td width="25%" align="right" className="box-container-boarder small-padding" style={{ border: '1px solid black', borderCollapse: 'collapse', paddingRight: 10 }}>
                            {detail.amount && parseFloat(detail?.amount)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </td>
                    }{!detail?.amount &&
                        <td width="25%" align="right" className="box-container-boarder small-padding" style={{ border: '1px solid black', borderCollapse: 'collapse', paddingRight: 10 }}>0.00</td>
                    }
                </tr>)
            }
        })
        return (
            <div style={{ padding: 20 }}>
                <table width="100%" style={{ padding: 10, fontSize: '30px' }}>
                    <tr>
                        <td align="center" width="100%"><span>{payslip?.companyName}</span>&nbsp;&nbsp;&nbsp;<span>Pay Slip</span><span>{payslipDate}</span></td>
                    </tr>
                </table>
                <br />
                <br />
                <table width="100%" style={{ padding: 10, border: '0px solid black', borderCollapse: 'collapse', fontSize: '22px' }}>
                    <tr style={{ border: '0px soild #000' }} >
                        <td width="12%" align="left"><strong>Id</strong></td>
                        <td width="25%" align="left">{employee?.azadea_id}</td>
                        <td width="11%" align="left"><strong>Employee</strong></td>
                        <td width="20%" align="left">{payslip?.fullName}</td>
                        <td width="12%" align="left"><strong>Annual Leave</strong></td>
                        <td width="20%" align="left">{payslip?.annualLeaveBalance}</td>
                    </tr>
                    <tr style={{ border: '0px soild #000' }} >
                        <td width="12%" align="left"><strong>Branch</strong></td>
                        <td width="25%" align="left">{payslip?.branchName}</td>
                        <td width="11%" align="left"><strong>Start Date</strong></td>
                        <td width="20%" align="left">{payslip?.startDate}</td>
                        <td width="12%" align="left"><strong>Comm Hours</strong></td>
                        <td width="20%" align="left">{payslip?.commissionHours}</td>
                    </tr>
                    <tr style={{ border: '0px soild #000' }} >
                        <td width="12%" align="left"><strong>Title</strong></td>
                        <td width="25%" align="left">{payslip?.title}</td>
                        <td width="11%" align="left"><strong>Status</strong></td>
                        <td width="20%" align="left">{payslip?.maritalStatus}</td>
                        <td width="12%" align="left"><strong>Non Comm</strong></td>
                        <td width="20%" align="left">{payslip?.nonCommissionHours}</td>
                    </tr>
                    <tr style={{ border: '0px soild #000' }} >
                        <td width="12%" align="left"><strong>Nationality</strong></td>
                        <td width="25%" align="left">{payslip?.nationality}</td>
                        <td width="11%" align="left"><strong>Grade</strong></td>
                        <td width="20%" align="left">{payslip?.grade}</td>
                        <td width="12%" align="left"><strong>Paid Hours</strong></td>
                        <td width="20%" align="left">{payslip?.paidHours}</td>
                    </tr>
                </table>
                <br />
                <br />
                <table width="100%" className="box-container-boarder-no-bottom" style={{ border: '1px solid black', borderBottom: '0px solid black', borderCollapse: 'collapse', fontSize: '24px' }}>
                    <tr className="box-container-boarder-no-bottom" style={{ border: '1px solid black', borderBottom: '0px solid black', borderCollapse: 'collapse' }}>
                        <td className="box-container-boarder-no-bottom custom-align-items-start" width="50%" style={{ paddingInline: 10, border: '1px solid black', borderBottom: '0px solid black', borderCollapse: 'collapse', alignContent: 'start' }}>
                            <table width="100%" style={{ padding: 10}}  >
                                <tr>
                                    <td width="40%"><b>Employee Pay Type</b></td>
                                    <td width="30%" align="right"><b>{year + ' - ' + month}</b></td>
                                    <td width="30%" align="right"><b>Year to Date</b></td>
                                </tr>
                                {to_pay_itme?.map(item => {
                                    return item
                                })}
                            </table>
                        </td>
                        <td width="50%" className="box-container-boarder-no-bottom custom-align-items-start" style={{ paddingInline: 10, border: '1px solid black', borderBottom: '0px solid black', borderCollapse: 'collapse', alignContent: 'start' }}>
                            <table width="100%" style={{ padding: 10}} >
                                <tr>
                                    <td width="45%"><b>Employee Deductions</b></td>
                                    <td width="25%" align="right"><b>{year + ' - ' + month}</b></td>
                                    <td width="30%" align="right"><b>Year to Date</b></td>
                                </tr>
                                {deduction_itme?.map(item => {
                                    return item
                                })}
                                <tr>
                                    <td width="40%"><b>Total Employee Deductions</b></td>
                                    <td width="30%" align="right">
                                        {total_deduction && total_deduction?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </td>
                                    <td width="30%" align="right">
                                        {total_deduction_YTD && total_deduction_YTD?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </td>
                                </tr>
                                <tr style={{ backgroundColor: '#' }} className="net-salary-tr">
                                    <td width="40%"><b>Net Salary</b></td>
                                    <td width="30%" align="right">
                                        {payslip?.amount && parseFloat(payslip?.amount)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </td>
                                    <td width="30%" align="right">
                                        {payslip?.amountYTD && parseFloat(payslip?.amountYTD)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </td>
                                </tr>
                                {NFFS_itme && NFFS_itme.length > 0 && <>
                                    <tr>
                                        <td width="40%"><b>NSSF Company Contribution</b></td>
                                        <td width="30%" align="right"><b>{year + ' - ' + month}</b></td>
                                        <td width="30%" align="right"><b>Year to Date</b></td>
                                    </tr>
                                </>}
                                {NFFS_itme?.map(item => {
                                    return item
                                })}
                            </table>
                        </td>
                    </tr>
                    <tr >
                        <td width="50%" className="box-container-boarder-no-top" style={{ paddingInline: 10, border: '1px solid black', borderTop: '0px solid black', borderCollapse: 'collapse' }}>
                            <table width="100%" style={{ padding: 10 }} >
                                <tr>
                                    <td width="40%"><b>Gross Income</b></td>
                                    <td width="30%" align="right"><b>
                                        {total_to_pay?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </b></td>
                                    <td width="30%" align="right"><b>
                                        {total_to_pay_YTD?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </b></td>
                                </tr>
                            </table>
                        </td>
                        <td width="50%" className="box-container-boarder-no-top" style={{ paddingInline: 10, border: '1px solid black', borderTop: '0px solid black', borderCollapse: 'collapse' }}>
                            <table width="100%" style={{ padding: 10 }} >
                                <tr>
                                    <td width="40%"><b>Total Company Contribution</b></td>
                                    <td width="30%" align="right"><b>
                                        {total_NFFS?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </b></td>
                                    <td width="30%" align="right"><b>
                                        {total_NFFS_YTD?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </b></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
                <br />
                <br />
                <table width="100%" className="box-container-boarder" style={{ padding: 10, border: '1px solid black', borderCollapse: 'collapse', fontSize: '24px' }}>
                    <tr className="box-container-boarder" style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                        <td className="box-container-boarder text-center" align={"center"} width="100%" style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                            <b>Description</b>
                        </td>
                    </tr>
                </table>
                <br />
                <br />
                <table width="100%" style={{ padding: 10, fontSize: '24px' }}  >
                    <tr>
                        <td width="100%">
                            <table width="100%" className="boarder-style" style={{ padding: 10, borderSpacing: 0, borderCollapse: 'collapse' }}  >
                                <tr>
                                    <td width="49%" className="box-container-boarder text-center" align={"center"} style={{ border: '1px solid black', borderCollapse: 'collapse' }}><b>Medical Insurance Contribution</b></td>
                                    <td width="2%"></td>
                                    <td width="49%" className="box-container-boarder text-center" align={"center"} style={{ border: '1px solid black', borderCollapse: 'collapse' }}><b>Medical Insurance Deduction</b></td>
                                </tr>
                                <tr style={{ padding: 0, borderSpacing: 0, borderCollapse: 'collapse', alignContent: 'start' }}>
                                    <td width="49%" className="custom-align-items-start zero-padding" style={{ padding: '0px', margin: '0px', alignContent: 'start' }}>
                                        <table width="100%" className="box-container-boarder" style={{ padding: 0, borderSpacing: 0, borderCollapse: 'collapse', alignContent: 'start' }} >
                                            {medical_itme?.map(item => {
                                                return item
                                            })}
                                        </table>
                                    </td>
                                    <td width="2%"></td>
                                    <td width="49%" className="custom-align-items-start zero-padding" style={{ padding: '0px', margin: '0px', alignContent: 'start' }}>
                                        <table width="100%" className="box-container-boarder" style={{ padding: 0, borderSpacing: 0, borderCollapse: 'collapse', alignContent: 'start' }} >
                                            {medical_deduction_itme?.map(item => {
                                                return item
                                            })}
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
        )
    }

    const viewPayslip = () => {
        if (payslip) {
            document.body.classList.add('page-loading')
            let html_obj = generate_report(payslip)
            html_obj.then(async html_obj => {
                if (html_obj) {
                    const newTab = window.open('', '_blank');
                    let html_st = await reactElementToJSXString(html_obj);
                    html_st = html_st.replaceAll('className', 'class')
                    if (newTab) {
                        newTab.document.write(`
                            <html>
                            <head>
                                <title>Payslip</title>
                                <style>
                                .boarder-style{
                                    border-collapse: collapse;
                                    border-spacing: 0px;
                                }
                                .box-container-boarder {
                                    border: 1px solid black;
                                    border-collapse: collapse;
                                    border-spacing:0px;
                                    align-content: start;
                                }
                                .zero-padding{
                                    padding:0px;                                    
                                }
                                .small-padding{
                                    padding-left: 10px;
                                    padding-right: 10px;
                                }
                                .box-container-boarder-no-bottom {
                                    border: 1px solid black;
                                    border-bottom: 0px !important;
                                    border-collapse: collapse;
                                    padding: 5px 15px;
                                }
                                .box-container-boarder-no-top {
                                    border: 1px solid black;
                                    border-top: 0px !important;
                                    border-collapse: collapse;
                                    padding: 5px 15px;
                                }
                                    body{
                                        font-family: 'Arial';
                                    }
                                    .net-salary-tr{
                                        background-color:#efefef
                                    }
                                    .custom-align-items-start{
                                        align-content: flex-start;
                                    }
                                </style>
                            </head>
                            <style>
                                .box-container-boarder {
                                    border: 1px solid black;
                                    border-collapse: collapse;
                                    border-spacing:0px;
                                    align-content: start;
                                }
                                .box-container-boarder-no-bottom {
                                    border: 1px solid black;
                                    border-bottom: 0px !important;
                                    border-collapse: collapse;
                                    padding: 5px 15px;
                                }
                                .box-container-boarder-no-top {
                                    border: 1px solid black;
                                    border-top: 0px !important;
                                    border-collapse: collapse;
                                    padding: 5px 15px;
                                }
                                .zero-padding{
                                    padding:0px;                                    
                                }
                                .small-padding{
                                    padding-left: 10px;
                                    padding-right: 10px;
                                }
                                body{
                                    font-family: 'Arial';
                                    padding: 20px;
                                }
                                .net-salary-tr{
                                    background-color:#efefef
                                }
                                .custom-align-items-start{
                                    align-content: flex-start;
                                }
                            </style>
                            <body>
                                ${html_st}
                            </body>
                            </html>
                        `);
                        newTab.document.close();
                    }
                    document.body.classList.remove('page-loading')
                } else {
                    document.body.classList.remove('page-loading')
                }
            })
        }
    }

    const downloadPayslip = () => {
        if (payslip) {
            document.body.classList.add('page-loading')
            let html_obj = generate_report(payslip)
            html_obj.then(async html_obj => {
                if (html_obj) {
                    const options = {
                        // method: 'open', // 'save' or 'open'
                        resolution: Resolution.HIGH,
                        page: {
                            margin: Margin.SMALL,
                            format: 'A4',
                            //   orientation: 'landscape',
                        },
                        canvas: {
                            //   mimeType: 'image/png',
                            qualityRatio: 1,
                        },
                        overrides: {
                            pdf: { compress: false },
                            canvas: { useCORS: true },
                        },
                    };
                    const parentElement = document.createElement('div');
                    const element =
                        <div>
                            <button onClick={() => generatePDF(getTargetElement, options)} id="export_data_pdf">Generate PDF</button>
                            <div id="content-id">
                                {html_obj}
                            </div>
                        </div>
                    ReactDOM.render(element, parentElement);
                    document.body.appendChild(parentElement)
                    const inputElement = document.querySelector('#export_data_pdf') as HTMLInputElement
                    inputElement.click()
                    document.body.removeChild(parentElement)
                    document.body.classList.remove('page-loading')
                    document.body.classList.remove('page-loading')
                }
            })
        }
    }

    // Month selection
    const [selectedMonthOption, setSelectedMonthOption] = useState<any>({ value: moment().format(AZKHelper.FRONTEND_DATE_MONTH_ONLY_FORMAT).toLowerCase(), label: moment().format(AZKHelper.FRONTEND_DATE_MONTH_ONLY_FORMAT) });
    const [month, setMonth] = useState<any>();
    const loadMonthOptions = async (search, loadedOptions) => {
        const options = [
            { value: 'january', label: 'January' },
            { value: 'february', label: 'February' },
            { value: 'march', label: 'March' },
            { value: 'april', label: 'April' },
            { value: 'may', label: 'May' },
            { value: 'june', label: 'June' },
            { value: 'july', label: 'July' },
            { value: 'august', label: 'August' },
            { value: 'september', label: 'September' },
            { value: 'october', label: 'October' },
            { value: 'november', label: 'November' },
            { value: 'december', label: 'December' }
        ];
        const filteredOptions = options.filter((option) =>
            option.label.toLowerCase().includes(search.toLowerCase())
        );
        return {
            options: filteredOptions,
            hasMore: false,
        };
    };
    const handleOnMonthChange = (selectedOption) => {
        setSelectedMonthOption(selectedOption)
        setMonth(selectedOption.value)
        formRef.current?.setFieldValue('payslip_month', selectedOption.value);

    };
    // End Month selection

    // Month selection
    const [selectedYearOption, setSelectedYearOption] = useState<any>({ value: new Date().getUTCFullYear(), label: new Date().getUTCFullYear() });
    const [year, setYear] = useState<any>();
    const [lastLoadedYear, setLastLoadedYear] = useState<any>(new Date().getUTCFullYear());
    const loadYearOptions = async (search, loadedOptions) => {
        const options: any[] = []
        for (let index = 0; index < 10; index++) {
            options.push({ value: lastLoadedYear - index, label: lastLoadedYear - index })
        }
        setLastLoadedYear(lastLoadedYear - 10)
        const filteredOptions = options.filter((option) =>
            option.label.toString().includes(search.toLowerCase())
        );
        return {
            options: filteredOptions,
            hasMore: lastLoadedYear > 2000,
        };
    };
    const handleOnYearChange = (selectedOption) => {
        setSelectedYearOption(selectedOption)
        setYear(selectedOption.value)
        formRef.current?.setFieldValue('payslip_year', selectedOption.value);
    };
    // End Year selection

    return (
        <>
            <Row className="m-0 my-2">
                <Col md='12' lg='12' xl='12'>
                    <Formik
                        innerRef={formRef}
                        validationSchema={editTransactionSchema}
                        onSubmit={onSubmitHandler}
                        initialValues={initialTransfaction}
                        validateeOnChange={true}
                        validateOnMount={true}
                        initialErrors={''}
                    >
                        {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
                            return (
                                <>
                                    <Form onSubmit={handleSubmit} noValidate>
                                        <fieldset>
                                            {!id && (
                                                <Row className="card m-0 py-2 mb-3">

                                                    <Row className="m-0 my-2">
                                                        <Col lg="12" md="12" sm="12" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label className="col-md-6 col-12">
                                                                    <h4 className="px-3 m-0 custom-employee-header justify-content-start">
                                                                        <i className="fa fa-magnifying-glass" aria-hidden="true"></i>{intl.formatMessage({ id: 'TRANSACTION.EMPLOYEE' })}
                                                                    </h4>
                                                                </Form.Label>
                                                                {employeeDomain && employeeDomain !== '' &&
                                                                    <AzkSelect
                                                                        compID="azk_temp"
                                                                        modelName="hr.employee"
                                                                        valueField="id"
                                                                        titleField="display_name_fr"
                                                                        dataDomain={employeeDomain}
                                                                        allowEmpty={true}
                                                                        fieldName='employee_id'
                                                                        onChange={(value) => {
                                                                            setFieldValue('employee_id', parseInt(value))
                                                                            handleEmployeeChange(value)
                                                                        }}
                                                                        searchFields={['name', 'azadea_id', 'branch_id', 'job_id', 'work_email', 'first_name']} />
                                                                }
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='employee_id' />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label className='required'>
                                                                    Month
                                                                </Form.Label>
                                                                <AsyncPaginate className='rounded-0 col-12'
                                                                    placeholder="Select an option"
                                                                    loadOptions={loadMonthOptions}
                                                                    onChange={handleOnMonthChange}
                                                                    defaultValue={selectedMonthOption}
                                                                    value={selectedMonthOption}
                                                                    id='payslip_month' name='payslip_month'

                                                                />
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='payslip_month' />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label className='required'>
                                                                    Year
                                                                </Form.Label>
                                                                <AsyncPaginate className='rounded-0 col-12'
                                                                    placeholder="Select an option"
                                                                    loadOptions={loadYearOptions}
                                                                    onChange={handleOnYearChange}
                                                                    defaultValue={selectedYearOption}
                                                                    value={selectedYearOption}
                                                                    id='payslip_year' name='payslip_year'
                                                                />
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='payslip_year' />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="4" md="4" sm="6" xs="12" className="d-flex justify-content-end">
                                                            <div className="align-self-end">
                                                                <button
                                                                    title="View"
                                                                    type='submit'
                                                                    className='btn btn-light btn-light-primary btn-sm'
                                                                >
                                                                    <i className="fas fa-check fs-2"></i>
                                                                    <span className='indicator-label'>View</span>
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            )}
                                        </fieldset>
                                        {employee && payslip && (
                                            <>
                                                <EmployeeDetail employee={employee} />
                                                <Row className="m-0 card">
                                                    <h4 className="py-2 px-6 m-0 card-header custom-card-header justify-content-start">
                                                        <i className="fa fa-info-circle" aria-hidden="true"></i>Payslip Detail</h4>
                                                    <Row className="m-0 my-2">
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2 d-flex justify-content-between'>
                                                                <Form.Label className="col-md-7 col-7">
                                                                    Annual Leave
                                                                </Form.Label>
                                                                <Form.Label className="col-md-5 col-5" align="right">
                                                                    {payslip?.annualLeaveBalance && parseFloat(payslip?.annualLeaveBalance)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                                </Form.Label>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2 d-flex justify-content-between'>
                                                                <Form.Label className="col-md-7 col-7">
                                                                    Paid Hours
                                                                </Form.Label>
                                                                <Form.Label className="col-md-5 col-5" align="right">
                                                                    {payslip?.paidHours?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                                </Form.Label>
                                                            </Form.Group>
                                                        </Col>

                                                        {payslip?.details?.filter(item => item.type === 'Employee Pay Type')?.map(item => {
                                                            return (<>
                                                                <Col lg="4" md="4" sm="6" xs="12">
                                                                    <Form.Group className='mb-2 d-flex justify-content-between'>
                                                                        <Form.Label className="col-md-7 col-7">
                                                                            {item.label}
                                                                        </Form.Label>
                                                                        <Form.Label className="col-md-5 col-5" align="right">
                                                                            {item.amount && parseFloat(item.amount)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                                        </Form.Label>
                                                                    </Form.Group>
                                                                </Col></>)
                                                        })}
                                                        {payslip?.details?.filter(item => item.type === 'Employee Deductions')?.map(item => {
                                                            return (<>
                                                                <Col lg="4" md="4" sm="6" xs="12">
                                                                    <Form.Group className='mb-2 d-flex justify-content-between'>
                                                                        <Form.Label className="col-md-7 col-7">
                                                                            {item.label}
                                                                        </Form.Label>
                                                                        <Form.Label className="col-md-5 col-5" align="right">
                                                                            {item.amount && parseFloat(item.amount)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                                        </Form.Label>
                                                                    </Form.Group>
                                                                </Col></>)
                                                        })}
                                                        {payslip?.details?.filter(item => item.type === 'NSSF')?.map(item => {
                                                            return (<>
                                                                <Col lg="4" md="4" sm="6" xs="12">
                                                                    <Form.Group className='mb-2 d-flex justify-content-between'>
                                                                        <Form.Label className="col-md-7 col-7">
                                                                            {item.label}
                                                                        </Form.Label>
                                                                        <Form.Label className="col-md-5 col-5" align="right">
                                                                            {item.amount && parseFloat(item.amount)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                                        </Form.Label>
                                                                    </Form.Group>
                                                                </Col></>)
                                                        })}
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2 d-flex justify-content-between'>
                                                                <Form.Label className="col-md-7 col-7">
                                                                    Net salary
                                                                </Form.Label>
                                                                <Form.Label className="col-md-5 col-5" align="right">
                                                                    {payslip.amount && parseFloat(payslip.amount)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                                </Form.Label>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="m-0 my-2 d-flex justify-content-end">
                                                        <div className="d-flex justify-content-end">
                                                            <button
                                                                title="View"
                                                                type='reset'
                                                                onClick={() => viewPayslip()}
                                                                className=' btn btn-light btn-light-warning mx-5 btn-sm'
                                                                data-kt-users-modal-action='cancel'
                                                            >
                                                                <i className={`fa fa-fw o_button_icon fa-eye fs-2`}></i>
                                                                <span className='indicator-label'>View</span>
                                                            </button>
                                                            <button
                                                                title="Download"
                                                                type='reset'
                                                                onClick={() => downloadPayslip()}
                                                                className=' btn btn-light btn-light-warning mx-5 btn-sm'
                                                                data-kt-users-modal-action='cancel'
                                                            >
                                                                <i className={`fa fa-fw o_button_icon fa-download fs-2`}></i>
                                                                <span className='indicator-label'>Download</span>
                                                            </button>
                                                        </div>
                                                    </Row>
                                                </Row>
                                            </>
                                        )}
                                    </Form>
                                </>
                            )
                        }}
                    </Formik>
                </Col >
            </Row>
        </>
    )
}

export default Transactionform;