import { Response } from "_metronic/helpers"


export type ExitInterViewRequestQustion = {
  id?: any,
  answer: any,
  answer_code: any,
  comment?: any,
  question_id: {
    id?: any,
    name?: any,
    question_sequence?: any,
    comment_title?: any,
    data_set?: any,
    type?: any,
    required_question?: any,
    answer_ids: any,
  }
}

export type ExitInterViewRequestSection = {
  id?: any,
  section_sequence?: any,
  section_id?: {
    title?: any,
    comment_title?: any,
    is_table_content: boolean,
  },
  comment?: any,
  questions: ExitInterViewRequestQustion[]
}

export type ExitInterViewRequest = {
  id?: number
  name?: string
  employee_id?: any
  x_state?: string
  x_state_view?: string
  can_review?: boolean
  can_delete?: boolean
  can_write?: boolean
  validated?: boolean,
  rejected?: boolean,
  state?: string
  az_country_id?: any
  az_company_id?: any
  brand_id?: any
  azadea_id?: number
  azadea_employee_name?: string
  branch_id?: any
  section_id?: any
  grade_id?: any
  job_id?: any
  time_set?: string
  can_edit?: boolean
  restarted?: boolean
  type_id?: {
    id?: any
    back_office?: boolean
  }
  sections?: ExitInterViewRequestSection[]
  questions?: ExitInterViewRequestQustion[]
  submit_approve?: boolean|false
  office_submit_approve?: boolean|false
  hr_department?: boolean|false
  executive_member?: boolean|false
  executive_employee_name?: any
  lang?: any
  interview_file_id?: any
  regrettable?: boolean|false
  did_not_sit_with_hr?: boolean|false
  comments?: string
}

export const initialTransfaction: ExitInterViewRequest = {
  name: '',
  type_id:{
    id:''
  }
}

export type ExitInterViewRequestQueryResponse = Response<Array<ExitInterViewRequest>>

