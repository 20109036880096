/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import clsx from 'clsx'
import { ID, toAbsoluteUrl } from '_metronic/helpers'
import axios, { ResponseType } from 'axios';
import { API_URL } from './azkHelper';
import { KTIcon } from '_metronic/helpers'
import "./azkChatter.scss";
import { Form, Row } from 'react-bootstrap';
import { useAuth } from 'app/modules/auth';
import { AZKHelper } from 'app/modules/components/azkHelper';

export interface AzkAtttachmentModel {
  id: number
  name: string
}

export interface AzFileModel {
  name: string
  datas: string
  res_model: string
  res_id: number
  type: string
}

export interface AzkMessageModel {
  id: number
  email_from: string
  author_id: {
    id: number
    name: string
    image_128?: string
  }
  author_avatar?: string
  body: string
  date: string
  attachment_ids?: Array<AzkAtttachmentModel>
}

export class AzkMessageModelClass implements AzkMessageModel {
  constructor(id, email_from, author_id, body, date) {
    this.id = id
    this.email_from = email_from
    this.author_id = author_id
    this.body = body
    this.date = date
  }
  id: number;
  email_from: string;
  author_id: { id: number; name: string; image_128?: string | undefined; };
  author_avatar?: string | undefined;
  body: string;
  date: string;
  attachment_ids?: AzkAtttachmentModel[] | undefined;
}

type Props = {
  modelName: string
  itemID?: any
  isDrawer?: boolean | false
  transactionClosed?: boolean | false
  sendMessagesCallback?: Function
  hitSend?: boolean | false

}

const AzkChatter: FC<Props> = ({ modelName, itemID = undefined, isDrawer = false, transactionClosed = false, sendMessagesCallback = Function, hitSend = false }) => {
  //   const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)

  const { currentUser, logout } = useAuth()
  const [files, setFiles] = useState<AzFileModel[] | undefined>(undefined);
  const [message, setMessage] = useState<string | undefined>(undefined)
  const [attached, setAttached] = useState<string | undefined>(undefined)
  const [lastID, setLastID] = useState<number>(0)
  const [messages, setMessages] = useState<AzkMessageModel[]>([])
  const [messageListIds, setMessageListIds] = useState<number[]>([])
  const Swal = require('sweetalert2')
  const getNewMessages = () => {
    if (itemID && modelName) {
      try {
        const filter = itemID ? `('res_id', '=', ${parseInt(itemID)}),('model', '=', '${modelName}')` : ``;
        axios.get(`${API_URL}/mail.message?filters=[${filter}]&include_fields=['id', 'date', ('author_id', ('id', 'name', 'image_128')), 'email_from', 'author_avatar', 'body', ('attachment_ids',[('id', 'name')])]`).then((result: any) => {
          if (result.data && result.data.results && result.data.count > 0) {
            let bufferMessages: AzkMessageModel[] = []
            for (let index = 0; index < result.data.count; index++) {
              const msg = result.data.results[index] as AzkMessageModel
              if (msg) {
                if (msg.id > lastID) setLastID(msg.id)
                bufferMessages.push(msg)
              }
            }
            setMessages(bufferMessages)
          }
        })
      } catch (error) {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    setMessages([])
    getNewMessages();
    if (hitSend) {
      sendMessage(true, undefined)
    }
  }, [modelName, itemID, hitSend])

  const openAttachWizard = () => {
    const inputElement = document.querySelector('#file') as HTMLInputElement
    inputElement.click()
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const attached: AzFileModel[] = []
    if (files) {
      await Array.from(files).forEach((file, index) => {
        const fileType = file.type;
        if (AZKHelper.ALLOWED_ATTACHMENT_TYPES.includes(fileType)) {
          const reader = new FileReader();
          reader.onloadend = async () => {
            const arrayBuffer = reader.result;
            if (arrayBuffer instanceof ArrayBuffer) {
              let binary = '';
              const uint8Array = new Uint8Array(arrayBuffer);
              const len = uint8Array.byteLength;
              for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(uint8Array[i]);
              }
              attached.push({
                datas: window.btoa(binary),
                name: file.name,
                res_model: modelName,
                res_id: parseInt(itemID),
                type: "binary"
              });
              // const decoder = new TextDecoder();
              // attached.push({datas: decoder.decode(uint8Array), name:file.name});
            } else if (arrayBuffer) {
              attached.push({
                datas: arrayBuffer.split(',')[1],
                name: file.name,
                res_model: modelName,
                res_id: parseInt(itemID),
                type: "binary"
              });
            }
            if (attached.length === 0) {
              setAttached(undefined)
            } else if (attached.length > 1) {
              setAttached(`${attached.length} Files`)
            } else {
              setAttached(attached[0].name)
            }
          };
          reader.readAsArrayBuffer(file);
        } else {
          Swal.fire({
            position: "top-right",
            title: 'Sorry!',
            text: 'Please check you attachments, allowed file types (' + AZKHelper.ALLOWED_ATTACHMENT_TYPE + ')!',
            icon: "error",
            showConfirmButton: false,
            timer: 5000,
            toast: true,
            iconColor: 'white',
            customClass: {
              popup: 'colored-toast'
            },
            timerProgressBar: true
          })
          setAttached(undefined)
          attached.splice(0, attached.length);
        }
      })
    } else {
      setAttached(undefined)
    }
    setAttached(undefined)
    setFiles(attached);
  };

  const sendAttachments = async () => {
    if (itemID) {
      const attachedIDs: { id: number }[] = []
      const promises = await files?.map(async (file, index) => new Promise(async (resolve, reject) => {
        const formData = new FormData();
        formData.append('res_model', modelName);
        formData.append('res_id', itemID.toString());
        formData.append('name', file.name);
        formData.append('type', "binary");
        formData.append('datas', file.datas);
        await axios.post(`${API_URL}/attachments/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          if (response && response?.data && response.data?.id) {
            attachedIDs.push({ id: response.data.id })
          }
          return response.data.id
        }).catch(function (error) {
          console.log(error)
          return error;
        })
        resolve(attachedIDs)
      }));
      if (promises)
        Promise.all(promises).then(attachedIDs => {
          sendMessage(false, attachedIDs)
        })
    }
  }

  const sendMessage = async (fromUI: boolean, attachedIDs) => {
    if (itemID) {
      if (fromUI && files && files.length > 0) {
        sendAttachments()
      } else {
        if ((message && message.length > 0) || (files && files.length > 0)) {
          let encodedMessage = ''
          if (message) encodedMessage = message.replaceAll(/</g, '&lt;').replace(/>/g, '&gt;')
          try {
            axios.post(`${API_URL}/mail.message`, {
              model: modelName,
              res_id: parseInt(itemID),
              body: encodedMessage,
              subtype_id: 2,
              message_type: "comment",
              attachment_ids: attachedIDs ? attachedIDs[0] : undefined
            }).then(response => {
              const ids = messageListIds || []
              ids.push(response.data.id)
              setMessageListIds(ids)
              setMessage('')
              setFiles(undefined)
              setAttached(undefined)
              if (hitSend)
                sendMessagesCallback(itemID)
              else
                getNewMessages()
            })
          } catch (error) {
            console.log(error)
          }
        } else {
          sendMessagesCallback(itemID)
        }
      }
    } else {
      if (message) {
        let bufferMessages: AzkMessageModel[] = []
        // (id, email_from, author_id, body, date)
        let messageObj = new AzkMessageModelClass(undefined, 'message', {
          id: currentUser?.id,
          name: currentUser?.name,
          image_128: currentUser?.image_128 ? 'data:image/png;base64,' + currentUser.image_128 : undefined
        }, message, 'now')
        bufferMessages.push(messageObj)
        setMessages(bufferMessages)
      } else {
        sendMessagesCallback(itemID)
      }
    }
  }

  const downloadAttachment = (id: number, responseType: ResponseType = 'blob') => {
    axios
      .get(`${API_URL}/attachments/${id}`, {
        responseType,
      })
      .then((response) => {
        let file_name = response.headers['content-disposition']
        file_name = file_name.replace('ttachment; filename=', '')
        file_name = file_name.replaceAll('\"', '')
        const blob = new Blob([response.data], { type: response.headers['content-type'] })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', file_name)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      })
      .catch((error) => {
        console.error('Download error:', error)
      })
  }
  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage(true, undefined)
    }
  }

  return (
    <Row className={`card mx-0 ${itemID? 'mt-4':''}`}>
      <div
        className='card-body p-3'
        id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
        style={{ maxHeight: '85hv !important' }}
      >
        <div
          className='p-1'
          id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
        >
          <textarea
            disabled={transactionClosed}
            className='form-control mb-3 mx-1'
            rows={1}
            data-kt-element='input'
            placeholder='Type a message'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={onEnterPress}
          ></textarea>
          <div className='d-flex flex-stack'>
            <div className='d-flex align-items-center me-2'>
              <input
                accept={AZKHelper.ALLOWED_ATTACHMENT_TYPE}
                disabled={transactionClosed}
                type='file'
                id='file'
                multiple
                style={{ display: 'none' }}
                onChange={handleFileChange} />
              <label htmlFor='file'>
                <button
                  disabled={transactionClosed}
                  className='btn btn-sm btn-icon btn-active-light-primary me-1'
                  type='button'
                  data-bs-toggle='tooltip'
                  title='Attach'
                  onClick={openAttachWizard}
                >
                  <i className='bi bi-paperclip fs-3'></i>
                </button>
                <span>{attached}</span>
              </label>
            </div>
            <button
              disabled={transactionClosed || !itemID}
              className='btn btn-sm btn-icon btn-primary me-1'
              type='button'
              data-kt-element='send'
              title="Send"
              onClick={() => sendMessage(true, undefined)}
            >
              <KTIcon iconName='send' className='fs-2' />
            </button>
          </div>
        </div>
        <div
          className={clsx('scroll-y me-n5 pe-5 chatter-messages-container', { 'h-300px chatter-messages-container': !isDrawer })}
          data-kt-element='messages'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies={
            isDrawer
              ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
              : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
          }
          data-kt-scroll-wrappers={
            isDrawer
              ? '#kt_drawer_chat_messenger_body'
              : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
          }
          data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
          style={{ maxHeight: '50hv !important' }}
        >
          {messages.map((message, index) => {
            const state = 'primary'
            const templateAttr = {}
            const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-start mb-2`
            return (
              <div
                key={`message${index}`}
                //   className={clsx('d-flex', contentClass, 'mb-2', {'d-none': message.template})}
                className={clsx('d-flex', contentClass, 'mb-2')}
                {...templateAttr}
              >
                <div
                  className={clsx(
                    'd-flex flex-column align-items',
                    //   `align-items-${message.type === 'in' ? 'start' : 'end'}`
                    `align-items-start`
                  )}
                >
                  <div className='d-flex align-items-center mb-2'>
                    <div className='symbol  symbol-35px symbol-circle '>
                      <img alt='Pic' src={message?.author_id?.image_128 ? 'data:image/png;base64,' + message.author_id.image_128 : toAbsoluteUrl('/media/avatars/blank.png')} />
                    </div>
                    <div className='ms-3'>
                      <span
                        className='fs-7 fw-bolder text-gray-900 text-hover-primary me-1'
                      >
                        {message.author_id.name}
                      </span>
                      <span className='text-muted fs-8 mb-1'>{message.date}</span>
                    </div>
                  </div>
                  <div
                    className={clsx(
                      'fs-7 px-2 py-0 rounded',
                      `bg-light-${state}`,
                      'text-dark fw-bold mw-lg-400px chatter-message-container',
                      // `text-${message.type === 'in' ? 'start' : 'end'}`
                      'text-start'
                    )}
                    data-kt-element='message-text'
                    dangerouslySetInnerHTML={{ __html: message.body? message.body.replaceAll(/<(?!\/?(p|H))[^>]+>/g, ''): ''}}
                  ></div>
                  {message.attachment_ids && message.attachment_ids.length > 0 && (<div className='p-1 m-0'>
                    {message.attachment_ids?.map((attach, index) => {
                      return <label className='my-1 chatter-attahment-container '><a onClick={() => downloadAttachment(attach.id)} className='text-hover-primary alert alert-info p-1 m-1' title={attach.name}><Form.Label className='m-0'> {attach.name}
                        <KTIcon iconName='file-down' className='fs-2 mx-1' /></Form.Label></a> </label>
                    })}
                  </div>)
                  }
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Row>
  )
}

export { AzkChatter }
