import AzkSelect from "_azkatech/azkSelect";
import { EmployeeDetail, EmpoyeeModel } from "app/modules/components/employeeDetail/employeeDetail";
import axios from "axios";
import * as Yup from 'yup'
import { ManagerAssignment, initialTransaction } from "./model";
import { FC, useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik, FormikProps, FormikValues } from 'formik'
import { CreateManagerAssignment, UpdateManagerAssignment, GetManagerAssignmentById, MODEL_NAME, deleteManagerAssignment } from "./_requests";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { AzkStatusRibbon } from "_azkatech/azkStatusRibbon";
import AzkActionButtons from "_azkatech/azkActionButtons";
import { Form, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { isNotEmpty, KTIcon } from "_metronic/helpers";
import { useNavigate } from "react-router";
import { API_URL } from "_azkatech/azkHelper";
import { AZKHelper } from "app/modules/components/azkHelper";
import { AzkChatter } from "_azkatech";
import { checkDateConstrains, checkTrnsactionTypeAccessId } from "app/utilities/transactionTyperequests";
import { useParams } from "react-router-dom";
import { GetEmployeeById } from "app/modules/components/employeeDetail/_requests";

type Props = {
}
const editTransactionSchema = Yup.object().shape({
    employee_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Employee is required'),
})

const Transactionform: FC<Props> = () => {
    const { id } = useParams();
    const Swal = require('sweetalert2')
    const intl = useIntl();
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<ManagerAssignment>>(null);
    const [employeeDomain, setEmployeeDomain] = useState('');
    const [branchDomain, setBranchDomain] = useState('');
    const [newEmployeeId, setNewEmployeeId] = useState<number>();
    const [newBranchId, setNewBranchId] = useState<number>();
    const [currentSupervisorId, setCurrentSupervisorId] = useState<number>();
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [filterEmployees, setFilterEmployees] = useState<number[]>([]);
    const [transactionClosed, setTransactionClosed] = useState<boolean>(false);

    const [canDelete, setCanDelete] = useState<boolean>(false);
    const [canValidate, setCanValidate] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [needSubmit, setNeedSubmit] = useState<boolean>(false);
    const [employee, setEmployee] = useState<EmpoyeeModel>();
    const [transactionState, setTransactionState] = useState<string>('draft');
    const [transactionStateName, setTransactionStateName] = useState<string>('Draft');
    const [hitSend, setHitSend] = useState<boolean>(false);
    const [objectId, setObjectId] = useState<number>();

    const [dateFrom, setDateFrom] = useState<any>(moment());
    const [startOftheMonth, setStartOftheMonth] = useState<any>(true);
    const [allowBackDate, setAllowBackDate] = useState<any>(true);

    const handleEmployeeChange = async function (employeeId, changeManagerAssignmentData): Promise<{}> {
        document.body.classList.add('page-loading')
        if (employeeId) {
            setEmployee(undefined)
            GetEmployeeById(employeeId).then(async response => {
                const tempEmployee = response
                if (tempEmployee) {
                    if (id || !tempEmployee.has_running_transaction) {
                        await setEmployee(response)
                        if (tempEmployee.id) handleEmployeeToFilter(tempEmployee.id)
                        setBranchDomain(`('country_id','=', ${tempEmployee.az_country_id?.id})`);
                        formRef.current?.setValues({
                            employee_id: tempEmployee.id,
                            az_country_id: tempEmployee.az_country_id?.id,
                            az_company_id: tempEmployee.az_company_id?.id,
                            payroll_id: tempEmployee.payroll_id?.id,
                            branch_id: tempEmployee.branch_id?.id,
                            brand_id: tempEmployee.brand_id?.id,
                            grade_id: tempEmployee.grade_id?.id,
                            section_id: tempEmployee.section_id?.id,
                            job_id: tempEmployee.job_id?.id,
                            time_set: tempEmployee.time_set,
                            // ---------------------------------------------------
                            target_salary: tempEmployee.salary,
                            target_as_effective_date: formRef.current.values.target_as_effective_date ? moment(formRef.current.values.target_as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT) : moment().format(AZKHelper.FRONTEND_DATE_FORMAT),
                        })
                        if (changeManagerAssignmentData) {
                            pobulateFormData(changeManagerAssignmentData)
                            handleDateConstraint(changeManagerAssignmentData.target_as_effective_date, tempEmployee.az_country_id?.id, tempEmployee.az_company_id?.id, tempEmployee.brand_id?.id)    
                        } else {
                            if (moment(tempEmployee.start_date).isAfter(moment.now())){
                                handleDateConstraint(moment(tempEmployee.start_date), tempEmployee.az_country_id?.id, tempEmployee.az_company_id?.id, tempEmployee.brand_id?.id, true)    
                            } else {
                                handleDateConstraint(moment.now(), tempEmployee.az_country_id?.id, tempEmployee.az_company_id?.id, tempEmployee.brand_id?.id, true)    
                            }
                            
                        }
                        document.body.classList.remove('page-loading')
                    } else {
                        document.body.classList.remove('page-loading')
                        Swal.fire({
                            position: "top-right",
                            title: 'Sorry!',
                            text: 'This employee ' + tempEmployee.name + ' already has an open transaction with details ' + tempEmployee.running_transaction + '!',
                            icon: "error",
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            iconColor: 'white',
                            customClass: {
                                popup: 'colored-toast'
                            },
                            timerProgressBar: true
                        })
                    }
                } else {
                    document.body.classList.remove('page-loading')
                }
            });
        } else {
            document.body.classList.remove('page-loading')
            setEmployee(undefined)
            setFilterEmployees([])
            setEmployeeDomain(``);
            formRef.current?.setValues({
                employee_id: undefined,
                az_country_id: undefined,
                az_company_id: undefined,
                payroll_id: undefined,
                branch_id: undefined,
                brand_id: undefined,
                grade_id: undefined,
                section_id: undefined,
                job_id: undefined,
                as_effective_date: undefined,
                time_set: undefined
            })
        }
        return true
    }

    const handleEmployeeToFilter = (employeeId: number, isAdd: boolean = true) => {
        let excludedEmployees: number[] = filterEmployees
        if (!excludedEmployees) {
            excludedEmployees = []
        }
        if (isAdd) excludedEmployees.push(employeeId)
        else excludedEmployees = excludedEmployees.filter((obj) => obj !== employeeId);
        setFilterEmployees(excludedEmployees)
        setEmployeeDomain(`('id','not in', [${filterEmployees}])`);
    }

    const handleAddEmployee = async function (employeeId) {
        if (employeeId) {
            document.body.classList.add('page-loading')
            await axios.get(`${API_URL}/hr.employee?filters=[('id','=',${employeeId})]&include_fields=['id','name', 'azadea_employee_name', 'as_effective_date', 'time_set', ('grade_id',('id','grade_name')), ('branch_id',('id','name')), ('job_id',('id','name')), ('brand_id',('id','name', 'brand_code')), ('az_country_id',('id','name')), ('az_company_id',('id','name')), ('payroll_id',('id','payroll_name')), ('section_id',('id','section')),('employment_category_id',('id','name', 'code')), 'azadea_id']`).then(async response => {
                if (response && response.data && response.data.results) {
                    let employees: any[] = formRef.current?.getFieldProps('employees').value
                    if (!employees) employees = []
                    employees.push(response.data.results[0])
                    formRef.current?.setFieldValue('employees', employees)
                    handleEmployeeToFilter(employeeId)
                    document.body.classList.remove('page-loading')
                } else {
                    document.body.classList.remove('page-loading')
                }
            })
        } else {
            Swal.fire({
                position: "top-right",
                title: 'Error!',
                text: 'Please select an employee!',
                icon: "warning",
                showConfirmButton: false,
                timer: AZKHelper.SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        }
    }

    const handleAddBranch = async function (branchId) {
        if (branchId) {
            document.body.classList.add('page-loading')
            await axios.get(`${API_URL}/hr.employee?filters=[('branch_id','=',${branchId})]&include_fields=['id','name', 'azadea_employee_name', 'as_effective_date', 'time_set', ('grade_id',('id','grade_name')), ('branch_id',('id','name')), ('job_id',('id','name')), ('brand_id',('id','name', 'brand_code')), ('az_country_id',('id','name')), ('az_company_id',('id','name')), ('payroll_id',('id','payroll_name')), ('section_id',('id','section')),('employment_category_id',('id','name', 'code')), 'azadea_id']`).then(async response => {
                if (response && response.data && response.data.results) {
                    let branchEmployees: any[] = response.data.results
                    let employees: any[] = formRef.current?.getFieldProps('employees').value
                    if (!employees) employees = []
                    if (branchEmployees && response.data.count > 0) {
                        await branchEmployees.map(branchEmployee => {
                            if (branchEmployee.id !== employee?.id) {
                                employees.push(branchEmployee);
                                handleEmployeeToFilter(branchEmployee.id);
                            }
                        })
                        formRef.current?.setFieldValue('employees', employees)
                        document.body.classList.remove('page-loading')
                    } else {
                        document.body.classList.remove('page-loading')
                    }
                }
            })
        } else {
            Swal.fire({
                position: "top-right",
                title: 'Error!',
                text: 'Please select a branch!',
                icon: "warning",
                showConfirmButton: false,
                timer: AZKHelper.SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        }
    }



    const handleAddBySupervisor = async function (supervisorId) {
        if (supervisorId) {
            document.body.classList.add('page-loading')
            await axios.get(`${API_URL}/hr.employee?filters=[('parent_id','=',${supervisorId})]&include_fields=['id','name', 'azadea_employee_name', 'as_effective_date', 'time_set', ('grade_id',('id','grade_name')), ('branch_id',('id','name')), ('job_id',('id','name')), ('brand_id',('id','name', 'brand_code')), ('az_country_id',('id','name')), ('az_company_id',('id','name')), ('payroll_id',('id','payroll_name')), ('section_id',('id','section')),('employment_category_id',('id','name', 'code')), 'azadea_id']`).then(async response => {
                if (response && response.data && response.data.results) {
                    let supervisorEmployees: any[] = response.data.results
                    let employees: any[] = formRef.current?.getFieldProps('employees').value
                    if (!employees) employees = []
                    if (supervisorEmployees && response.data.count > 0) {
                        await supervisorEmployees.map(branchEmployee => {
                            if (branchEmployee.id !== employee?.id) {
                                employees.push(branchEmployee);
                                handleEmployeeToFilter(branchEmployee.id);
                            }
                        })
                        formRef.current?.setFieldValue('employees', employees)
                        document.body.classList.remove('page-loading')
                    } else {
                        document.body.classList.remove('page-loading')
                        Swal.fire({
                            position: "top-right",
                            title: 'No Employee!',
                            text: 'there are no employees under the supervision of the selected!',
                            icon: "warning",
                            showConfirmButton: false,
                            timer: AZKHelper.SWAL_TOAST_TIMER,
                            toast: true,
                            iconColor: 'white',
                            customClass: {
                                popup: 'colored-toast'
                            },
                            timerProgressBar: true
                        })
                    }
                }
            })
        } else {
            Swal.fire({
                position: "top-right",
                title: 'Error!',
                text: 'Please select a supervisor !',
                icon: "warning",
                showConfirmButton: false,
                timer: AZKHelper.SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        }
    }

    const handleDeleteEmployee = async function (employeeId) {
        handleEmployeeToFilter(employeeId, false)
        if (employeeId) {
            let employees: any[] = formRef.current?.getFieldProps('employees').value
            if (!employees) employees = []
            employees = employees.filter((obj) => obj.id !== employeeId);
            formRef.current?.setFieldValue('employees', employees)
        }
    }

    const pobulateFormData = async (data?: ManagerAssignment) => {
        if (data) {
            formRef.current?.setValues({
                id: data.id,
                employee_id: data.employee_id?.id,
                az_country_id: data.az_country_id?.id,
                az_company_id: data.az_company_id?.id,
                payroll_id: data.payroll_id?.id,
                branch_id: data.branch_id?.id,
                brand_id: data.brand_id?.id,
                grade_id: data.grade_id?.id,
                section_id: data.section_id?.id,
                job_id: data.job_id?.id,
                as_effective_date: moment(data.as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT),
                time_set: data.time_set,
                target_salary: data.salary,
                azadea_employee_name: data.azadea_employee_name,
                employees: data.employees,
                target_as_effective_date: moment(data?.target_as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT),
            })
        }
    }


    const checkPermission = async (action: string) => {
        const allowed = await checkTrnsactionTypeAccessId(MODEL_NAME, action)
        if (!allowed) {
            if (id) {
                const allowed_read = await checkTrnsactionTypeAccessId(MODEL_NAME, 'read')
                if (!allowed_read) {
                    navigate(`/transactions/`, { state: {}, replace: true });
                    Swal.fire({
                        position: "top-right",
                        title: 'Access Error!',
                        text: 'You does not have access to perform read action!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                } else {
                    setReadOnly(true)
                }
            } else {
                navigate(`/transactions/`, { state: {}, replace: true });
                Swal.fire({
                    position: "top-right",
                    title: 'Access Error!',
                    text: 'You does not have access to perform this action!',
                    icon: "error",
                    showConfirmButton: false,
                    timer: AZKHelper.SWAL_TOAST_TIMER,
                    toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
            }
        }
    }

    const handleDateConstraint = async (target_as_effective_date, country_id, company_id, brand_id, employee_changed =false) => {
        let start_ofthe_month = false
        let allow_back_date = false
        let date_from = moment()
        if (!country_id) country_id = formRef.current?.getFieldProps('country_id').value
        if (!company_id) company_id = formRef.current?.getFieldProps('company_id').value
        if (!brand_id) brand_id = formRef.current?.getFieldProps('brand_id').value
        if (!target_as_effective_date) target_as_effective_date = formRef.current?.getFieldProps('target_as_effective_date').value
        const type_technical_name = MODEL_NAME.replaceAll('.', '_')
        if (country_id && country_id && brand_id)
            checkDateConstrains(country_id, company_id, brand_id, type_technical_name).then(data => {
                if (data.count > 0) {
                    if (data['results'][0]['back_dated']) {
                        allow_back_date = true
                        date_from = moment('2023-01-01')
                    }
                    if (data['results'][0]['start_of_month']) {
                        setStartOftheMonth(true)
                        start_ofthe_month = true
                    }
                    setStartOftheMonth(start_ofthe_month)
                    setDateFrom(date_from)
                    setAllowBackDate(allowBackDate)
                    handleDateChange(target_as_effective_date, date_from, start_ofthe_month, allow_back_date, employee_changed)
                }
            })
    }

    const handleDateChange = async (date_value, date_from, start_ofthe_month, allow_back_date, employee_changed =false) => {
        date_value = moment(date_value).startOf("day");
        date_from = moment(date_from).startOf("day");
        if (start_ofthe_month) {
            date_value = allow_back_date ? date_value.startOf('month') : date_value.add(1, 'month').startOf('month');
        } else {
            date_value = date_value;
        }
        if (date_value.isBefore(date_from)) {
            date_value = date_from;
        }
        if (id) {
            formRef.current?.setFieldValue('target_as_effective_date', date_value.format(AZKHelper.FRONTEND_DATE_FORMAT));
        }
        else {
            formRef.current?.setFieldValue('target_as_effective_date', date_value.format(AZKHelper.FRONTEND_DATE_FORMAT));
        }
        if (employee && employee.start_date && moment(employee.start_date ).startOf("day").isAfter(date_value) && !employee_changed){
            formRef.current?.setFieldValue('target_as_effective_date', moment(employee.start_date ).format(AZKHelper.FRONTEND_DATE_FORMAT));
            Swal.fire({
                position: "top-right",
                title: 'Wrong Date Value!',
                text: 'The selected Supervisor is not available in the selected date, date must be equals or grater then ' + moment(employee.start_date ).format(AZKHelper.FRONTEND_DATE_FORMAT) + ' !' ,
                icon: "warning",
                showConfirmButton: false,
                timer: AZKHelper.SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        }
    };

    useEffect(() => {
        if (!filterEmployees) setFilterEmployees([])
        setEmployeeDomain(`('id','not in', [${filterEmployees}])`);
        document.body.classList.add('page-loading')
        setObjectId(undefined)
        setCanValidate(false)
        handleEmployeeChange(undefined, undefined)
        if (id) {
            setObjectId(parseInt(id))
            checkPermission('write');
            document.body.classList.add('page-loading')
            GetManagerAssignmentById(parseInt(id)).then(((data: ManagerAssignment) => {
                handleEmployeeToFilter(data?.employee_id?.id)
                handleEmployeeChange(data?.employee_id?.id, data)
                if (data?.x_state && data?.x_state_view) {
                    setTransactionState(data?.x_state)
                    setTransactionStateName(data?.x_state_view)
                }
                if (data?.can_write && data?.can_write !== null) {
                    setCanEdit(data?.can_write)
                    if (data?.restarted) setCanSubmit(data?.restarted)
                }
                setTransactionClosed(data.validated || data.rejected ? true : false)
                setCanValidate(data?.can_review ? true : false)
                setCanDelete(data?.can_delete ? true : false)
            }))
        } else {
            checkPermission('create');
            formRef.current?.setFieldValue('employee_id', undefined)
            handleEmployeeChange(undefined, undefined)
            setCanEdit(true)
            document.body.classList.remove('page-loading')
        }
    }, [id])

    const cancel = () => {
        navigate(`/transactions/`, { state: {}, replace: true });
    }

    const deleteTransaction = () => {
        Swal.fire({
            position: "center",
            title: 'Delete',
            text: 'Delete Transaction confirm?',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed && id) {
                deleteManagerAssignment(parseInt(id)).then(() => {
                    cancel()
                    Swal.fire({
                        position: "top-right",
                        title: 'Deleted!',
                        text: 'Your transaction had been deleted!',
                        icon: "warning",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                })
            } else if (result.isDenied) {
                Swal.fire('Transaction was not deleted', '', 'info')
            }
        })
    }

    const submitTransaction = (transactionId: number) => {
        setNeedSubmit(false)
        axios.put(`${API_URL}/${MODEL_NAME}/${transactionId}/request_validation`, '{}').then(data => {
            navigate(`/transactions/`, { state: {}, replace: true });
            document.body.classList.remove('page-loading')
            Swal.fire({
                position: "top-right",
                title: 'Success!',
                text: 'Your transaction had been submited!',
                icon: "success",
                showConfirmButton: false,
                timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        })
    }

    const handleResultOfsubmit = (transactionId: number | undefined, isUpdate: boolean | false) => {
        if (needSubmit && transactionId) {
            submitTransaction(transactionId)
        } else {
            document.body.classList.remove('page-loading')
            navigate(`/transactions/`, { state: {}, replace: true })
            Swal.fire({
                position: "top-right",
                title: 'Success!',
                text: isUpdate ? 'Your transaction had been updated!' : 'Your transaction had been saved!',
                icon: "success",
                showConfirmButton: false,
                timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        }
    }

    const checkEmployees = async () => {
        let canSave = true
        let employees = formRef.current?.getFieldProps('employees').value
        if (!employees) employees = []
        // replace the element object
        if (employees.length === 0) canSave = false
        return canSave
    }

    const onSubmitHandler = async (values: ManagerAssignment, actions: FormikValues) => {
        document.body.classList.add('page-loading')
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        if (formRef.current?.isValid) {
            checkEmployees().then(async (canSave) => {
                if (canSave) {
                    if (isNotEmpty(values.id)) {
                        await UpdateManagerAssignment(values).then(data => {
                            if (id) handleResultOfsubmit(parseInt(id), true)
                        })
                    } else {
                        await CreateManagerAssignment(values).then(data => {
                            setObjectId(data?.id)
                            setHitSend(true)
                        })
                    }
                } else {
                    document.body.classList.remove('page-loading')
                    Swal.fire({
                        position: "top-right",
                        title: 'Error!',
                        text: 'Please fill the list of Employees properly!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                }
            })
        } else {
            actions.setSubmitting(false)
            formRef.current?.validateForm().then(errors => {
                if (Object.keys(errors).length > 0) {
                    console.log(errors)
                    //   setStatus('error');
                }
            });
        }
    }


    return (
        <>
            <Row className="m-0 my-2">
                <Col md='12' lg={id ? '9' : '9'} xl={id ? '9' : '9'}>
                    <Formik
                        innerRef={formRef}
                        validationSchema={editTransactionSchema}
                        onSubmit={onSubmitHandler}
                        initialValues={initialTransaction}
                        validateeOnChange={true}
                        validateOnMount={true}
                        initialErrors={''}
                    >
                        {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
                            // ,validateForm,isSubmitting, errors, touched, isValidating
                            return (
                                <>
                                    {/* {id && (<AzkStatusBar transactionId={id} modelName={MODEL_NAME}
                                        current={transactionState} />
                                    )} */}
                                    {canValidate && id &&
                                        <AzkActionButtons
                                            actionUrl={`${API_URL}/${MODEL_NAME}/${parseInt(id)}/`}
                                            thenNavigate="/transactions/"
                                            divStyle="card py-2 mb-3"
                                            buttons={[
                                                { title: "Approve", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.APPROVED' }), method: "validate_tier", confirmMsg: "Approve Transaction, Confirm?", cssClass: "btn btn-light btn-light-success", icon: "fa-thumbs-up" },
                                                { title: "Review", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.REVIEW' }), method: "restart_validation", confirmMsg: "Send Transaction back to draft, Confirm?", cssClass: "btn btn-light btn-light-warning", icon: "fa-refresh" },
                                                { title: "Decline", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.DECLINE' }), method: "reject_tier", confirmMsg: "Decline Transaction, Confirm?", cssClass: "btn btn-light btn-light-danger", icon: "fa-times" },
                                            ]} />}
                                    <Form onSubmit={handleSubmit} noValidate>
                                        <fieldset disabled={!canEdit|| readOnly}>
                                            {!id && (
                                                <Row className="card m-0 py-2 mb-3">
                                                    <Col>
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label>
                                                                <h4 className="px-3 m-0 custom-employee-header justify-content-start">
                                                                    <i className="fa fa-magnifying-glass" aria-hidden="true"></i>Supervisor
                                                                </h4>
                                                            </Form.Label>
                                                            <AzkSelect
                                                                compID="azk_temp"
                                                                modelName="hr.employee"
                                                                valueField="id"
                                                                titleField="display_name_fr"
                                                                dataDomain=''
                                                                allowEmpty={true}
                                                                loadAll={true}
                                                                fieldName='employee_id'
                                                                onChange={(value) => {
                                                                    handleEmployeeToFilter(values.employee_id, false)
                                                                    setFieldValue('employee_id', parseInt(value))
                                                                    handleEmployeeChange(value, undefined)
                                                                }}
                                                                defaultValue={values.employee_id}
                                                                disabled={!canEdit}
                                                                searchFields={['name', 'azadea_id', 'branch_id', 'job_id', 'work_email', 'first_name']} />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='employee_id' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            )}
                                        </fieldset>
                                        {employee && (
                                            <>
                                                <EmployeeDetail employee={employee} title="Supervisor" hideSalary={true} />
                                                <Row className="m-0 card">
                                                    <h4 className="py-2 px-6 m-0 card-header custom-card-header justify-content-start align-items-baseline" >
                                                        {/* style={    align-items: baseline;} */}
                                                        <i className="fa fa-info-circle" aria-hidden="true"></i>List of employes <small className="fs-9">(you can add one or more employee to be under supervision of the selected Supervisor above)</small></h4>

                                                    <fieldset disabled={!canEdit|| readOnly}>
                                                        <Row className="m-0 my-2">
                                                            <Col lg="4" md="4" sm="6" xs="12" >
                                                                <Form.Label>
                                                                    Branch
                                                                </Form.Label>
                                                                <Col lg="12" md="12" sm="12" xs="12" className="d-flex p-0">
                                                                    <Col lg="10" md="10" sm="10" xs="9">
                                                                        <AzkSelect
                                                                            compID="azk_temp"
                                                                            modelName="az.branch"
                                                                            valueField="id"
                                                                            titleField="name"
                                                                            dataDomain={branchDomain}
                                                                            allowEmpty={true}
                                                                            fieldName='new_branch_id'
                                                                            onChange={(value) => {
                                                                                setNewBranchId(value)
                                                                                if (value) {
                                                                                    setEmployeeDomain(`('id','not in', [${filterEmployees}]),('branch_id', '=', ${value})`);
                                                                                } else {
                                                                                    setEmployeeDomain(`('id','not in', [${filterEmployees}])`)
                                                                                }
                                                                            }}
                                                                            disabled={!canEdit} />
                                                                    </Col>

                                                                    <Col lg="2" md="2" sm="2" xs="3">
                                                                        <button
                                                                            type="reset"
                                                                            onClick={() => { handleAddBranch(newBranchId) }}
                                                                            className='btn btn-light btn-light-info btn-sm p-1 px-2 mx-2'
                                                                        ><i className="bi bi-plus p-0"></i>
                                                                        </button>
                                                                    </Col>
                                                                </Col>
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='employee_id' />
                                                                </div>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12" >
                                                                <Form.Label>
                                                                    Employee
                                                                </Form.Label>
                                                                <Col lg="12" md="12" sm="12" xs="12" className="d-flex p-0">
                                                                    <Col lg="10" md="10" sm="10" xs="9">
                                                                        <AzkSelect
                                                                            compID="azk_temp"
                                                                            modelName="hr.employee"
                                                                            valueField="id"
                                                                            titleField="display_name_fr"
                                                                            dataDomain={employeeDomain}
                                                                            allowEmpty={true}
                                                                            fieldName='new_employee_id'
                                                                            onChange={(value) => {
                                                                                setNewEmployeeId(value)
                                                                            }}
                                                                            disabled={!canEdit}
                                                                            searchFields={['name', 'azadea_id', 'branch_id', 'job_id', 'work_email', 'first_name']} />
                                                                    </Col>

                                                                    <Col lg="2" md="2" sm="2" xs="3">
                                                                        <button
                                                                            type="reset"
                                                                            onClick={() => { handleAddEmployee(newEmployeeId) }}
                                                                            className='btn btn-light btn-light-info btn-sm p-1 px-2 mx-2'
                                                                        ><i className="bi bi-plus p-0"></i>
                                                                        </button>
                                                                    </Col>
                                                                </Col>
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='employee_id' />
                                                                </div>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12" >
                                                                <Form.Label>
                                                                    Current Supervisor
                                                                </Form.Label>
                                                                <Col lg="12" md="12" sm="12" xs="12" className="d-flex p-0">
                                                                    <Col lg="10" md="10" sm="10" xs="9">
                                                                        <AzkSelect
                                                                            compID="azk_temp"
                                                                            modelName="hr.employee"
                                                                            valueField="id"
                                                                            titleField="display_name_fr"
                                                                            dataDomain={employeeDomain}
                                                                            allowEmpty={true}
                                                                            fieldName='current_supervisor_id'
                                                                            onChange={(value) => {
                                                                                setCurrentSupervisorId(value)
                                                                            }}
                                                                            disabled={!canEdit}
                                                                            searchFields={['name', 'azadea_id', 'branch_id', 'job_id', 'work_email', 'first_name']} />
                                                                    </Col>

                                                                    <Col lg="2" md="2" sm="2" xs="3">
                                                                        <button
                                                                            type="reset"
                                                                            onClick={() => { handleAddBySupervisor(currentSupervisorId) }}
                                                                            className='btn btn-light btn-light-info btn-sm p-1 px-2 mx-2'
                                                                        ><i className="bi bi-plus p-0"></i>
                                                                        </button>
                                                                    </Col>
                                                                </Col>
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='employee_id' />
                                                                </div>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Effective date
                                                                    </Form.Label>

                                                                    {!startOftheMonth &&
                                                                        <Form.Control
                                                                            className='p-1'
                                                                            type='date'
                                                                            onChange={(value) => {
                                                                                handleDateChange(value.target.value, dateFrom, startOftheMonth, allowBackDate)
                                                                            }}
                                                                            disabled={!canEdit}
                                                                            value={moment(values.target_as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                            min={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                        />
                                                                    }
                                                                    {startOftheMonth &&
                                                                        <Form.Control
                                                                            className='p-1'
                                                                            type='date'
                                                                            // isInvalid={isInvalidDate}
                                                                            onChange={(value) => {
                                                                                handleDateChange(value.target.value, dateFrom, startOftheMonth, allowBackDate)
                                                                            }}
                                                                            disabled={!canEdit}
                                                                            value={moment(values.target_as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                            min={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                        />
                                                                    }
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='target_as_effective_date' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="12" md="12" sm="12" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Employees
                                                                    </Form.Label>
                                                                    <Col lg="12" md="12" sm="12" xs="12">
                                                                        <div className='table-responsive'>
                                                                            <table
                                                                                id='kt_table_users'
                                                                                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                                                                <thead>
                                                                                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                                                                        <th className="fs-7">Azadea Id/ Name</th>
                                                                                        <th className="fs-7">Company</th>
                                                                                        <th className="fs-7">Branch</th>
                                                                                        <th className="fs-7">Section</th>
                                                                                        <th className="fs-7">Title</th>
                                                                                        <th className="fs-7"></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody className='text-gray-600 fw-bold'>
                                                                                    {(values && values.employees && values.employees.length > 0) ? (values.employees.map((employee) => {
                                                                                        return (
                                                                                            <>
                                                                                                <tr>
                                                                                                    <td className="fs-7 py-0">{employee.azadea_employee_name}</td>
                                                                                                    <td className="fs-7 py-0">{employee?.az_company_id?.name}</td>
                                                                                                    <td className="fs-7 py-0">{employee?.branch_id?.name}</td>
                                                                                                    <td className="fs-7 py-0">{employee?.section_id?.section}</td>
                                                                                                    <td className="fs-7 py-0">{employee?.job_id?.name}</td>
                                                                                                    <td className="fs-7 py-2">
                                                                                                        <button
                                                                                                            type="reset"
                                                                                                            onClick={() => { handleDeleteEmployee(employee?.id) }}
                                                                                                            className='btn btn-light btn-light-info btn-sm p-1 px-2'
                                                                                                        ><i className="bi bi-trash p-0"></i></button>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </>
                                                                                        )
                                                                                    })) : (
                                                                                        <tr>
                                                                                            <td colSpan={7}>
                                                                                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                                                                    No matching records found
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>)}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div className='text-danger'>
                                                                            <ErrorMessage name='element' />
                                                                        </div>
                                                                    </Col>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </fieldset>

                                                    {/* begin::Actions */}
                                                    <div className='py-5 d-flex justify-content-end'>
                                                        {!canValidate && canEdit &&
                                                            <button
                                                                title="Submit for approval"
                                                                type='submit'
                                                                onClick={() => { setNeedSubmit(true) }}
                                                                className='btn btn-light btn-light-info mx-5 btn-sm'
                                                                disabled={!canEdit}>
                                                                <i className="fa fa-podcast fs-2" aria-hidden="true"></i>
                                                                <span className='indicator-label'>Submit</span>
                                                            </button>
                                                        }
                                                        {!canValidate && canEdit &&
                                                            <button
                                                                title="Save"
                                                                type='submit'
                                                                onClick={() => { setNeedSubmit(false) }}
                                                                className='btn btn-light btn-light-primary mx-5 btn-sm  '
                                                                disabled={isSubmitting || !canEdit}
                                                            >
                                                                {/* <KTIcon iconName='fa-save' className='fs-2' /> */}
                                                                <i className="fas fa-check fs-2"></i>
                                                                <span className='indicator-label'>Save</span>
                                                            </button>
                                                        }
                                                        {!canValidate &&
                                                            <button
                                                                title="Discard & back"
                                                                type='reset'
                                                                onClick={() => cancel()}
                                                                className='btn btn-light btn-active-light-primary me-3 mx-5 btn-sm'
                                                                data-kt-users-modal-action='cancel'
                                                            >
                                                                <KTIcon iconName='exit-left' className='fs-2' />
                                                                <span className='indicator-label'>Discard</span>

                                                            </button>
                                                        }
                                                        {canDelete && id &&
                                                            <button
                                                                title="Delete"
                                                                type='reset'
                                                                onClick={() => deleteTransaction()}
                                                                className='btn btn-light btn-light-danger me-3 mx-5 btn-sm'
                                                                data-kt-users-modal-action='delete'
                                                            >
                                                                <KTIcon iconName='trash' className='fs-2' />
                                                                <span className='indicator-label'>Delete</span>

                                                            </button>
                                                        }
                                                    </div>
                                                    {/* end::Actions */}
                                                </Row>
                                            </>)}
                                    </Form>
                                </>
                            )
                        }}
                    </Formik>
                </Col>
                <Col md='12' lg='3' xl='3'>
                    {id && (<AzkStatusRibbon transactionId={parseInt(id)} modelName={MODEL_NAME}
                        current={transactionState} currentName={transactionStateName} />
                    )}
                    {employee && <AzkChatter transactionClosed={transactionClosed} isDrawer={true} modelName={MODEL_NAME} itemID={objectId} hitSend={hitSend} sendMessagesCallback={handleResultOfsubmit} />}
                </Col>
            </Row>
        </>
    )
}

export default Transactionform;