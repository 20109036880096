
import React, { useState, useEffect, createContext, useContext } from 'react';
import axios from 'axios';
import "./azkStatus.scss";
import { API_URL } from './azkHelper';
import { Col, Row } from 'react-bootstrap';
import AzkTransactionApprovers from './azkTransactionApprovers';

type AzkStatusRibbonProps = {
    modelName: string;
    current: any;
    currentName?: string;
    transactionId: number
};

export const AzkStatusRibbonClasses = { draft: 'btn  btn-light-primary btn-active-primary', pending: "btn btn-light-warning btn-active-warning", approved: "btn btn-light-success btn-active-success", rejected: "btn btn-light-danger btn-active-danger", deleted: "btn btn-light-danger btn-active-danger", payroll_release_completed: "btn btn-light-success btn-active-success", default: "btn btn-light-warning btn-active-warning"};
const AzkStatusRibbonActiveClasses = { draft: 'badge badge-primary', pending: "badge-warning", approved: "badge-success", rejected: "badge-danger" };

type AzkOption = {
    value: any
    label: string
}

export type StatusModalViewProps = {
    closeModal: () => void
}

export const initialStatusModalView = {
    closeModal: () => { },
}

const statusRibbonContext = createContext<StatusModalViewProps>(initialStatusModalView)

const AzkStatusRibbon: React.FC<AzkStatusRibbonProps> = ({ modelName, current, currentName, transactionId }) => {
    const [modelStatuses, setModelStatuses] = useState<AzkOption[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [transaction, setTransaction] = useState<any>();
    useEffect(() => {
        // const fetchData = async () => {
        //     try {
        //         const result = await axios.get(`${API_URL}/az.transaction.type?filters=[('technical_name', '=', '${modelName.replaceAll(".", "_")}')]&include_fields=[('statuses',[('name','technical_name', 'sequence')])]`);
        //         if (result.data && result.data.results && result.data.count > 0) {
        //             const statuses = result.data.results[0].statuses.sort((a, b)=>{return a.sequence - b.sequence})
        //             setModelStatuses(statuses.map((item: any) => ({ value: item.technical_name, label: item.name })));
        //         } else {
        //             setModelStatuses([]);
        //         }
        //     } catch (error) {
        //         console.error(error);
        //     }
        // };

        // fetchData();
    }, [modelName, current, currentName]);

    const openAprroverModal = async (clickedStatus: string) => {
        if (current === clickedStatus) {
            const response = await axios.get(`${API_URL}/${modelName}?filters=[('id','=',${transactionId})]&include_fields=[('current_approval_ids', [('id','name', 'login')]), 'review_ids']`)
            if (response && response.data && response.data.results) {
                setTransaction(response.data.results[0]);
            } else {
                setTransaction(undefined);
            }
            setShowModal(true)
        }
    }
    const closeModal = () => {
        setShowModal(false)
    }
    return (
        <>
            <div className="card row py-2 px-2 mx-0">
                <div className="azk-status-ribbon w-100 px-0">
                    {AzkStatusRibbonClasses[current] !== undefined &&
                        <button onClick={() => { openAprroverModal(current) }} className={`w-100 btn btn-sm px-0 ${AzkStatusRibbonClasses[current]}`}>{currentName}</button>
                    }
                    {AzkStatusRibbonClasses[current] === undefined &&
                        <button onClick={() => { openAprroverModal(current) }} className={`w-100 btn btn-sm px-0 ${AzkStatusRibbonClasses['default']}`}>{currentName}</button>
                    }

                </div>
            </div>
            {showModal && <AzkTransactionApprovers transaction={transaction} divStyle={''} closeModal={function (): {} {
                setShowModal(false)
                return closeModal
            }} />}

        </>);
};

const useApproverModalView = () => useContext(statusRibbonContext)
export { AzkStatusRibbon, useApproverModalView };