import axios, {AxiosResponse} from 'axios'
import { TravelPerDiem, TravelPerDiemQueryResponse } from './model'
import { ID, Response } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper' 
import useFixServerClientData from 'app/utilities/fix-Server-Client-Data'

const MODEL_NAME = 'az.trn.travel.per.diem'
const URL = `${API_URL}/${MODEL_NAME}`
const URL_SETTING = `${API_URL}/az.travel.request.setting`
const GET_URL = `${API_URL}/${MODEL_NAME}/`
const INCLUDE_FIELDS = `'id', 'name', 'employee_id', 'x_state', 'x_state_view', 'can_review', 'can_write', 'can_delete', 'validated', 'rejected', 'restarted', 'az_country_id', 'az_company_id', 'brand_id', 'azadea_id', 'azadea_employee_name', 'branch_id', 'section_id', 'grade_id', 'job_id', 'create_date', 'date', 'subject', 'employee_id', 'setting_id', 'travel_request_id', 'traveler_name', 'euro_per_diem', 'per_diem', 'total_euro_per_diem', 'total_per_diem', 'total_days', ('line_ids', [('id' ,'line_sequence', 'date', 'perdiem_x', 'days', 'euro_per_diem', 'per_diem', 'booked_travel', 'comment', 'is_euro_per_diem', 'is_euro_per_diem', 'flight_number', 'flight_date', 'departure_city', 'arrival_city', 'departure_time', 'arrival_time', ('detail_ids', [('id' ,'date', 'perdiem_x', 'euro_per_diem', 'comment', 'is_euro_per_diem')]))])`
const SETTING_INCLUDE_FIELDS = `'id', 'euro_per_diem', 'per_diem'`


const GetTravelSettingById = (id: ID): Promise<TravelPerDiem> => {
  return axios
    .get(`${URL_SETTING}?filters=[('id','=',${id})]&include_fields=[${SETTING_INCLUDE_FIELDS}]`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response['results'][0])
}

const GetTravelPerDiemById = (id: ID): Promise<TravelPerDiem> => {
  const { FixServerData} = useFixServerClientData();
  return axios
    .get(`${URL}?filters=[('id','=',${id})]&include_fields=[${INCLUDE_FIELDS}]`)
    .then((response: AxiosResponse<Response<TravelPerDiem>>) => response.data)
    .then((response: Response<TravelPerDiem>) => FixServerData(response['results'][0]))
}

const CreateTravelPerDiem = async (transaction: TravelPerDiem): Promise<TravelPerDiem | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transaction)
  return axios
    .post(URL, shallowTransaction)
    .then((response: AxiosResponse<TravelPerDiem>) => response.data)
}

const UpdateTravelPerDiem = async (transaction: TravelPerDiem): Promise<TravelPerDiem | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transaction)
  return axios
    .put(`${URL}/${transaction.id}`, shallowTransaction)
    .then((response: AxiosResponse<Response<TravelPerDiem>>) => response.data)
    .then((response: Response<TravelPerDiem>) => response.data)
}

const DeleteTravelPerDiem = (transactionId: number): Promise<TravelPerDiem | undefined> => {
  return axios
    .delete(`${URL}/${transactionId}`)
    .then((response: AxiosResponse<Response<TravelPerDiem>>) => response.data)
    .then((response: Response<TravelPerDiem>) => response.data)
}

export { GetTravelPerDiemById, CreateTravelPerDiem, UpdateTravelPerDiem, MODEL_NAME, DeleteTravelPerDiem, GetTravelSettingById}


