import { KTIcon } from "_metronic/helpers";
import { AZKHelper } from "app/modules/components/azkHelper";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";

class ButtonProps {
  title?: string
  method?: string
  confirmMsg?: string | ''
  icon?: string | ''
  cssClass?: string | 'btn btn-primary'
  disabled?: boolean | false;
  alertSuccess?: string | ''
  callback?: Function = ()=>{}
  backLink?: string | ''
}

interface AzkButtonsProps {
  actionUrl: string
  thenNavigate: string
  divStyle: string
  buttons: ButtonProps[];
  backLink?: string | '';
}

const AzkActionButtons: React.FC<AzkButtonsProps> = ({ actionUrl, thenNavigate, divStyle, buttons, backLink }) => {
  const navigate = useNavigate()
  function handleClick(button) {
    if (button.confirmMsg && button.confirmMsg !== undefined) {
      Swal.fire({
        position: "center",
        title: button.title,
        text: button.confirmMsg,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          clickCallBack(button)
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info')
        }
      })
    } else {
      clickCallBack(button)
    }
  };

  const clickCallBack = (button) => {
    if (button.callback) {
      button.callback.bind().call()
    } else {
      document.body.classList.add('page-loading')
      axios.put(`${actionUrl}${button.method}`, '{}').then(data => {
        document.body.classList.remove('page-loading')
        Swal.fire({
          position: "top-right",
          title: 'Success!',
          text: button.alertSuccess,
          icon: "success",
          showConfirmButton: false,
          timer: AZKHelper.SWAL_TOAST_TIMER,
          toast: true,
          iconColor: 'white',
          customClass: {
            popup: 'colored-toast'
          },
          timerProgressBar: true
        })
        if (thenNavigate) {
          navigate(thenNavigate, { state: {}, replace: true });
        }
      }, err =>{
        document.body.classList.remove('page-loading')
      })
    }
  }

  function back(): void {
    if(backLink){
      navigate(backLink, { state: {}, replace: true });
    }else{
    navigate(-1)
    }
  }

  return (
    
    <div className={divStyle}>
      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-start">
          {buttons.map((button, index) => (
            <button
              key={index}
              
              className={`${button.cssClass} mx-2 btn-sm ${button.title? '':'d-none'}`}
              onClick={() => handleClick(button)}
            >
              {button.icon && <i className={`fa fa-fw o_button_icon ${button.icon}`}></i>}
              <span className='indicator-label'>{button.title}</span>
            </button>
          ))}
        </div>

        <button
          title="Discard & back"
          type='reset'
          onClick={() => back()}
          className='btn btn-light btn-active-light-primary me-3 mx-5 btn-sm'
          data-kt-users-modal-action='cancel'
        >
          <KTIcon iconName='exit-left' className='fs-2' />
          <span className='indicator-label'>Discard</span>

        </button>
      </div>
    </div>
  );
};

export default AzkActionButtons;
