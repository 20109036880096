import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import azkSelect from "_azkatech/azkSelect"
import azkDataTable from "_azkatech/azkDataTable"
import { useNavigate } from 'react-router';
import {KTCard, KTIcon} from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper';


const TransactionList = () => {
    const [listDataDomain, setListDataDomain] = useState('');
    const [searchTerm, setSearchTerm] = useState<string>('')
    const navigate = useNavigate()
    const MODEL_NAME = "az.trn.manager.assignment"

    // #region Transfer List as DataTable
    const updateListDataDomain = (search, country, company, brand, state) => {
        let conds: string[] = [];
        if (search !== '') {
            conds.push(`'|','|',('name','ilike', '${search}'),('employee_name','ilike', '${search}'),('azadea_id','ilike', '${search}')`)
        }
        if (country !== '' && country) {
            conds.push(`('az_country_id','=', ${country})`)
        }
        if (company !== '' && company) {
            conds.push(`('az_company_id','=', ${company})`)
        }
        if (brand !== '' && brand) {
            conds.push(`('brand_id','=', ${brand})`)
        }
        if (state !== '' && state) {
            conds.push(`('x_state','=', '${state}')`)
        }
        return conds.join(',');
    }
    const handleEditBtnClick = (id) => {
        navigate(`/transactions/manager-assignment/${id}`,{state:{'transactionId':id}, replace:true});
    }
    const columns = [
        // {
        //     name: 'ID',
        //     selector: row => row.id,
        //     sortable: true,
        //     tech_name: 'id'
        // },
        {
            name: 'Transaction',
            selector: row => row.name,
            sortable: true,
            tech_name: 'name'
        },
        {
            name: 'Azadea ID',
            selector: row => row.employee_id? row.azadea_id: undefined,
            sortable: true,
            tech_name: 'azadea_id'
        },
        {
            name: 'Employee',
            selector: row => row.employee_id? row.employee_name: undefined,
            sortable: true,
            tech_name: 'employee_name'
        },
        {
            name: 'Country',
            selector: row => row.az_country_id? row.az_country_id.name: undefined,
            sortable: true,
            tech_name: 'az_country_id'
        },
        {
            name: 'Company',
            selector: row => row.az_company_id? row.az_company_id.name: undefined,
            sortable: true,
            tech_name: 'az_company_id'
        },
        {
            name: 'Brand',
            selector: row => row.brand_id? row.brand_id.name: undefined,
            sortable: true,
            tech_name: 'brand_id'
        },
        {
            name: 'Status',
            selector: row => row.x_state? row.x_state: undefined,
            sortable: true,
            tech_name: 'x_state',
            cell: (d) => [
                <span className={`p-1 fw-bold fs-7 ${(d.state === 'to_approve' && d.restarted) ? "text-primary" : ''} ${d.state === "approved" ? "text-success" : ''} ${d.state === "rejected" ? "text-danger" : ''} ${(d.state === 'to_approve' && !d.restarted) ? "text-info" : ''}`}
                >{d.x_state}</span>
            ]
        },
        // {
        //   name: "Director",
        //   selector: "director",
        //   sortable: true,
        //   cell: (d) => (
        //     <a href="https://google.com" target="_blank" className="dlink">
        //       {d.director}
        //     </a>
        //   )
        // },
        // {
        //   name: "Genres",
        //   selector: "genres",
        //   sortable: true,
        //   cell: (d) => <span>{d.genres.join(", ")}</span>
        // },
        {
            name: "Action",
            sortable: false,
            selector: undefined,
            cell: (d) => [
                // <span
                //   key={d.title}
                //   onClick={handleEditBtnClick.bind(this, d.id)}
                //   className="btn btn-primary"
                // ><KTIcon iconName='trash' className='fs-3' /></span>,
                <span key={d.id}
                    onClick={handleEditBtnClick.bind(this, d.id)}
                    className="btn btn-sm btn-icon btn-active-light-primary me-1"
                ><KTIcon iconName='pencil' className='fs-3' /></span>
            ]
        }
    ];
    const table = azkDataTable({
        modelName: MODEL_NAME,
        fields: "'id', 'name', 'employee_id', 'azadea_id', 'employee_name', ('az_country_id',('name')), ('az_company_id',('name')), ('brand_id',('name')), 'x_state', 'state', 'restarted'",
        dataDomain: listDataDomain,
        columns: columns
    })

    // #endregion Transfer List

    // #region State Dropdown
    const [az_state, setState] = useState('');
    const [modelStatuses, setModelStatuses] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(`${API_URL}/az.transaction.type?filters=[('technical_name', '=', '${MODEL_NAME.replaceAll(".","_")}')]&include_fields=[('statuses',[('name','technical_name')])]`);
                if(result.data && result.data.results && result.data.count>0){
                    setModelStatuses(result.data.results[0].statuses.map((item: any) => ({ value: item.technical_name, label: item.name })));
                }else{
                    setModelStatuses([]);
                }
            } catch (error) {
                console.error(error);
            }
        };
    
        fetchData();
      }, []);

    const handleStateChange = (option) => {
        console.log(option.value ? `('state_id','=', ${option.value})` : '')
        setState(option);
        setListDataDomain(updateListDataDomain(searchTerm, az_country, az_company, az_brand, option.value));
    }
    // #endregion Country Dropdown

    // #region Country Dropdown
    const [az_country, setCountry] = useState('');

    const handleCountryChange = (val) => {
        console.log(val ? `('country_id','=', ${val})` : '')
        setCountry(val);
        setCompanyDomain(val ? `('country_id','=', ${val})` : '');
        setListDataDomain(updateListDataDomain(searchTerm, val, az_company, az_brand, az_state));
    }

    const az_country_select = azkSelect({
        compID: "az_country_select",
        modelName: "az.country",
        valueField: "id",
        titleField: "name",
        allowEmpty: true,
        isSearchable: true,
        defaultValue: az_country,
        onChange: handleCountryChange
    });
    // #endregion Country Dropdown

    // #region Company Dropdown
    const [az_company, setCompany] = useState('');
    const [companyDomain, setCompanyDomain] = useState('');

    const handleCompanyChange = (val) => {
        setCompany(val);
        setBrandefaultValue('')
        if (val !== '') {
            setListDataDomain(updateListDataDomain(searchTerm, az_country, val, '', az_state));
            axios.get(`${API_URL}/az.company?filters=[('id', '=', ${val ? val : '0'})]&include_fields=['brand_ids']`).then((result: any) => {
                if (result.data && result.data.results && result.data.count > 0 && result.data.results[0].brand_ids !== null) {
                    setBrandDomain(`('id', 'in', [${result.data.results[0].brand_ids}])`);
                } else {
                    setBrandDomain('');
                }
            });
        } else {
            setBrandDomain('');
        }
    }

    const az_company_select = azkSelect({
        compID: "az_company_select",
        modelName: "az.company",
        valueField: "id",
        titleField: "name",
        dataDomain: companyDomain,
        allowEmpty: true,
        onChange: handleCompanyChange
    });
    // #endregion Company Dropdown

    // #region Brand Dropdown
    const [az_brand, setBrand] = useState('');
    const [brandDomain, setBrandDomain] = useState('');
    const [brandefaultValue, setBrandefaultValue] = useState('');

    const handleBrandChange = (val) => {
        console.log(val ? `('brand_id','=', ${val})` : '')
        setBrand(val);
        setBrandefaultValue(val)
        setListDataDomain(updateListDataDomain(searchTerm, az_country, az_company, val, az_state));
    }

    const az_brand_select = azkSelect({
        compID: "az_brand_select",
        modelName: "az.brand",
        valueField: "id",
        titleField: "name",
        dataDomain: brandDomain,
        allowEmpty: true,
        onChange: handleBrandChange,
        defaultValue: brandefaultValue
    });
    // #endregion Brand Dropdown

    return (
    <>
        <KTCard>
            <div className='card-header border-0 pt-6'>
                <div className='card-title'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                        type='text'
                        data-kt-user-table-filter='search'
                        className='form-control form-control-solid w-300px ps-14'
                        placeholder='Search Employee, Transaction...'
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value)
                            setListDataDomain(updateListDataDomain(e.target.value, az_country, az_company, az_brand, az_state))
                        }}
                        />
                    </div>
                    {/* end::Search */}
                </div>
                {/* begin::Card toolbar */}
                <div className='card-toolbar'>
                    {/* begin::Group actions */}
                    {/* begin::Export */}
                    {/* <button type='button' className='btn btn-light-primary me-3'>
                        <KTIcon iconName='exit-up' className='fs-2' />
                        Export
                    </button> */}
                    {/* end::Export */}

                    {/* begin::Add user */}
                    <button type='button' className='btn btn-primary' onClick={()=>navigate('/transactions/manager-assignment/new')}>
                        <KTIcon iconName='plus' className='fs-2' />
                        Add
                    </button>
                    {/* end::Add user */}
                    {/* end::Group actions */}
                </div>
                {/* end::Card toolbar */}
            </div>
            <div className='row m-0'>
                <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                    {/* begin::Label */}
                    <label className='fw-bold fs-6 my-auto col-12 col-md-4'>Status</label>
                    {/* end::Label */}

                    {/* begin::Input */}
                    <Select className='full-width p-1' onChange={handleStateChange} value={az_state} options={modelStatuses} isClearable isSearchable/>
                    {/* end::Input */}
                </div>
                <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                    {/* begin::Label */}
                    <label className='fw-bold fs-6 my-auto col-12 col-md-4'>Country</label>
                    {/* end::Label */}

                    {/* begin::Input */}
                    {az_country_select}
                    {/* end::Input */}
                </div>
                <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                    {/* begin::Label */}
                    <label className='fw-bold fs-6 my-auto col-12 col-md-4'>Company</label>
                    {/* end::Label */}

                    {/* begin::Input */}
                    {az_company_select}
                    {/* end::Input */}
                </div>
                <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                    {/* begin::Label */}
                    <label className='fw-bold fs-6 my-auto col-12 col-md-4'>Brand</label>
                    {/* end::Label */}

                    {/* begin::Input */}
                    {az_brand_select}
                    {/* end::Input */}
                </div>
            </div>
            {table}
        </KTCard>
    </>)
}

export default TransactionList;