import axios, { AxiosResponse } from 'axios'
import { API_URL } from '_azkatech/azkHelper'
import { useAuth } from 'app/modules/auth'
import { Response } from '_metronic/helpers'


const GET_URL = `${API_URL}/`

type NotificationByType = {
  id?: number,
  icon?: string,
  name?: string,
  model?: string,
  pending_count?; number
}
const useNotifications = () => {
  const { currentUser } = useAuth()
  const checkNotifications = () => {
    // Check if user has notifications
    if (currentUser)
      return axios
        .put(`${GET_URL}res.users/${currentUser.id}/review_user_count`)
        .then((response: AxiosResponse<NotificationByType[]>) => response.data)
  };

  const getNotifications = (model_name?: string) => {
    // Get list of notifications
    if (currentUser){
    if(model_name !== 'az.trn.salary.structure'){
      return axios
        .get(`${GET_URL}${model_name}?filters=[('can_review', '=', 'true')]&include_fields=['id', 'name', ('employee_id',('id', 'name', 'azadea_employee_name'))]&notifications=True&l`)
        .then((response: AxiosResponse<Response<any[]>>) => response.data)
        .then((response: Response<any[]>) => response['results'])
    }else {
      return axios
        .get(`${GET_URL}${model_name}?filters=[('can_review', '=', 'true')]&include_fields=['id', 'name', ('location_id',('id', 'name'))]`)
        .then((response: AxiosResponse<Response<any[]>>) => response.data)
        .then((response: Response<any[]>) => response['results'])
    }
  }
  };

  return { checkNotifications, getNotifications };
};
export {useNotifications}