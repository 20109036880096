import { Response } from "_metronic/helpers"

export type TravelRequestLine = {
  id?: number
  departure_date?: any
  departure_city?: any
  arrival_city?: any
  need_hotel?: boolean
  need_visa?: boolean
  purpose?: any
  passport_visa_info?: any
}

export type TravelRequest = {
  id?: number
  name?: string
  employee_id?: any
  type_id?: number
  x_state?: string
  x_state_view?: string
  can_review?: boolean
  can_delete?: boolean
  can_write?: boolean
  validated?: boolean,
  rejected?: boolean,
  state?: string
  az_country_id?: any
  az_company_id?: any
  brand_id?: any
  azadea_id?: number
  azadea_employee_name?: string
  branch_id?: any
  section_id?: any
  grade_id?: any
  job_id?: any
  time_set?: string
  can_edit?: boolean
  restarted?: boolean
  create_date?: any
  comment?: string
  mobile_number?: string
  date_of_birth?: any
  passport_number?: string
  passport_expiry?: any
  smoking?: boolean
  has_credit_card?: boolean
  travel_insurance?: boolean
  additional_remarks?: string
  name_in_passport?: string
  save_personal_data?: boolean

  line_ids: TravelRequestLine[]
}

export const initialTransfaction: TravelRequest = {
  name: '',

  line_ids: [
    {
      departure_city: '',
      arrival_city: '',
      need_hotel: false,
      need_visa:false,
      purpose: '',
      passport_visa_info:'',
      id: 0
    },
    // Add more initial values for additional lines
  ]
}

export type TravelRequestQueryResponse = Response<Array<TravelRequest>>

