export interface AuthModel {
  uid: number
  access_token: string
  expires_in: number
  refresh_token?: string
  refresh_expires_in: number
  company_id?: number
  // pin_code?: number

}

export interface UserAddressModel {
  addressLine: string
  city: string
  state: string
  postCode: string
}

export interface UserCommunicationModel {
  email: boolean
  sms: boolean
  phone: boolean
}

export interface UserEmailSettingsModel {
  emailNotification?: boolean
  sendCopyToPersonalEmail?: boolean
  activityRelatesEmail?: {
    youHaveNewNotifications?: boolean
    youAreSentADirectMessage?: boolean
    someoneAddsYouAsAsAConnection?: boolean
    uponNewOrder?: boolean
    newMembershipApproval?: boolean
    memberRegistration?: boolean
  }
  updatesFromKeenthemes?: {
    newsAboutKeenthemesProductsAndFeatureUpdates?: boolean
    tipsOnGettingMoreOutOfKeen?: boolean
    thingsYouMissedSindeYouLastLoggedIntoKeen?: boolean
    newsAboutStartOnPartnerProductsAndOtherServices?: boolean
    tipsOnStartBusinessProducts?: boolean
  }
}

export interface UserSocialNetworksModel {
  linkedIn: string
  facebook: string
  twitter: string
  instagram: string
}

export interface UserModel {
  id: number
  login: string
  name: string
  image_128?: string
  groups_id?: Array<number>
  partner_id?: {
    id: number
    email: string
    is_company: boolean
    company_type?: string
    parent_id?: number
  }
  company_id?: {
    id: number
    name: string
    logo?: string
  }
  auth?: AuthModel
}


export class ReportIssue {
  url?: string
  method?: string
  error_data?: string
  payload_data?: string
  comment?: string
}
