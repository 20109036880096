import { PageLink, PageTitle } from '_metronic/layout/core'
import { Outlet, Route, Routes } from 'react-router-dom'
import TransactionAppform from './app-form'

const transactionBreadCrumbs: Array<PageLink> = [
  {
    title: 'Payslip',
    path: '/app/self-service/payslip',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Payslip',
    path: '/app/self-service/payslip',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]


const PayslipAPPRouter = () => (
  <Routes>
    <Route
      element={
        <>
          <Outlet />
        </>
      }
    >
      <Route
        path=''
        element={
          <>
            <PageTitle breadcrumbs={transactionBreadCrumbs}>List</PageTitle>
            <TransactionAppform />
          </>
        }
      />
    </Route>
  </Routes>
)

export default PayslipAPPRouter
