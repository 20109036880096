import { GrouphModel } from "_azkatech/_models/_group"
import { Response } from "_metronic/helpers"
import { AZKHelper } from "app/modules/components/azkHelper"
import moment from "moment"

export type ClearanceElement={
  id?: number
  creation_info?: string,
  group_id?: any,
  az_country_id?: any
  element_id?: any
  amount?: number
  comment?: string,
  override_amount?: number
  override_comment?: string,
  pay_in_payroll?: boolean
}

export type ClearanceGroupNodues={
  id?: number
  group_id?: any,
  az_country_id?: any
  no_dues?: boolean
}

export type Clearance = {
  id?: number
  name?: string
  employee_id?: any
  type_id?: number
  x_state?: string
  x_state_view?: string
  can_review?: boolean
  can_delete?: boolean
  can_write?: boolean
  validated?: boolean, 
  rejected?: boolean,
  state?: string
  az_country_id?: any
  az_company_id?: any
  payroll_id?: any
  brand_id?: any
  azadea_id?: number
  azadea_employee_name?: string
  branch_id?: any
  section_id?: any
  grade_id?: any
  job_id?: any
  salary?: string
  as_effective_date?: string
  time_set?: string
  target_as_effective_date?: string
  can_edit?: boolean
  restarted?: boolean
  allowed_group_ids?: GrouphModel[]
  can_override?: boolean
  separation_trn_id?: any
  element_ids?: ClearanceElement[]
  clearance_group_nodue?: ClearanceGroupNodues[]
  element_ids_view_owner?: ClearanceElement[]
  current_approval_ids?: number[]
}

export const initialTransaction: Clearance = {
  name: '',
  as_effective_date: moment().format(AZKHelper.FRONTEND_DATE_FORMAT),
  target_as_effective_date:  moment().format(AZKHelper.FRONTEND_DATE_FORMAT),
}

export type ClearanceQueryResponse = Response<Array<Clearance>>

export const initialTransferCompany: Clearance = {

}
