import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import TransferRouter from 'app/modules/transactions/transfer/transferRouter'
import TransferWithinCompanyRouter from 'app/modules/transactions/transfer-in-same-company/transactionRouter'
import PromotionRouter from 'app/modules/transactions/promotion/transactionRouter'
import DemotionRouter from 'app/modules/transactions/demotion/transactionRouter'
import ChangeEmploymentCategoryRouter from 'app/modules/transactions/change-employment-category/transactionRouter'
import AdditionDeductionRouter from 'app/modules/transactions/addition-deduction/transactionRouter'
import ManagerAssignmentRouter from 'app/modules/transactions/manager-assignment/transactionRouter'
import SeparationRouter from 'app/modules/transactions/separation/transactionRouter'
import ClearanceRouter from 'app/modules/transactions/clearance/transactionRouter'
import SalaryStructureSettingRouter from 'app/modules/transactions/salary-structure-setting/transactionRouter'
import SalaryStructureRouter from 'app/modules/transactions/salary-structure/transactionRouter'
import TransactionListRouter from 'app/modules/transactions/transaction-list/transactionRouter'
import FinalSettlementRouter from 'app/modules/transactions/final-settlement/transactionRouter'
import LeaveRouter from 'app/modules/transactions/leave-submission/transactionRouter'
import LeaveAPPRouter from 'app/modules/transactions/leave-submission/transactionAPPRouter'
import PayslipAPPRouter from 'app/modules/transactions/payslip/transactionAPPRouter'
import PayslipRouter from 'app/modules/transactions/payslip/transactionRouter'
import SelfServiceRouter from 'app/modules/transactions/self-service/transactionRouter'
import SelfServiceAPPRouter from 'app/modules/transactions/self-service/transactionAPPRouter'
import AttendanceAdjustmentRouter from 'app/modules/transactions/attendance-adjustment/transactionRouter'
import AttendanceAdjustmentAPPRouter from 'app/modules/transactions/attendance-adjustment/transactionAPPRouter'
import ExitInterViewRequestAPPRouter from 'app/modules/transactions/exit-interview-request/transactionAPPRouter'
import ExitInterViewRequestRouter from 'app/modules/transactions/exit-interview-request/transactionRouter'
import TravelRequestAPPRouter from 'app/modules/transactions/travel-request/transactionAPPRouter'
import TravelRequestRouter from 'app/modules/transactions/travel-request/transactionRouter'
import TravelPerDiemRouter from 'app/modules/transactions/travel-per-diem/transactionRouter'
// import SelfServiceRouter from 'app/modules/transactions/self-service/transactionRouter'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const TransactionPage = lazy(() => import('../modules/transactions/TransactionPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        
        
        <Route
          path='home/*'
          element={
            <SuspensedView>
              <TransactionListRouter />
            </SuspensedView>
          }
        />
        <Route
          path='transactions/*'
          element={
            <SuspensedView>
              <TransactionListRouter />
            </SuspensedView>
          }
        />
        <Route
          path='transactions/transfer-company/*'
          element={
            <SuspensedView>
              <TransferRouter />
            </SuspensedView>
          }
        />
        <Route
          path='transactions/transfer-within-company/*'
          element={
            <SuspensedView>
              <TransferWithinCompanyRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='transactions/promotion/*'
          element={
            <SuspensedView>
              <PromotionRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='transactions/demotion/*'
          element={
            <SuspensedView>
              <DemotionRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='transactions/change-employment-category/*'
          element={
            <SuspensedView>
              <ChangeEmploymentCategoryRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='transactions/addition-deduction/*'
          element={
            <SuspensedView>
              <AdditionDeductionRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='transactions/manager-assignment/*'
          element={
            <SuspensedView>
              <ManagerAssignmentRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='transactions/separation/*'
          element={
            <SuspensedView>
              <SeparationRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='transactions/clearance/*'
          element={
            <SuspensedView>
              <ClearanceRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='transactions/final-settlement/*'
          element={
            <SuspensedView>
              <FinalSettlementRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='salary-structure/settings/*'
          element={
            <SuspensedView>
              <SalaryStructureSettingRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='salary-structure/records/*'
          element={
            <SuspensedView>
              <SalaryStructureRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='self-service/leave-submission/*'
          element={
            <SuspensedView>
              <LeaveRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='self-service/leave-submission/*'
          element={
            <SuspensedView>
              <LeaveRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='app/self-service/leave-submission/*'
          element={
            <SuspensedView>
              <LeaveAPPRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='self-service/payslip/*'
          element={
            <SuspensedView>
              <PayslipRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='app/self-service/payslip/*'
          element={
            <SuspensedView>
              <PayslipAPPRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='self-service/service/*'
          element={
            <SuspensedView>
              <SelfServiceRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='app/self-service/service/*'
          element={
            <SuspensedView>
              <SelfServiceAPPRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='self-service/attendance/*'
          element={
            <SuspensedView>
              <AttendanceAdjustmentRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='app/self-service/attendance/*'
          element={
            <SuspensedView>
              <AttendanceAdjustmentAPPRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='self-service/exit-interview/*'
          element={
            <SuspensedView>
              <ExitInterViewRequestRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='app/self-service/exit-interview/*'
          element={
            <SuspensedView>
              <ExitInterViewRequestAPPRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='self-service/travel-request/*'
          element={
            <SuspensedView>
              <TravelRequestRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='app/self-service/travel-request/*'
          element={
            <SuspensedView>
              <TravelRequestAPPRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='self-service/travel-per-diem/*'
          element={
            <SuspensedView>
              <TravelPerDiemRouter/>
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
