import AzkSelect from "_azkatech/azkSelect";
import { EmployeeDetail, EmpoyeeModel } from "app/modules/components/employeeDetail/employeeDetail";
import * as Yup from 'yup'
import { TravelPerDiem, TravelPerDiemLine, TravelPerDiemLineDetail, initialTransfaction } from "./model";
import { FC, useEffect, useRef, useState } from "react";
import { ErrorMessage, Field, FieldArray, Formik, FormikProps, FormikValues } from 'formik'
import { CreateTravelPerDiem, UpdateTravelPerDiem, GetTravelPerDiemById, MODEL_NAME, GetTravelSettingById } from "./_requests";
import "react-datepicker/dist/react-datepicker.css";
import { AzkStatusRibbon } from "_azkatech/azkStatusRibbon";
import AzkActionButtons from "_azkatech/azkActionButtons";
import { Form, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { ID, isNotEmpty, KTIcon } from "_metronic/helpers";
import { useNavigate } from "react-router";
import { API_URL } from "_azkatech/azkHelper";
import { AZKHelper } from "app/modules/components/azkHelper";
import { AzkChatter } from "_azkatech";
import { checkTrnsactionTypeAccessId } from "app/utilities/transactionTyperequests";
import { useParams } from "react-router-dom";
import { GetEmployeeByIdNoSalary, GetEmployeeByUserId } from "app/modules/components/employeeDetail/_requests";
import { AUTH_LOCAL_STORAGE_KEY, AuthModel } from "app/modules/auth";
import moment from "moment";
import PerDiemModal from "./per-diem-form";
import PerDiemDetailModal from "./per-diem-detail-form";
import { GetTravelRequestById } from "../travel-request/_requests";

type Props = {
}
const editTransactionSchema = Yup.object().shape({
    employee_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .test('rquired', 'One field must be filled', function (value) {
            let travel_request_id = Number.isNaN(this.parent['travel_request_id']) ? undefined : this.parent['travel_request_id']
            let traveler_name = this.parent['traveler_name']
            if (traveler_name === undefined || traveler_name === null) {
                traveler_name = undefined
            } else {
                traveler_name = traveler_name.trim()
            }
            return value !== undefined || travel_request_id !== undefined || (traveler_name !== '' && traveler_name !== undefined);
        }),
    setting_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Country/company is required'),
    subject: Yup.string().trim().required('Subject is required'),
    date: Yup.date().transform((value) => !moment(value).isValid() ? undefined : value).required('Date is required'),
    // .min(new Date(), 'Date from must be after today'),
    line_ids: Yup.array().of(
        Yup.object().shape({
            // date: Yup.date().transform((value) => !moment(value).isValid() ? undefined : value).required('Departure date is required')
            //     .min(new Date(), 'Date from must be after today'),
            // departure_city: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value).required('Departure city from is required'),
            // arrival_city: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value).required('Arrival city from is required'),
        })
    )
})

const Transactionform: FC<Props> = () => {
    const { id } = useParams();
    const Swal = require('sweetalert2')
    const intl = useIntl();
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<TravelPerDiem>>(null);
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [addNewEntry, setAddNewEntry] = useState<boolean>(false);
    const [transactionClosed, setTransactionClosed] = useState<boolean>(false);
    const [canValidate, setCanValidate] = useState<boolean>(false);
    const [employee, setEmployee] = useState<EmpoyeeModel>();
    const [transactionState, setTransactionState] = useState<string>('draft');
    const [transactionStateName, setTransactionStateName] = useState<string>('Draft');
    const [hitSend, setHitSend] = useState<boolean>(false);
    const [objectId, setObjectId] = useState<number>();
    const [euroPerDiem, setEuroPerDiem] = useState<number>(0);
    const [perDiem, setPerDiem] = useState<number>(0);

    const [lineDataForEdit, setLineDataForEdit] = useState<TravelPerDiemLine>();
    const [lineDataForDetails, setLineDataForDetails] = useState<TravelPerDiemLine>();
    const [lineDataForEditIndex, setLineDataForEditIndex] = useState<number>();

    const [employeeDomain, setEmployeeDomain] = useState<string>('');

    const [totalEuroPerDiem, setTotalEuroPerDiem] = useState<number>();
    const [totalPerDiem, setTotalPerDiem] = useState<number>();
    const [totalDays, setTotalDays] = useState<number>();

    const editLineDetails = async (line_value, line_index) => {
        let lines_data: TravelPerDiemLine[] = formRef?.current?.getFieldProps('line_ids').value || []
        await lines_data.map((line, index) => {
            if (line_index === index) {
                line.detail_ids = line_value['detail_ids']
                lines_data[index] = line
            }
        });
        calculatePerdiemValues(lines_data)
    };

    const editLine = async (lines_value, index) => {
        const raw_lines = lines_value['booked_travel']
        if (raw_lines && raw_lines !== undefined) {
            const lines = raw_lines.trim().split('\n');
            let lines_data: TravelPerDiemLine[] = formRef?.current?.getFieldProps('line_ids').value || []
            await lines.map((line) => {
                let travelPerDiemLine: TravelPerDiemLine = {}
                const parts = line.trim().split(/\s+/);
                travelPerDiemLine.booked_travel = line
                travelPerDiemLine.line_sequence = lines_data[index].line_sequence
                travelPerDiemLine.id = lines_data[index].id
                travelPerDiemLine.line_sequence = parts[0]
                travelPerDiemLine.flight_number = parts[1] + ' ' + parts[2]
                travelPerDiemLine.flight_date = parts[4]
                travelPerDiemLine.departure_city = parts[6]
                travelPerDiemLine.arrival_city = parts[7]
                travelPerDiemLine.departure_time = parts[8]
                travelPerDiemLine.arrival_time = parts[9]
                travelPerDiemLine.is_euro_per_diem = lines_value['is_euro_per_diem']
                travelPerDiemLine.perdiem_x = lines_value['perdiem_x']
                travelPerDiemLine.comment = lines_value['comment']
                const dateStr = parts[4];
                const day = parseInt(dateStr.slice(0, 2));
                const month = dateStr.slice(2, 5);
                //   const year = month === 'DEC' && day > 25 ? 2023 : 2024; // Adjust year for December dates
                const year = 2024; // Assuming the year is 2024
                const monthIndex = new Date(`${month} 1, ${year}`).getMonth();
                const date = new Date(year, monthIndex, day)
                travelPerDiemLine.date = moment(date).format(AZKHelper.BACKEND_DATE_FORMAT)
                lines_data[index] = travelPerDiemLine
            });
            calculatePerdiemValues(lines_data)
            setLineDataForEditIndex(undefined)
        }
    };

    const createLines = async (lines_value) => {
        const raw_lines = lines_value['booked_travel']
        if (raw_lines && raw_lines !== undefined) {
            const lines = raw_lines.trim().split('\n');
            let lines_data: TravelPerDiemLine[] = formRef?.current?.getFieldProps('line_ids').value || []
            await lines.map((line) => {
                let travelPerDiemLine: TravelPerDiemLine = {}
                const parts = line.trim().split(/\s+/);
                travelPerDiemLine.line_sequence = parts[0]
                travelPerDiemLine.booked_travel = line
                travelPerDiemLine.flight_number = parts[1] + ' ' + parts[2]
                travelPerDiemLine.flight_date = parts[4]
                travelPerDiemLine.departure_city = parts[6]
                travelPerDiemLine.arrival_city = parts[7]
                travelPerDiemLine.departure_time = parts[8]
                travelPerDiemLine.arrival_time = parts[9]
                travelPerDiemLine.is_euro_per_diem = lines_value['is_euro_per_diem']
                travelPerDiemLine.perdiem_x = lines_value['perdiem_x']
                travelPerDiemLine.comment = lines_value['comment']
                const dateStr = parts[4];
                const day = parseInt(dateStr.slice(0, 2));
                const month = dateStr.slice(2, 5);
                //   const year = month === 'DEC' && day > 25 ? 2023 : 2024; // Adjust year for December dates
                const year = 2024; // Assuming the year is 2024
                const monthIndex = new Date(`${month} 1, ${year}`).getMonth();
                const date = new Date(year, monthIndex, day)
                travelPerDiemLine.date = moment(date).format(AZKHelper.BACKEND_DATE_FORMAT)
                lines_data.push(travelPerDiemLine)
            });
            calculatePerdiemValues(lines_data)
        }
    };

    const calculatePerdiemValues = async (lines_data: TravelPerDiemLine[]) => {
        let total_euro_per_diem = 0
        let total_per_diem = 0
        let total_days = 0
        await lines_data.map((line, index) => {
            line.days = 0
            line.euro_per_diem = 0
            line.per_diem = 0
            if (index > 0) {
                lines_data[index - 1].days = moment(line.date).diff(lines_data[index - 1].date, 'day') + 1
                if (!lines_data[index - 1].detail_ids) {
                    if (lines_data[index - 1].days > 0) {
                        if (lines_data[index - 1].is_euro_per_diem) lines_data[index - 1].euro_per_diem = lines_data[index - 1].days * euroPerDiem * lines_data[index - 1].perdiem_x
                        else lines_data[index - 1].per_diem = lines_data[index - 1].days * perDiem * lines_data[index - 1].perdiem_x
                    } else {
                        lines_data[index - 1].days = 0
                        lines_data[index - 1].euro_per_diem = 0
                        lines_data[index - 1].per_diem = 0
                    }
                } else {
                    lines_data[index - 1].euro_per_diem = 0
                    lines_data[index - 1].per_diem = 0
                    lines_data[index - 1].detail_ids?.map(detail => {
                        if (detail.is_euro_per_diem) lines_data[index - 1].euro_per_diem = lines_data[index - 1].euro_per_diem + (euroPerDiem * detail.perdiem_x)
                        else lines_data[index - 1].per_diem = lines_data[index - 1].per_diem + (perDiem * detail.perdiem_x)
                    })
                }
                total_euro_per_diem = total_euro_per_diem + lines_data[index - 1].euro_per_diem
                total_per_diem = total_per_diem + lines_data[index - 1].per_diem
                total_days = total_days + lines_data[index - 1].days
            }
        })
        formRef?.current?.setFieldValue('line_ids', lines_data)
        setAddNewEntry(false)
        setLineDataForEdit(undefined)
        setLineDataForEditIndex(undefined)
        setTotalEuroPerDiem(total_euro_per_diem)
        setTotalPerDiem(total_per_diem)
        setTotalDays(total_days)
    }

    const handleDeleteElement = async (line: TravelPerDiemLine, index: number) => {
        let lines_data: TravelPerDiemLine[] = formRef.current?.getFieldProps('line_ids').value
        if (!lines_data) lines_data = []
        // replace the element object
        lines_data = await lines_data.filter((d, i) => i !== index);
        calculatePerdiemValues(lines_data)
    }

    const checkUserType = () => {
        let lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
        if (lsValue) {
            const auth: AuthModel = JSON.parse(lsValue) as AuthModel
            if (auth && auth.uid) {
                GetEmployeeByUserId(auth.uid).then(response => {
                    if (response) {
                        let domain = ``
                        // if (response.is_back_office_fr && response.is_back_office_fr !== null) setShopUser(!response?.is_back_office_fr)
                        // if (response.is_manager_fr) setMangerUser(response?.is_manager_fr)
                        // if (response.is_manager_fr && response.is_back_office_fr) {
                        //     domain = domain.concat(`'|', ('id', '=', ${response.id})`)
                        // }
                        // if (!response.is_manager_fr && !response.is_back_office_fr) {
                        //     domain = domain.concat(`('id', '=', ${response.id})`)
                        // }
                        // if (!response.is_manager_fr && response.is_back_office_fr) {
                        //     domain = domain.concat(`('id', '=', ${response.id})`)
                        // }
                        // if (response.is_manager_fr && !response.is_back_office_fr) {
                        //     domain = domain.concat(`'|', '|', ('id', '=', ${response.id}), ('parent_id', '=', ${response.id}), ('super_parent_id', '=', ${response.id})`)
                        // } else {
                        //     domain = domain.concat(`('id', '=', ${response.id})`)
                        // }
                        // setEmployeeDomain(domain)
                    }
                })
            }
        }
    }


    const handleGetTravelSettingChange = async function (settingId): Promise<{}> {
        if (settingId) {
            GetTravelSettingById(settingId).then(response => {
                if (response) {
                    setEuroPerDiem(response['euro_per_diem'])
                    setPerDiem(response['per_diem'])
                    formRef.current?.setFieldValue('euro_per_diem', response['euro_per_diem'])
                    formRef.current?.setFieldValue('per_diem', response['per_diem'])
                }
            })
        }
        return true
    }
    const handleTravelReqeustChange = async function (requestId, TravelPerDiemData): Promise<{}> {
        if (requestId) {
            document.body.classList.add('page-loading')
            formRef.current?.setFieldValue('employee_id', undefined)
            setEmployee(undefined)
            GetTravelRequestById(requestId).then(reqeustResponse => {
                if (reqeustResponse) {
                    GetEmployeeByIdNoSalary(reqeustResponse.employee_id.id).then(response => {
                        if (response) {
                            const tempEmployee = response
                            setEmployee(response)
                            formRef.current?.setValues({
                                travel_request_id: requestId,
                                az_country_id: tempEmployee.az_country_id?.id,
                                az_company_id: tempEmployee.az_company_id?.id,
                                branch_id: tempEmployee.branch_id?.id,
                                brand_id: tempEmployee.brand_id?.id,
                                grade_id: tempEmployee.grade_id?.id,
                                section_id: tempEmployee.section_id?.id,
                                job_id: tempEmployee.job_id?.id,
                                // ---------------------------------------------------
                                date: moment().format(AZKHelper.FRONTEND_DATE_FORMAT),
                                line_ids: []
                            })
                            if (TravelPerDiemData) {
                                pobulateFormData(TravelPerDiemData)
                            } else {
                            }
                            document.body.classList.remove('page-loading')
                        } else {
                            document.body.classList.remove('page-loading')
                        }
                    });
                }
            })
        }
        return true
    }

    const handleEmployeeChange = async function (employeeId, TravelPerDiemData): Promise<{}> {
        document.body.classList.add('page-loading')
        if (employeeId) {
            formRef.current?.setFieldValue('travel_request_id', undefined)
            setEmployee(undefined)
            GetEmployeeByIdNoSalary(employeeId).then(response => {
                if (response) {
                    const tempEmployee = response
                    setEmployee(response)
                    formRef.current?.setValues({
                        employee_id: tempEmployee.id,
                        az_country_id: tempEmployee.az_country_id?.id,
                        az_company_id: tempEmployee.az_company_id?.id,
                        branch_id: tempEmployee.branch_id?.id,
                        brand_id: tempEmployee.brand_id?.id,
                        grade_id: tempEmployee.grade_id?.id,
                        section_id: tempEmployee.section_id?.id,
                        job_id: tempEmployee.job_id?.id,
                        // ---------------------------------------------------
                        date: moment().format(AZKHelper.FRONTEND_DATE_FORMAT),
                        line_ids: []
                    })
                    if (TravelPerDiemData) {
                        pobulateFormData(TravelPerDiemData)
                    } else {
                    }
                    document.body.classList.remove('page-loading')
                } else {
                    document.body.classList.remove('page-loading')
                }
            });
        } else {
            document.body.classList.remove('page-loading')
            formRef.current?.resetForm();
            setEmployee(undefined)
            formRef.current?.setValues({
                employee_id: undefined,
                az_country_id: undefined,
                az_company_id: undefined,
                branch_id: undefined,
                brand_id: undefined,
                grade_id: undefined,
                section_id: undefined,
                job_id: undefined,
                time_set: undefined,
                date: moment().format(AZKHelper.FRONTEND_DATE_FORMAT),
                line_ids: []
            })
        }
        return true
    }

    const pobulateFormData = async (data?: TravelPerDiem) => {
        if (data) {
            setEuroPerDiem(data.euro_per_diem)
            setPerDiem(data.per_diem)
            formRef.current?.setValues({
                id: data.id,
                employee_id: data.employee_id?.id,
                setting_id: data.setting_id?.id,
                travel_request_id: data.travel_request_id?.id,
                traveler_name: data.traveler_name,
                euro_per_diem: data.euro_per_diem,
                per_diem: data.per_diem,
                az_country_id: data.az_country_id?.id,
                az_company_id: data.az_company_id?.id,
                branch_id: data.branch_id?.id,
                brand_id: data.brand_id?.id,
                grade_id: data.grade_id?.id,
                section_id: data.section_id?.id,
                job_id: data.job_id?.id,
                time_set: data.time_set,
                azadea_employee_name: data.azadea_employee_name,
                date: moment(data.date).format(AZKHelper.BACKEND_DATE_FORMAT),
                subject: data.subject,
                line_ids: data?.line_ids,
            })
            setTotalEuroPerDiem(data?.total_euro_per_diem)
            setTotalPerDiem(data?.total_per_diem)
            setTotalDays(data?.total_days)
        }
    }

    const checkPermission = async (action: string) => {
        const allowed = await checkTrnsactionTypeAccessId(MODEL_NAME, action)
        if (!allowed) {
            if (id) {
                const allowed_read = await checkTrnsactionTypeAccessId(MODEL_NAME, 'read')
                if (!allowed_read) {
                    navigate(`/self-service/travel-per-diem/`, { state: {}, replace: true });
                    Swal.fire({
                        position: "top-right",
                        title: 'Access Error!',
                        text: 'You does not have access to perform read action!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                } else {
                    setReadOnly(true)
                }
            } else {
                navigate(`/self-service/travel-per-diem/`, { state: {}, replace: true });
                Swal.fire({
                    position: "top-right",
                    title: 'Access Error!',
                    text: 'You does not have access to perform this action!',
                    icon: "error",
                    showConfirmButton: false,
                    timer: AZKHelper.SWAL_TOAST_TIMER,
                    toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
            }
        }
    }

    // const fetchPerDiemAmounts = (employee_id, country_id, company_id) => {
    //     axios.put(`${API_URL}/hr.employee/${parseInt(employee_id)}/search_per_diem_setting`, { 'country_id': country_id, 'company_id': company_id }).then(response => {
    //         if (response && response.data && response.data[0]) {
    //             setEuroPerDiem(response.data[0]['euro_per_diem'])
    //             setPerDiem(response.data[0]['per_diem'])
    //         }
    //     })
    // }

    useEffect(() => {
        checkUserType()
        document.body.classList.add('page-loading')
        formRef.current?.resetForm()
        setObjectId(undefined)
        setCanValidate(false)
        if (id) {
            setObjectId(parseInt(id))
            checkPermission('write');
            document.body.classList.add('page-loading')
            GetTravelPerDiemById(parseInt(id)).then(((data: TravelPerDiem) => {
                if (data?.employee_id?.id) handleEmployeeChange(data?.employee_id?.id, data)
                if (data?.travel_request_id?.id) handleTravelReqeustChange(data?.travel_request_id?.id, data)
                if (data?.x_state && data?.x_state_view) {
                    setTransactionState(data?.x_state)
                    setTransactionStateName(data?.x_state_view)
                }
                if (data?.can_write && data?.can_write !== null) {
                    setCanEdit(true)
                }
                // setTransactionClosed(data.validated || data.rejected ? true : false)
                // setCanValidate(data?.can_review ? true : false)
            }))
        } else {
            checkPermission('create');
            formRef.current?.setFieldValue('employee_id', undefined)
            handleEmployeeChange(undefined, undefined)
            setCanEdit(true)
            document.body.classList.remove('page-loading')
        }
    }, [id])


    const cancel = () => {
        navigate(`/self-service/travel-per-diem/`, { state: {}, replace: true });
    }


    const handleResultOfsubmit = (transactionId: ID | undefined, isUpdate: boolean | false) => {
        document.body.classList.remove('page-loading')
        navigate(`/self-service/travel-per-diem/`, { state: {}, replace: true })
        Swal.fire({
            position: "top-right",
            title: 'Success!',
            text: isUpdate ? 'Your transaction had been updated!' : 'Your transaction had been saved!',
            icon: "success",
            showConfirmButton: false,
            timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
            toast: true,
            iconColor: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            timerProgressBar: true
        })
    }


    const fixValues = async (values: TravelPerDiem) => {
        if (values.line_ids) {
            await values.line_ids.forEach(async line => {
                line.id = undefined
                if (line.detail_ids) {
                    await line.detail_ids.forEach(detail => {
                        detail.id = undefined
                    })
                }
            })
            return values
        } else {
            return values
        }
    }
    const onSubmitHandler = async (values: TravelPerDiem, actions: FormikValues) => {
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        if (formRef.current?.isValid) {
            values.total_euro_per_diem = totalEuroPerDiem
            values.total_per_diem = totalPerDiem
            values.total_days = totalDays
            fixValues(values).then(async result => {
                if (result) {
                    // values['self_service_field'] = result['fieldsValue']
                    document.body.classList.add('page-loading')
                    if (isNotEmpty(values.id)) {
                        await UpdateTravelPerDiem(result).then(data => {
                            if (id) handleResultOfsubmit(parseInt(id), true)
                        })
                    } else {
                        await CreateTravelPerDiem(result).then(data => {
                            setObjectId(data?.id)
                            setHitSend(true)
                            if (data?.id) handleResultOfsubmit(data?.id, false)
                            else document.body.classList.remove('page-loading')
                        })
                    }
                }
            })

        } else {
            actions.setSubmitting(false)
            formRef.current?.validateForm().then(errors => {
                if (Object.keys(errors).length > 0) {
                    console.log(errors)
                    //   setStatus('error');
                }
            });
        }
    }

    const loadTypeOptions = async (search, loadedOptions) => {
        const options: any[] = []
        options.push({ value: 'support', label: 'Support' })
        options.push({ value: 'training', label: 'Training' })
        const filteredOptions = options.filter((option) =>
            option.label.toString().includes(search.toLowerCase())
        );
        return {
            options: filteredOptions,
            hasMore: false,
        };
    };

    return (
        <>
            <Row className="m-0 my-2">
                <Col md='12' lg={id ? '9' : '9'} xl={id ? '9' : '9'}>
                    <Formik
                        innerRef={formRef}
                        validationSchema={editTransactionSchema}
                        onSubmit={onSubmitHandler}
                        initialValues={initialTransfaction}
                        validateeOnChange={true}
                        validateOnMount={true}
                        initialErrors={''}
                    >
                        {({ values, setFieldValue, handleSubmit, isSubmitting, validateForm }) => {
                            // ,validateForm,isSubmitting, errors, touched, isValidating
                            return (
                                <>
                                    {/* {id && (<AzkStatusBar transactionId={id} modelName={MODEL_NAME}
                                        current={transactionState} />
                                    )} */}
                                    {canValidate && id &&
                                        <AzkActionButtons
                                            backLink="/self-service/travel-per-diem/"
                                            actionUrl={`${API_URL}/${MODEL_NAME}/${id}/`}
                                            thenNavigate="/self-service/travel-per-diem/"
                                            divStyle="card py-2 mb-3"
                                            buttons={[
                                                { title: "Approve", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.APPROVED' }), method: "validate_tier", confirmMsg: "Approve Transaction, Confirm?", cssClass: "btn btn-light btn-light-success", icon: "fa-thumbs-up" },
                                                { title: "Review", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.REVIEW' }), method: "restart_validation", confirmMsg: "Send Transaction back to draft, Confirm?", cssClass: "btn btn-light btn-light-warning", icon: "fa-refresh" },
                                                { title: "Decline", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.DECLINE' }), method: "reject_tier", confirmMsg: "Decline Transaction, Confirm?", cssClass: "btn btn-light btn-light-danger", icon: "fa-times" },
                                            ]} />}
                                    <Form onSubmit={handleSubmit} noValidate>
                                        <fieldset disabled={!canEdit || readOnly}>
                                            {!id && (
                                                <Row className="card m-0 py-2 mb-3">
                                                    <Col>
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label>
                                                                <h4 className="px-3 m-0 custom-employee-header justify-content-start">
                                                                    <i className="fa fa-magnifying-glass" aria-hidden="true"></i>{intl.formatMessage({ id: 'TRANSACTION.EMPLOYEE' })}
                                                                </h4>
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Row className="m-0 p-0">
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label className="required">
                                                                    Employee
                                                                </Form.Label>
                                                                <AzkSelect
                                                                    compID="azk_temp"
                                                                    modelName="hr.employee"
                                                                    valueField="id"
                                                                    titleField="display_name_fr"
                                                                    dataDomain={employeeDomain}
                                                                    allowEmpty={true}
                                                                    fieldName='employee_id'
                                                                    onChange={(value) => {
                                                                        setFieldValue('employee_id', parseInt(value))
                                                                        if (value && value != null) {
                                                                            handleEmployeeChange(value, undefined)
                                                                            setFieldValue('traveler_name', '')
                                                                            setFieldValue('travel_request_id', null)
                                                                        }
                                                                    }}
                                                                    defaultValue={values.employee_id}
                                                                    disabled={!canEdit || (values.travel_request_id !== null && values.travel_request_id !== undefined && !Number.isNaN(values.travel_request_id)) || (values.traveler_name !== undefined && values.traveler_name !== '')}
                                                                    searchFields={['name', 'azadea_id', 'branch_id', 'job_id', 'work_email', 'first_name']} />
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='employee_id' />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label className="required">
                                                                    Travel Request
                                                                </Form.Label>

                                                                <AzkSelect
                                                                    compID="azk_temp_travel_reqeuest"
                                                                    modelName="az.trn.travel.request"
                                                                    valueField="id"
                                                                    titleField="employee_name"
                                                                    dataDomain={`('validated', '=', True)`}
                                                                    allowEmpty={true}
                                                                    fieldName='travel_request_id'
                                                                    onChange={(value) => {
                                                                        setFieldValue('travel_request_id', parseInt(value))
                                                                        if (value && value != null) {
                                                                            handleTravelReqeustChange(value, undefined)
                                                                            setFieldValue('traveler_name', '')
                                                                            setFieldValue('employee_id', undefined)
                                                                        }
                                                                    }}
                                                                    defaultValue={values.travel_request_id}
                                                                    disabled={!canEdit || (values.employee_id !== null && values.employee_id !== undefined && !Number.isNaN(values.employee_id)) || (values.traveler_name !== undefined && values.traveler_name !== '')}
                                                                    searchFields={['name', 'employee_name']} />
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='employee_id' />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label className="required">
                                                                    Traveler Name
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className="p-1"
                                                                    id="traveler_name"
                                                                    name="traveler_name"
                                                                    key="traveler_name"
                                                                    onBlur={(value) => {
                                                                        setFieldValue('traveler_name', value.target.value)
                                                                        if (value.target.value && value.target.value.trim() != '') {
                                                                            setFieldValue('travel_request_id', '')
                                                                            setFieldValue('employee_id', undefined)
                                                                        }
                                                                    }}
                                                                    disabled={!canEdit || (values.employee_id !== null && values.employee_id !== undefined && !Number.isNaN(values.employee_id)) || (values.travel_request_id !== null && values.travel_request_id !== null && !Number.isNaN(values.travel_request_id))}
                                                                    defaultValue={values.traveler_name}
                                                                />
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='employee_id' />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            )}
                                        </fieldset>
                                        {employee && (
                                            <>
                                                <EmployeeDetail employee={employee} />
                                            </>)}
                                        {(employee || values.traveler_name) && (
                                            <Row className="m-0 card">
                                                <h4 className="py-2 px-6 m-0 card-header custom-card-header justify-content-start">
                                                    <i className="fa fa-info-circle" aria-hidden="true"></i>Travel Per Diem Details</h4>
                                                <fieldset >
                                                    {/* disabled={!canEdit || readOnly} */}
                                                    <Row className="m-0 my-2">
                                                        <Col lg="8" md="8" sm="6" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label className="required">
                                                                    Subject
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className='p-1'
                                                                    name="subject"
                                                                    id="subject"
                                                                    onChange={(value) => {
                                                                        setFieldValue('subject', value.target.value)
                                                                    }}
                                                                    disabled={!canEdit}
                                                                    defaultValue={values.subject}
                                                                />
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='subject' />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label className="required">
                                                                    Date
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className='p-1'
                                                                    type='date'
                                                                    name="date"
                                                                    id="date"
                                                                    onChange={(value) => {
                                                                        setFieldValue('date', moment(value.target.value).format(AZKHelper.BACKEND_DATE_FORMAT))
                                                                    }}
                                                                    disabled={!canEdit}
                                                                    min={moment().format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                    value={moment(values.date).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                />
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='date' />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label className="required">
                                                                    Country/company
                                                                </Form.Label>
                                                                <AzkSelect
                                                                    compID="azk_temp_travel_setting"
                                                                    modelName="az.travel.request.setting"
                                                                    valueField="id"
                                                                    titleField="name"
                                                                    dataDomain={``}
                                                                    allowEmpty={true}
                                                                    fieldName='setting_id'
                                                                    onChange={(value) => {
                                                                        setFieldValue('setting_id', parseInt(value))
                                                                        handleGetTravelSettingChange(value)
                                                                    }}
                                                                    defaultValue={values.setting_id}
                                                                    disabled={!canEdit}
                                                                    searchFields={['name']} />
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='setting_id' />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="m-0 my-2">
                                                        <div className=''> {/* table-responsive */}
                                                            <table
                                                                id='kt_table_users'
                                                                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                                                <thead>
                                                                    <tr className='text-start text-muted fw-bold fs-8 text-uppercase gs-0'>
                                                                        <th>Details</th>
                                                                        <th>Perdiem X</th>
                                                                        <th>Days</th>
                                                                        <th>AMT USD</th>
                                                                        <th>AMT EUR</th>
                                                                        <th>Details</th>
                                                                        <th>Edit</th>
                                                                        <th>Delete</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className='text-gray-600 fs-8'>
                                                                    <FieldArray name="line_ids">
                                                                        {({ push, remove }) => (
                                                                            <>
                                                                                {values.line_ids.map((line, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>
                                                                                            {line.booked_travel}
                                                                                            {/* moment(value.target.value).format(AZKHelper.FRONTEND_DATE_FORMAT) */}
                                                                                        </td>
                                                                                        <td>
                                                                                            {line.perdiem_x}
                                                                                        </td>
                                                                                        <td>
                                                                                            {line.days}
                                                                                        </td>
                                                                                        <td>
                                                                                            {line.per_diem}
                                                                                        </td>
                                                                                        <td>
                                                                                            {line.euro_per_diem}
                                                                                        </td>
                                                                                        <td>
                                                                                            <button
                                                                                                name={"details" + index}
                                                                                                id={"details" + index}
                                                                                                key={"details" + index}
                                                                                                type="reset"
                                                                                                onClick={() => {
                                                                                                    setLineDataForDetails(line)
                                                                                                    setLineDataForEditIndex(index)
                                                                                                }}
                                                                                                className='btn btn-light btn-light-info p-1 px-2'
                                                                                            ><i className="bi bi-list p-0"></i></button>
                                                                                        </td>
                                                                                        <td>
                                                                                            <button
                                                                                                name={"edit" + index}
                                                                                                id={"edit" + index}
                                                                                                key={"edit" + index}
                                                                                                type="reset"
                                                                                                onClick={() => {
                                                                                                    setLineDataForEdit(line)
                                                                                                    setLineDataForEditIndex(index)
                                                                                                }}
                                                                                                className='btn btn-light btn-light-info p-1 px-2'
                                                                                            ><i className="bi bi-pen p-0"></i></button>
                                                                                        </td>
                                                                                        <td>
                                                                                            <button
                                                                                                name={"delete" + index}
                                                                                                id={"delete" + index}
                                                                                                key={"delete" + index}
                                                                                                type="reset"
                                                                                                onClick={() => { handleDeleteElement(line, index) }}
                                                                                                className='btn btn-light btn-light-info p-1 px-2'
                                                                                            ><i className="bi bi-trash p-0"></i></button>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                                <tr>
                                                                                    <td>
                                                                                        <button
                                                                                            title="Add entry"
                                                                                            type='reset'
                                                                                            className='btn btn-light btn-light-info mx-5 btn-sm'
                                                                                            onClick={() => {
                                                                                                setAddNewEntry(true)
                                                                                            }}
                                                                                        >
                                                                                            <i className="fa fa-plus fs-2" aria-hidden="true"></i>
                                                                                            <span className='indicator-label'>Add new Entry</span>
                                                                                        </button>
                                                                                    </td>
                                                                                    <td></td>
                                                                                    <td>
                                                                                        {totalDays}
                                                                                    </td>
                                                                                    <td>
                                                                                        {totalPerDiem}
                                                                                    </td>
                                                                                    <td>
                                                                                        {totalEuroPerDiem}
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        )}
                                                                    </FieldArray>
                                                                </tbody>
                                                            </table>


                                                        </div>
                                                    </Row>
                                                </fieldset>

                                                {/* begin::Actions */}
                                                <div className='py-5 d-flex justify-content-end'>
                                                    {!canValidate &&
                                                        <button
                                                            title="Submit for approval"
                                                            type='submit'
                                                            className='btn btn-light btn-light-info mx-5 btn-sm'
                                                        >
                                                            <i className="fa fa-podcast fs-2" aria-hidden="true"></i>
                                                            <span className='indicator-label'>Submit</span>
                                                        </button>
                                                    }
                                                    {!canValidate &&
                                                        <button
                                                            title="Discard & back"
                                                            type='reset'
                                                            onClick={() => cancel()}
                                                            className='btn btn-light btn-active-light-primary me-3 mx-5 btn-sm'
                                                            data-kt-users-modal-action='cancel'
                                                        >
                                                            <KTIcon iconName='exit-left' className='fs-2' />
                                                            <span className='indicator-label'>Discard</span>

                                                        </button>
                                                    }
                                                </div>
                                                {/* end::Actions */}
                                            </Row>
                                        )}
                                    </Form>
                                </>
                            )
                        }}
                    </Formik>
                </Col>
                <Col md='12' lg='3' xl='3'>
                    {id && (<AzkStatusRibbon transactionId={parseInt(id)} modelName={MODEL_NAME}
                        current={transactionState} currentName={transactionStateName} />
                    )}
                    {employee && <AzkChatter transactionClosed={transactionClosed} isDrawer={true} modelName={MODEL_NAME} itemID={objectId} hitSend={hitSend} sendMessagesCallback={handleResultOfsubmit} />}
                </Col>
            </Row>
            {(addNewEntry || lineDataForEdit) && <PerDiemModal lineData={lineDataForEdit} closeModal={function (): {} {
                setAddNewEntry(false)
                setLineDataForEdit(undefined)
                setLineDataForEditIndex(undefined)
                return true
            }} confirmModal={function (values): {} {
                if (lineDataForEditIndex !== undefined) {
                    editLine(values, lineDataForEditIndex)
                } else {
                    createLines(values)
                }
                return true

            }} />}

            {lineDataForDetails && <PerDiemDetailModal lineData={lineDataForDetails} closeModal={function (): {} {
                setLineDataForDetails(undefined)
                setLineDataForEditIndex(undefined)
                return true
            }} confirmModal={function (values): {} {
                editLineDetails(values, lineDataForEditIndex)
                setLineDataForDetails(undefined)
                return true

            }} />}
        </>
    )
}

export default Transactionform;
