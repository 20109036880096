import axios, {AxiosResponse} from 'axios'
import { ExitInterViewRequest, ExitInterViewRequestQueryResponse } from './model'
import { ID, Response } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper' 
import useFixServerClientData from 'app/utilities/fix-Server-Client-Data'

const MODEL_NAME = 'az.trn.exit.interview.request'
const URL = `${API_URL}/${MODEL_NAME}`
const GET_URL = `${API_URL}/${MODEL_NAME}/`
const INCLUDE_FIELDS = `'id', 'name', ('employee_id', ('id', 'name', 'start_date', 'actual_end_employee_date', ('parent_id', ('id', 'name')))), 'x_state', 'x_state_view', 'can_review', 'can_write', 'can_delete', 'validated', 'rejected', 'restarted', 'az_country_id', 'az_company_id', 'brand_id', 'azadea_id', 'azadea_employee_name', 'branch_id', 'section_id', 'grade_id', 'job_id', 'submit_approve', 'office_submit_approve', 'executive_employee_name', 'hr_department', 'executive_member', 'lang', 'interview_file_id', 'regrettable', 'did_not_sit_with_hr', 'comments', ('type_id', ('id', 'back_office')), ('sections', [('id' , 'section_sequence', 'comment', ('section_id', ('id' ,'name', 'is_table_content', 'comment_title', 'title')), ('questions', [('id' , 'answer', 'answer_code', 'question_sequence', 'comment', ('question_id', ('id' ,'name', 'question_sequence', 'comment_title', 'type', 'data_set', 'required_question', ('answer_ids', [('code', 'description')]))))]))])`
const INCLUDE_FIELDS_LEAVER = `'id', 'name', ('employee_id', ('id', 'name', 'start_date', 'actual_end_employee_date')), 'x_state', 'x_state_view', 'can_review', 'can_write', 'can_delete', 'validated', 'rejected', 'restarted', 'az_country_id', 'az_company_id', 'brand_id', 'azadea_id', 'azadea_employee_name', 'branch_id', 'section_id', 'grade_id', 'job_id', 'submit_approve', 'office_submit_approve', 'executive_employee_name', 'hr_department', 'executive_member', 'lang', 'interview_file_id', 'regrettable', 'did_not_sit_with_hr', 'comments', ('type_id', ('id', 'back_office')), ('sections', [('id' , 'section_sequence', 'comment', ('section_id', ('id' ,'name', 'is_table_content', 'comment_title', 'title')), ('questions', [('id' , 'answer', 'answer_code', 'question_sequence', 'comment', ('question_id', ('id' ,'name', 'question_sequence', 'comment_title', 'type', 'data_set', 'required_question', ('answer_ids', [('code', 'description')]))))]))])`




const GetExitInterViewRequestByUserId = (userid: ID): Promise<ExitInterViewRequest> => {
  const { FixServerData} = useFixServerClientData();
  return axios
    .get(`${URL}?filters=[('employee_id.user_id','=',${userid})]&include_fields=[${INCLUDE_FIELDS_LEAVER}]`)
    .then((response: AxiosResponse<Response<ExitInterViewRequest>>) => response.data)
    .then((response: Response<ExitInterViewRequest>) => FixServerData(response['results'][0]))
}

const GetExitInterViewRequestById = (id: ID): Promise<ExitInterViewRequest> => {
  const { FixServerData} = useFixServerClientData();
  return axios
    .get(`${URL}?filters=[('id','=',${id})]&include_fields=[${INCLUDE_FIELDS}]`)
    .then((response: AxiosResponse<Response<ExitInterViewRequest>>) => response.data)
    .then((response: Response<ExitInterViewRequest>) => FixServerData(response['results'][0]))
}

const CreateExitInterViewRequest = async (transaction: ExitInterViewRequest): Promise<ExitInterViewRequest | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transaction)
  return axios
    .post(URL, shallowTransaction)
    .then((response: AxiosResponse<ExitInterViewRequest>) => response.data)
}

const UpdateExitInterViewRequest = async (transaction: ExitInterViewRequest): Promise<ExitInterViewRequest | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transaction)
  return axios
    .put(`${URL}/${transaction.id}`, shallowTransaction)
    .then((response: AxiosResponse<Response<ExitInterViewRequest>>) => response.data)
    .then((response: Response<ExitInterViewRequest>) => response.data)
}

const RequestValidationExitInterViewRequest = async (transactionId: number): Promise<ExitInterViewRequest | undefined> => {
  return axios
    .put(`${URL}/${transactionId}/request_validation`, {})
    .then((response: AxiosResponse<Response<ExitInterViewRequest>>) => response.data)
    .then((response: Response<ExitInterViewRequest>) => response.data)
}

const UpdateExitInterViewRequestLang = async (transactionId: number, lang): Promise<ExitInterViewRequest | undefined> => {
  return axios
    .put(`${URL}/${transactionId}`,{'lang': lang})
    .then((response: AxiosResponse<Response<ExitInterViewRequest>>) => response.data)
    .then((response: Response<ExitInterViewRequest>) => response.data)
}

const DeleteExitInterViewRequest = (transactionId: number): Promise<ExitInterViewRequest | undefined> => {
  return axios
    .delete(`${URL}/${transactionId}`)
    .then((response: AxiosResponse<Response<ExitInterViewRequest>>) => response.data)
    .then((response: Response<ExitInterViewRequest>) => response.data)
}

export { GetExitInterViewRequestById, CreateExitInterViewRequest, UpdateExitInterViewRequest, MODEL_NAME, DeleteExitInterViewRequest, GetExitInterViewRequestByUserId, UpdateExitInterViewRequestLang, RequestValidationExitInterViewRequest}


