import axios, { AxiosResponse } from 'axios'
import { FinalSettlement } from './model'
import { ID, Response } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper'
import useFixServerClientData from 'app/utilities/fix-Server-Client-Data'

const MODEL_NAME = 'az.trn.final.settlement'
const URL = `${API_URL}/${MODEL_NAME}`
const GET_URL = `${API_URL}/${MODEL_NAME}/`
const INCLUDE_FIELDS = `'id', 'name', 'employee_id', 'type_id', 'x_state', 'x_state_view', 'can_review', 'can_review_fs', 'can_write', 'can_delete', 'validated', 'rejected', 'restarted', 'az_country_id', 'az_company_id', 'payroll_id', 'brand_id', 'azadea_id', 'azadea_employee_name', 'branch_id', 'section_id', 'grade_id', 'job_id', 'salary', 'as_effective_date', 'time_set', 'target_as_effective_date', 'can_edit', 'current_approval_ids', 'clearance_trn_id', 'salary_day', 'notice_period', 'leave_salary', 'indemnity', 'adjustment', 'other_deduction', 'ticket_amount', 'other_addition', 'indemnity_zero', 'partial_payment', 'allowed_paramters', 'can_approve', 'can_reject', 'can_review', 'can_regenerate', 'can_view', 'can_download_pdf', 'can_upload_pdf', 'can_to_pay', 'can_completed', 'can_c2p_partial_done', 'can_reset_upload', ('steps', [('id', 'name')]), 'need_validation', 'marked_to_pay_partial', 'marked_to_pay', 'marked_file_uploaded'`
const INCLUDE_FIELDS_FULL = `${INCLUDE_FIELDS},'serial_number', 'end_nature', 'joining_date', 'indem_start_date', 'actual_termination_date', 'end_date', 'basic_salary', 'housing', 'other_allowance', 'working_days', 'salary_days', 'housing_days', 'transportation_days', 'commission', 'notice_period_days', 'salary_notice_period', 'housing_notice_period', 'transportation_notice_period', 'leave_days', 'leave_amount', 'indemnity_days', 'indemnity_year', 'indemnity_amount', 'net_to_pay', 'currency_name', 'bankname', 'iban_number', 'remark', 'fs_letter', 'date_group_start_date', 'template', 'housing_all', 'trans_all', 'other_deduction_amount', 'other_addition_amount', 'swift_code', 'deductions', 'eos_extra_hours', 'commission_settlement', 'eos_public_holiday'`
const LIST_INCLUDE_FIELDS = `'id', 'name', 'employee_id', 'type_id', 'x_state', 'x_state_view', 'can_review', 'can_write', 'can_delete', 'validated', 'rejected', 'restarted', 'az_country_id', 'az_company_id', 'payroll_id', 'brand_id', 'azadea_id', 'azadea_employee_name', 'branch_id', 'section_id', 'grade_id', 'job_id', 'as_effective_date', 'time_set', 'target_as_effective_date', 'can_edit', 'current_approval_ids', 'clearance_trn_id', 'salary_day', 'notice_period', 'leave_salary', 'indemnity', 'adjustment', 'deduction', 'other', 'indemnity_zero', 'partial_payment', 'allowed_paramters', 'can_approve', 'can_reject', 'can_review', 'can_regenerate', 'can_view', 'can_download_pdf', 'can_upload_pdf', 'can_to_pay', 'can_completed', 'can_c2p_partial_done', 'can_reset_upload'`
const ORDER_BY = 'target_as_effective_date desc, az_country_id desc'
const GetFinalSettlementList = (): Promise<FinalSettlement[]> => {
  const { FixServerArrayData } = useFixServerClientData();
  return axios
    .get(`${URL}?include_fields=[${LIST_INCLUDE_FIELDS}]&order=${ORDER_BY}`)
    .then((response: AxiosResponse<Response<FinalSettlement[]>>) => response.data)
    .then((response: Response<FinalSettlement[]>) => FixServerArrayData(response['results']))
}


const GetFinalSettlementById = async (id: ID): Promise<FinalSettlement> => {
  const { FixServerData } = await useFixServerClientData();
  return axios
    .get(`${URL}?filters=[('id','=',${id})]&include_fields=[${INCLUDE_FIELDS_FULL}]`)
    .then((response: AxiosResponse<Response<FinalSettlement>>) => response.data)
    .then((response: Response<FinalSettlement>) => FixServerData(response['results'][0]))
}

const GetFinalSettlementByClearanceId = async (clearance_id: ID): Promise<FinalSettlement> => {
  const { FixServerData } = await useFixServerClientData();
  return axios
    .get(`${URL}?filters=[('clearance_trn_id','=',${clearance_id})]&include_fields=[${INCLUDE_FIELDS_FULL}]`)
    .then((response: AxiosResponse<Response<FinalSettlement>>) => response.data)
    .then((response: Response<FinalSettlement>) => FixServerData(response['results'][0]))
}

const CreateFinalSettlement = async (transaction: FinalSettlement): Promise<FinalSettlement | undefined> => {
  const { FixClientData } = useFixServerClientData();
  const shallowTransaction = await FixClientData(transaction)
  return axios
    .post(URL, shallowTransaction)
    .then((response: AxiosResponse<FinalSettlement>) => response.data)
}

const UpdateFinalSettlement = async (transaction: FinalSettlement): Promise<FinalSettlement | undefined> => {
  const { FixClientData } = useFixServerClientData();
  const shallowTransaction = await FixClientData(transaction)
  return axios
    .put(`${URL}/${transaction.id}`, shallowTransaction)
    .then((response: AxiosResponse<Response<FinalSettlement>>) => response.data)
    .then((response: Response<FinalSettlement>) => response.data)
}

const DeleteFinalSettlement = (transactionId: number): Promise<FinalSettlement | undefined> => {
  return axios
    .delete(`${URL}/${transactionId}`)
    .then((response: AxiosResponse<Response<FinalSettlement>>) => response.data)
    .then((response: Response<FinalSettlement>) => response.data)
}
export { GetFinalSettlementById, CreateFinalSettlement, UpdateFinalSettlement, MODEL_NAME, DeleteFinalSettlement, GetFinalSettlementList}

