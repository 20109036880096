import { useState, useEffect } from 'react';
import axios from 'axios';
import azkSelect from "_azkatech/azkSelect"
import { useNavigate } from 'react-router';
import { KTCard, KTCardBody, KTIcon } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper';
import * as authHelper from 'app/modules/auth/core/AuthHelpers'
import { Form, Row } from 'react-bootstrap';
import moment from 'moment';
import { AZKHelper } from 'app/modules/components/azkHelper';
import { AuthModel } from 'app/modules/auth';
import { CSVLink } from 'react-csv'
import ReactDOM from 'react-dom';
import DataTable from 'react-data-table-component';
import { SystemAttendanceLine } from './model';
import { GetEmployeeByUserId } from 'app/modules/components/employeeDetail/_requests';
import AzkSelect from '_azkatech/azkSelect';
import { AsyncPaginate } from 'react-select-async-paginate';

export const transactionModle = {
    transfer_company: 'az.trn.attendance.adjustment',
};

const DetailedReportView = () => {
    const navigate = useNavigate()

    const [selectedExcelData, setSelectedExcelData] = useState<any[]>([]);
    const [currectEmployee, setCurrectEmployee] = useState<number>();
    const [dateFrom, setDateFrom] = useState<any>();
    const [dateTo, setDateTo] = useState<any>();
    const [employeeId, setEmployeeId] = useState<number>()

    const [systemData, setSystemData] = useState<SystemAttendanceLine[]>();
    const [offset, setOffset] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(perPage);
    const [employeeDomain, setEmployeeDomain] = useState<string>('');
    const [employeeDomainReady, setEmployeeDomainReady] = useState<boolean>(false);
    const [isManger, setIsManger] = useState<boolean>(false);
    const [shopUser, setShopUser] = useState<boolean>(true);
    const [superManager, setSuperManager] = useState<boolean>(false);
    const [dataReqeusted, setDataReqeusted] = useState<boolean>(false);
    // #region Transfer List as DataTable
    const TRN_list_LOCAL_STORAGE_FILTER_KEY = 'azk-trn-self-service-list-filter-hr-lite'

    const viewSystemResult = async (pageNumber, itemPerPage) => {
        setDataReqeusted(true)
        let remoteData = []
        if (dateFrom && moment(dateFrom).isValid() && dateTo && moment(dateTo).isValid()) {
            document.body.classList.add('page-loading')
            try {
                setSelectedExcelData([])
                const date_from = moment(dateFrom).utc(true)?.format(AZKHelper.FRONTEND_DATE_FORMAT)
                const date_to = moment(dateTo).utc(true)?.format(AZKHelper.FRONTEND_DATE_FORMAT)
                let entryType = null;
                if (az_adjustment_type) entryType = az_adjustment_type?.value
                const result = await axios.put(`${API_URL}/hr.employee/${currectEmployee}/search_attendence_detailed_report?fromDate=${date_from}&toDate=${date_to}&azadeaId=${employeeId}&type=${entryType}&country=${az_country}&company=${az_company}&brand=${az_brand}&shop=${az_branch}&pageNumber=${pageNumber}&pageSize=${itemPerPage}`, { 'fromDate': date_from, 'toDate': date_to, 'azadeaId': employeeId, 'type': entryType, 'country': az_country, 'company': az_company, 'brand': az_brand, 'shop': az_branch, 'level': 1, 'download': false, 'pageNumber': pageNumber, 'pageSize': itemPerPage })
                remoteData = result.data ? result.data : []
                if (result.data?.hasNext) setTotalRows(itemPerPage * pageNumber + itemPerPage)
                document.body.classList.remove('page-loading')
                setDataReqeusted(false)
            } catch (error) {
                console.error(error);
                document.body.classList.remove('page-loading')
                setDataReqeusted(false)
            }
            if (remoteData)
                setSystemData(remoteData);
        }
    }

    const SystemColumns = [
        {
            name: 'Azadea ID',
            selector: row => row.azadeaId ? row.azadea_id : undefined,
            sortable: false,
            tech_name: 'azadea_id',
            width: '17%',
            cell: (d) => [(<>
                <Row className='m-0'>
                    <Row className='fs-7 fw-bold text-gray-800'>
                        {d.employeeName}
                    </Row>
                    <Row className='fs-8 fw-bold text-gray-600'>
                        {d.azadeaId}
                    </Row>
                </Row>
            </>)
            ]
        },
        {
            name: 'Country',
            selector: row => row.countryName ? row.countryName : undefined,
            sortable: false,
            show: false,
            tech_name: 'countryName',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {d.countryName}
                </Row>
            </>)]
        },
        {
            name: 'Company',
            selector: row => row.companyName ? row.companyName : undefined,
            sortable: false,
            show: false,
            tech_name: 'companyName',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {d.companyName}
                </Row>
            </>)]
        },
        {
            name: 'Brand',
            selector: row => row.employeeBrandName ? row.employeeBrandName : undefined,
            sortable: false,
            tech_name: 'employeeBrandName ',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {d.employeeBrandName}
                </Row>
            </>)]
        },
        {
            name: 'Organization Name',
            selector: row => row.organizationName ? row.organizationName : undefined,
            sortable: false,
            tech_name: 'organizationName',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {d.organizationName}
                </Row>
            </>)]
        },
        {
            name: 'Punch Organization Name',
            selector: row => row.punchOrganizationName ? row.punchOrganizationName : undefined,
            sortable: false,
            tech_name: 'punchOrganizationName',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {d.punchOrganizationName}
                </Row>
            </>)]
        },
        {
            name: 'Date',
            selector: row => row.attendanceDate ? row.attendanceDate : undefined,
            sortable: false,
            tech_name: 'attendanceDate',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {moment(d.attendanceDate).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                </Row>
            </>)]
        },
        {
            name: <> <span className='w-100'>Punch</span> </>,
            selector: row => row.attendanceType ? row.attendanceType : undefined,
            sortable: false,
            tech_name: 'attendanceType',
            cell: (d) => [
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {d.attendanceType === 'I' ? 'In' : 'Out'}
                </Row>
            ]
        },
        {
            name: <> <span className='w-100'>Time</span> </>,
            selector: row => row.attendanceHour ? row.attendanceHour : undefined,
            sortable: false,
            tech_name: 'attendanceHour',
            cell: (d) => [
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {d.attendanceHour}:{d.attendanceMinute}
                </Row>
            ]
        },
        {
            name: <> <span className='w-100'>Type</span> </>,
            selector: row => row.entryType ? row.entryType : undefined,
            sortable: false,
            tech_name: 'entryType',
            cell: (d) => [
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {d.entryType}
                </Row>
            ]
        },
        // {
        //     name: <> <span className='w-100 text-center'>Actions</span> </>,
        //     sortable: false,
        //     selector: undefined,
        //     cell: (d) => [
        //         (<>
        //             <div className='w-100 m-0 d-flex justify-content-center'>
        //                 <span
        //                     onClick={handleEditBtnClick.bind(this, d.id)}
        //                     className="btn btn-sm btn-icon btn-active-light-primary me-1"
        //                 ><KTIcon iconName='pencil' className='fs-3' /></span>
        //             </div>
        //         </>)
        //     ]
        // }
    ]


    const onSelectedRowsChange = row => {
        getDataToExport(row.selectedRows)
    };

    const getDataToExport = async (data: any[]) => {
        let excelDataList: any[] = []
        if (data) {
            await data.forEach(row => {
                const object = builtExportObject(row)
                excelDataList.push(object)
            })
            setSelectedExcelData(excelDataList)
        }
    }

    const hitExport = (data: any[]) => {
        // if (data && data.length > 0) {
        //     const inputElement = document.querySelector('#export_data_excel') as HTMLInputElement
        //     inputElement.click()
        // }
        document.body.classList.remove('page-loading')
        if (data && data.length > 0) {
            const parentElement = document.createElement('div');
            const element = <div><CSVLink data={data} filename={'Detaild Report ' + moment().format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)} id="export_data_excel" ></CSVLink></div>
            ReactDOM.render(element, parentElement);
            document.body.appendChild(parentElement)
            const inputElement = document.querySelector('#export_data_excel') as HTMLInputElement
            inputElement.click()
            document.body.removeChild(parentElement)
        }
    }

    const exportSelectedDataToExcel = async () => {
        document.body.classList.add('page-loading')
        hitExport(selectedExcelData)
    }

    const exportDataToExcel = async () => {
        let excelDataList: any[] = []
        document.body.classList.add('page-loading')
        const date_from = moment(dateFrom).utc(true)?.format(AZKHelper.FRONTEND_DATE_FORMAT)
        const date_to = moment(dateTo).utc(true)?.format(AZKHelper.FRONTEND_DATE_FORMAT)
        let entryType = null;
        if (az_adjustment_type) entryType = az_adjustment_type?.value
        axios.put(`${API_URL}/hr.employee/${currectEmployee}/search_attendence_detailed_report?fromDate=${date_from}&toDate=${date_to}&azadeaId=${employeeId}&type=${entryType}&country=${az_country}&company=${az_company}&brand=${az_brand}&shop=${az_branch}&pageNumber=1&pageSize=9999`, { 'fromDate': date_from, 'toDate': date_to, 'azadeaId': employeeId, 'type': entryType, 'country': az_country, 'company': az_company, 'brand': az_brand, 'shop': az_branch, 'level': 1, 'download': true, 'pageNumber': 1, 'pageSize': 9999 })
            .then(async response => {
                const file_name = response.data['file_name']
                const blob = new Blob([response.data['content']], { type: 'text/csv' })
                const url = window.URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', file_name)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                window.URL.revokeObjectURL(url)
                document.body.classList.remove('page-loading')
            })
    }

    const builtExportObject = (row) => {
        const object = {}
        object['Date'] = moment(row.attendanceDate).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)
        object['Azadea ID'] = row.azadeaId
        object['Employee Name'] = row.employeeName
        object['Country'] = row.countryName
        object['Company'] = row.companyName
        object['Brand'] = row.employeeBrandName
        object['organization'] = row.organizationName
        object['Punch Organization Name'] = row.punchOrganizationName
        object['Entry Type'] = row.attendanceType === 'I' ? 'In' : 'Out'
        object['Hour'] = row.attendanceHour
        object['Min'] = row.attendanceMinute
        return object
    }

    const handlePageChange = page => {
        let newOffset = page;
        setOffset(newOffset);
        setSystemData([]);
        viewSystemResult(newOffset, perPage)
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setSystemData([]);
        viewSystemResult(offset, newPerPage)
    };

    // #endregion Transfer List

    // #region State Dropdown

    const fetchUser = async () => {
        const from_date = moment().startOf('month').format(AZKHelper.BACKEND_DATE_FORMAT)
        const to_date = moment().startOf('month').add(1, 'month').format(AZKHelper.BACKEND_DATE_FORMAT)
        setDateFrom(from_date)
        setDateTo(to_date)
        let _auth = authHelper.getAuth()
        if (!_auth) {
            const auth = localStorage.getItem('azk-auth-hr-lite')
            if (auth) {
                _auth = JSON.parse(auth) as AuthModel
            }
        }
        GetEmployeeByUserId(_auth?.uid).then(async response => {
            let isMangerVal = false
            let isShopUserVal = true
            let isSuperManager = false
            if (response && response.is_manager_fr && response.is_manager_fr !== null) {
                setIsManger(true)
                isMangerVal = true
                setCurrectEmployee(response.id)
            } else {
                setIsManger(false)
                isMangerVal = false
                setCurrectEmployee(response.id)
            }
            if (response.is_supper_access && response.is_supper_access !== null) {
                setSuperManager(response?.is_supper_access);
                isSuperManager = true
            }
            if (response.is_back_office_fr && response.is_back_office_fr !== null) {
                setShopUser(!response?.is_back_office_fr)
                isShopUserVal = !response?.is_back_office_fr
            }

            if (response.id) changeEmployeeDomain(response.id, isMangerVal, shopUser, az_country, az_company, az_branch, az_brand, isSuperManager)
            // if (response.is_supper_access && response.is_supper_access !== null) setSuperManager(response?.is_supper_access)
            const filterVals: string | null = localStorage.getItem(TRN_list_LOCAL_STORAGE_FILTER_KEY)
        })
    }

    useEffect(() => {
        document.body.classList.remove('page-loading')
        fetchUser();
    }, []);

    const loadTypeOptions = async (search, loadedOptions) => {
        const options: any[] = []
        options.push({ value: 'System', label: 'system' })
        options.push({ value: 'Manual', label: 'Manual' })
        const filteredOptions = options.filter((option) =>
            option.label.toString().includes(search.toLowerCase())
        );
        return {
            options: filteredOptions,
            hasMore: false,
        };
    };

    const [az_adjustment_type, setAttendanceAdjustmentType] = useState<any>('');

    // #endregion Type Dropdown


    const loadLevelOptions = async (search, loadedOptions) => {
        const options: any[] = []
        options.push({ value: '1', label: '1' })
        options.push({ value: '2', label: '2' })
        options.push({ value: '3', label: '3' })
        const filteredOptions = options.filter((option) =>
            option.label.toString().includes(search.toLowerCase())
        );
        return {
            options: filteredOptions,
            hasMore: false,
        };
    };

    const [az_report_level, setAzReportLevel] = useState<any>('1');

    // #region Country Dropdown
    const [az_country, setCountry] = useState('');
    const [activetCountryDomain, setActivetCountryDomain] = useState(false);

    const handleCountryChange = (val) => {
        if (activetCountryDomain) {
            setCountry(val);
            setCompanyDomain(val ? `('country_id','=', ${val})` : '');
            setBranchDomain(val ? `('country_id','=', ${val})` : '');
            if (currectEmployee) changeEmployeeDomain(currectEmployee, isManger, shopUser, val, az_company, az_branch, az_brand, superManager)
        }
        setActivetCountryDomain(true)
    }

    const az_country_select = azkSelect({
        compID: "az_country_select",
        modelName: "az.country",
        valueField: "id",
        titleField: "name",
        allowEmpty: true,
        isSearchable: true,
        defaultValue: az_country,
        onChange: handleCountryChange
    });
    // #endregion Country Dropdown


    // #region Company Dropdown
    const [az_company, setCompany] = useState(null);
    const [companyDomain, setCompanyDomain] = useState('');
    const [activetCompanyDomain, setActivetCompanyDomain] = useState(false);

    const handleCompanyChange = (val) => {
        if (activetCompanyDomain) {
            setCompany(val);
            setBrandefaultValue('')
            const conds: string[] = []
            if (az_country) {
                conds.push(`('country_id','=', ${az_country})`)
            }
            if (val) {
                conds.push(`('company_id','=', ${val})`)
            }
            if (currectEmployee) changeEmployeeDomain(currectEmployee, isManger, shopUser, az_country, val, az_branch, az_brand, superManager)
            setBranchDomain(conds.join(','));
            if (val !== '') {
                axios.get(`${API_URL}/az.company?filters=[('id', '=', ${val ? val : '0'})]&include_fields=['brand_ids']`).then((result: any) => {
                    if (result.data && result.data.results && result.data.count > 0 && result.data.results[0].brand_ids !== null) {
                        setBrandDomain(`('id', 'in', [${result.data.results[0].brand_ids}])`);
                    } else {
                        setBrandDomain('');
                    }
                });
            } else {
                setBrandDomain('');
            }
        }
        setActivetCompanyDomain(true)
    }

    const az_company_select = azkSelect({
        compID: "az_company_select",
        modelName: "az.company",
        valueField: "id",
        titleField: "name",
        dataDomain: companyDomain,
        defaultValue: az_company,
        allowEmpty: true,
        onChange: handleCompanyChange
    });
    // #endregion Company Dropdown

    // #region Brand Dropdown
    const [az_brand, setBrand] = useState('');
    const [brandDomain, setBrandDomain] = useState('');
    const [brandefaultValue, setBrandefaultValue] = useState('');
    const [activetBrandDomain, setActivetBrandDomain] = useState(false);

    const handleBrandChange = (val) => {
        if (activetBrandDomain) {
            setBrand(val);
            setBrandefaultValue(val)
            const conds: string[] = []
            if (az_country) {
                conds.push(`('country_id','=', ${az_country})`)
            }
            if (az_company) {
                conds.push(`('az_company_id','=', ${az_company})`)
            }
            if (val) {
                conds.push(`('brand_id','=', ${val})`)
                if (currectEmployee) changeEmployeeDomain(currectEmployee, isManger, shopUser, az_country, az_company, az_branch, val, superManager)
            }
            setBranchDomain(conds.join(','));
        }
        setActivetBrandDomain(true)
    }

    const az_brand_select = azkSelect({
        compID: "az_brand_select",
        modelName: "az.brand",
        valueField: "id",
        titleField: "name",
        dataDomain: brandDomain,
        allowEmpty: true,
        onChange: handleBrandChange,
        defaultValue: brandefaultValue
    });

    // #endregion Brand Dropdown


    // #region Branch Dropdown
    const [az_branch, setBranch] = useState('');
    const [branchDomain, setBranchDomain] = useState('');

    const handleBranchChange = (val) => {
        setBranch(val);
        if (currectEmployee) changeEmployeeDomain(currectEmployee, isManger, shopUser, az_country, az_company, val, az_brand, superManager)
    }

    const az_branch_select = azkSelect({
        compID: "az_branch_select",
        modelName: "az.branch",
        valueField: "id",
        titleField: "organization_name",
        allowEmpty: true,
        dataDomain: branchDomain,
        isSearchable: true,
        defaultValue: az_branch,
        onChange: handleBranchChange
    });
    // #endregion Branch Dropdown

    const changeEmployeeDomain = (employee_id, is_manager_fr, is_shop_user, country_id, company_id, branch_id, brand_id, isSuperManager) => {
        let conds: string[] = [];
        if (isSuperManager) {
            conds.push(``)
        } else if (is_manager_fr && is_shop_user) {
            conds.push(`'|', '|', ('id', '=', ${employee_id}), ('parent_id', '=', ${employee_id}), ('super_parent_id', '=', ${employee_id})`)
        } else if (is_manager_fr && !is_shop_user) {
            conds.push(`'|', ('id', '=', ${employee_id}), ('parent_id', '=', ${employee_id})`)
        } else {
            conds.push(`('id', '=', ${employee_id})`)
        }
        if (country_id && country_id !== null) {
            conds.push(`('az_country_id', '=', ${country_id})`)
        }
        if (company_id && company_id !== null) {
            conds.push(`('az_company_id', '=', ${company_id})`)
        }
        if (branch_id && branch_id !== null) {
            conds.push(`('branch_id', '=', ${branch_id})`)
        }
        if (brand_id && brand_id !== null) {
            conds.push(`('brand_id', '=', ${brand_id})`)
        }
        setEmployeeDomain(conds.join(','))
        setEmployeeDomainReady(true)
    }
    return (
        <>
            <KTCard className='mt-3'>
                <div className='card-body'>
                    <div className='row m-0'>

                        <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                            {/* begin::Label */}
                            <label className='fw-bold fs-7 my-auto col-12 col-md-12'>Country</label>
                            {/* end::Label */}

                            {/* begin::Input */}
                            {az_country_select}
                            {/* end::Input */}
                        </div>
                        <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                            {/* begin::Label */}
                            <label className='fw-bold fs-7 my-auto col-12 col-md-12'>Company</label>
                            {/* end::Label */}

                            {/* begin::Input */}
                            {az_company_select}
                            {/* end::Input */}
                        </div>
                        <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                            {/* begin::Label */}
                            <label className='fw-bold fs-7 my-auto col-12 col-md-12'>Brand</label>
                            {/* end::Label */}

                            {/* begin::Input */}
                            {az_brand_select}
                            {/* end::Input */}
                        </div>

                        <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                            {/* begin::Label */}
                            <label className='fw-bold fs-7 my-auto col-12 col-md-12'>Organization Name</label>
                            {/* end::Label */}

                            {/* begin::Input */}
                            {az_branch_select}
                            {/* end::Input */}
                        </div>
                        <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                            <label className='fw-bold fs-7 my-auto col-12 col-md-12'>From</label>
                            <Form.Control
                                className='p-1'
                                type='date'
                                onChange={(event) => {
                                    setDateFrom(moment(event.target.value).format(AZKHelper.BACKEND_DATE_FORMAT))
                                    // if (requestView) setListDataDomain(updateListDataDomain(currectEmployee, searchTerm, az_country, az_company, az_brand, az_state, myRequests, az_adjustment_reason_id, moment(event.target.value).format(AZKHelper.BACKEND_DATE_FORMAT), dateTo))
                                }}
                                defaultValue={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                value={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                max={moment(dateTo).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                            />
                            {dataReqeusted && !moment(dateFrom).isValid() &&
                                <div className='text-danger'>
                                    Date from is required Field
                                </div>
                            }
                        </div>

                        <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                            <label className='fw-bold fs-7 my-auto col-12 col-md-12'>To</label>
                            <Form.Control
                                className='p-1'
                                type='date'
                                onChange={(event) => {
                                    setDateTo(moment(event.target.value).format(AZKHelper.BACKEND_DATE_FORMAT))
                                }}
                                defaultValue={moment(dateTo).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                value={moment(dateTo).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                min={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                            />
                            {dataReqeusted && !moment(dateTo).isValid() &&
                                <div className='text-danger'>
                                    Date to is required Field
                                </div>
                            }
                        </div>
                        <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                            {/* begin::Label */}
                            <label className='fw-bold fs-7 my-auto col-12 col-md-12'>Azadea ID</label>
                            {/* end::Label */}

                            {/* begin::Input */}
                            {employeeDomainReady &&
                                <AzkSelect
                                    compID="azk_temp"
                                    modelName="hr.employee"
                                    valueField="ex_az_id"
                                    titleField="azadea_employee_name"
                                    dataDomain={employeeDomain}
                                    allowEmpty={true}
                                    fieldName='employee_id'
                                    onChange={(value) => {
                                        setEmployeeId(parseInt(value))
                                    }}
                                    searchFields={['name', 'azadea_id', 'branch_id', 'job_id', 'work_email', 'first_name']} />
                            }
                            {/* end::Input */}
                        </div>
                        {/* <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                            <label className='fw-bold fs-7 my-auto col-12 col-md-12'>Type</label>
                            <AsyncPaginate className='rounded-0 col-12'
                                placeholder="Select an option"
                                loadOptions={loadTypeOptions}
                                onChange={(value) => {
                                    if (value) setAttendanceAdjustmentType(value)
                                    else setAttendanceAdjustmentType(null)
                                }}
                                isClearable={true}
                                defaultValue={az_adjustment_type}
                                value={az_adjustment_type}
                                id={`adjustment_type`} name={`adjustment_type`}
                            />
                        </div> */}

                        <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                            {/* begin::Label */}
                            <label className='fw-bold fs-7 my-auto col-12 col-md-12'>Subordinate Level</label>
                            {/* end::Label */}
                            {/* begin::Input */}
                            <AsyncPaginate className='rounded-0 col-12'
                                placeholder="Select an option"
                                loadOptions={loadLevelOptions}
                                onChange={(value) => {
                                    if (value) setAzReportLevel(value)
                                    else setAzReportLevel('1')
                                }}
                                isClearable={true}
                                defaultValue={az_report_level}
                                value={az_report_level}
                                id={`az_report_level`} name={`az_report_level`}
                            />
                            {/* end::Input */}
                        </div>

                        <div className='fv-row mb-2 col-lg-12 col-md-12 col-12 d-flex align-items-center justify-content-end p-0'>
                            <label className='text-danger px-2'>
                                The system will only display the top 50 records. To view all data, please click the "Download" button
                            </label>
                            <button name="search-system-records" type='button' className={`btn btn-light-primary me-3 btn-sm`} onClick={() => viewSystemResult(offset, perPage)} >
                                <KTIcon iconName='search-list' className='fs-2' />
                                Search
                            </button>
                            <button type='button' onClick={exportSelectedDataToExcel} className={`btn btn-light-primary me-3 btn-sm ${selectedExcelData && selectedExcelData.length > 0 ? '' : 'd-none'}`}>
                                <KTIcon iconName='exit-up' className='fs-2' />
                                Export Selected
                            </button>
                            <button type='button' className={`btn btn-light-primary me-3 btn-sm`} onClick={exportDataToExcel} >
                                <KTIcon iconName='cloud-download' className='fs-2' />
                                Download
                            </button>
                        </div>
                    </div>
                </div>
            </KTCard >
            <KTCard className='mt-3'>
                {systemData && <>
                    <KTCardBody className='py-4 cutom-padding'>
                        <div className='table-responsive'>
                            <DataTable
                                columns={SystemColumns}
                                data={systemData}
                                // onSort={handleSort}
                                // onRowClicked={onRowClickedFun}
                                // sortServer
                                // progressPending={pending}
                                // pagination
                                // paginationServer
                                // selectableRowDisabled={(row) => {
                                //    return diableSelectableRows && (row['state'] !== "to_approve" || row['restarted'])
                                // }}
                                // paginationTotalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                selectableRows={true}
                                // expandableRows={true}
                                // expandableRowsComponent
                                onSelectedRowsChange={onSelectedRowsChange}

                            />
                        </div>
                    </KTCardBody>
                </>}
            </KTCard>
        </>)
}

export default DetailedReportView;