import { Response } from "_metronic/helpers"

export type Leave = {
  id?: number
  name?: string
  employee_id?: any
  type_id?: number
  x_state?: string
  x_state_view?: string
  can_review?: boolean
  can_delete?: boolean
  can_write?: boolean
  validated?: boolean, 
  rejected?: boolean,
  state?: string
  az_country_id?: any
  az_company_id?: any
  brand_id?: any
  azadea_id?: number
  azadea_employee_name?: string
  branch_id?: any
  section_id?: any
  grade_id?: any
  job_id?: any
  time_set?: string
  can_edit?: boolean
  restarted?: boolean
  leave_type_id?: any
  date_from?: string
  date_to?: string
  half_day_from?: string
  time_from?: string
  time_to?: string
  relationship_type_id?: any
  family_member_id?: any
  childbirth_date?: string
  expected_delivery_date?: string
  country_visited_id?: any
  reason_for_travel_id?: any
  document?: any

  is_hourly?: boolean
  is_half_day?: boolean
  lookup_code?: string
}

export const initialTransfaction: Leave = {
  name: '',
}

export type LeaveQueryResponse = Response<Array<Leave>>

export const initialTransferCompany: Leave = {

}
