/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  KTIcon,
  toAbsoluteUrl,
} from '../../../helpers'
import { useNotifications } from 'app/utilities/notifications'
import { AZKHelper } from 'app/modules/components/azkHelper'

type NotificationByType = {
  id?: number,
  icon?: string,
  name?: string,
  model?: string,
  pending_count?; number,
  notifications?: Notification[]
}
type Notification = {
  id?; number,
  icon?: string,
  state?: string
  display_status?: string
  name?: string,
  model?: string,
  employee_id?: {
    id?: number,
    name?: string,
    azadea_employee_name?: string,
  }
  location_id?: {
    id?: number,
    name?: string
  }
  brand_id?: {
    id?: number,
    name?: string
  }
  requested_by?: any
  requested_date?: string
}
const HeaderNotificationsMenu = (notificationsBytype: NotificationByType[]) => { //notifications: NotificationByType[]
  const navigate = useNavigate()
  const { checkNotifications, getNotifications } = useNotifications();
  const [notificationByType, setNotificationByType] = useState<NotificationByType[]>([])

  useEffect(() => {
    if (Object.values(notificationsBytype) && Object.values(notificationsBytype).length > 0) {
      Object.values(notificationsBytype).forEach((type, index) => {
        fillNotifications(type, index)
      })
    }
  }, [notificationsBytype])

  const fillNotifications = (type: NotificationByType, index: number) => {
    getNotifications(type.model)?.then((data: Notification[]) => {
      if (data && data.length > 0) {
        type.notifications = data
        replaceTypeObject(type, index)
      }
    })
  }

  const replaceTypeObject = async (element: NotificationByType, index: number) => {
    let elements: NotificationByType[] = notificationByType
    if (!elements) elements = []
    // replace the element object
    elements[index] = element
    setNotificationByType(elements)
  }
  const hideMenu = () => {
    const menuBtn = document.querySelector('#notification-list-btn') as HTMLInputElement
    menuBtn.click()
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      id='notification-list'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')` }}
      >
        <h3 className='text-white fw-bold px-9 mt-3 mb-2'>
          Notifications
        </h3>

        <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9'>
          <li className='nav-item'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-3 pt-0'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_1'
            >
              Alerts
            </a>
          </li>

          {/* <li className='nav-item'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_2'
            >
              Updates
            </a>
          </li>

          <li className='nav-item'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_3'
            >
              Logs
            </a>
          </li> */}
        </ul>
      </div>

      <div className='tab-content'>
        <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
          <div className='scroll-y mh-325px px-8 pb-4'>
            {notificationByType && notificationByType.length > 0 && notificationByType?.map((notificationType: NotificationByType, index) => (
              <>
                <div key={`notificationByType${index}`} className='d-flex flex-stack pt-4 border-bottom' onClick={() => { fillNotifications(notificationType, index) }}>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-35px me-4'>
                      <span>
                        {' '}
                        <KTIcon iconName={notificationType.icon ? notificationType.icon : ''} />
                      </span>
                    </div>

                    <div className='mb-0 me-2'>
                      <span className='fs-6 text-gray-800 fw-bolder'>
                        {notificationType.name}
                      </span>
                      {/* <div className='text-gray-400 fs-7'>{alert.description}</div> */}
                    </div>
                  </div>
                  <span className='badge badge-light fs-8'>{notificationType.pending_count}</span>
                </div>
                <div className='row'>
                  {notificationType.notifications && notificationType.notifications.length > 0 && notificationType.notifications?.map((notification, index) => (
                    <>
                      {notificationType.model === AZKHelper.TRANSACTIONTYPE.AZ_TRN_LEAVE && <>
                        <Link to={`/self-service/leave-submission/${notification.id}/edit`} onClick={hideMenu}>
                          <div key={`notification${index}`} className='d-flex flex-stack pt-2'>
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-35px me-4'>
                                <span>
                                  {' '}
                                  <KTIcon iconName={notification.icon ? notification.icon : ''} />
                                </span>
                              </div>
                              <div className='mb-0 me-2 fs-7'>
                                {notification.name}
                                <div className='text-gray-400 fs-7'>{notification.requested_by}</div>
                              </div>
                            </div>
                            <span className='badge badge-light fs-8'>{notification?.location_id?.name}</span>
                            <span className='badge badge-light fs-8'>{notification?.employee_id?.azadea_employee_name}</span>
                          </div>
                        </Link>
                      </>}
                      {notificationType.model === AZKHelper.TRANSACTIONTYPE.AZ_TRN_SELF_SERVICE && <>
                        <Link to={`/self-service/service/${notification.id}/view`} onClick={hideMenu}>
                          <div key={`notification${index}`} className='d-flex flex-stack pt-2'>
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-35px me-4'>
                                <span>
                                  {' '}
                                  <KTIcon iconName={notification.icon ? notification.icon : ''} />
                                </span>
                              </div>
                              <div className='mb-0 me-2 fs-7'>
                                {notification.name}
                                <div className='text-gray-400 fs-7'>{notification.requested_by}</div>
                              </div>
                            </div>
                            <span className='badge badge-light fs-8'>{notification?.location_id?.name}</span>
                            <span className='badge badge-light fs-8'>{notification?.employee_id?.azadea_employee_name}</span>
                          </div>
                        </Link>
                      </>}
                      {notificationType.model === AZKHelper.TRANSACTIONTYPE.AZ_TRN_ATTENDANCE_ADJUSTMENT && <>
                        <Link to={`/self-service/attendance/${notification.id}/view`} onClick={hideMenu}>
                          <div key={`notification${index}`} className='d-flex flex-stack pt-2'>
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-35px me-4'>
                                <span>
                                  {' '}
                                  <KTIcon iconName={notification.icon ? notification.icon : ''} />
                                </span>
                              </div>
                              <div className='mb-0 me-2 fs-7'>
                                {notification.name}
                                <div className='text-gray-400 fs-7'>{notification.requested_by}</div>
                              </div>
                            </div>
                            <span className='badge badge-light fs-8'>{notification?.location_id?.name}</span>
                            <span className='badge badge-light fs-8'>{notification?.employee_id?.azadea_employee_name}</span>
                          </div>
                        </Link>
                      </>}
                      {notificationType.model === AZKHelper.TRANSACTIONTYPE.AZ_TRN_EXIT_INTERVIEW && <>
                        <Link to={`/self-service/exit-interview/${notification.id}/view`} onClick={hideMenu}>
                          <div key={`notification${index}`} className='d-flex flex-stack pt-2'>
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-35px me-4'>
                                <span>
                                  {' '}
                                  <KTIcon iconName={notification.icon ? notification.icon : ''} />
                                </span>
                              </div>
                              <div className='mb-0 me-2 fs-7'>
                                {notification.name}
                                <div className='text-gray-400 fs-7'>{notification.requested_by}</div>
                              </div>
                            </div>
                            <span className='badge badge-light fs-8'>{notification?.location_id?.name}</span>
                            <span className='badge badge-light fs-8'>{notification?.employee_id?.azadea_employee_name}</span>
                          </div>
                        </Link>
                      </>}
                      {notificationType.model === AZKHelper.TRANSACTIONTYPE.AZ_TRN_TRAVEL_REQUEST && <>
                        <Link to={`/self-service/travel-request/${notification.id}/view`} onClick={hideMenu}>
                          <div key={`notification${index}`} className='d-flex flex-stack pt-2'>
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-35px me-4'>
                                <span>
                                  {' '}
                                  <KTIcon iconName={notification.icon ? notification.icon : ''} />
                                </span>
                              </div>
                              <div className='mb-0 me-2 fs-7'>
                                {notification.name}
                                <div className='text-gray-400 fs-7'>{notification.requested_by}</div>
                              </div>
                            </div>
                            <span className='badge badge-light fs-8'>{notification?.location_id?.name}</span>
                            <span className='badge badge-light fs-8'>{notification?.employee_id?.azadea_employee_name}</span>
                          </div>
                        </Link>
                      </>}
                      {notificationType.model === AZKHelper.TRANSACTIONTYPE.AZ_TRN_TRAVEL_PER_DEIM && <>
                        <Link to={`/self-service/travel-per-diem/${notification.id}/view`} onClick={hideMenu}>
                          <div key={`notification${index}`} className='d-flex flex-stack pt-2'>
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-35px me-4'>
                                <span>
                                  {' '}
                                  <KTIcon iconName={notification.icon ? notification.icon : ''} />
                                </span>
                              </div>
                              <div className='mb-0 me-2 fs-7'>
                                {notification.name}
                                <div className='text-gray-400 fs-7'>{notification.requested_by}</div>
                              </div>
                            </div>
                            <span className='badge badge-light fs-8'>{notification?.location_id?.name}</span>
                            <span className='badge badge-light fs-8'>{notification?.employee_id?.azadea_employee_name}</span>
                          </div>
                        </Link>
                      </>}
                      {notificationType.model !== AZKHelper.TRANSACTIONTYPE.AZ_TRN_LEAVE &&
                        notificationType.model !== AZKHelper.TRANSACTIONTYPE.AZ_TRN_SELF_SERVICE &&
                        notificationType.model !== AZKHelper.TRANSACTIONTYPE.AZ_TRN_ATTENDANCE_ADJUSTMENT &&
                        notificationType.model !== AZKHelper.TRANSACTIONTYPE.AZ_TRN_EXIT_INTERVIEW &&
                        notificationType.model !== AZKHelper.TRANSACTIONTYPE.AZ_TRN_TRAVEL_REQUEST &&
                        notificationType.model !== AZKHelper.TRANSACTIONTYPE.AZ_TRN_TRAVEL_PER_DEIM && <>
                          <Link to={`/transactions/${notificationType?.model?.replaceAll('az.trn.', '').replaceAll('.', '-')}/${notification.id}/edit`} onClick={hideMenu}>
                            <div key={`notification${index}`} className='d-flex flex-stack pt-2'>
                              <div className='d-flex align-items-center'>
                                <div className='symbol symbol-35px me-4'>
                                  <span>
                                    {' '}
                                    <KTIcon iconName={notification.icon ? notification.icon : ''} />
                                  </span>
                                </div>
                                <div className='mb-0 me-2 fs-7'>
                                  {notification.name}
                                  <div className='text-gray-400 fs-7'>{notification.requested_by}</div>
                                </div>
                              </div>
                              <span className='badge badge-light fs-8'>{notification?.location_id?.name}</span>
                              <span className='badge badge-light fs-8'>{notification?.employee_id?.azadea_employee_name}</span>
                            </div>
                          </Link>
                        </>}

                    </>



                  ))}
                </div>
              </>
            ))}
          </div>
        </div>

        {/* <div className='tab-pane fade' id='kt_topbar_notifications_2' role='tabpanel'>
          <div className='d-flex flex-column px-9'>
            <div className='pt-10 pb-0'>
              <h3 className='text-dark text-center fw-bolder'>Get Pro Access</h3>

              <div className='text-center text-gray-600 fw-bold pt-1'>
                Outlines keep you honest. They stoping you from amazing poorly about drive
              </div>

              <div className='text-center mt-5 mb-9'>
                <a
                  href='#'
                  className='btn btn-sm btn-primary px-6'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_upgrade_plan'
                >
                  Upgrade
                </a>
              </div>
            </div>

            <div className='text-center px-4'>
              <img className='mw-100 mh-200px' alt='metronic' src={useIllustrationsPath('1.png')} />
            </div>
          </div>
        </div>

        <div className='tab-pane fade' id='kt_topbar_notifications_3' role='tabpanel'>
          <div className='scroll-y mh-325px my-5 px-8'>
            {defaultLogs.map((log, index) => (
              <div key={`log${index}`} className='d-flex flex-stack py-4'>
                <div className='d-flex align-items-center me-2'>
                  <span className={clsx('w-70px badge', `badge-light-${log.state}`, 'me-4')}>
                    {log.code}
                  </span>

                  <a href='#' className='text-gray-800 text-hover-primary fw-bold'>
                    {log.message}
                  </a>

                  <span className='badge badge-light fs-8'>{log.time}</span>
                </div>
              </div>
            ))}
          </div>
          <div className='py-3 text-center border-top'>
            <Link
              to='/crafted/pages/profile'
              className='btn btn-color-gray-600 btn-active-color-primary'
            >
              View All <KTIcon iconName='arrow-right' className='fs-5' />
            </Link>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export { HeaderNotificationsMenu }
