import axios, { AxiosResponse } from 'axios'
import { Payslip } from './model'
import { ID, Response } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper'
import useFixServerClientData from 'app/utilities/fix-Server-Client-Data_v_2'

const MODEL_NAME = 'hr.employee'
const URL = `${API_URL}/${MODEL_NAME}`

const PutPayslip = (id: ID, month: string, year: string): Promise<Payslip> => {
  const { FixServerData } = useFixServerClientData();
  return axios
    .put(`${URL}/${id}/payslip?month=${month}&year=${year}`, { 'month': month, 'year': year })
    .then((response: AxiosResponse<Response<Payslip>>) => response.data)
    .then((response: Response<Payslip>) => FixServerData(response, true))
}
export { PutPayslip }


