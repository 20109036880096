import axios, {AxiosResponse} from 'axios'
import { FinalSettlement } from './model'
import { ID, Response } from '_metronic/helpers'
import moment from 'moment'
import { AZKHelper } from 'app/modules/components/azkHelper'
import { API_URL } from '_azkatech/azkHelper' 
import { GrouphHelper } from '_azkatech/_models/_group'
import useFixServerClientData from 'app/utilities/fix-Server-Client-Data'

const MODEL_NAME = 'az.trn.final.settlement'
const URL = `${API_URL}/${MODEL_NAME}`
const GET_URL = `${API_URL}/${MODEL_NAME}/`
const  ELEMENT_INCLUDE_FIELDS = `('id', 'creation_info', 'group_id', 'az_country_id', 'element_id', 'amount', 'comment', 'override_amount', 'override_comment', 'pay_in_payroll')`
const INCLUDE_FIELDS = `'id', 'name', 'employee_id', 'type_id', 'x_state', 'x_state_view', 'restarted', 'az_country_id', 'az_company_id', 'payroll_id', 'brand_id', 'azadea_id', 'azadea_employee_name', 'branch_id', 'section_id', 'grade_id', 'job_id', 'salary', 'as_effective_date', 'time_set', 'target_as_effective_date', 'can_edit', 'current_approval_ids', 'separation_trn_id', 'can_override', ('allowed_group_ids', [${GrouphHelper.INCLUDE_FIELDS}]), ('element_ids', [${ELEMENT_INCLUDE_FIELDS}]) `
const FS_INCLUDE_FIELDS = `'id', 'name', 'employee_id', 'type_id', 'x_state', 'x_state_view', 'can_review', 'can_write', 'can_delete', 'validated', 'rejected', 'restarted', 'az_country_id', 'az_company_id', 'payroll_id', 'brand_id', 'azadea_id', 'azadea_employee_name', 'branch_id', 'section_id', 'grade_id', 'job_id', 'salary', 'as_effective_date', 'time_set', 'target_as_effective_date', 'can_edit', 'current_approval_ids', 'clearance_trn_id', 'salary_day', 'notice_period', 'leave_salary', 'indemnity', 'adjustment', 'other_deduction', 'ticket_amount', 'ticket_amount_val', 'other_addition', 'indemnity_zero', 'partial_payment', 'allowed_paramters', 'can_approve', 'can_reject', 'can_review', 'can_regenerate', 'can_view', 'can_download_pdf', 'can_upload_pdf', 'can_to_pay', 'can_completed', 'can_c2p_partial_done', ('steps', [('id', 'name')]), 'need_validation', 'marked_to_pay_partial', 'marked_to_pay', 'marked_file_uploaded', 'can_edit_params','comment'`

// const getFinalSettlement = (domain: string): Promise<FinalSettlementQueryResponse> => {
//   return axios
//     .get(`${GET_URL}?filters=[${domain}]`)
//     .then((d: AxiosResponse<FinalSettlementQueryResponse>) => d.data)
// }


const GetFinalSettlementByClearanceId = async (clearance_id: ID): Promise<FinalSettlement> => {
  const { FixServerData } = await useFixServerClientData();
  return axios
    .get(`${URL}?filters=[('clearance_trn_id','=',${clearance_id})]&include_fields=[${FS_INCLUDE_FIELDS}]`)
    .then((response: AxiosResponse<Response<FinalSettlement>>) => response.data)
    .then((response: Response<FinalSettlement>) => FixServerData(response['results'][0]))
}

const getFinalSettlementById = (id: ID): Promise<FinalSettlement> => {
  return axios
    .get(`${URL}?filters=[('id','=',${id})]&include_fields=[${INCLUDE_FIELDS}]`)
    .then((response: AxiosResponse<Response<FinalSettlement>>) => response.data)
    .then((response: Response<FinalSettlement>) => response['results'][0])
}

const CreateFinalSettlement = async (transaction: FinalSettlement): Promise<FinalSettlement | undefined> => {
  const { FixClientData } = useFixServerClientData();
  const shallowTransaction = await FixClientData(transaction)
  return axios
    .post(URL, shallowTransaction)
    .then((response: AxiosResponse<FinalSettlement>) => response.data)
}

const updateFinalSettlement = (transfer: FinalSettlement): Promise<FinalSettlement | undefined> => {
  const shallowTransaction = fixDate(transfer)
  return axios
    .put(`${URL}/${transfer.id}`, shallowTransaction)
    .then((response: AxiosResponse<Response<FinalSettlement>>) => response.data)
    .then((response: Response<FinalSettlement>) => response.data)
}

const deleteFinalSettlement = (transactionId: number): Promise<FinalSettlement | undefined> => {
  return axios
    .delete(`${URL}/${transactionId}`)
    .then((response: AxiosResponse<Response<FinalSettlement>>) => response.data)
    .then((response: Response<FinalSettlement>) => response.data)
}
export { getFinalSettlementById, CreateFinalSettlement, updateFinalSettlement, MODEL_NAME, deleteFinalSettlement, GetFinalSettlementByClearanceId}
function fixDate(transfer: FinalSettlement) {
  let shallowTransaction = Object.assign({}, transfer);
  shallowTransaction.as_effective_date = moment(transfer.as_effective_date).utc(true)?.format(AZKHelper.BACKEND_DATE_FORMAT)
  shallowTransaction.target_as_effective_date = moment(transfer.target_as_effective_date).utc(true)?.format(AZKHelper.BACKEND_DATE_FORMAT)
  return shallowTransaction
}

