import { useEffect, useState } from 'react';
import axios from 'axios';
import azkSelect from "_azkatech/azkSelect"
import azkDataTable from "_azkatech/azkDataTable"
import { useNavigate } from 'react-router';
import { KTCard, KTIcon } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper';
import Swal from 'sweetalert2';
import { Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { AZKHelper } from 'app/modules/components/azkHelper';
import { CSVLink } from 'react-csv'
import ReactDOM from 'react-dom';
import { DeleteSalaryStructureSetting } from './_requests';
import useFixServerClientData from 'app/utilities/fix-Server-Client-Data';

const TransactionList = () => {
    const [listDataDomain, setListDataDomain] = useState('');
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [refresh, setRefresh] = useState<boolean>(true)
    const navigate = useNavigate()
    const MODEL_NAME = "az.salary.structure.setting"

    const [selectedExcelData, setSelectedExcelData] = useState<any[]>([]);
    const [pageLoaded, setPageLoaded] = useState<boolean>(false);
    const [selectedApplyData, setSelectedApplyData] = useState<any[]>([]);
    // #region Transfer List as DataTable
    const TRN_list_LOCAL_STORAGE_FILTER_KEY = 'azk-trn-sss-list-filter-hr-lite'
    const fields = "'id', 'name', 'applied', 'as_effective_date', ('az_country_id',('name')), ('brand_id',('name')), ('location_id',('name')), ('job_id',('name')), ('grade_id',('grade_name')), ('employment_category_id',('code')), 'person_type', 'has_com', 'com', 'package', 'trigger_compute' "
    const updateListDataDomain = (search, country, location, brand, job_id) => {
        let filter = {}
        filter['showFilters'] = showFilters
        let conds: string[] = [];
        if (country !== '' && country && country != 'undefined') {
            conds.push(`('az_country_id','=', ${country})`)
            if (handleChangeSelection) filter['az_country_id'] = country
        }
        if (brand !== '' && brand && brand != 'undefined') {
            conds.push(`('brand_id','=', ${brand})`)
            if (handleChangeSelection) filter['brand_id'] = brand
        }
        if (location !== '' && location) {
            conds.push(`('location_id','=', ${location})`)
            if (handleChangeSelection) filter['location_id'] = location
        }
        if (job_id !== '' && job_id) {
            conds.push(`('job_id','=', ${job_id})`)
            if (handleChangeSelection) filter['job_id'] = job_id
        }
        if (search !== '' && search && search != 'undefined') {
            if (handleChangeSelection) filter['search'] = search
            search = encodeURIComponent(search)
            conds.push(`'|', '|',('az_country_id','ilike', '${search}'), ('location_id','ilike', '${search}'), ('brand_id','ilike', '${search}')`)
        }
        const lsValue = JSON.stringify(filter)
        if (handleChangeSelection) localStorage.setItem(TRN_list_LOCAL_STORAGE_FILTER_KEY, lsValue)
        setSelectedApplyData([])
        return conds.join(',');
    }

    const handleEditBtnClick = (id) => {
        navigate(`/salary-structure/settings/${id}`, { state: { 'transactionId': id }, replace: true });
    }

    const handleDeleteBtnClick = (id) => {
        Swal.fire({
            position: "center",
            title: 'Delete',
            text: 'Delete salary structure setting confirm?',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
        }).then((result) => {
            document.body.classList.add('page-loading')
            /* Read more about isConfirmed, isDenied below */
            setRefresh(false)
            if (result.isConfirmed && id) {
                DeleteSalaryStructureSetting(parseInt(id)).then(() => {
                    document.body.classList.remove('page-loading')
                    setRefresh(true)
                    Swal.fire({
                        position: "top-right",
                        title: 'Deleted!',
                        text: 'A setting had been deleted!',
                        icon: "warning",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                })
            } else if (result.isDenied) {
                Swal.fire('Salary structure was not deleted', '', 'info')
            }
        })
    }


    const { FixClientData } = useFixServerClientData();
    const handleApplyBtnClick = (settingId: number) => {
        let selectedApplySettings = selectedApplyData;
        if (!selectedApplySettings || selectedApplySettings.length === 0) {
            let selectedApplySettings: any[] = [];
            selectedApplySettings.push(settingId)
        } else {
            settingId = selectedApplySettings[0]
        }
        if (settingId) {
            Swal.fire({
                position: "center",
                title: "Apply",
                text: "Are you sure you want to apply this setting?",
                showCancelButton: true,
                confirmButtonText: 'Confirm',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    document.body.classList.add('page-loading')
                    axios.put(`${API_URL}/${MODEL_NAME}/${settingId}/action_apply_changes`, { 'setting_ids': selectedApplySettings }).then(data => {
                        Swal.fire({
                            position: "top-right",
                            title: 'Success!',
                            text: 'Your Setting had been applied!',
                            icon: "success",
                            showConfirmButton: false,
                            timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                            toast: true,
                            iconColor: 'white',
                            customClass: {
                                popup: 'colored-toast'
                            },
                            timerProgressBar: true
                        })
                        document.body.classList.remove('page-loading')
                    }, err => {
                        document.body.classList.remove('page-loading')
                    })
                }
            })
        }
    }
    const columns = [
        // {
        //     name: 'Setting',
        //     selector: row => row.name,
        //     sortable: true,
        //     tech_name: 'name'
        // },
        {
            name: 'Country',
            selector: row => row.az_country_id ? row.az_country_id.name : undefined,
            sortable: true,
            //  width: '10%',
            tech_name: 'az_country_id',
            cell: (d) => [(<>
                <OverlayTrigger
                    placement='top'
                    overlay={
                        <Tooltip id='tooltip-user-name'>{d.az_country_id.name}</Tooltip>} children={
                            <Row>
                                {d.az_country_id.name}
                            </Row>
                        }></OverlayTrigger>
            </>)]
        },
        {
            name: 'Brand',
            //  width: '10%',
            selector: row => row.brand_id ? row.brand_id.name : undefined,
            sortable: true,
            tech_name: 'brand_id',
            cell: (d) => [(<>
                <OverlayTrigger
                    placement='top'
                    overlay={
                        <Tooltip id='tooltip-user-name'>{d.brand_id.name}</Tooltip>} children={
                            <Row>
                                {d.brand_id.name}
                            </Row>
                        }></OverlayTrigger>
            </>)]
        },
        {
            name: 'location',
            //  width: '10%',
            selector: row => row.location_id ? row.location_id.name : undefined,
            sortable: true,
            tech_name: 'location_id',
            cell: (d) => [(<>

                <OverlayTrigger
                    placement='top'
                    overlay={
                        <Tooltip id='tooltip-user-name'>{d.location_id?.name}</Tooltip>} children={
                            <Row>
                                {d.location_id?.name}
                            </Row>
                        }></OverlayTrigger>
            </>)]
        },
        {
            name: 'Title',
            //  width: '10%',
            selector: row => row.job_id ? row.job_id.name : undefined,
            sortable: true,
            tech_name: 'job_id',
            cell: (d) => [(<>
                <OverlayTrigger
                    placement='top'
                    overlay={
                        <Tooltip id='tooltip-user-name'>{d.job_id.name}</Tooltip>} children={
                            <Row>
                                {d.job_id.name}
                            </Row>
                        }></OverlayTrigger>
            </>)
            ]
        },
        {
            name: 'Grade',
            width: '8%',
            selector: row => row.grade_id ? row.grade_id.grade_name : undefined,
            sortable: true,
            tech_name: 'grade_id'
        },
        {
            name: 'TimeSet',
            width: '8%',
            selector: row => row.employment_category_id ? row.employment_category_id.code : undefined,
            sortable: true,
            tech_name: 'employment_category_id'
        },
        {
            name: 'Type',
            width: '7%',
            selector: row => row.person_type ? row.person_type : undefined,
            sortable: true,
            tech_name: 'person_type'
        },
        {
            name: 'Has Com',
            width: '7%',
            selector: row => row.has_com ? 'Yes' : 'No',
            sortable: true,
            tech_name: 'has_com'
        },
        {
            name: 'Package',
            //  width: '10%',
            selector: row => row.package ? row.package : undefined,
            sortable: true,
            tech_name: 'package'
        },
        {
            name: 'Com. %',
            width: '7%',
            selector: row => row.com ? row.com : undefined,
            sortable: true,
            tech_name: 'com'
        },
        {
            name: 'Effective Date',
            //  width: '10%',
            selector: row => row.as_effective_date ? row.as_effective_date : undefined,
            sortable: true,
            tech_name: 'as_effective_date',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {moment(d.as_effective_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                </Row>
            </>)]
        },
        // {
        //   name: "Director",
        //   selector: "director",
        //   sortable: true,
        //   cell: (d) => (
        //     <a href="https://google.com" target="_blank" className="dlink">
        //       {d.director}
        //     </a>
        //   )
        // },
        // {
        //   name: "Genres",
        //   selector: "genres",
        //   sortable: true,
        //   cell: (d) => <span>{d.genres.join(", ")}</span>
        // },
        {
            name: "Action",
            sortable: false,
            selector: undefined,
            cell: (d) => [
                <div className='w-100 m-0 d-flex justify-content-center'>
                    {!d.applied && (
                        <Form.Check
                            className='p-1 mt-2'
                            id={'to_apply' + d.id}
                            key={'to_apply_key' + d.id}
                            type="checkbox"
                            name={'to_apply' + d.id}
                            onChange={(value) => {
                                handleSelectSetting(value.target.checked, d.id)
                            }
                            }
                        />
                    )}
                    <span key={d.id}
                        onClick={handleEditBtnClick.bind(this, d.id)}
                        className="btn btn-sm btn-icon btn-active-light-primary me-1"
                    ><KTIcon iconName='pencil' className='fs-3' /></span>
                    {!d.applied && (<span key={d.id}
                        onClick={handleDeleteBtnClick.bind(this, d.id)}
                        className="btn btn-sm btn-icon btn-active-light-primary me-1"
                    ><KTIcon iconName='trash' className='fs-3' /></span>)}
                </div>
            ]
        }
    ];

    const handleSelectSetting = ((selected, setting_id) => {
        let excelDataListIds: any[] = []
        excelDataListIds = selectedApplyData
        if (selected) {
            excelDataListIds.push(setting_id)
            setSelectedApplyData(excelDataListIds)
        } else {
            const index = excelDataListIds.indexOf(setting_id)
            if (index >= 0) {
                excelDataListIds.splice(index, 1)
            }
        }
        setSelectedApplyData(excelDataListIds)
        if (excelDataListIds.length == 0) {
            setShowApplyBtn(false)
        } else {
            setShowApplyBtn(true)
        }
    })
    const getDataToExport = async (data: any[]) => {
        let excelDataList: any[] = []
        if (data) {
            await data.forEach(row => {
                const object = builtExportObject(row)
                excelDataList.push(object)
            })
            setSelectedExcelData(excelDataList)
        }
    }
    const hitExport = (data: any[]) => {
        // if (data && data.length > 0) {
        //     const inputElement = document.querySelector('#export_data_excel') as HTMLInputElement
        //     inputElement.click()
        // }
        document.body.classList.remove('page-loading')
        if (data && data.length > 0) {
            const parentElement = document.createElement('div');
            const element = <div><CSVLink data={data} filename={'Settings List ' + moment().format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)} id="export_data_excel" ></CSVLink></div>
            ReactDOM.render(element, parentElement);
            document.body.appendChild(parentElement)
            const inputElement = document.querySelector('#export_data_excel') as HTMLInputElement
            inputElement.click()
            document.body.removeChild(parentElement)
        }
    }

    const exportSelectedDataToExcel = async () => {
        document.body.classList.add('page-loading')
        hitExport(selectedExcelData)
    }

    const applySelected = async () => {
        handleApplyBtnClick(selectedExcelData[0])
    }

    const exportDataToExcel = async () => {
        let excelDataList: any[] = []
        document.body.classList.add('page-loading')
        await axios.get(`${API_URL}/${MODEL_NAME}?filters=[${listDataDomain}]&include_fields=[${fields}]&order='create_date desc, as_effective_date desc, name desc'`).then(async response => {
            const remoteData = response.data?.results ? response.data.results : []
            await remoteData.forEach(row => {
                const object = builtExportObject(row)
                excelDataList.push(object)
            })
            if (excelDataList && excelDataList.length > 0) {
                hitExport(excelDataList)
            }
        })
    }

    const builtExportObject = (row) => {
        const object = {}
        object['Name'] = row.name
        object['Creation Date'] = moment(row.create_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)
        object['Effective Date'] = moment(row.as_effective_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)
        object['Country'] = row.az_country_id.name
        object['Location'] = row.location_id?.name
        object['Brand'] = row.brand_id.name
        object['Title'] = row.job_id.name
        object['Grade'] = row.grade_id.grade_name
        if (row.applied) object['Applied'] = 'Yes'
        else object['Applied'] = 'No'
        return object
    }
    const table = azkDataTable({
        modelName: MODEL_NAME,
        fields: fields,
        dataDomain: listDataDomain,
        columns: columns,
        dataOrder: 'as_effective_date desc, name desc',
        exportFunction: getDataToExport,
        pageReady: pageLoaded
    })

    // #endregion Transfer List
    const fillFilterValues = async () => {
        const filterVals: string | null = localStorage.getItem(TRN_list_LOCAL_STORAGE_FILTER_KEY)
        if (filterVals) {
            const jsonObj = JSON.parse(filterVals)
            setTimeout(() => {
                if (jsonObj['search']) setSearchTerm(jsonObj['search'])
                if (jsonObj['az_country_id']) setCountry(jsonObj['az_country_id'])
                if (jsonObj['brand_id']) setBrandefaultValue(jsonObj['brand_id'])
                if (jsonObj['location_id']) setLocation(jsonObj['location_id'])
                if (jsonObj['job_id']) setTitle(jsonObj['job_id'])
                setListDataDomain(updateListDataDomain(jsonObj['search'], jsonObj['az_country_id'], jsonObj['location_id'], jsonObj['brand_id'], jsonObj['job_id']));
            }, 500)
        }
        setTimeout(() => {
            setPageLoaded(true);
        }, 1000)
    }

    useEffect(() => {
        setSelectedApplyData([])
        document.body.classList.remove('page-loading')
        fillFilterValues()
    }, []);
    // #region Country Dropdown
    const [az_country, setCountry] = useState('');

    const handleCountryChange = (val) => {
        setCountry(val);
        setLocationDomain(val ? `('country_id','=', ${val})` : '');
        setTitleDomain(val ? `('country_id','=', ${val})` : '');
        setListDataDomain(updateListDataDomain(searchTerm, val, az_location, az_brand, az_title));
    }

    const az_country_select = azkSelect({
        compID: "az_country_select",
        modelName: "az.country",
        valueField: "id",
        titleField: "name",
        allowEmpty: true,
        isSearchable: true,
        defaultValue: az_country,
        onChange: handleCountryChange
    });
    // #endregion Country Dropdown

    // #region Company Dropdown
    const [az_location, setLocation] = useState('');
    const [locationDomain, setLocationDomain] = useState('');

    const handleLocationChange = (val) => {
        setLocation(val);
        if (val !== '') {
            setListDataDomain(updateListDataDomain(searchTerm, az_country, val, az_brand, az_title));
        } else {
            setBrandDomain('');
        }
    }

    const az_location_select = azkSelect({
        compID: "az_location_select",
        modelName: "az.location",
        valueField: "id",
        titleField: "name",
        dataDomain: locationDomain,
        defaultValue: az_location,
        allowEmpty: true,
        onChange: handleLocationChange
    });
    // #endregion Company Dropdown

    // #region Brand Dropdown
    const [az_brand, setBrand] = useState('');
    const [brandDomain, setBrandDomain] = useState('');
    const [brandefaultValue, setBrandefaultValue] = useState('');

    const handleBrandChange = (val) => {
        console.log(val ? `('brand_id','=', ${val})` : '')
        setBrand(val);
        setBrandefaultValue(val)
        setListDataDomain(updateListDataDomain(searchTerm, az_country, az_location, val, az_title));
    }

    const az_brand_select = azkSelect({
        compID: "az_brand_select",
        modelName: "az.brand",
        valueField: "id",
        titleField: "name",
        dataDomain: brandDomain,
        allowEmpty: true,
        onChange: handleBrandChange,
        defaultValue: brandefaultValue
    });
    // #endregion Brand Dropdown

    // #region Job Dropdown
    const [az_title, setTitle] = useState('');
    const [titleDomain, setTitleDomain] = useState('');

    const handleTitleChange = (val) => {
        setTitle(val);
        if (val !== '') {
            setTitle(val)
            setListDataDomain(updateListDataDomain(searchTerm, az_country, az_location, az_brand, val));
        } else {
            setBrandDomain('');
        }
    }

    const az_title_select = azkSelect({
        compID: "az_title_select",
        modelName: "hr.job",
        valueField: "id",
        titleField: "name",
        dataDomain: titleDomain,
        defaultValue: az_title,
        allowEmpty: true,
        onChange: handleTitleChange
    });
    // #endregion Job Dropdown

    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [showApplyBtn, setShowApplyBtn] = useState<boolean>(false);
    const [handleChangeSelection, setHandleChangeSelection] = useState<boolean>(false);

    function showFilter() {
        setShowFilters(!showFilters)
        setHandleChangeSelection(true)
    }
    return (
        <>
            <KTCard>
                <div className='card-header'>
                    <div className='card-title'>
                        {/* begin::Search */}
                        <div className='d-flex align-items-center position-relative my-1'>
                            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                            <input
                                type='text'
                                data-kt-user-table-filter='search'
                                className='form-control form-control-solid w-300px ps-14'
                                placeholder='Search Setting...'
                                value={searchTerm}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value)
                                    setListDataDomain(updateListDataDomain(e.target.value, az_country, az_location, az_brand, az_title))
                                }}
                            />
                        </div>
                        {/* end::Search */}
                    </div>
                    {/* begin::Card toolbar */}
                    <div className='card-toolbar'>
                        {/* begin::Group actions */}
                        {/* begin::Export */}
                        {showApplyBtn &&
                            (<button type='button' onClick={applySelected} className={`btn btn-light-primary me-3 btn-sm `}>
                                <KTIcon iconName='save-2' className='fs-2' />
                                Apply Selected
                            </button>)
                        }
                        <button type='button' className='btn btn-light-primary me-3 btn-sm' onClick={() => showFilter()}>
                            <KTIcon iconName='filter-search' className='fs-2' />
                            Filter
                        </button>
                        {/* <CSVLink data={excelData} filename={'Transaction List ' + moment().format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)} id="export_data_excel" ></CSVLink> */}
                        <button type='button' onClick={exportSelectedDataToExcel} className={`btn btn-light-primary me-3 btn-sm ${selectedExcelData && selectedExcelData.length > 0 ? '' : 'd-none'}`}>
                            <KTIcon iconName='exit-up' className='fs-2' />
                            Export Selected
                        </button>
                        <button type='button' className={`btn btn-light-primary me-3 btn-sm ${selectedExcelData && selectedExcelData.length > 0 ? 'd-none' : ''}`} onClick={exportDataToExcel} >
                            <KTIcon iconName='exit-up' className='fs-2' />
                            Export
                        </button>
                        {/* end::Export */}
                        <button type='button' className='btn btn-light-primary me-3 btn-sm' onClick={() => navigate('/salary-structure/settings/new')}>
                            <KTIcon iconName='plus' className='fs-2' />
                            Add
                        </button>
                        {/* end::Group actions */}
                    </div>
                    {/* end::Card toolbar */}
                </div>
            </KTCard>
            {showFilters &&
                <KTCard className='mt-3'>
                    <div className='card-body'>
                        <div className='row m-0'>
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-6 my-auto col-12 col-md-4'>Country</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {handleChangeSelection && (<>
                                    {az_country_select}
                                </>)}
                                {/* end::Input */}
                            </div>
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-6 my-auto col-12 col-md-4'>Brand</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {handleChangeSelection && (<>
                                    {az_brand_select}
                                </>)}
                                {/* end::Input */}
                            </div>
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-6 my-auto col-12 col-md-4'>Location</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {handleChangeSelection && (<>
                                    {az_location_select}
                                </>)}
                                {/* end::Input */}
                            </div>
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-6 my-auto col-12 col-md-4'>Title</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {handleChangeSelection && (<>
                                    {az_title_select}
                                </>)}
                                {/* end::Input */}
                            </div>
                        </div>
                    </div>
                </KTCard>
            }
            <KTCard className='mt-3'>
                {refresh && table}
            </KTCard>
        </>)
}

export default TransactionList;


