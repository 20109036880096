/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
// import { FC } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getOauthProviders, getUserByToken, login } from '../core/_requests'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'

//email: Yup.string().email('Wrong email format')
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

type oauthProvider = {
  name?: string,
  body?: string,
  auth_link?: string
}
/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [authProviders, setAuthProviders] = useState<oauthProvider[]>([])
  const { saveAuth, setCurrentUser } = useAuth()

  const fillOauthProviders = () => {

    if ((authProviders && authProviders.length === 0 ) && !loaded)
      getOauthProviders().then(providers => {
        setAuthProviders(providers['providers'])
      })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await login(values.email, values.password)
        saveAuth(auth)
        const user = await getUserByToken(auth)
        setCurrentUser(user)
      } catch (error: any) {
        console.error(error)
        saveAuth(undefined)
        // setStatus('The login details are incorrect')
        setStatus(error.message)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })


  useEffect(() => {
    fillOauthProviders()
    setLoaded(true)
  })
  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
        </div>
        {/* begin::Heading */}

        {/* begin::Form group */}
        {/* <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          )}
        </div> */}
        {/* end::Form group */}

        {/* begin::Form group */}
        {/* <div className='fv-row mb-3'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          <input
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div> */}
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div />

          {/* begin::Link */}
          {/* <Link to='/web/reset_password' className='link-primary'>
            Forgot Password ?
          </Link> */}
          {/* end::Link */}
        </div>
        {/* end::Wrapper */}

        {/* begin::Action */}
        {/* <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div> */}
        {/* end::Action */}

        {/* {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : (<></>)} */}
      </form>
      {authProviders && authProviders.length > 0 ? (
        authProviders.map((provider) => (
          <>
            <div className='mb-lg-15 text-center'>
              <h4><a href={provider.auth_link}>{provider.body}</a></h4>
            </div>
          </>
        ))
      ) : (<></>)}
    </>
  )
}
