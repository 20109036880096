import AzkSelect from "_azkatech/azkSelect";
import { EmployeeDetail, EmpoyeeModel } from "app/modules/components/employeeDetail/employeeDetail";
import axios from "axios";
import * as Yup from 'yup'
import { AttendanceAdjustment, AttendanceAdjustmentLine, initialTransfaction } from "./model";
import { FC, useEffect, useRef, useState } from "react";
import { ErrorMessage, Field, FieldArray, Formik, FormikProps, FormikValues } from 'formik'
import { CreateAttendanceAdjustment, UpdateAttendanceAdjustment, GetAttendanceAdjustmentById, MODEL_NAME } from "./_requests";
import "react-datepicker/dist/react-datepicker.css";
import { AzkStatusRibbon } from "_azkatech/azkStatusRibbon";
import AzkActionButtons from "_azkatech/azkActionButtons";
import { Form, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { ID, isNotEmpty, KTIcon } from "_metronic/helpers";
import { useNavigate } from "react-router";
import { API_URL } from "_azkatech/azkHelper";
import { AZKHelper } from "app/modules/components/azkHelper";
import { AzkChatter } from "_azkatech";
import { checkTrnsactionTypeAccessId } from "app/utilities/transactionTyperequests";
import { useParams } from "react-router-dom";
import { GetEmployeeByIdAttendance, GetEmployeeByIdNoSalary, GetEmployeeByUserId } from "app/modules/components/employeeDetail/_requests";
import { AUTH_LOCAL_STORAGE_KEY, AuthModel } from "app/modules/auth";
import moment from "moment";
import { AsyncPaginate } from "react-select-async-paginate";

type Props = {
}
let number_of_back_day = 0
let allowed_time_diff = 0
let is_submitting_form = false
const editTransactionSchema = Yup.object().shape({
    employee_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Employee is required'),
    adjustment_reason_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Attendance Adjustment reason is required'),
    line_ids: Yup.array().of(
        Yup.object().shape({
            date: Yup.date().transform((value) => !moment(value).isValid() ? undefined : value).required('Date is required')
                .test('min', (min) => { return `Date must be equal or after ${moment().clone().subtract(number_of_back_day, 'day').format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}` }, function (value) {
                    const start_date = moment().clone().subtract(number_of_back_day, 'day').startOf('day')
                    return !start_date.isAfter(moment(value));
                })
                .max(new Date(), 'Date must be equal or before today`s date'),
            hour: Yup.string().required('Field id is required'),
            min: Yup.string().required('Field id is required'),
            adjustment_type: Yup.string().required('Field id is required'),
        })
    )
})

const Transactionform: FC<Props> = () => {
    const { id } = useParams();
    const Swal = require('sweetalert2')
    const intl = useIntl();
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<AttendanceAdjustment>>(null);
    const [attendanceAdjustmentTypeDomain, setAttendanceAdjustmentTypeDomain] = useState('');
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [transactionClosed, setTransactionClosed] = useState<boolean>(false);
    const [canValidate, setCanValidate] = useState<boolean>(false);
    const [employee, setEmployee] = useState<EmpoyeeModel>();
    const [transactionState, setTransactionState] = useState<string>('draft');
    const [transactionStateName, setTransactionStateName] = useState<string>('Draft');
    const [hitSend, setHitSend] = useState<boolean>(false);
    const [objectId, setObjectId] = useState<number>();

    const [formFields, setFormFields] = useState<AttendanceAdjustmentLine[]>();

    const [mangerUser, setMangerUser] = useState<boolean>(false);
    const [shopUser, setShopUser] = useState<boolean>(true);
    const [employeeDomain, setEmployeeDomain] = useState<string>('');
    const checkUserType = () => {
        let lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
        if (lsValue) {
            const auth: AuthModel = JSON.parse(lsValue) as AuthModel
            if (auth && auth.uid) {
                GetEmployeeByUserId(auth.uid).then(response => {
                    if (response) {
                        let domain = ``
                        if (response.is_back_office_fr && response.is_back_office_fr !== null) setShopUser(!response?.is_back_office_fr)
                        if (response.is_manager_fr) setMangerUser(response?.is_manager_fr)
                        // if (response.is_manager_fr && response.is_back_office_fr) {
                        //     domain = domain.concat(`'|', ('id', '=', ${response.id})`)
                        // }
                        // if (!response.is_manager_fr && !response.is_back_office_fr) {
                        //     domain = domain.concat(`('id', '=', ${response.id})`)
                        // }
                        // if (!response.is_manager_fr && response.is_back_office_fr) {
                        //     domain = domain.concat(`('id', '=', ${response.id})`)
                        // }
                        if (response.is_manager_fr && !response.is_back_office_fr) {
                            domain = domain.concat(`'|', '|', ('id', '=', ${response.id}), ('parent_id', '=', ${response.id}), ('super_parent_id', '=', ${response.id})`)
                        } else {
                            domain = domain.concat(`('id', '=', ${response.id})`)
                        }
                        setEmployeeDomain(domain)
                    }
                })
            }
        }
    }

    const handleEmployeeChange = async function (employeeId, AttendanceAdjustmentData): Promise<{}> {
        document.body.classList.add('page-loading')
        if (employeeId) {
            setEmployee(undefined)
            GetEmployeeByIdAttendance(employeeId).then(response => {
                if (response) {
                    const tempEmployee = response
                    setEmployee(response)
                    const start_of_the_month = moment().startOf('month')
                    const today = moment().clone()
                    if (tempEmployee.allwoed_back_number_of_day === -1 || tempEmployee.allwoed_back_number_of_day === null) {
                        number_of_back_day = moment().diff(start_of_the_month, 'day')
                    } else {
                        if (today.subtract(tempEmployee.allwoed_back_number_of_day, 'day').isBefore(start_of_the_month)) {
                            number_of_back_day = moment().diff(start_of_the_month, 'day')
                        } else {
                            number_of_back_day = tempEmployee.allwoed_back_number_of_day
                        }
                    }
                    allowed_time_diff = tempEmployee.allowed_time_diff
                    formRef.current?.setValues({
                        employee_id: tempEmployee.id,
                        az_country_id: tempEmployee.az_country_id?.id,
                        az_company_id: tempEmployee.az_company_id?.id,
                        branch_id: tempEmployee.branch_id?.id,
                        brand_id: tempEmployee.brand_id?.id,
                        grade_id: tempEmployee.grade_id?.id,
                        section_id: tempEmployee.section_id?.id,
                        job_id: tempEmployee.job_id?.id,
                        // time_set: tempEmployee.time_set,
                        // ---------------------------------------------------
                        line_ids: [
                            {
                                date: moment().format(AZKHelper.FRONTEND_DATE_FORMAT), hour: undefined, min: undefined, adjustment_type: undefined,
                                id: 0
                            },]
                    })
                    if (AttendanceAdjustmentData) {
                        pobulateFormData(AttendanceAdjustmentData)
                    } else {
                    }
                    document.body.classList.remove('page-loading')
                } else {
                    document.body.classList.remove('page-loading')
                }
            });
        } else {
            document.body.classList.remove('page-loading')
            formRef.current?.resetForm();
            setEmployee(undefined)
            formRef.current?.setValues({
                employee_id: undefined,
                az_country_id: undefined,
                az_company_id: undefined,
                branch_id: undefined,
                brand_id: undefined,
                grade_id: undefined,
                section_id: undefined,
                job_id: undefined,
                time_set: undefined,
                adjustment_reason_id: undefined,
                line_ids: [
                    {
                        date: moment().format(AZKHelper.FRONTEND_DATE_FORMAT), hour: undefined, min: undefined, adjustment_type: undefined,
                        id: 0
                    },]
            })
        }
        return true
    }

    const pobulateFormData = async (data?: AttendanceAdjustment) => {
        if (data) {
            formRef.current?.setValues({
                id: data.id,
                employee_id: data.employee_id?.id,
                az_country_id: data.az_country_id?.id,
                az_company_id: data.az_company_id?.id,
                branch_id: data.branch_id?.id,
                brand_id: data.brand_id?.id,
                grade_id: data.grade_id?.id,
                section_id: data.section_id?.id,
                job_id: data.job_id?.id,
                time_set: data.time_set,
                azadea_employee_name: data.azadea_employee_name,
                adjustment_reason_id: data?.adjustment_reason_id?.id,
                line_ids: []
            })
        }
    }

    const checkPermission = async (action: string) => {
        const allowed = await checkTrnsactionTypeAccessId(MODEL_NAME, action)
        if (!allowed) {
            if (id) {
                const allowed_read = await checkTrnsactionTypeAccessId(MODEL_NAME, 'read')
                if (!allowed_read) {
                    navigate(`/self-service/attendance/`, { state: {}, replace: true });
                    Swal.fire({
                        position: "top-right",
                        title: 'Access Error!',
                        text: 'You does not have access to perform read action!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                } else {
                    setReadOnly(true)
                }
            } else {
                navigate(`/self-service/attendance/`, { state: {}, replace: true });
                Swal.fire({
                    position: "top-right",
                    title: 'Access Error!',
                    text: 'You does not have access to perform this action!',
                    icon: "error",
                    showConfirmButton: false,
                    timer: AZKHelper.SWAL_TOAST_TIMER,
                    toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
            }
        }
    }

    useEffect(() => {
        checkUserType()
        document.body.classList.add('page-loading')
        formRef.current?.resetForm()
        setObjectId(undefined)
        setCanValidate(false)
        if (id) {
            setObjectId(parseInt(id))
            checkPermission('write');
            document.body.classList.add('page-loading')
            GetAttendanceAdjustmentById(parseInt(id)).then(((data: AttendanceAdjustment) => {
                handleEmployeeChange(data?.employee_id?.id, data)
                if (data?.x_state && data?.x_state_view) {
                    setTransactionState(data?.x_state)
                    setTransactionStateName(data?.x_state_view)
                }
                if (data?.can_write && data?.can_write !== null) {
                    setCanEdit(data?.can_write)
                }
                setTransactionClosed(data.validated || data.rejected ? true : false)
                setCanValidate(data?.can_review ? true : false)
            }))
        } else {
            checkPermission('create');
            formRef.current?.setFieldValue('employee_id', undefined)
            handleEmployeeChange(undefined, undefined)
            setCanEdit(true)
            document.body.classList.remove('page-loading')
        }
    }, [id])


    const cancel = () => {
        navigate(`/self-service/attendance/`, { state: {}, replace: true });
    }


    const handleResultOfsubmit = (transactionId: ID | undefined, isUpdate: boolean | false) => {
        document.body.classList.remove('page-loading')
        navigate(`/self-service/attendance/`, { state: {}, replace: true })
        Swal.fire({
            position: "top-right",
            title: 'Success!',
            text: isUpdate ? 'Your transaction had been updated!' : 'Your transaction had been saved!',
            icon: "success",
            showConfirmButton: false,
            timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
            toast: true,
            iconColor: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            timerProgressBar: true
        })
    }

    const validateFields = async () => {
        let canSave = true
        let fields: AttendanceAdjustmentLine[] = []
        fields = formRef?.current?.getFieldProps('line_ids').value
        // if (!fields) return false
        // replace the element object
        const lines: string[] = []
        let dates: any[] = []
        await fields.map((element) => {
            const line_text = moment(element.date).format(AZKHelper.FRONTEND_DATE_FORMAT) + element.adjustment_type + element.hour + element.min;
            if (lines.includes(line_text)) canSave = false
            lines.push(line_text)
            const line_time = moment(element.date)
            if (element.hour) line_time.hour(parseInt(element.hour))
            if (element.min) line_time.minute(parseInt(element.min))
            line_time.second(0)
            line_time.millisecond(0)
            dates.push(line_time)
        });
        const sorted_dates = dates.sort((a, b) => { return moment(a).diff(moment(b)) })
        await sorted_dates.map((date_line, index) => {
            if (index > 0) {
                if (date_line.diff(sorted_dates[index - 1], 'minute') <= allowed_time_diff) {
                    canSave = false
                }
            }
        });
        return { canSave }
    }
    const onSubmitHandler = async (values: AttendanceAdjustment, actions: FormikValues) => {
        formRef.current?.validateForm()
        if (formRef.current?.isValid && is_submitting_form) {
            validateFields().then(async result => {
                if (result['canSave']) {
                    document.body.classList.add('page-loading')
                    if (isNotEmpty(values.id)) {
                        await UpdateAttendanceAdjustment(values).then(data => {
                            if (id) handleResultOfsubmit(parseInt(id), true)
                        })
                    } else {
                        await CreateAttendanceAdjustment(values).then(data => {
                            setObjectId(data?.id)
                            setHitSend(true)
                        })
                    }
                } else {
                    Swal.fire({
                        position: "top-right",
                        title: 'Error!',
                        text: 'Duplicate attandance line!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                }
            })
        }
    }

    const loadTypeOptions = async (search, loadedOptions) => {
        const options: any[] = []
        options.push({ value: 'in', label: 'In' })
        options.push({ value: 'out', label: 'Out' })
        const filteredOptions = options.filter((option) =>
            option.label.toString().includes(search.toLowerCase())
        );
        return {
            options: filteredOptions,
            hasMore: false,
        };
    };


    const loadHourOptions = async (search, loadedOptions) => {
        const options: any[] = []
        for (let index = 0; index < 24; index++) {
            options.push({ value: index, label: index.toString().padStart(2, '0') })
        }
        const filteredOptions = options.filter((option) =>
            option.label.toString().includes(search.toLowerCase())
        );
        return {
            options: filteredOptions,
            hasMore: false,
        };
    };


    const loadMinutesOptions = async (search, loadedOptions) => {
        const options: any[] = []
        for (let index = 0; index < 60; index += 1) {
            options.push({ value: index, label: index.toString().padStart(2, '0') })
        }
        const filteredOptions = options.filter((option) =>
            option.label.toString().includes(search.toLowerCase())
        );
        return {
            options: filteredOptions,
            hasMore: false,
        };
    };


    return (
        <>
            <Row className="m-0 my-2">
                <Col md='12' lg={id ? '9' : '9'} xl={id ? '9' : '9'}>
                    <Formik
                        innerRef={formRef}
                        validationSchema={editTransactionSchema}
                        onSubmit={onSubmitHandler}
                        initialValues={initialTransfaction}
                    >
                        {({ values, setFieldValue, handleSubmit, isSubmitting, validateForm, setSubmitting, handleBlur }) => {
                            // ,validateForm,isSubmitting, errors, touched, isValidating
                            return (
                                <>
                                    {/* {id && (<AzkStatusBar transactionId={id} modelName={MODEL_NAME}
                                        current={transactionState} />
                                    )} */}
                                    {canValidate && id &&
                                        <AzkActionButtons
                                            backLink="/self-service/attendance/"
                                            actionUrl={`${API_URL}/${MODEL_NAME}/${id}/`}
                                            thenNavigate="/self-service/attendance/"
                                            divStyle="card py-2 mb-3"
                                            buttons={[
                                                { title: "Approve", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.APPROVED' }), method: "validate_tier", confirmMsg: "Approve Transaction, Confirm?", cssClass: "btn btn-light btn-light-success", icon: "fa-thumbs-up" },
                                                { title: "Review", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.REVIEW' }), method: "restart_validation", confirmMsg: "Send Transaction back to draft, Confirm?", cssClass: "btn btn-light btn-light-warning", icon: "fa-refresh" },
                                                { title: "Decline", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.DECLINE' }), method: "reject_tier", confirmMsg: "Decline Transaction, Confirm?", cssClass: "btn btn-light btn-light-danger", icon: "fa-times" },
                                            ]} />}
                                    <Form onSubmit={handleSubmit}>
                                        <fieldset disabled={!canEdit || readOnly}>
                                            {!id && (
                                                <Row className="card m-0 py-2 mb-3">
                                                    <Col>
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label>
                                                                <h4 className="px-3 m-0 custom-employee-header justify-content-start">
                                                                    <i className="fa fa-magnifying-glass" aria-hidden="true"></i>{intl.formatMessage({ id: 'TRANSACTION.EMPLOYEE' })}
                                                                </h4>
                                                            </Form.Label>
                                                            {employeeDomain && employeeDomain !== '' &&
                                                                <AzkSelect
                                                                    compID="azk_temp"
                                                                    modelName="hr.employee"
                                                                    valueField="id"
                                                                    titleField="display_name_fr"
                                                                    dataDomain={employeeDomain}
                                                                    allowEmpty={true}
                                                                    fieldName='employee_id'
                                                                    onChange={(value) => {
                                                                        setFieldValue('employee_id', parseInt(value))
                                                                        handleEmployeeChange(value, undefined)
                                                                    }}
                                                                    defaultValue={values.employee_id}
                                                                    disabled={!canEdit}
                                                                    searchFields={['name', 'azadea_id', 'branch_id', 'job_id', 'work_email', 'first_name']} />
                                                            }
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='employee_id' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            )}
                                        </fieldset>
                                        {employee && (
                                            <>
                                                <EmployeeDetail employee={employee} />
                                                <Row className="m-0 card">
                                                    <h4 className="py-2 px-6 m-0 card-header custom-card-header justify-content-start">
                                                        <i className="fa fa-info-circle" aria-hidden="true"></i>Attendance Adjustment Details</h4>
                                                    <fieldset disabled={!canEdit || readOnly}>
                                                        <Row className="m-0 my-2">
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Reason
                                                                    </Form.Label>
                                                                    <AzkSelect
                                                                        compID="az_adjustment_reason_id_select"
                                                                        modelName="az.attendance.adjust.reason"
                                                                        valueField="id"
                                                                        titleField="name"
                                                                        dataDomain={attendanceAdjustmentTypeDomain}
                                                                        isMultiple={false}
                                                                        allowEmpty={true}
                                                                        fieldName='adjustment_reason_id'
                                                                        disabled={!canEdit}
                                                                        onChange={(value) => {
                                                                            if (value !== null) {
                                                                                setFieldValue('adjustment_reason_id', parseInt(value))
                                                                            } else {
                                                                                setFieldValue('adjustment_reason_id', undefined)
                                                                            }
                                                                        }}
                                                                        defaultValue={values.adjustment_reason_id} />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='adjustment_reason_id' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>

                                                        </Row>
                                                        <Row className="m-0 my-2">

                                                            <div className=''> {/* table-responsive */}
                                                                <table
                                                                    id='kt_table_users'
                                                                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                                                    <thead>
                                                                        <tr className='text-start text-muted fw-bold fs-8 text-uppercase gs-0'>
                                                                            <th style={{ width: "25%" }}>Date</th>
                                                                            <th style={{ width: "25%" }}>Type</th>
                                                                            <th style={{ width: "20%" }}>Hour</th>
                                                                            <th style={{ width: "20%" }}>Min</th>
                                                                            <th style={{ width: "10%" }}></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='text-gray-600 fs-8'>
                                                                        <FieldArray name="line_ids">
                                                                            {({ push, remove }) => (
                                                                                <>
                                                                                    {values.line_ids.map((line, index) => (
                                                                                        <tr key={index}>
                                                                                            <td>
                                                                                                <Form.Control
                                                                                                    className='p-1'
                                                                                                    type="date"
                                                                                                    id={`line_ids[${index}].date`}
                                                                                                    name={`line_ids[${index}].date`}
                                                                                                    onChange={(value) => {
                                                                                                        line.date = moment(value.target.value).format(AZKHelper.FRONTEND_DATE_FORMAT)
                                                                                                        values.line_ids[index].date = moment(value.target.value).format(AZKHelper.FRONTEND_DATE_FORMAT)
                                                                                                        formRef?.current?.validateForm()
                                                                                                    }}
                                                                                                    min={moment().subtract(number_of_back_day, 'day').startOf('day').format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                                                    max={moment().format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                                                    value={moment(values.line_ids[index].date).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                                                />
                                                                                                <div className='text-danger'>
                                                                                                    <ErrorMessage name={`line_ids[${index}].date`} component="div" />
                                                                                                </div>
                                                                                            </td>

                                                                                            <td>
                                                                                                <AsyncPaginate className='rounded-0 col-12'
                                                                                                    placeholder="Select an option"
                                                                                                    loadOptions={loadTypeOptions}
                                                                                                    onChange={(value) => {
                                                                                                        line.adjustment_type = value?.value
                                                                                                        values.line_ids[index].adjustment_type = value?.value
                                                                                                        formRef?.current?.validateForm()
                                                                                                    }}
                                                                                                    defaultValue={{ value: values.line_ids[index].adjustment_type, label: values.line_ids[index].adjustment_type }}
                                                                                                    value={{ value: values.line_ids[index].adjustment_type, label: values.line_ids[index].adjustment_type }}
                                                                                                    id={`line_ids[${index}].adjustment_type`} name={`line_ids[${index}].adjustment_type`}
                                                                                                />
                                                                                                <div className='text-danger'>
                                                                                                    <ErrorMessage name={`line_ids[${index}].adjustment_type`} component="div" />
                                                                                                </div>
                                                                                            </td>

                                                                                            <td>
                                                                                                <AsyncPaginate className='rounded-0 col-12'
                                                                                                    placeholder="Select an option"
                                                                                                    loadOptions={loadHourOptions}
                                                                                                    onChange={(value) => {
                                                                                                        line.hour = value?.value
                                                                                                        values.line_ids[index].hour = value?.value
                                                                                                        formRef?.current?.validateForm()
                                                                                                    }}
                                                                                                    defaultValue={{ value: values.line_ids[index].hour, label: values.line_ids[index].hour }}
                                                                                                    value={{ value: values.line_ids[index].hour, label: values.line_ids[index].hour }}
                                                                                                    id={`line_ids[${index}].hour`} name={`line_ids[${index}].hour`} />
                                                                                                <div className='text-danger'>
                                                                                                    <ErrorMessage name={`line_ids[${index}].hour`} component="div" />
                                                                                                </div>
                                                                                            </td>

                                                                                            <td>
                                                                                                <AsyncPaginate className='rounded-0 col-12'
                                                                                                    placeholder="Select an option"
                                                                                                    loadOptions={loadMinutesOptions}
                                                                                                    onChange={(value) => {
                                                                                                        line.min = value?.value
                                                                                                        values.line_ids[index].min = value?.value
                                                                                                        formRef?.current?.validateForm()
                                                                                                    }}
                                                                                                    defaultValue={{ value: values.line_ids[index].min, label: values.line_ids[index].min }}
                                                                                                    value={{ value: values.line_ids[index].min, label: values.line_ids[index].min }}
                                                                                                    id={`line_ids[${index}].min`} name={`line_ids[${index}].min`} />
                                                                                                <div className='text-danger'>
                                                                                                    <ErrorMessage name={`line_ids[${index}].min`} component="div" />
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                {(index > 0 || values.line_ids.length > 1) && <button
                                                                                                    name={`line_ids[${index}].min` + 'delete_line'}
                                                                                                    title="delte"
                                                                                                    type="reset"
                                                                                                    onClick={() => { remove(index) }}
                                                                                                    className='btn btn-light btn-light-info p-1 px-2'
                                                                                                ><i className="bi bi-trash p-0"></i></button>
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                    <tr>
                                                                                        <td>
                                                                                            <button
                                                                                                title="Add entry"
                                                                                                type='submit'
                                                                                                className='btn btn-light btn-light-info mx-5 btn-sm'
                                                                                                onClick={() => {
                                                                                                    is_submitting_form = false
                                                                                                    if (formRef?.current?.isValid) {
                                                                                                        push({
                                                                                                            date: moment().format(AZKHelper.FRONTEND_DATE_FORMAT), hour: undefined, min: undefined, adjustment_type: '',
                                                                                                            id: 0
                                                                                                        })
                                                                                                        formRef?.current?.getFieldHelpers('line_ids').setTouched(true)
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <i className="fa fa-plus fs-2" aria-hidden="true"></i>
                                                                                                <span className='indicator-label'>Add new Entry</span>
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )}
                                                                        </FieldArray>
                                                                    </tbody>
                                                                </table>


                                                            </div>
                                                        </Row>
                                                    </fieldset>

                                                    {/* begin::Actions */}
                                                    <div className='py-5 d-flex justify-content-end'>
                                                        {!canValidate && canEdit &&
                                                            <button
                                                                title="Submit for approval"
                                                                type='submit'
                                                                className='btn btn-light btn-light-info mx-5 btn-sm'
                                                                disabled={!canEdit}
                                                                onClick={() => {
                                                                    is_submitting_form = true
                                                                }}>
                                                                <i className="fa fa-podcast fs-2" aria-hidden="true"></i>
                                                                <span className='indicator-label'>Submit</span>
                                                            </button>
                                                        }
                                                        {!canValidate &&
                                                            <button
                                                                title="Discard & back"
                                                                type='reset'
                                                                onClick={() => cancel()}
                                                                className='btn btn-light btn-active-light-primary me-3 mx-5 btn-sm'
                                                                data-kt-users-modal-action='cancel'
                                                            >
                                                                <KTIcon iconName='exit-left' className='fs-2' />
                                                                <span className='indicator-label'>Discard</span>

                                                            </button>
                                                        }
                                                    </div>
                                                    {/* end::Actions */}
                                                </Row>
                                            </>)}
                                    </Form>
                                </>
                            )
                        }}
                    </Formik>
                </Col>
                <Col md='12' lg='3' xl='3'>
                    {id && (<AzkStatusRibbon transactionId={parseInt(id)} modelName={MODEL_NAME}
                        current={transactionState} currentName={transactionStateName} />
                    )}
                    {employee && <AzkChatter transactionClosed={transactionClosed} isDrawer={true} modelName={MODEL_NAME} itemID={objectId} hitSend={hitSend} sendMessagesCallback={handleResultOfsubmit} />}
                </Col>
            </Row>
        </>
    )
}

export default Transactionform;
