import CryptoJS from 'crypto-js';

import { AUTH_LOCAL_STORAGE_KEY, AuthModel } from 'app/modules/auth';
import { AZKHelper } from 'app/modules/components/azkHelper';
// import { useNavigate } from 'react-router-dom';

const useAESEncrypt = () => {
    const Swal = require('sweetalert2')
    // const navigate = useNavigate()

    const keyError = () => {
        document.body.classList.remove('page-loading')
        Swal.fire({
            position: "top-right",
            title: 'Sorry!',
            text: 'Please enter a vaild pin code to proceed!',
            icon: "error",
            showConfirmButton: false,
            timer: 5000,
            toast: true,
            iconColor: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            timerProgressBar: true
        })
        // navigate(`/transactions/`, { state: {}, replace: true });
        throw new Error('Pin code not valid!')
    }

    const getKey = () => {
        let lsValue = localStorage.getItem(AZKHelper.PIN_CODE_LOCAL_STORAGE_KEY)
        if (lsValue) {
            const jsonObj = JSON.parse(lsValue)
            if (jsonObj['pin_code']) {
                const bytes = CryptoJS.AES.decrypt(jsonObj['pin_code'], AZKHelper.PIN_CODE_KEY);
                return bytes.toString(CryptoJS.enc.Utf8);
            } else {
                keyError()
            }
        } else{
            keyError()
        }
    }
    const decodeAndDecrypt = (cipherText) => {
        const key = getKey()
        if (key) {
            const keyBytes = CryptoJS.enc.Utf8.parse(key);
            const encryptedBytes = CryptoJS.enc.Hex.parse(cipherText);
            const decrypted = CryptoJS.AES.decrypt({ ciphertext: encryptedBytes }, keyBytes, { mode: CryptoJS.mode.ECB });
            const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
            if (!decryptedText) {
                keyError()
            }
            return decryptedText
        }
    };

    const decodeAndDecryptText = (cipherText) => {
        let key = getKey()
        if (key) {
            key = CryptoJS.enc.Utf8.parse(key);
            let iv = CryptoJS.lib.WordArray.create(CryptoJS.lib.WordArray.random(16));
            var cipherBytes = CryptoJS.enc.Hex.parse(cipherText);
            var decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherBytes }, key, {
                iv: iv,
                mode: CryptoJS.mode.CBC
            });
            const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
            if (!decryptedText) {
                keyError()
            }
            return decryptedText
        }
    };

    const encryptAndEncode = (plaintext) => {
        const key = getKey()
        if (key) {
            plaintext = '' + plaintext
            const keyBytes = CryptoJS.enc.Utf8.parse(key);
            const encrypted = CryptoJS.AES.encrypt(plaintext, keyBytes, { mode: CryptoJS.mode.ECB });
            const encryptedText = encrypted.ciphertext.toString(CryptoJS.enc.Hex);
            if (!encryptedText) {
                keyError()
            }
            return encryptedText;
        }
    };

    return {
        decodeAndDecrypt,
        encryptAndEncode,
        decodeAndDecryptText
    };
};

export default useAESEncrypt;