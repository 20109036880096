import axios, { AxiosResponse } from 'axios'
import { Response } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper'

const MODEL_NAME = 'az.transaction.submit.duration.chart'
const URL = `${API_URL}/${MODEL_NAME}`
const INCLUDE_FIELDS = `'count', 'submit_duration', 'az_country_id', 'az_company_id',  'brand_id', 'type_name', 'type_id' `



export type TransactionSubmitDurationChart = {
  count?: number
  az_country_id?: any
  az_company_id?: any
  brand_id?: any
  type_name: string
  type_id: number
  submit_duration: number
}

const GetTransactionSubmitDurationChartData = (listDataDomain: string): Promise<TransactionSubmitDurationChart[]> => {
  return axios
    .get(`${URL}?filters=[${listDataDomain}]&include_fields=[${INCLUDE_FIELDS}]`)
    .then((response: AxiosResponse<Response<TransactionSubmitDurationChart[]>>) => response.data)
    .then((response: Response<TransactionSubmitDurationChart[]>) => response['results'])
}

export { GetTransactionSubmitDurationChartData, MODEL_NAME}


