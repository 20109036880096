import { PageLink, PageTitle } from '_metronic/layout/core'
import { Outlet, Route, Routes } from 'react-router-dom'
import Transactionform from './form'
import TransactionList from './list'
import TransactionDetailedReportView from './list-detailed_report'

const transactionBreadCrumbs: Array<PageLink> = [
  {
    title: 'Leave',
    path: '/self-service/leave-submission',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Leave',
    path: '/self-service/leave-submission',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const LeaveRouter = () => (
  <Routes>
    <Route
      element={
        <>
          <Outlet />
        </>
      }
    >
      <Route
        path=''
        element={
          <>
            <PageTitle breadcrumbs={transactionBreadCrumbs}>List</PageTitle>
            <TransactionList />
          </>
        }
      />
      <Route
        path=':id/edit'
        element={
          <>
            <PageTitle breadcrumbs={transactionBreadCrumbs}>Edit</PageTitle>
            <Transactionform />
          </>
        }
      />
      <Route
        path='new'
        element={
          <>
            <PageTitle breadcrumbs={transactionBreadCrumbs}>New</PageTitle>
            <Transactionform />
          </>
        }
      />
      <Route
        path='detailed-report'
        element={
          <>
            <PageTitle breadcrumbs={transactionBreadCrumbs}>New</PageTitle>
            <TransactionDetailedReportView />
          </>
        }
      />
      <Route
        path='leave-blance-report'
        element={
          <>
            <PageTitle breadcrumbs={transactionBreadCrumbs}>New</PageTitle>
            <TransactionDetailedReportView />
          </>
        }
      />
      <Route
        path='leave-history-report'
        element={
          <>
            <PageTitle breadcrumbs={transactionBreadCrumbs}>New</PageTitle>
            <TransactionDetailedReportView />
          </>
        }
      />
    </Route>
  </Routes>
)

export default LeaveRouter
