import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper'

const MODEL_NAME = 'az.final.settelement.chart'
const URL = `${API_URL}/${MODEL_NAME}`
const INCLUDE_FIELDS = `'count', 'clearance_completed', 'payroll_validation', 'marked_to_pay', 'exceeded_due_date', 'az_country_id', 'az_company_id',  'brand_id'`

export type FinalSettelementChart = {
  count?: number
  az_country_id?: any
  az_company_id?: any
  brand_id?: any
  clearance_completed: number
  payroll_validation: number
  marked_to_pay: number
  exceeded_due_date: number
}

const GetFinalSettelementChartData = (listDataDomain: string): Promise<FinalSettelementChart[]> => {
  return axios
    .get(`${URL}?filters=[${listDataDomain}]&include_fields=[${INCLUDE_FIELDS}]`)
    .then((response: AxiosResponse<Response<FinalSettelementChart[]>>) => response.data)
    .then((response: Response<FinalSettelementChart[]>) => response['results'])
}

export { GetFinalSettelementChartData , MODEL_NAME}


