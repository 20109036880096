export class GrouphHelper {

  static INCLUDE_FIELDS= `('id', 'name')`
  static RELATION_INCLUDE_FIELDS= `('group_id',[('id', 'name')])`
  constructor(parameters) {
    
  }
}
export interface GrouphModel {
  id: number
  name: string
}