import { KTIcon } from "_metronic/helpers";
import axios, { Axios } from "axios";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { useApproverModalView } from "./azkStatusBar";
import { Row } from "react-bootstrap";

interface AzkTransactionApproversProps {
  transaction: {
    name: string;
    id: string;
    current_approval_ids: {
      name: string;
      login: string
    }[];
    review_ids: number[]
  };
  divStyle: string;
  closeModal: () => {}
}

const AzkTransactionApprovers: React.FC<AzkTransactionApproversProps> = ({ transaction, closeModal }) => {

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])
  return (
    // <div className={divStyle}>
    //   <h3 className="m-0">This transaction under Validation of:</h3>
    //   <ul className="m-0">
    //   {approvers.map((approver) => (
    //     <li>{approver.name}</li>
    //   ))}
    //   </ul>
    // </div>
    <>

      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>

            <div className='modal-header py-2'>
              {/* begin::Modal title */}
              <h4 className='m-0'>Current Approvers on <small>{transaction.name}</small></h4>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => closeModal()}
                style={{ cursor: 'pointer' }}
              >
                <i className="fa fa-times fs-1 btn btn-sm" aria-hidden="true"></i>
              </div>
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-7 my-5' >
              {transaction && transaction.current_approval_ids ? transaction.current_approval_ids.map((approver) => (
                (<>
                  <div className="d-flex">
                    <i className="fas fa-check fs-2 mx-2"></i>
                    <h6>{approver.name}</h6>
                  </div>
                </>)

              )) : ''}
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  );
};

export default AzkTransactionApprovers;
