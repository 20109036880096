import AzkSelect from "_azkatech/azkSelect";
import { EmployeeDetail, EmpoyeeModel } from "app/modules/components/employeeDetail/employeeDetail";
import axios from "axios";
import * as Yup from 'yup'
import { AdditionDeduction, AdditionDeductionDetail, initialTransaction } from "./model";
import { FC, useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik, FormikProps, FormikValues } from 'formik'
import { CreateAdditionDeduction, UpdateAdditionDeduction, GetAdditionDeductionById, MODEL_NAME, DeleteAdditionDeduction } from "./_requests";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { AzkStatusRibbon } from "_azkatech/azkStatusRibbon";
import AzkActionButtons from "_azkatech/azkActionButtons";
import { Form, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { ID, isNotEmpty, KTIcon } from "_metronic/helpers";
import { useNavigate } from "react-router";
import { API_URL } from "_azkatech/azkHelper";
import { AZKHelper } from "app/modules/components/azkHelper";
import { AzkChatter } from "_azkatech";
import { checkDateConstrains, checkTrnsactionTypeAccessId } from "app/utilities/transactionTyperequests";
import { useParams } from "react-router-dom";
import { useSalaryReqeust } from "app/utilities/salaryRequests";
import { GetEmployeeById } from "app/modules/components/employeeDetail/_requests";

type Props = {
}
const editTransactionSchema = Yup.object().shape({
    employee_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Employee is required'),
    // detail_ids: Yup.number().transform((value) => Number.isNaN(value.length) ? undefined : value.length)
    //     .required('Element is required')
})

const Transactionform: FC<Props> = () => {
    const { id } = useParams();
    const Swal = require('sweetalert2')
    const intl = useIntl();
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<AdditionDeduction>>(null);
    const [elementDomain, setElementDomain] = useState('');
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [transactionClosed, setTransactionClosed] = useState<boolean>(false);
    const [canValidate, setCanValidate] = useState<boolean>(false);
    const [canDelete, setCanDelete] = useState<boolean>(false);
    const [disabledOptions, setDisabledOptions] = useState<any[]>([]);
    const [needSubmit, setNeedSubmit] = useState<boolean>(false);
    const [employee, setEmployee] = useState<EmpoyeeModel>();
    const [transactionState, setTransactionState] = useState<string>('draft');
    const [transactionStateName, setTransactionStateName] = useState<string>('Draft');
    const [hitSend, setHitSend] = useState<boolean>(false);
    const [objectId, setObjectId] = useState<number>();
    const [dateFrom, setDateFrom] = useState<any>(moment());
    const [startOftheMonth, setStartOftheMonth] = useState<any>(true);
    const [allowBackDate, setAllowBackDate] = useState<any>(true);

    const handleEmployeeChange = async function (employeeId, changeAdditionDeductionData): Promise<{}> {
        document.body.classList.add('page-loading')
        if (employeeId) {
            setEmployee(undefined)
            GetEmployeeById(employeeId).then(response => {
                if (response) {
                    const tempEmployee = response
                    if (id || !tempEmployee.has_running_transaction) {
                        // response.salary = undefined
                        setEmployee(response)
                        setElementDomain(tempEmployee.az_country_id ? `('country_id','=', ${tempEmployee.az_country_id?.id})` : '');
                        formRef.current?.setValues({
                            employee_id: tempEmployee.id,
                            az_country_id: tempEmployee.az_country_id?.id,
                            az_company_id: tempEmployee.az_company_id?.id,
                            payroll_id: tempEmployee.payroll_id?.id,
                            branch_id: tempEmployee.branch_id?.id,
                            brand_id: tempEmployee.brand_id?.id,
                            grade_id: tempEmployee.grade_id?.id,
                            section_id: tempEmployee.section_id?.id,
                            job_id: tempEmployee.job_id?.id,
                            as_effective_date: moment(tempEmployee.as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT),
                            time_set: tempEmployee.time_set,
                            // ---------------------------------------------------
                            target_salary: tempEmployee.salary,
                            target_as_effective_date: formRef.current.values.target_as_effective_date ? moment(formRef.current.values.target_as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT) : moment().format(AZKHelper.FRONTEND_DATE_FORMAT),
                        })
                        if (changeAdditionDeductionData) {
                            pobulateFormData(changeAdditionDeductionData)
                            handleDateConstraint(changeAdditionDeductionData.target_as_effective_date, tempEmployee.az_country_id?.id, tempEmployee.az_company_id?.id, tempEmployee.brand_id?.id)    
                        } else {
                            handleDateConstraint(moment.now(), tempEmployee.az_country_id?.id, tempEmployee.az_company_id?.id, tempEmployee.brand_id?.id)    
                        }
                        document.body.classList.remove('page-loading')
                    } else {
                        document.body.classList.remove('page-loading')
                        Swal.fire({
                            position: "top-right",
                            title: 'Sorry!',
                            text: 'This employee ' + tempEmployee.name + ' already has an open transaction with details ' + tempEmployee.running_transaction + '!',
                            icon: "error",
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            iconColor: 'white',
                            customClass: {
                                popup: 'colored-toast'
                            },
                            timerProgressBar: true
                        })
                    }
                } else {
                    document.body.classList.remove('page-loading')
                }
            });
        } else {
            document.body.classList.remove('page-loading')
            formRef.current?.resetForm();
            setEmployee(undefined)
            formRef.current?.setValues({
                employee_id: undefined,
                az_country_id: undefined,
                az_company_id: undefined,
                payroll_id: undefined,
                branch_id: undefined,
                brand_id: undefined,
                grade_id: undefined,
                section_id: undefined,
                job_id: undefined,
                as_effective_date: undefined,
                time_set: undefined,
                salary: undefined
            })
        }
        return true
    }

    const pobulateFormData = async (data?: AdditionDeduction) => {
        if (data) {
            formRef.current?.setValues({
                id: data.id,
                employee_id: data.employee_id?.id,
                az_country_id: data.az_country_id?.id,
                az_company_id: data.az_company_id?.id,
                payroll_id: data.payroll_id?.id,
                branch_id: data.branch_id?.id,
                brand_id: data.brand_id?.id,
                grade_id: data.grade_id?.id,
                section_id: data.section_id?.id,
                job_id: data.job_id?.id,
                as_effective_date: moment(data.as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT),
                time_set: data.time_set,
                target_salary: data.salary,
                salary: data.salary,
                azadea_employee_name: data.azadea_employee_name,
                detail_ids: data.detail_ids,
                target_as_effective_date: moment(data?.target_as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT),
            })
        }
    }

    const checkPermission = async (action: string) => {
        const allowed = await checkTrnsactionTypeAccessId(MODEL_NAME, action)
        if (!allowed) {
            if (id) {
                const allowed_read = await checkTrnsactionTypeAccessId(MODEL_NAME, 'read')
                if (!allowed_read) {
                    navigate(`/transactions/`, { state: {}, replace: true });
                    Swal.fire({
                        position: "top-right",
                        title: 'Access Error!',
                        text: 'You does not have access to perform read action!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                } else {
                    setReadOnly(true)
                }
            } else {
                navigate(`/transactions/`, { state: {}, replace: true });
                Swal.fire({
                    position: "top-right",
                    title: 'Access Error!',
                    text: 'You does not have access to perform this action!',
                    icon: "error",
                    showConfirmButton: false,
                    timer: AZKHelper.SWAL_TOAST_TIMER,
                    toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
            }
        }
    }

    const handleDateConstraint = async (target_as_effective_date, country_id, company_id, brand_id) => {
        let start_ofthe_month = false
        let allow_back_date = false
        let date_from = moment()
        if (!country_id) country_id = formRef.current?.getFieldProps('country_id').value
        if (!company_id) company_id = formRef.current?.getFieldProps('company_id').value
        if (!brand_id) brand_id = formRef.current?.getFieldProps('brand_id').value
        if (!target_as_effective_date) target_as_effective_date = formRef.current?.getFieldProps('target_as_effective_date').value
        const type_technical_name = MODEL_NAME.replaceAll('.', '_')
        if (country_id && country_id && brand_id)
            checkDateConstrains(country_id, country_id, brand_id, type_technical_name).then(data => {
                if (data.count > 0) {
                    if (data['results'][0]['back_dated']) {
                        allow_back_date = true
                        date_from = moment('2023-01-01')
                    }
                    if (data['results'][0]['start_of_month']) {
                        setStartOftheMonth(true)
                        start_ofthe_month = true
                    }
                    setStartOftheMonth(start_ofthe_month)
                    setDateFrom(date_from)
                    setAllowBackDate(allowBackDate)
                    handleDateChange(target_as_effective_date, date_from, start_ofthe_month, allow_back_date)
                }
            })
    }

    const handleDateChange = async (date_value, date_from, start_ofthe_month, allow_back_date) => {
        date_value = moment(date_value).startOf("day");
        date_from = moment(date_from).startOf("day");
        if (start_ofthe_month) {
            date_value = allow_back_date ? date_value.startOf('month') : date_value.add(1, 'month').startOf('month');
        } else {
            date_value = date_value;
        }
        if (date_value.isBefore(date_from)) {
            date_value = date_from;
        }
        if (id) {
            const target_as_effective_date = moment(formRef.current?.getFieldProps('target_as_effective_date').value)
            formRef.current?.setFieldValue('target_as_effective_date', date_value.format(AZKHelper.FRONTEND_DATE_FORMAT));
        }
        else {
            formRef.current?.setFieldValue('target_as_effective_date', date_value.format(AZKHelper.FRONTEND_DATE_FORMAT));
        }
    };

    useEffect(() => {

        document.body.classList.add('page-loading')
        formRef.current?.resetForm()
        setObjectId(undefined)
        setCanValidate(false)
        handleEmployeeChange(undefined, undefined)
        if (id) {
            setObjectId(parseInt(id))
            checkPermission('write');
            document.body.classList.add('page-loading')
            GetAdditionDeductionById(parseInt(id)).then(((data: AdditionDeduction) => {
                handleEmployeeChange(data?.employee_id?.id, data)
                if (data?.x_state && data?.x_state_view) {
                    setTransactionState(data?.x_state)
                    setTransactionStateName(data?.x_state_view)
                }
                if (data?.can_write && data?.can_write !== null) {
                    setCanEdit(data?.can_write)
                }
                setTransactionClosed(data.validated || data.rejected ? true : false)
                setCanValidate(data?.can_review ? true : false)
                setCanDelete(data?.can_delete ? true : false)
            }))
        } else {
            checkPermission('create');
            formRef.current?.setFieldValue('employee_id', undefined)
            handleEmployeeChange(undefined, undefined)
            setCanEdit(true)
            document.body.classList.remove('page-loading')
        }
    }, [id])

    const cancel = () => {
        navigate(`/transactions/`, { state: {}, replace: true });
    }

    const deleteTransaction = () => {
        Swal.fire({
            position: "center",
            title: 'Delete',
            text: 'Delete Transaction confirm?',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed && id) {
                DeleteAdditionDeduction(parseInt(id)).then(() => {
                    cancel()
                    Swal.fire({
                        position: "top-right",
                        title: 'Deleted!',
                        text: 'Your transaction had been deleted!',
                        icon: "warning",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                })
            } else if (result.isDenied) {
                Swal.fire('Transaction was not deleted', '', 'info')
            }
        })
    }

    const submitTransaction = (transactionId: number) => {
        setNeedSubmit(false)
        axios.put(`${API_URL}/${MODEL_NAME}/${transactionId}/request_validation`, '{}').then(data => {
            document.body.classList.remove('page-loading')
            navigate(`/transactions/`, { state: {}, replace: true });
            Swal.fire({
                position: "top-right",
                title: 'Success!',
                text: 'Your transaction had been submited!',
                icon: "success",
                showConfirmButton: false,
                timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        })
    }

    const handleResultOfsubmit = (transactionId: ID | undefined, isUpdate: boolean | false) => {
        if (needSubmit && transactionId) {
            submitTransaction(transactionId)
        } else {
            document.body.classList.remove('page-loading')
            navigate(`/transactions/`, { state: {}, replace: true })
            Swal.fire({
                position: "top-right",
                title: 'Success!',
                text: isUpdate ? 'Your transaction had been updated!' : 'Your transaction had been saved!',
                icon: "success",
                showConfirmButton: false,
                timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        }
    }

    const checkElement = async () => {
        let canSave = true
        let elements: AdditionDeductionDetail[] = formRef.current?.getFieldProps('detail_ids').value
        if (!elements) elements = []
        // replace the element object
        await elements.map((element) => {
            if (!element.amount || !element.amount || !element.comment)
                canSave = false
        });
        return canSave
    }

    const onSubmitHandler = async (values: AdditionDeduction, actions: FormikValues) => {
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        if (formRef.current?.isValid) {
            checkElement().then(async (canSave) => {
                if (canSave) {
                    document.body.classList.add('page-loading')
                    if (isNotEmpty(values.id)) {
                        await UpdateAdditionDeduction(values).then(data => {
                            if (id) handleResultOfsubmit(parseInt(id), true)
                        })
                    } else {
                        await CreateAdditionDeduction(values).then(data => {
                            setObjectId(data?.id)
                            setHitSend(true)
                        })
                    }
                } else {
                    Swal.fire({
                        position: "top-right",
                        title: 'Error!',
                        text: 'Please fill the list of elements properly!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                }
            })
        } else {
            actions.setSubmitting(false)
            formRef.current?.validateForm().then(errors => {
                if (Object.keys(errors).length > 0) {
                    console.log(errors)
                    //   setStatus('error');
                }
            });
        }
    }

    const checkIfElementDropdownComment = (element_id: number, element: AdditionDeductionDetail, index: number) => {
        document.body.classList.add('page-loading')
        axios.get(`${API_URL}/az.clearance.elements/${element_id}`).then(result => {
            if (result && result.data) {
                if (result.data['comment_dropdown']) {
                    element.comment_dropdown = true
                } else {
                    element.comment_dropdown = false
                }
                replaceElementObject(element, index)
                document.body.classList.remove('page-loading')
            }
        }, err => {
            document.body.classList.remove('page-loading')
        })

    }
    const replaceElementObject = async (element: AdditionDeductionDetail, index: number) => {
        let elements: AdditionDeductionDetail[] = formRef.current?.getFieldProps('detail_ids').value
        if (!elements) elements = []
        // replace the element object
        await elements.map((d, i) => {
            if (i === index) {
                return element;
            } else {
                return d;
            }
        });
        formRef.current?.setFieldValue('detail_ids', elements)
        disabledOption(elements)
    }

    const handleDeleteElement = (detail: AdditionDeductionDetail, indexTodelete: number) => {
        detail.element_id = undefined
        detail.amount = undefined
        let elements: AdditionDeductionDetail[] = formRef.current?.getFieldProps('detail_ids').value
        if (!elements) elements = []
        const elements1 = elements.filter((_, index) => index !== indexTodelete);
        formRef.current?.setFieldValue('detail_ids', elements1)
        disabledOption(elements1)
    }

    const disabledOption = async (elements: any[]) => {
        const disabledOptionList: any[] = []
        await elements.map((d, i) => {
            disabledOptionList.push(d.element_id)
        })
        setDisabledOptions(disabledOptionList)
    }

    const handleAddElement = () => {
        const detail: AdditionDeductionDetail = {}
        detail.element_id = undefined
        detail.amount = undefined
        let elements: AdditionDeductionDetail[] = formRef.current?.getFieldProps('detail_ids').value
        if (!elements) elements = []
        elements.push(detail)
        formRef.current?.setFieldValue('detail_ids', elements)
    }

    return (
        <>
            <Row className="m-0 my-2">
                <Col md='12' lg={id ? '9' : '9'} xl={id ? '9' : '9'}>
                    <Formik
                        innerRef={formRef}
                        validationSchema={editTransactionSchema}
                        onSubmit={onSubmitHandler}
                        initialValues={initialTransaction}
                        validateeOnChange={true}
                        validateOnMount={true}
                        initialErrors={''}
                    >
                        {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
                            // ,validateForm,isSubmitting, errors, touched, isValidating
                            return (
                                <>
                                    {/* {id && (<AzkStatusBar transactionId={id} modelName={MODEL_NAME}
                                        current={transactionState} />
                                    )} */}
                                    {canValidate && id &&
                                        <AzkActionButtons
                                            actionUrl={`${API_URL}/${MODEL_NAME}/${id}/`}
                                            thenNavigate="/transactions/"
                                            divStyle="card py-2 mb-3"
                                            buttons={[
                                                { title: "Approve", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.APPROVED' }), method: "validate_tier", confirmMsg: "Approve Transaction, Confirm?", cssClass: "btn btn-light btn-light-success", icon: "fa-thumbs-up" },
                                                { title: "Review", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.REVIEW' }), method: "restart_validation", confirmMsg: "Send Transaction back to draft, Confirm?", cssClass: "btn btn-light btn-light-warning", icon: "fa-refresh" },
                                                { title: "Decline", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.DECLINE' }), method: "reject_tier", confirmMsg: "Decline Transaction, Confirm?", cssClass: "btn btn-light btn-light-danger", icon: "fa-times" },
                                            ]} />}
                                    <Form onSubmit={handleSubmit} noValidate>
                                        <fieldset disabled={!canEdit|| readOnly}>
                                            {!id && (
                                                <Row className="card m-0 py-2 mb-3">
                                                    <Col>
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label>
                                                                <h4 className="px-3 m-0 custom-employee-header justify-content-start">
                                                                    <i className="fa fa-magnifying-glass" aria-hidden="true"></i>{intl.formatMessage({ id: 'TRANSACTION.EMPLOYEE' })}
                                                                </h4>
                                                            </Form.Label>
                                                            <AzkSelect
                                                                compID="azk_temp"
                                                                modelName="hr.employee"
                                                                valueField="id"
                                                                titleField="display_name_fr"
                                                                dataDomain=''
                                                                allowEmpty={true}
                                                                fieldName='employee_id'
                                                                onChange={(value) => {
                                                                    setFieldValue('employee_id', parseInt(value))
                                                                    handleEmployeeChange(value, undefined)
                                                                }}
                                                                defaultValue={values.employee_id}
                                                                disabled={!canEdit}
                                                                searchFields={['name', 'azadea_id', 'branch_id', 'job_id', 'work_email', 'first_name']} />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='employee_id' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            )}
                                        </fieldset>

                                        {employee && (
                                            <>
                                                <Row className="m-0 my-2">
                                                    <EmployeeDetail employee={employee} hideSalary={true} />
                                                </Row>
                                                <Row className="m-0 card">
                                                    <h4 className="py-2 px-6 m-0 card-header custom-card-header justify-content-start">
                                                        <i className="fa fa-info-circle" aria-hidden="true"></i>Addition Deduction Details</h4>

                                                    <fieldset disabled={!canEdit|| readOnly}>
                                                        <Row className="m-0 my-2">
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Effective date
                                                                    </Form.Label>
                                                                    {!startOftheMonth &&
                                                                        <Form.Control
                                                                            className='p-1'
                                                                            type='date'
                                                                            onChange={(value) => {
                                                                                handleDateChange(value.target.value, dateFrom, startOftheMonth, allowBackDate)
                                                                            }}
                                                                            disabled={!canEdit}
                                                                            value={moment(values.target_as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                            min={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                        />
                                                                    }
                                                                    {startOftheMonth &&
                                                                        <Form.Control
                                                                            className='p-1'
                                                                            type='date'
                                                                            // isInvalid={isInvalidDate}
                                                                            onChange={(value) => {
                                                                                handleDateChange(value.target.value, dateFrom, startOftheMonth, allowBackDate)
                                                                            }}
                                                                            disabled={!canEdit}
                                                                            value={moment(values.target_as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                            min={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                        />
                                                                    }
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='target_as_effective_date' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>

                                                        <Row className="m-0 my-2">
                                                            <div className=''> {/* table-responsive */}
                                                                <table
                                                                    id='kt_table_users'
                                                                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                                                    <thead>
                                                                        <tr className='text-start text-muted fw-bold fs-8 text-uppercase gs-0'>
                                                                            <th>Element</th>
                                                                            <th>Amount <small>(In local currency)</small></th>
                                                                            <th>Comment</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='text-gray-600 fs-8'>
                                                                        {(values && values.detail_ids && values.detail_ids.length > 0) ? (values.detail_ids.map((element, index) => {
                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        <td className="py-0">
                                                                                            <Form.Group className='mb-2'>
                                                                                                <AzkSelect
                                                                                                    compID={`az_clearance_element-` + index}
                                                                                                    modelName="az.clearance.elements"
                                                                                                    valueField="id"
                                                                                                    titleField="element_name"
                                                                                                    dataDomain={elementDomain}
                                                                                                    disabledOptions={disabledOptions}
                                                                                                    isMultiple={false}
                                                                                                    allowEmpty={true}
                                                                                                    fieldName={`element_id-` + index}
                                                                                                    disabled={!canEdit}
                                                                                                    onChange={(value) => {
                                                                                                        element.element_id = parseInt(value)
                                                                                                        replaceElementObject(element, index)
                                                                                                        checkIfElementDropdownComment(parseInt(value), element, index)
                                                                                                    }}
                                                                                                    defaultValue={element.element_id} />
                                                                                                <div className='text-danger'>
                                                                                                    <ErrorMessage name='element_id' />
                                                                                                </div>
                                                                                            </Form.Group>
                                                                                        </td>
                                                                                        <td>
                                                                                            <Form.Control
                                                                                                className='p-1'
                                                                                                id={`amount-` + index}
                                                                                                name={`amount-` + index}
                                                                                                defaultValue={element.amount}
                                                                                                type="number"
                                                                                                min={0}
                                                                                                onChange={(value) => {
                                                                                                    element.amount = parseInt(value.target.value)
                                                                                                    replaceElementObject(element, index)
                                                                                                }}
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            {!element.comment_dropdown &&
                                                                                                <Form.Control
                                                                                                    className='p-1'
                                                                                                    id={`comment-` + index}
                                                                                                    name={`comment-` + index}
                                                                                                    defaultValue={element.comment}
                                                                                                    maxLength={250}
                                                                                                    onChange={(value) => {
                                                                                                        element.comment = value.target.value
                                                                                                        replaceElementObject(element, index)
                                                                                                    }}
                                                                                                />
                                                                                            } {element.comment_dropdown &&
                                                                                                <AzkSelect
                                                                                                    compID={`az_clearance_element_comment-` + index}
                                                                                                    modelName="az.clearance.element.comment"
                                                                                                    valueField="code"
                                                                                                    titleField="description"
                                                                                                    // dataDomain={}
                                                                                                    // disabledOptions={}
                                                                                                    isMultiple={false}
                                                                                                    allowEmpty={true}
                                                                                                    fieldName={`element_comment_id-` + index}
                                                                                                    disabled={!canEdit}
                                                                                                    onChange={(value) => {
                                                                                                        element.comment = value
                                                                                                        replaceElementObject(element, index)
                                                                                                    }}
                                                                                                    defaultValue={element.comment} />
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            <button
                                                                                                type="reset"
                                                                                                onClick={() => { handleDeleteElement(element, index) }}
                                                                                                className='btn btn-light btn-light-info p-1 px-2'
                                                                                            ><i className="bi bi-trash p-0"></i></button>
                                                                                        </td>
                                                                                    </tr>

                                                                                </>
                                                                            )
                                                                        })) : (
                                                                            <tr>
                                                                                <td colSpan={11}>
                                                                                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                                                        No matching records found
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )}
                                                                        <tr>
                                                                            <td colSpan={3}>
                                                                                <button
                                                                                    type="reset"
                                                                                    onClick={() => { handleAddElement() }}
                                                                                    className='btn btn-light btn-light-info p-1 px-2 btn-sm'
                                                                                ><i className="fa fa-plus p-0"></i> Add</button>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Row>
                                                    </fieldset>

                                                    {/* begin::Actions */}
                                                    <div className='py-5 d-flex justify-content-end'>
                                                        {!canValidate && canEdit &&
                                                            <button
                                                                title="Submit for approval"
                                                                type='submit'
                                                                onClick={() => { setNeedSubmit(true) }}
                                                                className='btn btn-light btn-light-info mx-5 btn-sm'
                                                                disabled={!canEdit}>
                                                                <i className="fa fa-podcast fs-2" aria-hidden="true"></i>
                                                                <span className='indicator-label'>Submit</span>
                                                            </button>
                                                        }
                                                        {!canValidate && canEdit &&
                                                            <button
                                                                title="Save"
                                                                type='submit'
                                                                onClick={() => { setNeedSubmit(false) }}
                                                                className='btn btn-light btn-light-primary mx-5 btn-sm  '
                                                                disabled={isSubmitting || !canEdit}
                                                            >
                                                                {/* <KTIcon iconName='fa-save' className='fs-2' /> */}
                                                                <i className="fas fa-check fs-2"></i>
                                                                <span className='indicator-label'>Save</span>
                                                            </button>
                                                        }
                                                        {!canValidate &&
                                                            <button
                                                                title="Discard & back"
                                                                type='reset'
                                                                onClick={() => cancel()}
                                                                className='btn btn-light btn-active-light-primary me-3 mx-5 btn-sm'
                                                                data-kt-users-modal-action='cancel'
                                                            >
                                                                <KTIcon iconName='exit-left' className='fs-2' />
                                                                <span className='indicator-label'>Discard</span>

                                                            </button>
                                                        }
                                                        {canDelete && id &&
                                                            <button
                                                                title="Delete"
                                                                type='reset'
                                                                onClick={() => deleteTransaction()}
                                                                className='btn btn-light btn-light-danger me-3 mx-5 btn-sm'
                                                                data-kt-users-modal-action='delete'
                                                            >
                                                                <KTIcon iconName='trash' className='fs-2' />
                                                                <span className='indicator-label'>Delete</span>

                                                            </button>
                                                        }
                                                    </div>
                                                    {/* end::Actions */}
                                                </Row>
                                            </>)}
                                    </Form>
                                </>
                            )
                        }}
                    </Formik>
                </Col>
                <Col md='12' lg='3' xl='3'>
                    {id && (<AzkStatusRibbon transactionId={parseInt(id)} modelName={MODEL_NAME}
                        current={transactionState} currentName={transactionStateName} />
                    )}
                    {employee && <AzkChatter transactionClosed={transactionClosed} isDrawer={true} modelName={MODEL_NAME} itemID={objectId} hitSend={hitSend} sendMessagesCallback={handleResultOfsubmit} />}                </Col>
            </Row>
        </>
    )
}

export default Transactionform;