import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { FinalSettelementChart } from 'app/modules/charts/final-settelement-chart'
import { TransactrionsStatusChart } from 'app/modules/charts/transaction-status-chart'
import { TransactrionSubmitDurationChart } from 'app/modules/charts/transaction-status-submit-duration-chart'
import { AZKHelper } from 'app/modules/components/azkHelper'
import { useAuth } from 'app/modules/auth'

const DashboardPage = () => {

  const [accessrights, setAccessrights] = useState<string[]>([]);
  const [accessrightsLoaded, setAccessrightsLoaded] = useState<Boolean>(false);
  const { hasAuthority } = useAuth()

  const fillAccessreights = async () => {
    setAccessrightsLoaded(true);
    setAccessrights([])
    const neededAccessRights = [
      [AZKHelper.TRANSACTIONTYPE.AZ_CHART_TRANSACTION_STATUS, AZKHelper.READ_ACTION],
      [AZKHelper.TRANSACTIONTYPE.AZ_CHART_SUBMIT_DURATION, AZKHelper.READ_ACTION],
      [AZKHelper.TRANSACTIONTYPE.AZ_CHART_FS, AZKHelper.READ_ACTION],
    ]
    const promises = await neededAccessRights?.map(async (accessRight, index) => new Promise(async (resolve, reject) => {
      const allowed = await hasAuthority(accessRight[0], accessRight[1])
      if (allowed) {
        if (accessRight[1] === AZKHelper.READ_ACTION) {
          resolve(accessRight[0])
        }
        else
          resolve(accessRight[0])
      } else {
        resolve('')
      }

    }));
    if (promises)

      Promise.all(promises).then((allowed: any) => {
        setAccessrightsLoaded(true);
        setAccessrights(allowed)
      })
  }
  useEffect(() => {
    // We have to show toolbar only for dashboard page
    fillAccessreights()
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]} description=''>
        Dashboard
      </PageTitle>
      {/* begin::Row */}
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        {/* begin::Col */}
        {/* <div className='col-xl-4'>
          <MixedWidget6
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
          />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        {/* <div className='col-xl-4'>
          <MixedWidget7 className='card-xl-stretch' chartColor='primary' chartHeight='225px' />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        {/* <div className='col-xl-4'>
          <MixedWidget8
            className='card-xl-stretch mb-5 mb-xl-8'
            chartColor='danger'
            chartHeight='150px'
          />
        </div> */}
        {/* end::Col */}
        {accessrightsLoaded && (<>

          {/* begin::Col */}
          {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_CHART_TRANSACTION_STATUS) && < TransactrionsStatusChart className={''} />}
          {/* end::Col */}

          {/* begin::Col */}
          {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_CHART_SUBMIT_DURATION) && <TransactrionSubmitDurationChart className={''} />}
          {/* end::Col */}

          {/* begin::Col */}
          {accessrights.includes(AZKHelper.TRANSACTIONTYPE.AZ_CHART_FS) && <FinalSettelementChart className={''} />}
          {/* end::Col */}
        </>)
        }
      </div>
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
