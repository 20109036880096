import { Response } from "_metronic/helpers"
import { AZKHelper } from "app/modules/components/azkHelper"
import moment from "moment"

export type SalaryStructureSetting = {
  id?: number
  name?: string
  az_country_id?: any
  brand_id?: any
  location_id?: any
  job_id?: any
  grade_id?: any
  person_type?: any
  employment_category_id?: any
  package?: string
  has_com?: boolean
  com?: number
  as_effective_date?: string
  is_service_charge?: boolean
  applied?: boolean
  business_type?: any
  is_editable: boolean
  hrlt_brand_domain_ids?: any
}

export const initialTransaction: SalaryStructureSetting = {
  name: '',
  is_editable: true,
  has_com: false,
  as_effective_date: moment().format(AZKHelper.FRONTEND_DATE_FORMAT)
}

export type SalaryStructureSettingQueryResponse = Response<Array<SalaryStructureSetting>>

export const initialTransferCompany: SalaryStructureSetting = {
  is_editable: true

}
