import AzkSelect from "_azkatech/azkSelect";
import { EmployeeDetail, EmpoyeeModel } from "app/modules/components/employeeDetail/employeeDetail";
import axios from "axios";
import * as Yup from 'yup'
import { TransferCompany, initialTransfer } from "./transferModel";
import { FC, useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik, FormikProps, FormikValues } from 'formik'
import { CreateTransferCompany, UpdateTransferCompany, GetTransferCompanyById, MODEL_NAME, DeleteTransferCompany } from "./_requests";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { AzkStatusRibbon } from "_azkatech/azkStatusRibbon";
import AzkActionButtons from "_azkatech/azkActionButtons";
import { Form, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { ID, isNotEmpty, KTIcon } from "_metronic/helpers";
import { useNavigate } from "react-router";
import { API_URL } from "_azkatech/azkHelper";
import { AZKHelper } from "app/modules/components/azkHelper";
import { AzkChatter } from "_azkatech";
import { checkDateConstrains, checkTrnsactionTypeAccessId } from "app/utilities/transactionTyperequests";
import { useParams } from "react-router-dom";
import { useSalaryReqeust } from "app/utilities/salaryRequests";
import { GetEmployeeById } from "app/modules/components/employeeDetail/_requests";

type Props = {
}
const editTransferSchema = Yup.object().shape({
    employee_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Employee is required'),
    target_company_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Company is required'),
    target_brand_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Brand is required'),
    target_branch_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Branch is required'),
    target_job_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Title is required'),
    required_section_id: Yup.boolean(),
    target_section_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value).when('required_section_id', (required_section_id, field) =>
        (required_section_id[0] === true) ? field.required('Section is required') : field
    ),
    target_grade_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Grade is required'),
    target_supervisor_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Supervisor is required'),
})


const TransferForm: FC<Props> = () => {
    const { id } = useParams();
    const Swal = require('sweetalert2')
    const intl = useIntl();
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<TransferCompany>>(null);
    const [companyDomain, setCompanyDomain] = useState<string | undefined>(undefined);
    const [payrollDomain, setPayrollDomain] = useState<string | undefined>(undefined);
    const [brandDomain, setBrandDomain] = useState<string | undefined>(undefined);
    const [branchDomain, setBranchDomain] = useState<string | undefined>(undefined);
    const [titleDomain, setTitleDomain] = useState<string | undefined>(undefined);
    const [sectionDomain, setSectionDomain] = useState<string | undefined>(undefined);
    const [gradeDomain, setGradeDomain] = useState<string | undefined>(undefined);
    const [supervisorDomain, setSupervisorDomain] = useState<string | undefined>(undefined);
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [transactionClosed, setTransactionClosed] = useState<boolean>(false);
    const [canValidate, setCanValidate] = useState<boolean>(false);
    const [canDelete, setCanDelete] = useState<boolean>(false);
    const [canEditCustom, setCanEditCustom] = useState<boolean>(false);
    const [needSubmit, setNeedSubmit] = useState<boolean>(false);
    const [employee, setEmployee] = useState<EmpoyeeModel>();
    const [transactionState, setTransactionState] = useState<string>('draft');
    const [transactionStateName, setTransactionStateName] = useState<string>('Draft');
    const [hitSend, setHitSend] = useState<boolean>(false);
    const [objectId, setObjectId] = useState<number>();
    const { GetSalaryByTitlebranch } = useSalaryReqeust()

    const [dateFrom, setDateFrom] = useState<any>(moment());
    const [startOftheMonth, setStartOftheMonth] = useState<any>(true);
    const [allowBackDate, setAllowBackDate] = useState<any>(true);


    const handleEmployeeChange = async function (employeeId, transferCompanyData): Promise<{}> {
        document.body.classList.add('page-loading')
        if (employeeId) {
            setEmployee(undefined)
            GetEmployeeById(employeeId).then(response => {
                if (response) {
                    const tempEmployee = response
                    if (id || !tempEmployee.has_running_transaction) {
                        setEmployee(response)
                        setSupervisorDomain(tempEmployee.id ? `('id','<>', ${tempEmployee.id}), ('coach_id','<>', ${tempEmployee.id}), ('parent_id','<>', ${tempEmployee.id})` : undefined)
                        setTitleDomain(tempEmployee.az_country_id ? `('country_id','=', ${tempEmployee.az_country_id?.id})` : undefined);
                        setCompanyDomain(tempEmployee.az_country_id ? `('id','<>', ${tempEmployee.az_company_id?.id}),('country_id','=', ${tempEmployee.az_country_id?.id})` : undefined);
                        setGradeDomain(tempEmployee.az_country_id ? `('country_id','=', ${tempEmployee.az_country_id?.id})` : undefined);
                        // handleCompanyChange(tempEmployee.az_company_id?.id)
                        // handleBranchChange(tempEmployee.branch_id?.id)
                        formRef.current?.setValues({
                            employee_id: tempEmployee.id,
                            az_country_id: tempEmployee.az_country_id?.id,
                            az_company_id: tempEmployee.az_company_id?.id,
                            payroll_id: tempEmployee.payroll_id?.id,
                            branch_id: tempEmployee.branch_id?.id,
                            brand_id: tempEmployee.brand_id?.id,
                            grade_id: tempEmployee.grade_id?.id,
                            section_id: tempEmployee.section_id?.id,
                            job_id: tempEmployee.job_id?.id,
                            as_effective_date: moment(tempEmployee.as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT),
                            time_set: tempEmployee.time_set,
                            salary: tempEmployee.salary,
                            // ---------------------------------------------------
                            required_section_id: false,
                            target_salary: tempEmployee.salary,
                            target_grade_id: tempEmployee.grade_id?.id,
                            target_job_id: tempEmployee.job_id?.id,
                            target_as_effective_date: formRef.current.values.target_as_effective_date ? moment(formRef.current.values.target_as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT) : moment(tempEmployee?.target_as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT),
                            target_supervisor_id: tempEmployee.parent_id?.id
                        })
                        if (transferCompanyData) {
                            pobulateFormData(transferCompanyData)
                            handleDateConstraint(transferCompanyData.target_as_effective_date, tempEmployee.az_country_id?.id, tempEmployee.az_company_id?.id, tempEmployee.brand_id?.id)    
                        } else {
                            handleDateConstraint(moment.now(), tempEmployee.az_country_id?.id, tempEmployee.az_company_id?.id, tempEmployee.brand_id?.id)    
                        }
                        document.body.classList.remove('page-loading')
                    } else {
                        document.body.classList.remove('page-loading')
                        Swal.fire({
                            position: "top-right",
                            title: 'Sorry!',
                            text: 'This employee ' + tempEmployee.name + ' already has an open transaction with details ' + tempEmployee.running_transaction + '!',
                            icon: "error",
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            iconColor: 'white',
                            customClass: {
                                popup: 'colored-toast'
                            },
                            timerProgressBar: true
                        })
                    }
                } else {
                    document.body.classList.remove('page-loading')
                }
            });
        } else {
            document.body.classList.remove('page-loading')
            formRef.current?.resetForm();
            setEmployee(undefined)
            formRef.current?.setValues({
                employee_id: undefined,
                az_country_id: undefined,
                az_company_id: undefined,
                payroll_id: undefined,
                branch_id: undefined,
                brand_id: undefined,
                grade_id: undefined,
                section_id: undefined,
                job_id: undefined,
                as_effective_date: undefined,
                time_set: undefined,
                salary: undefined,
                target_company_id: undefined,
                target_branch_id: undefined,
                target_payroll_id: undefined,
                target_grade_id: undefined,
                target_brand_id: undefined,
                target_job_id: undefined,
                target_section_id: undefined,
                target_supervisor_id: undefined,
                target_salary: undefined,
            })
        }
        return true
    }

    const pobulateFormData = async (data?: TransferCompany) => {
        if (data) {
            await handleCompanyChange(data.target_company_id?.id)
            await handleBrandChange(data.target_brand_id?.id, data.target_company_id?.id)
            await handleBranchChange(data.target_branch_id?.id, false)
            formRef.current?.setValues({
                id: data.id,
                employee_id: data.employee_id?.id,
                az_country_id: data.az_country_id?.id,
                az_company_id: data.az_company_id?.id,
                payroll_id: data.payroll_id?.id,
                branch_id: data.branch_id?.id,
                brand_id: data.brand_id?.id,
                grade_id: data.grade_id?.id,
                section_id: data.section_id?.id,
                job_id: data.job_id?.id,
                as_effective_date: moment(data.as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT),
                time_set: data.time_set,
                salary: data.salary,
                azadea_employee_name: data.azadea_employee_name,
                current_approval_ids: data.current_approval_ids,

                target_salary: data.target_salary,
                target_company_id: data.target_company_id?.id,
                target_payroll_id: data.target_payroll_id?.id,
                target_branch_id: data.target_branch_id?.id,
                target_brand_id: data.target_brand_id?.id,
                target_grade_id: data.target_grade_id?.id,
                target_section_id: data.target_section_id?.id,
                target_job_id: data.target_job_id?.id,
                target_as_effective_date: moment(data?.target_as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT),
                target_supervisor_id: data.target_supervisor_id?.id
            })
        }
    }

    const checkPermission = async (action: string) => {
        const allowed = await checkTrnsactionTypeAccessId(MODEL_NAME, action)
        if (!allowed) {
            navigate(`/transactions/`, { state: {}, replace: true });
            Swal.fire({
                position: "top-right",
                title: 'Access Error!',
                text: 'You does not have access to perform this action!',
                icon: "error",
                showConfirmButton: false,
                timer: AZKHelper.SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        }
    }

    const handleDateConstraint = async (target_as_effective_date, country_id, company_id, brand_id) => {
        let start_ofthe_month = false
        let allow_back_date = false
        let date_from = moment()
        if (!country_id) country_id = formRef.current?.getFieldProps('country_id').value
        if (!company_id) company_id = formRef.current?.getFieldProps('company_id').value
        if (!brand_id) brand_id = formRef.current?.getFieldProps('brand_id').value
        if (!target_as_effective_date) target_as_effective_date = formRef.current?.getFieldProps('target_as_effective_date').value
        const type_technical_name = MODEL_NAME.replaceAll('.', '_')
        if (country_id && country_id && brand_id)
            checkDateConstrains(country_id, company_id, brand_id, type_technical_name).then(data => {
                if (data.count > 0) {
                    if (data['results'][0]['back_dated']) {
                        allow_back_date = true
                        date_from = moment('2023-01-01')
                    }
                    if (data['results'][0]['start_of_month']) {
                        setStartOftheMonth(true)
                        start_ofthe_month = true
                    }
                    setStartOftheMonth(start_ofthe_month)
                    setDateFrom(date_from)
                    setAllowBackDate(allowBackDate)
                    handleDateChange(target_as_effective_date, date_from, start_ofthe_month, allow_back_date)
                }
            })
    }

    const handleDateChange = async (date_value, date_from, start_ofthe_month, allow_back_date) => {
        date_value = moment(date_value).startOf("day");
        date_from = moment(date_from).startOf("day");
        if (start_ofthe_month) {
            date_value = allow_back_date ? date_value.startOf('month') : date_value.add(1, 'month').startOf('month');
        } else {
            date_value = date_value;
        }
        if (date_value.isBefore(date_from)) {
            date_value = date_from;
        }
        if (id) {
            const target_as_effective_date = moment(formRef.current?.getFieldProps('target_as_effective_date').value)
            formRef.current?.setFieldValue('target_as_effective_date', date_value.format(AZKHelper.FRONTEND_DATE_FORMAT));
        }
        else {
            formRef.current?.setFieldValue('target_as_effective_date', date_value.format(AZKHelper.FRONTEND_DATE_FORMAT));
        }
    };

    useEffect(() => {
        document.body.classList.add('page-loading')
        formRef.current?.resetForm()
        setObjectId(undefined)
        setCanValidate(false)
        handleEmployeeChange(undefined, undefined)
        if (id) {
            setObjectId(parseInt(id))
            checkPermission('write');
            document.body.classList.add('page-loading')
            GetTransferCompanyById(Number.parseInt(id)).then(((data: TransferCompany) => {
                handleEmployeeChange(data?.employee_id?.id, data)
                if (data?.x_state && data?.x_state_view) {
                    setTransactionState(data?.x_state)
                    setTransactionStateName(data?.x_state_view)
                }
                if (data?.can_write && data?.can_write !== null) {
                    setCanEdit(data?.can_write)
                }
                setTransactionClosed(data.validated || data.rejected ? true : false)
                setCanValidate(data?.can_review ? true : false)
                setCanDelete(data?.can_delete ? true : false)
            }))
        } else {
            checkPermission('create');
            formRef.current?.setFieldValue('employee_id', undefined)
            handleEmployeeChange(undefined, undefined)
            setCanEdit(true)
            document.body.classList.remove('page-loading')
        }
    }, [id])

    const handleCompanyChange = (val) => {
        formRef.current?.setFieldValue('target_brand_id', undefined)
        if (val !== '' && val) {
            axios.get(`${API_URL}/az.company?filters=[('id', '=', ${val ? val : '0'})]&include_fields=['name','brand_ids', 'payroll_id']`).then((result: any) => {
                if (result.data && result.data.results && result.data.count > 0 && result.data.results[0].brand_ids !== null) {
                    setBrandDomain(`('id', 'in', [${result.data.results[0].brand_ids}])`);
                    let companyName: string = encodeURIComponent(result.data.results[0].name)
                    if (result.data.results[0].payroll_id)
                        formRef.current?.setFieldValue('target_payroll_id', result.data.results[0].payroll_id[0])
                    setPayrollDomain(`('payroll_name', 'ilike', '${companyName}')`);
                } else {
                    setBrandDomain(undefined);
                }
            });
        } else {
            setBrandDomain(undefined);
            formRef.current?.setFieldValue('target_payroll_id', undefined)
            formRef.current?.setFieldValue('target_brand_id', undefined)
        }
    }

    const handleBrandChange = (val, az_company: number) => {
        if (val) {
            handleDateConstraint(formRef.current?.getFieldProps('target_as_effective_date')?.value, formRef.current?.getFieldProps('az_country_id')?.value, az_company, val)
            formRef.current?.setFieldValue('target_branch_id', undefined)
            setBranchDomain(val ? `${az_company ? `('az_company_id','=', ${az_company}),` : ''}('brand_id','=', ${val})` : '');
        } else {
            setBranchDomain(undefined);
            formRef.current?.setFieldValue('target_branch_id', undefined)
        }
    }

    const handleJobChange = (fromUI: boolean, get_salary_structure: boolean, job_id?: number, branch_id?: number) => {
        if (fromUI) {
            document.body.classList.add('page-loading')
            if (!job_id) job_id = formRef.current?.getFieldProps('target_job_id')?.value
            if (!branch_id) branch_id = formRef.current?.getFieldProps('target_branch_id')?.value
            const time_set = formRef.current?.getFieldProps('time_set')?.value
            const country_id = formRef.current?.getFieldProps('az_country_id')?.value
            if (job_id && branch_id && time_set && get_salary_structure) {
                formRef.current?.setFieldValue('target_salary', NaN)
                formRef.current?.setFieldValue('target_grade_id', undefined)
                GetSalaryByTitlebranch(job_id, branch_id, time_set, country_id).then(result => {
                    document.body.classList.remove('page-loading')
                    formRef.current?.setFieldValue('target_salary', parseFloat(result['salary']))
                    formRef.current?.setFieldValue('target_grade_id', result['grade_id'])
                })
            } else {
                document.body.classList.remove('page-loading')
            }
        }
    }

    const handleBranchChange = (val, fromUI: boolean) => {
        setCanEditCustom(false)
        formRef.current?.setFieldValue('target_section_id', undefined)
        if (val !== '' && val) {
            axios.get(`${API_URL}/az.branch?filters=[('id', '=', ${val ? val : '0'})]&include_fields=['is_back_office']`).then((result: any) => {
                if (result.data && result.data.results && result.data.count > 0 && result.data.results[0].brand_ids !== null) {
                    setCanEditCustom(result.data.results[0].is_back_office)
                    handleJobChange(fromUI, !result.data.results[0].is_back_office, undefined, val)
                    formRef.current?.setFieldValue('required_section_id', !result.data.results[0].is_back_office)
                }
            });
        }
        setSectionDomain(val ? `('organization_id','=', ${val})` : '');
    }

    const cancel = () => {
        navigate(`/transactions/`, { state: {}, replace: true });
    }

    const deleteTransaction = () => {
        Swal.fire({
            position: "center",
            title: 'Delete',
            text: 'Delete Transaction confirm?',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed && id) {
                DeleteTransferCompany(Number.parseInt(id)).then(() => {
                    cancel()
                    Swal.fire({
                        position: "top-right",
                        title: 'Deleted!',
                        text: 'Your transaction had been deleted!',
                        icon: "warning",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                })
            } else if (result.isDenied) {
                Swal.fire('Transaction was not deleted', '', 'info')
            }
        })
    }

    const submitTransaction = (transactionId: number) => {
        setNeedSubmit(false)
        axios.put(`${API_URL}/${MODEL_NAME}/${transactionId}/request_validation`, '{}').then(data => {
            document.body.classList.remove('page-loading')
            navigate(`/transactions/`, { state: {}, replace: true });
            Swal.fire({
                position: "top-right",
                title: 'Success!',
                text: 'Your transaction had been submited!',
                icon: "success",
                showConfirmButton: false,
                timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        })
    }

    const handleResultOfsubmit = (transactionId: ID | undefined, isUpdate: boolean | false) => {
        if (needSubmit && transactionId) {
            submitTransaction(transactionId)
        } else {
            document.body.classList.remove('page-loading')
            navigate(`/transactions/`, { state: {}, replace: true })
            Swal.fire({
                position: "top-right",
                title: 'Success!',
                text: isUpdate ? 'Your transaction had been updated!' : 'Your transaction had been saved!',
                icon: "success",
                showConfirmButton: false,
                timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        }
    }

    const onSubmitHandler = async (values: TransferCompany, actions: FormikValues) => {
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        if (formRef.current?.isValid) {
            document.body.classList.add('page-loading')
            if (isNotEmpty(values.id)) {
                await UpdateTransferCompany(values).then(data => {
                    if (id) handleResultOfsubmit(Number.parseInt(id), true)
                })
            } else {
                await CreateTransferCompany(values).then(data => {
                    setObjectId(data?.id)
                    setHitSend(true)
                })
            }
        } else {
            actions.setSubmitting(false)
            formRef.current?.validateForm().then(errors => {
                if (Object.keys(errors).length > 0) {
                    console.log(errors)
                    //   setStatus('error');
                }
            });
        }
    }

    return (
        <>
            <Row className="m-0 my-2">
                <Col md='12' lg={id ? '9' : '9'} xl={id ? '9' : '9'} >
                    <Formik
                        innerRef={formRef}
                        validationSchema={editTransferSchema}
                        onSubmit={onSubmitHandler}
                        initialValues={initialTransfer}
                        validateeOnChange={true}
                        validateOnMount={true}
                        initialErrors={''}
                    >
                        {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
                            // ,validateForm,isSubmitting, errors, touched, isValidating
                            return (
                                <>
                                    {/* {id && (<AzkStatusBar transactionId={id} modelName={MODEL_NAME}
                                        current={transactionState} />
                                    )} */}
                                    {canValidate && id &&
                                        <AzkActionButtons
                                            actionUrl={`${API_URL}/${MODEL_NAME}/${id}/`}
                                            thenNavigate="/transactions/"
                                            divStyle="card py-2 mb-3"
                                            buttons={[
                                                { title: "Approve", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.APPROVED' }), method: "validate_tier", confirmMsg: "Approve Transaction, Confirm?", cssClass: "btn btn-light btn-light-success", icon: "fa-thumbs-up" },
                                                { title: "Review", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.REVIEW' }), method: "restart_validation", confirmMsg: "Send Transaction back to draft, Confirm?", cssClass: "btn btn-light btn-light-warning", icon: "fa-refresh" },
                                                { title: "Decline", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.DECLINE' }), method: "reject_tier", confirmMsg: "Decline Transaction, Confirm?", cssClass: "btn btn-light btn-light-danger", icon: "fa-times" },
                                            ]} />}
                                    <Form onSubmit={handleSubmit} noValidate>
                                        <fieldset disabled={!canEdit}>
                                            {!id && (
                                                <Row className="card m-0 py-2 mb-3">
                                                    <Col>
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label>
                                                                <h4 className="px-3 m-0 custom-employee-header justify-content-start">
                                                                    <i className="fa fa-magnifying-glass" aria-hidden="true"></i>{intl.formatMessage({ id: 'TRANSACTION.EMPLOYEE' })}
                                                                </h4>
                                                            </Form.Label>
                                                            <AzkSelect
                                                                compID="azk_temp"
                                                                modelName="hr.employee"
                                                                valueField="id"
                                                                titleField="display_name_fr"
                                                                dataDomain=''
                                                                allowEmpty={true}
                                                                fieldName='employee_id'
                                                                onChange={(value) => {
                                                                    setFieldValue('employee_id', parseInt(value))
                                                                    handleEmployeeChange(value, undefined)
                                                                }}
                                                                defaultValue={values.employee_id}
                                                                disabled={!canEdit}
                                                                searchFields={['name', 'azadea_id', 'branch_id', 'job_id', 'work_email', 'first_name']} />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='employee_id' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            )}
                                        </fieldset>
                                        {employee && (
                                            <>
                                                <EmployeeDetail employee={employee} />
                                                <Row className="m-0 card">
                                                    <h4 className="py-2 px-6 m-0 card-header custom-card-header justify-content-start">
                                                        <i className="fa fa-info-circle" aria-hidden="true"></i>Transfer Details</h4>

                                                    <fieldset disabled={!canEdit}>
                                                        <Row className="m-0 my-2">
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        {intl.formatMessage({ id: 'TRANSACTION.COMPANY' })}
                                                                    </Form.Label>
                                                                    <AzkSelect
                                                                        compID="az_company_select"
                                                                        modelName="az.company"
                                                                        valueField="id"
                                                                        titleField="name"
                                                                        dataDomain={companyDomain}
                                                                        isMultiple={false}
                                                                        allowEmpty={true}
                                                                        fieldName='target_company_id'
                                                                        disabled={!canEdit}
                                                                        disableInitCall={true}
                                                                        onChange={(value) => {
                                                                            setFieldValue('target_company_id', parseInt(value))
                                                                            handleCompanyChange(value)
                                                                        }}
                                                                        defaultValue={values.target_company_id} />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='target_company_id' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Payroll
                                                                    </Form.Label>
                                                                    <AzkSelect
                                                                        compID="az_payroll_select"
                                                                        modelName="az.payroll"
                                                                        valueField="id"
                                                                        titleField="payroll_name"
                                                                        dataDomain={payrollDomain}
                                                                        isMultiple={false}
                                                                        allowEmpty={true}
                                                                        disableInitCall={true}
                                                                        fieldName='target_payroll_id'
                                                                        disabled={true}
                                                                        onChange={(value) => {
                                                                            setFieldValue('target_payroll_id', parseInt(value))
                                                                        }}
                                                                        defaultValue={values.target_payroll_id} />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='target_payroll_id' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Brand
                                                                    </Form.Label>
                                                                    <AzkSelect
                                                                        compID="az_brand_select"
                                                                        modelName="az.brand"
                                                                        valueField="id"
                                                                        titleField="name"
                                                                        dataDomain={brandDomain}
                                                                        isMultiple={false}
                                                                        allowEmpty={true}
                                                                        disableInitCall={true}
                                                                        fieldName='target_brand_id'
                                                                        disabled={!canEdit || !values.target_company_id}
                                                                        onChange={(value) => {
                                                                            setFieldValue('target_brand_id', parseInt(value))
                                                                            handleBrandChange(value, formRef.current?.getFieldProps('target_company_id')?.value)
                                                                        }}
                                                                        defaultValue={values.target_brand_id} />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='target_brand_id' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Functional
                                                                    </Form.Label>
                                                                    <AzkSelect
                                                                        compID="az_branch_select"
                                                                        modelName="az.branch"
                                                                        valueField="id"
                                                                        titleField="organization_name"
                                                                        dataDomain={branchDomain}
                                                                        disableInitCall={true}
                                                                        isMultiple={false}
                                                                        allowEmpty={true}
                                                                        fieldName='target_branch_id'
                                                                        disabled={!canEdit || !values.target_brand_id}
                                                                        onChange={(value) => {
                                                                            setFieldValue('target_branch_id', parseInt(value))
                                                                            handleBranchChange(value, true)
                                                                        }}
                                                                        defaultValue={values.target_branch_id} />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='target_branch_id' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className={!canEditCustom ? 'required' : ''}>
                                                                        Section
                                                                    </Form.Label>
                                                                    <AzkSelect
                                                                        compID="az_section_select"
                                                                        modelName="az.section"
                                                                        valueField="id"
                                                                        titleField="section"
                                                                        dataDomain={sectionDomain}
                                                                        isMultiple={false}
                                                                        disableInitCall={true}
                                                                        allowEmpty={true}
                                                                        fieldName='target_section_id'
                                                                        disabled={canEditCustom || !canEdit || !values.target_branch_id}
                                                                        onChange={(value) => {
                                                                            setFieldValue('target_section_id', parseInt(value))
                                                                        }}
                                                                        defaultValue={values.target_section_id} />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='target_section_id' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Title
                                                                    </Form.Label>
                                                                    <AzkSelect
                                                                        compID="az_title_select"
                                                                        modelName="hr.job"
                                                                        valueField="id"
                                                                        titleField="name"
                                                                        dataDomain={titleDomain}
                                                                        isMultiple={false}
                                                                        allowEmpty={true}
                                                                        disableInitCall={true}
                                                                        fieldName='target_job_id'
                                                                        disabled={!canEdit}
                                                                        onChange={(value) => {
                                                                            setFieldValue('target_job_id', parseInt(value))
                                                                            handleJobChange(true, true, parseInt(value), undefined)
                                                                        }}
                                                                        defaultValue={values.target_job_id} />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='target_job_id' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Effective date
                                                                    </Form.Label>

                                                                    {!startOftheMonth &&
                                                                        <Form.Control
                                                                            className='p-1'
                                                                            type='date'
                                                                            onChange={(value) => {
                                                                                handleDateChange(value.target.value, dateFrom, startOftheMonth, allowBackDate)
                                                                            }}
                                                                            disabled={!canEdit}
                                                                            value={moment(values.target_as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                            min={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                        />
                                                                    }
                                                                    {startOftheMonth &&
                                                                        <Form.Control
                                                                            className='p-1'
                                                                            type='date'
                                                                            // isInvalid={isInvalidDate}
                                                                            onChange={(value) => {
                                                                                handleDateChange(value.target.value, dateFrom, startOftheMonth, allowBackDate)
                                                                            }}
                                                                            disabled={!canEdit}
                                                                            value={moment(values.target_as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                            min={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                        />
                                                                    }
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='target_as_effective_date' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Grade
                                                                    </Form.Label>
                                                                    <AzkSelect
                                                                        compID="az_grade_select"
                                                                        modelName="az.grade"
                                                                        valueField="id"
                                                                        titleField="grade_name"
                                                                        dataDomain={gradeDomain}
                                                                        isMultiple={false}
                                                                        allowEmpty={false}
                                                                        disableInitCall={true}
                                                                        fieldName='target_grade_id'
                                                                        disabled={!canEditCustom || !canEdit}
                                                                        onChange={(value) => {
                                                                            setFieldValue('target_grade_id', parseInt(value))
                                                                        }}
                                                                        defaultValue={values.target_grade_id} />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='target_grade_id' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Salary
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type="number"
                                                                        className='p-1'
                                                                        id='target_salary'
                                                                        name='target_salary'
                                                                        disabled={!canEditCustom || !canEdit}
                                                                        onChange={(value) =>
                                                                            setFieldValue('target_salary', value.target.value)
                                                                        }
                                                                        value={values.target_salary}
                                                                        min={1}
                                                                    />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='target_salary' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Supervisor
                                                                    </Form.Label>
                                                                    <AzkSelect
                                                                        compID="az_supervisor_select"
                                                                        modelName="hr.employee"
                                                                        valueField="id"
                                                                        titleField="name"
                                                                        dataDomain={supervisorDomain}
                                                                        disabled={!canEdit}
                                                                        disableInitCall={true}
                                                                        isMultiple={false}
                                                                        allowEmpty={true}
                                                                        fieldName='target_supervisor_id'
                                                                        onChange={(value) => {
                                                                            setFieldValue('target_supervisor_id', parseInt(value))
                                                                        }}
                                                                        defaultValue={values.target_supervisor_id}
                                                                        searchFields={['name', 'azadea_id', 'branch_id', 'job_id', 'work_email', 'first_name']} />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='target_supervisor_id' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </fieldset>
                                                    {/* begin::Actions */}
                                                    <div className='py-5 d-flex justify-content-end'>
                                                        {!canValidate && canEdit &&
                                                            <button
                                                                title="Submit for approval"
                                                                type='submit'
                                                                onClick={() => { setNeedSubmit(true) }}
                                                                className='btn btn-light btn-light-info mx-5 btn-sm'
                                                                disabled={!canEdit}>
                                                                <i className="fa fa-podcast fs-2" aria-hidden="true"></i>
                                                                <span className='indicator-label'>Submit</span>
                                                            </button>
                                                        }
                                                        {!canValidate && canEdit &&
                                                            <button
                                                                title="Save"
                                                                type='submit'
                                                                onClick={() => { setNeedSubmit(false) }}
                                                                className='btn btn-light btn-light-primary mx-5 btn-sm  '
                                                                disabled={isSubmitting || !canEdit}
                                                            >
                                                                {/* <KTIcon iconName='fa-save' className='fs-2' /> */}
                                                                <i className="fas fa-check fs-2"></i>
                                                                <span className='indicator-label'>Save</span>
                                                            </button>
                                                        }
                                                        {!canValidate &&
                                                            <button
                                                                title="Discard & back"
                                                                type='reset'
                                                                onClick={() => cancel()}
                                                                className='btn btn-light btn-active-light-primary me-3 mx-5 btn-sm'
                                                                data-kt-users-modal-action='cancel'
                                                            >
                                                                <KTIcon iconName='exit-left' className='fs-2' />
                                                                <span className='indicator-label'>Discard</span>

                                                            </button>
                                                        }
                                                        {canDelete && id &&
                                                            <button
                                                                title="Delete"
                                                                type='reset'
                                                                onClick={() => deleteTransaction()}
                                                                className='btn btn-light btn-light-danger me-3 mx-5 btn-sm'
                                                                data-kt-users-modal-action='delete'
                                                            >
                                                                <KTIcon iconName='trash' className='fs-2' />
                                                                <span className='indicator-label'>Delete</span>

                                                            </button>
                                                        }
                                                    </div>
                                                    {/* end::Actions */}
                                                </Row>
                                            </>)}
                                    </Form>
                                </>
                            )
                        }}
                    </Formik>
                </Col>
                <Col md='12' lg='3' xl='3'>
                    {id && (<AzkStatusRibbon transactionId={parseInt(id)} modelName={MODEL_NAME}
                        current={transactionState} currentName={transactionStateName} />
                    )}
                    {employee && <AzkChatter transactionClosed={transactionClosed} isDrawer={true} modelName={MODEL_NAME} itemID={objectId} hitSend={hitSend} sendMessagesCallback={handleResultOfsubmit} />}
                </Col>
            </Row>
        </>
    )
}

export default TransferForm;