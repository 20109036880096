import React, { useState, useEffect, ReactHTML } from 'react';
import axios, { ResponseType } from 'axios';
import azkSelect from "_azkatech/azkSelect"
import azkDataTable from "_azkatech/azkDataTable"
import { useNavigate } from 'react-router';
import { KTCard, KTIcon } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper';
import * as authHelper from 'app/modules/auth/core/AuthHelpers'
import { AzkStatusRibbon } from '_azkatech/azkStatusRibbon';
import { Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { AZKHelper } from 'app/modules/components/azkHelper';
import { AuthModel } from 'app/modules/auth';
import { CSVLink } from 'react-csv'
import ReactDOM from 'react-dom';

export const transactionModle = {
    transfer_company: 'az.trn.transfer.company',
    transfer_within_company: 'az.trn.transfer.within.company',
    demotion: 'az.trn.demotion',
    promotion: 'az.trn.promotion',
    addition_deduction: 'az.trn.addition.deduction',
    change_employment_category: 'az.trn.change.employment.category',
    manager_assignment: 'az.trn.manager.assignment',
    separation: 'az.trn.separation',
    clearance: 'az.trn.clearance',
    final_settlement: 'az.trn.final.settlement'
};

const TransactionListView = () => {
    const [listDataDomain, setListDataDomain] = useState("");
    const [searchTerm, setSearchTerm] = useState<string>('')
    const navigate = useNavigate()
    const MODEL_NAME = "az.all.transaction.view"

    const [myRequests, setMyRequests] = useState<boolean>(false);
    const [deleted, setDeleted] = useState<boolean>(false);
    const [selectedExcelData, setSelectedExcelData] = useState<any[]>([]);
    const [userId, setUserId] = useState<number>();

    const [dateFrom, setDateFrom] = useState<any>();
    const [dateTo, setDateTo] = useState<any>();
    const [pageLoaded, setPageLoaded] = useState<boolean>(false);

    // #region Transfer List as DataTable
    const TRN_list_LOCAL_STORAGE_FILTER_KEY = 'azk-trn-list-filter-hr-lite'
    const fields = "'trn_id', 'name', 'employee_id', 'azadea_id', 'as_effective_date', 'create_date', 'employee_name', ('az_country_id',('name')), ('target_company_id',('id', 'name')), ('job_id',('name')),('target_brand_id',('id', 'name')), 'x_state', 'state', 'x_state_view', 'type_link', 'type_name', 'fs_file_uploaded', 'fs_file_uploaded_id', 'restarted'"
    const updateListDataDomain = (search, country, company, brand, state, type_id, myRequest, dateFrom, dateTo, deleted) => {
        let filter = {}
        filter['showFilters'] = showFilters
        let conds: string[] = [];
        if (country !== '' && country && country != 'undefined') {
            conds.push(`('az_country_id','=', ${country})`)
            if (handleChangeSelection) filter['az_country_id'] = country
        }
        if (company !== '' && company && company != 'undefined') {
            conds.push(`('target_company_id','=', ${company})`)
            if (handleChangeSelection) filter['target_company_id'] = company
        }
        if (brand !== '' && brand && brand != 'undefined') {
            conds.push(`('target_brand_id','=', ${brand})`)
            if (handleChangeSelection) filter['target_brand_id'] = brand
        }
        if (state !== '' && state && state != 'undefined') {
            conds.push(`('x_state','ilike', '${state}')`)
            if (handleChangeSelection) filter['x_state'] = state
        }
        if (type_id !== '' && type_id && type_id != 'undefined') {
            conds.push(`('type_id','=', ${type_id})`)
            if (handleChangeSelection) filter['type_id'] = type_id
        }
        if (myRequest && userId && myRequest != 'undefined') {
            conds.push(`('create_uid','=', ${userId})`)
            if (handleChangeSelection) filter['create_uid'] = userId
        }
        if (dateFrom && dateFrom != 'undefined' && dateFrom != "Invalid date") {
            conds.push(`('as_effective_date','>=', '${dateFrom}')`)
            if (handleChangeSelection) filter['date_from'] = dateFrom
        }
        if (dateTo && dateTo != 'undefined' && dateTo != "Invalid date") {
            conds.push(`('as_effective_date','<=', '${dateTo}')`)
            if (handleChangeSelection) filter['date_to'] = dateTo
        }
        if (deleted && deleted !== undefined && deleted != 'undefined') {
            conds.push(`('deleted','=', 'True')`)
        } else {
            const and = encodeURIComponent('&')
            if (handleChangeSelection) filter['deleted'] = deleted
            if (search !== '') {
                conds.push(`'${and}', ('deleted','<>', 'True')`)
            } else {
                conds.push(`('deleted','<>', 'True')`)
            }
        }
        if (search !== '' && search && search != 'undefined') {
            filter['search'] = search
            search = encodeURIComponent(search)
            conds.push(`'|', '|','|', '|', '|',('name','ilike', '${search}'), ('employee_id','ilike', '${search}'), ('azadea_id','ilike', '${search}'), ('az_country_id','ilike', '${search}'), ('target_company_id','ilike', '${search}'), ('target_brand_id','ilike', '${search}')`)
        }
        const lsValue = JSON.stringify(filter)
        if (handleChangeSelection) localStorage.setItem(TRN_list_LOCAL_STORAGE_FILTER_KEY, lsValue)
        return conds.join(',');
    }


    const handleEditBtnClick = (id: any, link: string) => {
        link = link.replaceAll('_', '-')
        navigate(`/transactions/${link}/${id}/edit`, { state: {}, replace: true });
    }

    const handleDownloadBtnClick = (attachment: any, responseType: ResponseType = 'blob') => {
        axios
            .get(`${API_URL}/attachments/${attachment.id}`, {
                responseType,
            })
            .then((response) => {
                let file_name = response.headers['content-disposition']
                file_name = file_name.replace('ttachment; filename=', '')
                file_name = file_name.replaceAll('\"', '')
                const blob = new Blob([response.data], { type: response.headers['content-type'] })
                const url = window.URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', file_name)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                window.URL.revokeObjectURL(url)
            })
            .catch((error) => {
                console.error('Download error:', error)
            })
    }

    const columns = [
        {
            name: 'Transaction',
            selector: row => row.name,
            sortable: true,
            searchable: true,
            tech_name: 'name',
            width: '14%',
            cell: (d) => [(<>
                <Row className='m-0 p-0'>
                    <Row className='fs-7 fw-bold text-gray-800 p-0'>
                        <OverlayTrigger
                            // key={`${d.id}-${d.name}`}
                            placement='top'
                            overlay={
                                <Tooltip id='tooltip-user-name'>{d.name}</Tooltip>} children={
                                    <Row className='fs-8 m-0 fw-bold text-gray-600 text-nowrap'>
                                        {d.name}
                                    </Row>

                                }></OverlayTrigger>
                    </Row>
                    <Row className='fs-8 fw-bold text-gray-600 p-0'>
                        <OverlayTrigger
                            // key={`${d.id}-${d.type_name}`}
                            placement='top'
                            overlay={
                                <Tooltip id='tooltip-user-name'>{d.type_name}</Tooltip>} children={
                                    <Row className='fs-8 m-0 fw-bold text-gray-600'>
                                        {d.type_name}
                                    </Row>

                                }></OverlayTrigger>
                    </Row>
                </Row>
            </>)
            ]
        },
        {
            name: 'Creation Date',
            selector: row => row.create_date ? row.create_date : undefined,
            sortable: true,
            tech_name: 'create_date',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {moment(d.create_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                </Row>
            </>)]
        },
        {
            name: 'Azadea ID',
            selector: row => row.employee_id ? row.azadea_id : undefined,
            sortable: true,
            tech_name: 'azadea_id',
            width: '17%',
            cell: (d) => [(<>
                <Row className='m-0'>
                    <Row className='fs-7 fw-bold text-gray-800'>
                        {d.employee_name}
                    </Row>
                    <Row className='fs-8 fw-bold text-gray-600'>
                        {d.azadea_id}
                    </Row>
                </Row>
            </>)
            ]
        },
        {
            name: 'Effective Date',
            selector: row => row.as_effective_date ? row.as_effective_date : undefined,
            sortable: true,
            tech_name: 'as_effective_date',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {moment(d.as_effective_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                </Row>
            </>)]
        },
        {
            name: 'Country',
            selector: row => row.az_country_id ? row.az_country_id.name : undefined,
            sortable: true,
            tech_name: 'az_country_id',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {d.az_country_id.name}
                </Row>
            </>)]
        },
        {
            name: 'Company',
            selector: row => row.target_company_id ? row.target_company_id.name : undefined,
            sortable: true,
            tech_name: 'target_company_id',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {d.target_company_id.name}
                </Row>
            </>)]
        },
        {
            name: 'Brand',
            selector: row => row.target_brand_id ? row.target_brand_id.name : undefined,
            sortable: true,
            tech_name: 'target_brand_id',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {d.target_brand_id.name}
                </Row>
            </>)]
        },
        {
            name: <> <span className='w-100 text-center'>Status</span> </>,
            selector: row => row.x_state ? row.x_state : undefined,
            sortable: true,
            tech_name: 'x_state',
            style: {
                display: 'block',
                'text-wrap': 'nowrap'
            },
            cell: (d) => [
                <AzkStatusRibbon current={d.x_state} modelName={transactionModle[d.type_link]} transactionId={d.trn_id} currentName={d.x_state_view} />
            ]
        },
        // {
        //   name: "Director",
        //   selector: "director",
        //   sortable: true,
        //   cell: (d) => (
        //     <a href="https://google.com" target="_blank" className="dlink">
        //       {d.director}
        //     </a>
        //   )
        // },
        // {
        //   name: "Genres",
        //   selector: "genres",
        //   sortable: true,
        //   cell: (d) => <span>{d.genres.join(", ")}</span>
        // },
        {
            name: <> <span className='w-100 text-center'>Actions</span> </>,
            sortable: false,
            selector: undefined,
            cell: (d) => [
                (<>
                    {/* // <span
                //   key={d.title}
                //   onClick={handleEditBtnClick.bind(this, d.id)}
                //   className="btn btn-sm btn-icon btn-active-light-primary me-1"
                // ><KTIcon iconName='trash' className='fs-3' /></span>, */}
                    <div className='w-100 m-0 d-flex justify-content-center'>
                        <span
                            onClick={handleEditBtnClick.bind(this, d.trn_id, d.type_link)}
                            className="btn btn-sm btn-icon btn-active-light-primary me-1"
                        ><KTIcon iconName='pencil' className='fs-3' /></span>
                        {d.fs_file_uploaded && d.type_link == 'final_settlement' &&
                            <span
                                onClick={handleDownloadBtnClick.bind(this, d.fs_file_uploaded_id, 'blob')}
                                className="btn btn-sm btn-icon btn-active-light-primary me-1"
                            ><KTIcon iconName='document' className='fs-3' /></span>
                        }
                        {d.fs_file_uploaded && d.type_link == 'clearance' && d.restarted == true &&
                            <span
                                className="btn btn-sm btn-icon me-1"
                            ><KTIcon iconName='double-check' className='fs-3' /></span>
                        }
                    </div>
                </>)
            ]
        }
    ];

    const getDataToExport = async (data: any[]) => {
        let excelDataList: any[] = []
        if (data) {
            await data.forEach(row => {
                const object = builtExportObject(row)
                excelDataList.push(object)
            })
            setSelectedExcelData(excelDataList)
        }
    }

    const hitExport = (data: any[]) => {
        // if (data && data.length > 0) {
        //     const inputElement = document.querySelector('#export_data_excel') as HTMLInputElement
        //     inputElement.click()
        // }
        document.body.classList.remove('page-loading')
        if (data && data.length > 0) {
            const parentElement = document.createElement('div');
            const element = <div><CSVLink data={data} filename={'Transaction List ' + moment().format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)} id="export_data_excel" ></CSVLink></div>
            ReactDOM.render(element, parentElement);
            document.body.appendChild(parentElement)
            const inputElement = document.querySelector('#export_data_excel') as HTMLInputElement
            inputElement.click()
            document.body.removeChild(parentElement)
        }
    }

    const exportSelectedDataToExcel = async () => {
        document.body.classList.add('page-loading')
        hitExport(selectedExcelData)
    }

    const exportDataToExcel = async () => {
        let excelDataList: any[] = []
        document.body.classList.add('page-loading')
        await axios.get(`${API_URL}/${MODEL_NAME}?filters=[${listDataDomain}]&include_fields=[${fields}]&order='create_date desc, as_effective_date desc, name desc'`).then(async response => {
            const remoteData = response.data?.results ? response.data.results : []
            await remoteData.forEach(row => {
                const object = builtExportObject(row)
                excelDataList.push(object)
            })
            if (excelDataList && excelDataList.length > 0) {
                hitExport(excelDataList)
            }
        })
    }

    const builtExportObject = (row) => {
        const object = {}
        object['Name'] = row.name
        object['Type'] = row.type_name
        object['Creation Date'] = moment(row.create_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)
        object['Azadea ID'] = row.azadea_id
        object['Employee Name'] = row.employee_name
        object['Effective Date'] = moment(row.as_effective_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)
        object['Country'] = row.az_country_id.name
        object['Company'] = row.target_company_id.name
        object['Brand'] = row.target_brand_id.name
        // object['Title'] = row.target_job_id.name
        object['Status'] = row.x_state_view
        return object
    }
    const table = azkDataTable({
        modelName: MODEL_NAME,
        fields: fields,
        dataDomain: listDataDomain,
        columns: columns,
        dataOrder: 'create_date desc, as_effective_date desc, name desc',
        exportFunction: getDataToExport,
        pageReady: pageLoaded
    })

    // #endregion Transfer List

    // #region State Dropdown

    const fetchUser = async () => {
        let _auth = authHelper.getAuth()
        if (!_auth) {
            const auth = localStorage.getItem('azk-auth-hr-lite')
            if (auth) {
                _auth = JSON.parse(auth) as AuthModel
            }
        }
        setUserId(_auth?.uid)
        const filterVals: string | null = localStorage.getItem(TRN_list_LOCAL_STORAGE_FILTER_KEY)
        if (filterVals) {
            const jsonObj = JSON.parse(filterVals)
            // setShowFilters(jsonObj['showFilters'])
            if (jsonObj['search']) await setSearchTerm(jsonObj['search'])
            if (jsonObj['az_country_id']) await setCountry(jsonObj['az_country_id'])
            if (jsonObj['target_brand_id']) await setBrandefaultValue(jsonObj['target_brand_id'])
            if (jsonObj['target_company_id']) await setCompany(jsonObj['target_company_id'])
            if (jsonObj['date_from']) await setDateFrom(jsonObj['date_from'])
            if (jsonObj['date_to']) await setDateTo(jsonObj['date_to'])
            if (jsonObj['deleted']) await setDeleted(jsonObj['deleted'])
            if (jsonObj['type_id']) await setType(jsonObj['type_id'])
            if (jsonObj['x_state']) await setState(jsonObj['x_state'])
            setTimeout(() => {
                setListDataDomain(updateListDataDomain(jsonObj['search'], jsonObj['az_country_id'], jsonObj['target_company_id'], jsonObj['target_brand_id'], jsonObj['x_state'], jsonObj['type_id'], myRequests, dateFrom, dateTo, deleted));
            }, 500)
        }
        setTimeout(() => {
            setPageLoaded(true);
        }, 1000)
    }

    useEffect(() => {
        // const init_date_from  = moment().clone().subtract(1, 'month').startOf('month')
        // const init_date_to  = moment().clone().add(1, 'month').startOf('month')
        // setDateFrom(init_date_from)
        // setDateTo(init_date_to)
        document.body.classList.remove('page-loading')
        
        fetchUser();
    }, []);

    // #region status Dropdown
    const [az_state, setState] = useState('');
    const handleStateChange = (option) => {
        if (handleChangeSelection) {
            setState(option);
            setListDataDomain(updateListDataDomain(searchTerm, az_country, az_company, az_brand, option, az_type, myRequests, dateFrom, dateTo, deleted));
        }
    }

    const az_state_select = azkSelect({
        compID: "az_state_select",
        modelName: "az.transaction.status",
        valueField: "technical_name",
        dataDomain: ``,
        titleField: "name",
        allowEmpty: true,
        isSearchable: true,
        defaultValue: az_state,
        onChange: handleStateChange
    });
    // #endregion status Dropdown


    // #region Type Dropdown
    const [az_type, setType] = useState('');
    const [activetTypeDomain, setActivetTypeDomain] = useState(false);

    const handleTypeChange = (option) => {
        if (activetTypeDomain) {
            setType(option);
            if (handleChangeSelection) {
                setListDataDomain(updateListDataDomain(searchTerm, az_country, az_company, az_brand, az_state, option, myRequests, dateFrom, dateTo, deleted));
            }
        }
        setActivetTypeDomain(true)
    }

    const az_type_select = azkSelect({
        compID: "az_type_select",
        modelName: "az.transaction.type",
        valueField: "id",
        dataDomain: `('basic_transaction', '=', 'true')`,
        titleField: "name",
        allowEmpty: true,
        isSearchable: true,
        defaultValue: az_type,
        onChange: handleTypeChange
    });
    // #End region Type Dropdown

    // #region Country Dropdown
    const [az_country, setCountry] = useState('');
    const [activetCountryDomain, setActivetCountryDomain] = useState(false);

    const handleCountryChange = (val) => {
        if (activetCountryDomain) {
            if (handleChangeSelection) {
                setCountry(val);
                setCompanyDomain(val ? `('country_id','=', ${val})` : '');
                setListDataDomain(updateListDataDomain(searchTerm, val, az_company, az_brand, az_state, az_type, myRequests, dateFrom, dateTo, deleted));
            }
        }
        setActivetCountryDomain(true)
    }

    const az_country_select = azkSelect({
        compID: "az_country_select",
        modelName: "az.country",
        valueField: "id",
        titleField: "name",
        allowEmpty: true,
        isSearchable: true,
        defaultValue: az_country,
        onChange: handleCountryChange
    });
    // #endregion Country Dropdown


    // #region Company Dropdown
    const [az_company, setCompany] = useState(null);
    const [companyDomain, setCompanyDomain] = useState('');
    const [activetCompanyDomain, setActivetCompanyDomain] = useState(false);

    const handleCompanyChange = (val) => {
        if (activetCompanyDomain) {
            setCompany(val);
            setBrandefaultValue('')
            if (val !== '') {
            if (handleChangeSelection) {
                setListDataDomain(updateListDataDomain(searchTerm, az_country, val, '', az_state, az_type, myRequests, dateFrom, dateTo, deleted));
            }
                axios.get(`${API_URL}/az.company?filters=[('id', '=', ${val ? val : '0'})]&include_fields=['brand_ids']`).then((result: any) => {
                    if (result.data && result.data.results && result.data.count > 0 && result.data.results[0].brand_ids !== null) {
                        setBrandDomain(`('id', 'in', [${result.data.results[0].brand_ids}])`);
                    } else {
                        setBrandDomain('');
                    }
                });
            } else {
                setBrandDomain('');
            }
        }
        setActivetCompanyDomain(true)
    }

    const az_company_select = azkSelect({
        compID: "az_company_select",
        modelName: "az.company",
        valueField: "id",
        titleField: "name",
        dataDomain: companyDomain,
        defaultValue: az_company,
        allowEmpty: true,
        onChange: handleCompanyChange
    });
    // #endregion Company Dropdown

    // #region Brand Dropdown
    const [az_brand, setBrand] = useState('');
    const [brandDomain, setBrandDomain] = useState('');
    const [brandefaultValue, setBrandefaultValue] = useState('');
    const [activetBrandDomain, setActivetBrandDomain] = useState(false);

    const handleBrandChange = (val) => {
        if (activetBrandDomain) {
            setBrand(val);
            setBrandefaultValue(val)
            if (handleChangeSelection) {
            setListDataDomain(updateListDataDomain(searchTerm, az_country, az_company, val, az_state, az_type, myRequests, dateFrom, dateTo, deleted));
        }
    }
        setActivetBrandDomain(true)
    }

    const az_brand_select = azkSelect({
        compID: "az_brand_select",
        modelName: "az.brand",
        valueField: "id",
        titleField: "name",
        dataDomain: brandDomain,
        allowEmpty: true,
        onChange: handleBrandChange,
        defaultValue: brandefaultValue
    });

    // #endregion Brand Dropdown

    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [handleChangeSelection, setHandleChangeSelection] = useState<boolean>(false);

    function showFilter() {
        setShowFilters(!showFilters)
        setHandleChangeSelection(true)
    }

    return (
        <>
            <KTCard>
                <div className='card-header'>
                    <div className='card-title'>
                        {/* begin::Search */}
                        <div className='d-flex align-items-center position-relative my-1'>
                            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                            <input
                                type='text'
                                data-kt-user-table-filter='search'
                                className='form-control form-control-solid w-300px ps-14'
                                placeholder='Search Employee, Transaction...'
                                value={searchTerm}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value)
                                    setListDataDomain(updateListDataDomain(e.target.value, az_country, az_company, az_brand, az_state, az_type, myRequests, dateFrom, dateTo, deleted))
                                }}
                            />
                        </div>
                        {/* end::Search */}
                    </div>
                    {/* begin::Card toolbar */}
                    <div className='card-toolbar'>
                        {/* begin::Group actions */}
                        {/* begin::Export */}
                        <button type='button' className='btn btn-light-primary me-3 btn-sm' onClick={() => showFilter()}>
                            <KTIcon iconName='filter-search' className='fs-2' />
                            Filter
                        </button>
                        {/* <CSVLink data={excelData} filename={'Transaction List ' + moment().format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)} id="export_data_excel" ></CSVLink> */}
                        <button type='button' onClick={exportSelectedDataToExcel} className={`btn btn-light-primary me-3 btn-sm ${selectedExcelData && selectedExcelData.length > 0 ? '' : 'd-none'}`}>
                            <KTIcon iconName='exit-up' className='fs-2' />
                            Export Selected
                        </button>
                        <button type='button' className={`btn btn-light-primary me-3 btn-sm ${selectedExcelData && selectedExcelData.length > 0 ? 'd-none' : ''}`} onClick={exportDataToExcel} >
                            <KTIcon iconName='exit-up' className='fs-2' />
                            Export
                        </button>
                        {/* end::Export */}

                        {/* end::Group actions */}
                    </div>
                    {/* end::Card toolbar */}
                </div>
            </KTCard>
            {showFilters &&
                <KTCard className='mt-3'>
                    <div className='card-body'>
                        <div className='row m-0'>
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-7 my-auto col-12 col-md-4'>Type</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {handleChangeSelection && (<>
                                    {az_type_select}
                                </>)}
                                {/* end::Input */}
                            </div>
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-7 my-auto col-12 col-md-4'>Status</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {handleChangeSelection && (<>
                                {az_state_select}
                                </>)}
                                {/* end::Input */}
                            </div>
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-7 my-auto col-12 col-md-4'>Country</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {handleChangeSelection && (<>
                                {az_country_select}
                                </>)}
                                {/* end::Input */}
                            </div>
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-7 my-auto col-12 col-md-4'>Company</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {handleChangeSelection && (<>
                                {az_company_select}
                                </>)}
                                {/* end::Input */}
                            </div>
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-7 my-auto col-12 col-md-4'>Brand</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {az_brand_select}
                                {/* end::Input */}
                            </div>

                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-7 my-auto col-12 col-md-4'>Date From</label>
                                <Form.Control
                                    className='p-1'
                                    type='date'
                                    onChange={(event) => {
                                        setDateFrom(moment(event.target.value).format(AZKHelper.BACKEND_DATE_FORMAT))
                                        setListDataDomain(updateListDataDomain(searchTerm, az_country, az_company, az_brand, az_state, az_type, myRequests, moment(event.target.value).format(AZKHelper.BACKEND_DATE_FORMAT), dateTo, deleted));
                                    }}
                                    defaultValue={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                    max={moment(dateTo).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                />
                            </div>

                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-7 my-auto col-12 col-md-4'>Date to</label>
                                <Form.Control
                                    className='p-1'
                                    type='date'
                                    onChange={(event) => {
                                        setDateTo(moment(event.target.value).format(AZKHelper.BACKEND_DATE_FORMAT))
                                        setListDataDomain(updateListDataDomain(searchTerm, az_country, az_company, az_brand, az_state, az_type, myRequests, dateFrom, moment(event.target.value).format(AZKHelper.BACKEND_DATE_FORMAT), deleted));
                                    }}
                                    defaultValue={moment(dateTo).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                    min={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                />
                            </div>

                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-7 my-auto col-12 col-md-4'></label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                <div className='d-flex align-items-center justify-content-center'>
                                    <label htmlFor='my_reqest'>
                                        <Form.Check
                                            type="checkbox"
                                            id='my_reqest'
                                            value='my_reqest'
                                            label='My Requests'
                                            name='my_reqest'
                                            checked={myRequests}
                                            onChange={(val) => {
                                                setMyRequests(val.target.checked);
                                                setListDataDomain(updateListDataDomain(searchTerm, az_country, az_company, az_brand, az_state, az_type, val.target.checked, dateFrom, dateTo, deleted))
                                            }}
                                            className='p-1'
                                        />
                                    </label>
                                </div>
                                {/* end::Input */}
                            </div>

                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-7 my-auto col-12 col-md-4'></label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                <div className='d-flex align-items-center justify-content-center'>
                                    <label htmlFor='my_reqest'>
                                        <Form.Check
                                            type="checkbox"
                                            id='deleted'
                                            value='deleted'
                                            label='Show Deleted'
                                            name='deleted'
                                            checked={deleted}
                                            onChange={(val) => {
                                                setDeleted(val.target.checked);
                                                setListDataDomain(updateListDataDomain(searchTerm, az_country, az_company, az_brand, az_state, az_type, myRequests, dateFrom, dateTo, val.target.checked))
                                            }}
                                            className='p-1'
                                        />
                                    </label>
                                </div>
                                {/* end::Input */}
                            </div>
                        </div>
                    </div>
                </KTCard>
            }
            <KTCard className='mt-3'>
                {table}
            </KTCard>
        </>)
}

export default TransactionListView;