import { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import { AZKHelper } from '../azkHelper';
import moment from 'moment';
/**
 @todo: Create Empoyee Helper Class like the Group helper class an replace Included fields
**/
type EmpoyeeModel = {
    id?: number;
    name?: string;
    azadea_employee_name?: string;
    grade_id?: {
        id: number,
        grade_name: string
    };
    branch_id?: {
        id: number,
        name: string,
        organization_name: string,
    };
    brand_id?: {
        id: number,
        name: string,
        brand_code: string
    };
    payroll_id?: {
        id: number,
        payroll_name: string
    };
    az_country_id?: {
        id: number,
        name: string,
        due_date: string
    };
    az_company_id?: {
        id: number,
        name: string
    };
    section_id?: {
        id: number,
        section: string
    }
    job_id?: {
        id: number,
        name: string
    }
    azadea_id?: number;
    salary?: string;
    as_effective_date?: string;
    start_date?: string;
    target_as_effective_date?: string;
    time_set?: string;
    has_running_transaction?: boolean,
    running_transaction?: string,
    parent_id?: {
        id: number,
        name: string
    },
    employment_category_id?:{
        id: number,
        name: string,
        code: string,
    }
    is_manager?: boolean
    is_back_office?: boolean
    is_manager_fr?: boolean
    is_back_office_fr?: boolean
    is_supper_access?: boolean
    name_in_passport: string,
    passport_number: string,
    passport_expiry: any
    birthday: any
    mobile_phone: string,
    allwoed_back_number_of_day: number
    allowed_time_diff: number
    allwoed_travel_request: any
};

type EmployeeInfoPrps = {
    employee?: EmpoyeeModel;
    hideSalary?: boolean,
    classcss?: string;
    title?: string;
}

const EmployeeDetail = (employeeInfoPrps: EmployeeInfoPrps) => {

    useEffect(() => {
        return () => {
            // document.body.classList.remove('modal-open')
        }
    }, [])

    return (
        <>
            <Row className={`m-0 mb-3 card row rounded ${employeeInfoPrps.classcss ? employeeInfoPrps.classcss : ""}`}>
                <div
                    className='card-header cursor-pointer px-3 employee_details-card-header'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_az_employee_details'
                    aria-expanded='true'
                    aria-controls='kt_az_employee_details'
                >
                    <div className='row card-title m-0  w-100 '>
                        <div className='d-flex justify-content-between'>
                            <h4 className='mb-2 mt-3'>
                                <i className="fa fa-user" aria-hidden="true"></i>
                                {employeeInfoPrps.title ? employeeInfoPrps.title : 'Employee'} Details</h4>
                            <i className="rounded btn btn-sm btn-icon btn-active-light-primary btn-light-primary fa-solid fa-chevron-up rounded"></i>
                        </div>
                    </div>
                </div>
                <Row id='kt_az_employee_details' className='m-0 collapse show pt-4 pb-2 rounded'>
                    <Col lg="4" md="4" sm="6" xs="12">
                        <Form.Group className='mb-2 d-md-flex d-block'>
                            <Form.Label className='pt-1 p-0 col-12 col-md-6 col-lg-4'>Country
                            </Form.Label>
                            <Form.Control
                                className='p-1'
                                type="text"
                                aria-label="Disabled input example"
                                name='country_id'
                                disabled
                                readOnly
                                defaultValue={employeeInfoPrps?.employee?.az_country_id?.name}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="12">
                        <Form.Group className='mb-2 d-md-flex d-block'>
                            <Form.Label className='pt-1 p-0 col-12 col-md-6 col-lg-4'>Company
                            </Form.Label>
                            <Form.Control
                                className='p-1'
                                type="text"
                                aria-label="Disabled input example"
                                disabled
                                readOnly
                                name='az_company_id'
                                defaultValue={employeeInfoPrps?.employee?.az_company_id?.name}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="12">
                        <Form.Group className='mb-2 d-md-flex d-block'>
                            <Form.Label className='pt-1 p-0 col-12 col-md-6 col-lg-4'>Payroll
                            </Form.Label>
                            <Form.Control
                                className='p-1'
                                type="text"
                                aria-label="Disabled input example"
                                disabled
                                readOnly
                                defaultValue={employeeInfoPrps?.employee?.payroll_id?.payroll_name}
                                name='payroll_id'
                            />
                        </Form.Group>
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="12">
                        <Form.Group className='mb-2 d-md-flex d-block'>
                            <Form.Label className='pt-1 p-0 col-12 col-md-6 col-lg-4'>Azadea Id/ Name
                            </Form.Label>
                            <Form.Control
                                className='p-1'
                                type="text"
                                aria-label="Disabled input example"
                                disabled
                                readOnly
                                defaultValue={employeeInfoPrps?.employee?.azadea_employee_name}
                                name='azadea_employee_name'
                            />
                        </Form.Group>
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="12">
                        <Form.Group className='mb-2 d-md-flex d-block'>
                            <Form.Label className='pt-1 p-0 col-12 col-md-6 col-lg-4'>Brand
                            </Form.Label>
                            <Form.Control
                                className='p-1'
                                type="text"
                                aria-label="Disabled input example"
                                disabled
                                readOnly
                                defaultValue={employeeInfoPrps?.employee?.brand_id?.name}
                                name='brand_id'
                            />
                        </Form.Group>
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="12">
                        <Form.Group className='mb-2 d-md-flex d-block'>
                            <Form.Label className='pt-1 p-0 col-12 col-md-6 col-lg-4'>Functional
                            </Form.Label>
                            <Form.Control
                                className='p-1'
                                type="text"
                                aria-label="Disabled input example"
                                disabled
                                readOnly
                                defaultValue={employeeInfoPrps?.employee?.branch_id?.organization_name}
                                name='branch_id'
                            />
                        </Form.Group>
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="12">
                        <Form.Group className='mb-2 d-md-flex d-block'>
                            <Form.Label className='pt-1 p-0 col-12 col-md-6 col-lg-4'>Section
                            </Form.Label>
                            <Form.Control
                                className='p-1'
                                type="text"
                                aria-label="Disabled input example"
                                disabled
                                readOnly
                                defaultValue={employeeInfoPrps?.employee?.section_id?.section}
                                name='section_id'
                            />
                        </Form.Group>
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="12">
                        <Form.Group className='mb-2 d-md-flex d-block'>
                            <Form.Label className='pt-1 p-0 col-12 col-md-6 col-lg-4'>Title
                            </Form.Label>
                            <Form.Control
                                className='p-1'
                                type="text"
                                aria-label="Disabled input example"
                                disabled
                                readOnly
                                defaultValue={employeeInfoPrps?.employee?.job_id?.name}
                                name='job_id'
                            />
                        </Form.Group>
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="12">
                        <Form.Group className='mb-2 d-md-flex d-block'>
                            <Form.Label className='pt-1 p-0 col-12 col-md-6 col-lg-4'>
                                Effective date
                            </Form.Label>
                            <Form.Control
                                className='p-1'
                                type="date"
                                aria-label="Disabled input example"
                                disabled
                                readOnly
                                defaultValue={employeeInfoPrps?.employee?.as_effective_date ? moment(employeeInfoPrps?.employee?.as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT) : ''}
                                name='as_effective_date'
                            />
                        </Form.Group>
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="12">
                        <Form.Group className='mb-2 d-md-flex d-block'>
                            <Form.Label className='pt-1 p-0 col-12 col-md-6 col-lg-4'>
                                Timeset
                            </Form.Label>
                            <Form.Control
                                className='p-1'
                                type="text"
                                aria-label="Disabled input example"
                                disabled
                                readOnly
                                defaultValue={employeeInfoPrps?.employee?.time_set}
                            />
                        </Form.Group>
                    </Col>
                    <Col lg="4" md="4" sm="6" xs="12">
                        <Form.Group className='mb-2 d-md-flex d-block'>
                            <Form.Label className='pt-1 p-0 col-12 col-md-6 col-lg-4'>Grade
                            </Form.Label>
                            <Form.Control
                                className='p-1'
                                type="text"
                                aria-label="Disabled input example"
                                disabled
                                readOnly
                                defaultValue={employeeInfoPrps?.employee?.grade_id?.grade_name}
                                name='grade_id'
                            />
                        </Form.Group>
                    </Col>
                    {!employeeInfoPrps?.hideSalary && employeeInfoPrps?.employee?.salary && (<Col lg="4" md="4" sm="6" xs="12">
                        <Form.Group className='mb-2 d-md-flex d-block'>
                            <Form.Label className='pt-1 p-0 col-12 col-md-6 col-lg-4'>
                                Salary
                            </Form.Label>
                            <Form.Control
                                className='p-1'
                                type="text"
                                aria-label="Disabled input example"
                                disabled
                                readOnly
                                defaultValue={employeeInfoPrps?.employee?.salary}
                                name='salary'
                            />
                        </Form.Group>
                    </Col>)}
                </Row>
            </Row>
        </>
    )
}

export { EmployeeDetail }
export type { EmpoyeeModel }