import { SystemAttendanceLine } from "./model";
import { FC, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Row, Col } from "react-bootstrap";
import moment from "moment";
import { AZKHelper } from "app/modules/components/azkHelper";

type Props = {
    line: SystemAttendanceLine,
    closeModal: () => {}
}

const TransactionSystemAPPViewform: FC<Props> = ({ line, closeModal }) => {

    useEffect(() => {
        document.body.classList.remove('page-loading')
    }, [])

    return (
        <>{line && <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
                style={{ zIndex: 99999 }}
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered px-3'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>

                        <div className='modal-header py-3 px-2'>
                            {/* begin::Modal title */}
                            <h4 className='m-0'>Details</h4>
                            {/* end::Modal title */}
                        </div>
                        {/* <div className='text-center py-3'>
                            <h4 className='m-0'>Leave Details</h4>
                        </div> */}
                        {/* begin::Modal body */}
                        <div className='' >
                            <Row className="m-0 py-3">
                                <Col lg="4" md="4" sm="6" xs="12">
                                    <Form.Group className='mb-2 d-flex justify-content-between'>
                                        <Form.Label className="payslip-form-label">
                                            Id/Name
                                        </Form.Label>
                                        <Form.Label className="payslip-form-label-value">
                                            {line?.azadeaId} / {line?.employeeName}
                                        </Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="12">
                                    <Form.Group className='mb-2 d-flex justify-content-between'>
                                        <Form.Label className="payslip-form-label">
                                            Date
                                        </Form.Label>
                                        <Form.Label className="payslip-form-label-value">
                                            {moment(line?.attendanceDate?.replace('T', ' ')).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                                        </Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="12">
                                    <Form.Group className='mb-2 d-flex justify-content-between'>
                                        <Form.Label className="payslip-form-label">
                                            Type
                                        </Form.Label>
                                        <Form.Label className="payslip-form-label-value">
                                            {line.attendanceType === 'I' ? 'In' : 'Out'}
                                        </Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="12">
                                    <Form.Group className='mb-2 d-flex justify-content-between'>
                                        <Form.Label className="payslip-form-label">
                                            Hour
                                        </Form.Label>
                                        <Form.Label className="payslip-form-label-value">
                                            {line?.attendanceHour}
                                        </Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="12">
                                    <Form.Group className='mb-2 d-flex justify-content-between'>
                                        <Form.Label className="payslip-form-label">
                                            Mins
                                        </Form.Label>
                                        <Form.Label className="payslip-form-label-value">
                                            {line?.attendanceMinute}
                                        </Form.Label>
                                    </Form.Group>
                                </Col>

                            </Row>
                        </div>
                        {/* end::Modal body */}
                        <div className='my-2 row m-0'>
                            <div className={`col-12 d-flex justify-content-end py-3`}>
                                <button id='discard-button' type='button' className='btn btn-dark rounded-0 btn-sm col-4'
                                    onClick={() => closeModal()}>
                                    <span className='indicator-label'>Discard</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>}
        </>
    )
}

export default TransactionSystemAPPViewform;
