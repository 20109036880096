import axios, {AxiosResponse} from 'axios'
import { AdditionDeduction, AdditionDeductionQueryResponse } from './model'
import { ID, Response } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper' 
import useFixServerClientData from 'app/utilities/fix-Server-Client-Data'

const MODEL_NAME = 'az.trn.addition.deduction'
const URL = `${API_URL}/${MODEL_NAME}`
const GET_URL = `${API_URL}/${MODEL_NAME}/`
const INCLUDE_FIELDS = `'id', 'name', 'employee_id', 'type_id', 'x_state', 'x_state_view', 'can_review', 'can_write', 'can_delete', 'validated', 'rejected', 'restarted', 'az_country_id', 'az_company_id', 'payroll_id', 'brand_id', 'azadea_id', 'azadea_employee_name', 'branch_id', 'section_id', 'grade_id', 'job_id', 'salary', 'as_effective_date', 'time_set', 'target_as_effective_date', 'can_edit', ('detail_ids', [('id', 'element_id', 'amount', 'comment', 'comment_dropdown')]), ('detail_ids_view', [('id', 'element_id', 'amount', 'comment', 'comment_dropdown')])`


const GetAdditionDeductionById = (id: ID): Promise<AdditionDeduction> => {
  const { FixServerData} = useFixServerClientData();
  return axios
    .get(`${URL}?filters=[('id','=',${id})]&include_fields=[${INCLUDE_FIELDS}]`)
    .then((response: AxiosResponse<Response<AdditionDeduction>>) => response.data)
    .then((response: Response<AdditionDeduction>) => {
      response['results'][0]['detail_ids'] = response['results'][0]['detail_ids_view']
      return FixServerData(response['results'][0], true)
    })
}

const CreateAdditionDeduction = async (transfer: AdditionDeduction): Promise<AdditionDeduction | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transfer, true)
  return axios
    .post(URL, shallowTransaction)
    .then((response: AxiosResponse<AdditionDeduction>) => response.data)
}

const UpdateAdditionDeduction = async (transfer: AdditionDeduction): Promise<AdditionDeduction | undefined> => {
  const { FixClientData} = useFixServerClientData();
  const shallowTransaction = await FixClientData(transfer, true)
  return axios
    .put(`${URL}/${transfer.id}`, shallowTransaction)
    .then((response: AxiosResponse<Response<AdditionDeduction>>) => response.data)
    .then((response: Response<AdditionDeduction>) => response.data)
}

const DeleteAdditionDeduction = (transactionId: number): Promise<AdditionDeduction | undefined> => {
  return axios
    .delete(`${URL}/${transactionId}`)
    .then((response: AxiosResponse<Response<AdditionDeduction>>) => response.data)
    .then((response: Response<AdditionDeduction>) => response.data)
}

export { GetAdditionDeductionById, CreateAdditionDeduction, UpdateAdditionDeduction, MODEL_NAME, DeleteAdditionDeduction}
