import { PageLink, PageTitle } from '_metronic/layout/core'
import { Outlet, Route, Routes } from 'react-router-dom'
import Transactionform from './form'
import TransactionList from './list'

const transactionBreadCrumbs: Array<PageLink> = [
  {
    title: 'Transactions',
    path: '/transactions/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Demotion',
    path: '/transactions/demotion',
    isSeparator: false,
    isActive: true,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DemotionRouter = () => (
  <Routes>
    <Route
      element={
        <>
          <Outlet />
        </>
      }
    >
      <Route
        path=''
        element={
          <>
            <PageTitle breadcrumbs={transactionBreadCrumbs}>List</PageTitle>
            <TransactionList />
          </>
        }
      />
      <Route
        path=':id/edit'
        element={
          <>
            <PageTitle breadcrumbs={transactionBreadCrumbs}>Edit</PageTitle>
            <Transactionform />
          </>
        }
      />
      <Route
        path='new'
        element={
          <>
            <PageTitle breadcrumbs={transactionBreadCrumbs}>New</PageTitle>
            <Transactionform />
          </>
        }
      />
    </Route>
  </Routes>
)

export default DemotionRouter
