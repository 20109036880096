import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from './azkHelper';
import { ErrorMessage, FormikProps } from 'formik';
import { AZKHelper } from 'app/modules/components/azkHelper';
import { Col, Form } from 'react-bootstrap';
import AzkSelect from './azkSelect';
import moment from 'moment';
import { AsyncPaginate } from 'react-select-async-paginate';

type AzkazkFieldProps = {
  inputType: string,
  lableTitle: string
  modelName: string | '';
  data_set: string | '';
  compID: string;
  onChange?: Function | undefined;
  required?: boolean | false;
  fieldName?: string | '';
  formRef?: FormikProps<any>;
  value?: any
  valid?: boolean | true;
  min?: any
  max?: any
  isSubmit?: boolean | false;
  appView?: boolean | false;
};

type AzkOption = {
  value: any
  label: string
}

const AzkazkField: React.FC<AzkazkFieldProps> = ({ inputType, lableTitle, modelName,
  data_set, compID, onChange, required, fieldName, formRef, value, min, max, valid, isSubmit, appView = false }) => {

  const [isValid, setIsValid] = useState<boolean>(true);
  const [fieldChanged, setFieldChanged] = useState<boolean>(false);
  const [validationMsessage, setValidationMsessage] = useState<string>('');
  const [feildValue, setFeildValue] = useState<any>('');
  useEffect(() => {
    // if(inputType === AZKHelper.INPUT_CHECK_BOX_FIELD && (value === null || value === undefined)){
    //   value = false;
    //   setValidationMsessage(``)
    //   setIsValid(true)
    //   if (onChange) onChange(value, true)
    // }
    if (isSubmit) validateField(feildValue)
  }, [isSubmit]);

  const validateField = (newValue) => {
    setIsValid(true)
    if (required) {
      // if(inputType === AZKHelper.INPUT_CHECK_BOX_FIELD){
      //   setValidationMsessage(``)
      //   setIsValid(true)
      //   return true
      // }
      if (!newValue || newValue === '' || newValue === undefined || newValue === null) {
        setIsValid(false)
        setValidationMsessage('This field is required!')
        return false
      } else {
        if (inputType === AZKHelper.INPUT_TEXT_FIELD && min && newValue && newValue.trim().length < min) {
          setValidationMsessage(`This field is min length is ${min}!`)
          setIsValid(false)
          return false
        } else if (inputType === AZKHelper.INPUT_TEXT_FIELD && max && newValue && newValue.trim().length > max) {
          setValidationMsessage(`This field is max length is ${max}!`)
          setIsValid(false)
          return false
        }
        else {
          setValidationMsessage(``)
          setIsValid(true)
          return true
        }
      }
    } else {
      setIsValid(true)
      setValidationMsessage('')
      return true
    }
  };

  return (
    <>
      {inputType === AZKHelper.INPUT_TEXT_FIELD && <>
        <Col lg="4" md="4" sm="6" xs="12">
          <Form.Group className='mb-2'>
            <Form.Label className={`${required ? 'required' : ''}`}>
              {lableTitle}
            </Form.Label>
            <Form.Control
              required={required}
              className={`p-1 ${appView ? 'rounded-0' : ''}`}
              type='text'
              onBlur={(val) => {
                validateField(val.target.value)
              }}
              onChange={async (val) => {
                value = val.target.value
                const validField = await validateField(value)
                if (onChange) onChange(value, validField)
                if (fieldName) formRef?.setFieldValue(fieldName, value.target.value);
                setFeildValue(value)
              }}
            />
            <div className='text-danger'>
              {!isValid &&
                validationMsessage
              }
            </div>
          </Form.Group>
        </Col>
      </>}
      {inputType === AZKHelper.INPUT_NOMBER_FIELD && <>
        <Col lg="4" md="4" sm="6" xs="12">
          <Form.Group className='mb-2'>
            <Form.Label className={`${required ? 'required' : ''}`}>
              {lableTitle}
            </Form.Label>
            <Form.Control
              required={required}
              className={`p-1 ${appView ? 'rounded-0' : ''}`}
              type='number'
              onBlur={(val) => {
                validateField(val.target.value)
              }}
              onChange={async (val) => {
                value = val.target.value
                const validField = await validateField(value)
                if (onChange) onChange(value, validField)
                if (fieldName) formRef?.setFieldValue(fieldName, value.target.value);
                setFeildValue(value)
              }}
            />
            <div className='text-danger'>
              {!isValid &&
                validationMsessage
              }
            </div>
          </Form.Group>
        </Col>
      </>}
      {inputType === AZKHelper.INPUT_DATE_FIELD && <>
        <Col lg="4" md="4" sm="6" xs="12">
          <Form.Group className='mb-2'>
            <Form.Label className={`${required ? 'required' : ''}`}>
              {lableTitle}
            </Form.Label>
            <Form.Control
              required={required}
              className={`p-1 ${appView ? 'rounded-0' : ''}`}
              type='date'
              onBlur={(val) => {
                validateField(val.target.value)
              }}
              onChange={async (val) => {
                value = val.target.value
                const validField = await validateField(value)
                if (onChange) onChange(moment(value).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT), validField)
                if (fieldName) formRef?.setFieldValue(fieldName, moment(value).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT));
                setFeildValue(moment(value).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT))
              }}
            />
            <div className='text-danger'>
              {!isValid &&
                validationMsessage
              }
            </div>
          </Form.Group>
        </Col>
      </>}
      {inputType === AZKHelper.INPUT_DROPDOWN_FIELD && <>
        <Col lg="4" md="4" sm="6" xs="12">
          <Form.Group className='mb-2'>
            <Form.Label className={`${required ? 'required' : ''}`}>
              {lableTitle}
            </Form.Label>
            <AzkSelect
              compID="azk_temp"
              modelName={modelName}
              valueField="name"
              titleField="name"
              dataDomain=""
              allowEmpty={false}
              fieldName={fieldName}
              onChange={async (val) => {
                if (val !== null || fieldChanged) {
                  setFieldChanged(true)
                  value = val
                  const validField = await validateField(value)
                  if (onChange) onChange(value, validField)
                  if (fieldName) formRef?.setFieldValue(fieldName, value.target.value);
                  setFeildValue(value)
                }
              }}
              defaultValue={value}
              searchFields={['name']} />
            <div className='text-danger'>
              {!isValid &&
                validationMsessage
              }
            </div>
          </Form.Group>
        </Col>
      </>}
      {inputType === AZKHelper.INPUT_FREE_DROPDOWN_FIELD && <>
        <Col lg="4" md="4" sm="6" xs="12">
          <Form.Group className='mb-2'>
            <Form.Label className={`${required ? 'required' : ''}`}>
              {lableTitle}
            </Form.Label>
            <AsyncPaginate className='rounded-0 col-12'
              placeholder="Select an option"
              loadOptions={async (search, loadedOptions) => {
                const options: any[] = []
                const data = data_set.split(';')
                for (const item in data) {
                  options.push({ value: data[item], label: data[item] })
                }
                const filteredOptions = options.filter((option) =>
                  option.label.toString().includes(search.toLowerCase())
                );
                return {
                  options: filteredOptions,
                  hasMore: false,
                };
              }}
              onChange={async (val) => {
                if ((val !== null || fieldChanged) && val) {
                  console.log('---------val--------', val['value'])
                  setFieldChanged(true)
                  value = val['value']
                  const validField = await validateField(value)
                  if (onChange) onChange(value, validField)
                  if (fieldName) formRef?.setFieldValue(fieldName, value);
                  setFeildValue(value)

                }
              }}
              defaultValue={{ value: value, label: value }}/>
            <div className='text-danger'>
              {!isValid &&
                validationMsessage
              }
            </div>
          </Form.Group>
        </Col>
      </>}
      {inputType === AZKHelper.INPUT_CHECK_BOX_FIELD && <>
        <Col lg="4" md="4" sm="6" xs="12">
          <Form.Group className='mb-2'>
            <Form.Label className={`${required ? 'required' : ''}`}>
              {lableTitle}
            </Form.Label>
            <Form.Check
              required={required}
              className={`p-1 ${appView ? 'rounded-0' : ''}`}
              type='checkbox'
              onBlur={(val) => {
                validateField(val.target.value)
              }}
              onChange={async (val) => {
                value = val.target.checked
                const validField = await validateField(value)
                if (onChange) onChange(value, validField)
                if (fieldName) formRef?.setFieldValue(fieldName, value);
                setFeildValue(value)
              }}
              defaultChecked={false}
            />
            <div className='text-danger'>
              {!isValid &&
                validationMsessage
              }
            </div>
          </Form.Group>
        </Col>
      </>}
      {inputType === AZKHelper.INPUT_RADIO_BUTTON_FIELD && <>
        <Col lg="4" md="4" sm="6" xs="12">
          <Form.Group className='mb-2'>
            <Form.Label className={`${required ? 'required' : ''}`}>
              {lableTitle}
            </Form.Label>
            {data_set.split(';').map(item => {
              return <>
                <Col lg="12" md="12" sm="12" xs="12" style={{ marginLeft: '2.25rem' }}>
                  <Form.Check
                    required={required}
                    className={`p-1 ${appView ? 'rounded-0' : ''}`}
                    type='radio'
                    label={item}
                    radioGroup={lableTitle}
                    name={lableTitle}
                    onBlur={(val) => {
                      validateField(val.target.value)
                    }}
                    onChange={async (val) => {
                      value = item
                      const validField = await validateField(value)
                      if (onChange) onChange(value, validField)
                      if (fieldName) formRef?.setFieldValue(fieldName, value);
                      setFeildValue(value)
                    }}
                  />
                </Col></>
            })}
            <div className='text-danger'>
              {!isValid &&
                validationMsessage
              }
            </div>
          </Form.Group>
        </Col>
      </>}
    </>
  );
};

export default AzkazkField;