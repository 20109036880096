import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import azkSelect from "_azkatech/azkSelect"
import azkDataTable from "_azkatech/azkDataTable"
import { useNavigate } from 'react-router';
import { KTCard, KTIcon } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper';

import moment from 'moment';
import { AZKHelper } from 'app/modules/components/azkHelper';
import { CSVLink } from 'react-csv'
import ReactDOM from 'react-dom';
import { OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { AzkStatusRibbon } from '_azkatech/azkStatusRibbon';

const TransactionList = () => {
    const [listDataDomain, setListDataDomain] = useState('');
    const [searchTerm, setSearchTerm] = useState<string>('')
    const navigate = useNavigate()
    const MODEL_NAME = "az.trn.salary.structure"

    const [selectedExcelData, setSelectedExcelData] = useState<any[]>([]);
    const [pageLoaded, setPageLoaded] = useState<boolean>(false);
    const [dateFrom, setDateFrom] = useState<any>();
    const [dateTo, setDateTo] = useState<any>();
    // #region Transfer List as DataTable
    const TRN_list_LOCAL_STORAGE_FILTER_KEY = 'azk-trn-ss-list-filter-hr-lite'
    const fields = "'id', 'name', ('az_country_id',('name')), ('brand_id',('name')), ('location_id',('name')), 'as_effective_date', 'state_view', 'x_state'"
    const updateListDataDomain = (search, country, location, brand, state) => {
        let filter = {}
        filter['showFilters'] = showFilters
        let conds: string[] = [];
        if (country !== '' && country && country != 'undefined') {
            conds.push(`('az_country_id','=', ${country})`)
            if (handleChangeSelection) filter['az_country_id'] = country
        }
        if (country !== '' && country) {
            conds.push(`('az_country_id','=', ${country})`)
        }
        if (brand !== '' && brand && brand != 'undefined') {
            conds.push(`('brand_id','=', ${brand})`)
            if (handleChangeSelection) filter['brand_id'] = brand
        }
        if (location !== '' && location) {
            conds.push(`('location_id','=', ${location})`)
            if (handleChangeSelection) filter['location_id'] = location
        }
        if (state !== '' && state && state != 'undefined') {
            conds.push(`('x_state','ilike', '${state}')`)
            if (handleChangeSelection) filter['x_state'] = state
        }
        if (dateFrom) {
            conds.push(`('as_effective_date','>=', '${dateFrom}')`)
        }
        if (dateTo) {
            conds.push(`('as_effective_date','<=', '${dateTo}')`)
        }
        if (search !== '' && search && search != 'undefined') {
            if (handleChangeSelection) filter['search'] = search
            search = encodeURIComponent(search)
            conds.push(`'|', '|',('az_country_id','ilike', '${search}'), ('location_id','ilike', '${search}'), ('brand_id','ilike', '${search}')`)
        }
        const lsValue = JSON.stringify(filter)
        if (handleChangeSelection) localStorage.setItem(TRN_list_LOCAL_STORAGE_FILTER_KEY, lsValue)
        return conds.join(',');
    }
    const handleEditBtnClick = (id) => {
        navigate(`/salary-structure/records/${id}`, { state: { 'transactionId': id }, replace: true });
    }
    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            tech_name: 'name'
        },
        {
            name: 'Country',
            selector: row => row.az_country_id ? row.az_country_id.name : undefined,
            sortable: true,
            tech_name: 'az_country_id',
            cell: (d) => [(<>
                <OverlayTrigger 
                    placement='top'
                    overlay={
                        <Tooltip id='tooltip-user-name'>{d.az_country_id.name}</Tooltip>} children={
                            <Row>
                                {d.az_country_id.name}
                            </Row>
                        }></OverlayTrigger>
            </>)]
        },
        {
            name: 'Brand',
            selector: row => row.brand_id ? row.brand_id.name : undefined,
            sortable: true,
            tech_name: 'brand_id',
            cell: (d) => [(<>
                <OverlayTrigger 
                    placement='top'
                    overlay={
                        <Tooltip id='tooltip-user-name'>{d.brand_id.name}</Tooltip>} children={
                            <Row>
                                {d.brand_id.name}
                            </Row>
                        }></OverlayTrigger>
            </>)]
        },
        {
            name: 'Branch',
            selector: row => row.location_id ? row.location_id.name : undefined,
            sortable: true,
            tech_name: 'location_id',
            cell: (d) => [(<>
                <OverlayTrigger 
                    placement='top'
                    overlay={
                        <Tooltip id='tooltip-user-name'>{d.location_id.name}</Tooltip>} children={
                            <Row>
                                {d.location_id.name}
                            </Row>
                        }></OverlayTrigger>
            </>)]
        },
        {
            name: 'Effective Date',
            //  width: '10%',
            selector: row => row.as_effective_date ? row.as_effective_date : undefined,
            sortable: true,
            tech_name: 'as_effective_date',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {moment(d.as_effective_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                </Row>
            </>)]
        },
        {
            name: <> <span className='w-100 text-center'>Status</span> </>,
            selector: row => row.state_view ? row.state_view : undefined,
            sortable: true,
            tech_name: 'state_view',
            style: {
                display: 'block',
                'text-wrap': 'nowrap'
            },
            cell: (d) => [
                <AzkStatusRibbon current={d.x_state} modelName={'az.trn.salary.structure'} transactionId={d.id} currentName={d.state_view} />
            ]
        },
        // {
        //   name: "Director",
        //   selector: "director",
        //   sortable: true,
        //   cell: (d) => (
        //     <a href="https://google.com" target="_blank" className="dlink">
        //       {d.director}
        //     </a>
        //   )
        // },
        // {
        //   name: "Genres",
        //   selector: "genres",
        //   sortable: true,
        //   cell: (d) => <span>{d.genres.join(", ")}</span>
        // },
        {
            name: "Action",
            sortable: false,
            selector: undefined,
            cell: (d) => [
                // <span
                //   key={d.title}
                //   onClick={handleEditBtnClick.bind(this, d.id)}
                //   className="btn btn-primary"
                // ><KTIcon iconName='trash' className='fs-3' /></span>,
                <span key={d.id}
                    onClick={handleEditBtnClick.bind(this, d.id)}
                    className="btn btn-sm btn-icon btn-active-light-primary me-1"
                ><KTIcon iconName='pencil' className='fs-3' /></span>
            ]
        }
    ];

    const getDataToExport = async (data: any[]) => {
        let excelDataList: any[] = []
        if (data) {
            await data.forEach(row => {
                const object = builtExportObject(row)
                excelDataList.push(object)
            })
            setSelectedExcelData(excelDataList)
        }
    }

    const hitExport = (data: any[]) => {
        // if (data && data.length > 0) {
        //     const inputElement = document.querySelector('#export_data_excel') as HTMLInputElement
        //     inputElement.click()
        // }
        document.body.classList.remove('page-loading')
        if (data && data.length > 0) {
            const parentElement = document.createElement('div');
            const element = <div><CSVLink data={data} filename={'Transaction List ' + moment().format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)} id="export_data_excel" ></CSVLink></div>
            ReactDOM.render(element, parentElement);
            document.body.appendChild(parentElement)
            const inputElement = document.querySelector('#export_data_excel') as HTMLInputElement
            inputElement.click()
            document.body.removeChild(parentElement)
        }
    }

    const exportSelectedDataToExcel = async () => {
        document.body.classList.add('page-loading')
        hitExport(selectedExcelData)
    }

    const exportDataToExcel = async () => {
        let excelDataList: any[] = []
        document.body.classList.add('page-loading')
        await axios.get(`${API_URL}/${MODEL_NAME}?filters=[${listDataDomain}]&include_fields=[${fields}]&order='as_effective_date desc, name desc'`).then(async response => {
            const remoteData = response.data?.results ? response.data.results : []
            await remoteData.forEach(row => {
                const object = builtExportObject(row)
                excelDataList.push(object)
            })
            if (excelDataList && excelDataList.length > 0) {
                hitExport(excelDataList)
            }
        })
    }

    const builtExportObject = (row) => {
        const object = {}
        object['Name'] = row.name + '\n'
        object['Creation Date'] = moment(row.create_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)
        object['Effective Date'] = moment(row.as_effective_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)
        object['Country'] = row.az_country_id.name
        object['Branch'] = row.location_id.name
        object['Brand'] = row.brand_id.name
        object['Status'] = row.x_state_view
        return object
    }
    const table = azkDataTable({
        modelName: MODEL_NAME,
        fields: fields,
        dataDomain: listDataDomain,
        columns: columns,
        dataOrder: 'as_effective_date desc, name desc',
        exportFunction: getDataToExport,
        pageReady: pageLoaded
    })

    // #endregion Transfer List

    const fillFilterValues = async () => {
        const filterVals: string | null = localStorage.getItem(TRN_list_LOCAL_STORAGE_FILTER_KEY)
        if (filterVals) {
            const jsonObj = JSON.parse(filterVals)
            // setShowFilters(jsonObj['showFilters'])
            if (jsonObj['search']) await setSearchTerm(jsonObj['search'])
            if (jsonObj['az_country_id']) await setCountry(jsonObj['az_country_id'])
            if (jsonObj['brand_id']) await setBrandefaultValue(jsonObj['brand_id'])
            if (jsonObj['location_id']) await setLocation(jsonObj['location_id'])
            if (jsonObj['x_state']) await setState(jsonObj['x_state'])
            setListDataDomain(updateListDataDomain(jsonObj['search'], jsonObj['az_country_id'], jsonObj['location_id'], jsonObj['brand_id'], jsonObj['x_state']));
        }
        setTimeout(() => {
            setPageLoaded(true);
        }, 500)
    }
    useEffect(() => {
        document.body.classList.remove('page-loading')
        fillFilterValues()
    }, []);

    // #region Country Dropdown
    const [az_country, setCountry] = useState('');

    const handleCountryChange = (val) => {
        if (val) {
            setCountry(val);
            if (handleChangeSelection) {
                setLocationDomain(val ? `('country_id','=', ${val})` : '');
                setListDataDomain(updateListDataDomain(searchTerm, val, az_location, az_brand, az_state));
            }
        } else {
            setLocation('')
            setBrandefaultValue('')
        }
    }

    const az_country_select = azkSelect({
        compID: "az_country_select",
        modelName: "az.country",
        valueField: "id",
        titleField: "name",
        allowEmpty: true,
        isSearchable: true,
        defaultValue: az_country,
        onChange: handleCountryChange
    });
    // #endregion Country Dropdown

    // #region Company Dropdown
    const [az_location, setLocation] = useState('');
    const [locationDomain, setLocationDomain] = useState('');

    const handleLocationChange = (val) => {
        setLocation(val);
        if (val !== '') {
            if (handleChangeSelection) {
                setListDataDomain(updateListDataDomain(searchTerm, az_country, val, az_brand, az_state));
            }
        } else {
            // setBrandDomain('');
        }
    }

    const az_location_select = azkSelect({
        compID: "az_location_select",
        modelName: "az.branch",
        valueField: "id",
        titleField: "name",
        dataDomain: locationDomain,
        defaultValue: az_location,
        allowEmpty: true,
        onChange: handleLocationChange
    });
    // #endregion Company Dropdown

    // #region Brand Dropdown
    const [az_brand, setBrand] = useState('');
    const [brandDomain, setBrandDomain] = useState('');
    const [brandefaultValue, setBrandefaultValue] = useState('');

    const handleBrandChange = (val) => {
        console.log(val ? `('brand_id','=', ${val})` : '')
        setBrand(val);
        setBrandefaultValue(val)
        if (handleChangeSelection) {
            if (val) {
                if (az_country) {
                    setLocationDomain(`('country_id','=', ${az_country}),('brand_id','=', ${val})`);
                } else {
                    setLocationDomain(`('brand_id','=', ${val})`);
                }
            }
            setListDataDomain(updateListDataDomain(searchTerm, az_country, az_location, val, az_state));
            if (!val) {
                setLocation('')
            }
        }
    }

    const az_brand_select = azkSelect({
        compID: "az_brand_select",
        modelName: "az.brand",
        valueField: "id",
        titleField: "name",
        dataDomain: brandDomain,
        allowEmpty: true,
        onChange: handleBrandChange,
        defaultValue: brandefaultValue
    });
    // #endregion Brand Dropdown

    const [az_state, setState] = useState('');
    const handleStateChange = (option) => {
        setState(option);
        setListDataDomain(updateListDataDomain(searchTerm, az_country, az_location, az_brand, option));
    }

    const az_state_select = azkSelect({
        compID: "az_state_select",
        modelName: "az.transaction.status",
        valueField: "technical_name",
        dataDomain: `('basic_status', '=', 'true')`,
        titleField: "name",
        allowEmpty: true,
        isSearchable: true,
        defaultValue: az_state,
        onChange: handleStateChange
    });

    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [handleChangeSelection, setHandleChangeSelection] = useState<boolean>(false);
    function showFilter() {
        setShowFilters(!showFilters)
        setHandleChangeSelection(true)
    }
    return (
        <>
            <KTCard>
                <div className='card-header'>
                    <div className='card-title'>
                        {/* begin::Search */}
                        <div className='d-flex align-items-center position-relative my-1'>
                            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                            <input
                                type='text'
                                data-kt-user-table-filter='search'
                                className='form-control form-control-solid w-300px ps-14'
                                placeholder='Search ...'
                                value={searchTerm}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value)
                                    setListDataDomain(updateListDataDomain(e.target.value, az_country, az_location, az_brand, az_state))
                                }}
                            />
                        </div>
                        {/* end::Search */}
                    </div>
                    {/* begin::Card toolbar */}
                    <div className='card-toolbar'>
                        {/* begin::Group actions */}
                        {/* begin::Export */}
                        <button type='button' className='btn btn-light-primary me-3 btn-sm' onClick={() => showFilter()}>
                            <KTIcon iconName='filter-search' className='fs-2' />
                            Filter
                        </button>
                        {/* <CSVLink data={excelData} filename={'Transaction List ' + moment().format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)} id="export_data_excel" ></CSVLink> */}
                        <button type='button' onClick={exportSelectedDataToExcel} className={`btn btn-light-primary me-3 btn-sm ${selectedExcelData && selectedExcelData.length > 0 ? '' : 'd-none'}`}>
                            <KTIcon iconName='exit-up' className='fs-2' />
                            Export Selected
                        </button>
                        <button type='button' className={`btn btn-light-primary me-3 btn-sm ${selectedExcelData && selectedExcelData.length > 0 ? 'd-none' : ''}`} onClick={exportDataToExcel} >
                            <KTIcon iconName='exit-up' className='fs-2' />
                            Export
                        </button>
                        {/* end::Export */}
                        <button type='button' className='btn btn-light-primary me-3 btn-sm' onClick={() => navigate('/salary-structure/records/new')}>
                            <KTIcon iconName='plus' className='fs-2' />
                            Add
                        </button>
                        {/* end::Group actions */}
                    </div>
                    {/* end::Card toolbar */}
                </div>
            </KTCard>
            {showFilters &&
                <KTCard className='mt-3'>
                    <div className='card-body'>
                        <div className='row m-0'>
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-6 my-auto col-12 col-md-4'>Country</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {handleChangeSelection && (<>
                                    {az_country_select}
                                </>)}
                                {/* end::Input */}
                            </div>
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-6 my-auto col-12 col-md-4'>Brand</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {handleChangeSelection && (<>
                                    {az_brand_select}
                                </>)}
                                {/* end::Input */}
                            </div>
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-6 my-auto col-12 col-md-4'>Branch</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {handleChangeSelection && (<>
                                    {az_location_select}
                                </>)}
                                {/* end::Input */}
                            </div>
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='fw-bold fs-7 my-auto col-12 col-md-4'>Status</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {handleChangeSelection && (<>
                                    {az_state_select}
                                </>)}
                                {/* end::Input */}
                            </div>
                        </div>
                    </div>
                </KTCard >
            }
            <KTCard className='mt-3'>
                {pageLoaded && table}
            </KTCard>
        </>)
}

export default TransactionList;